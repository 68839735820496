import React from 'react';
import {Modal, ModalBody} from 'reactstrap';
import rocket from "../../../assets/images/rocket.svg";
import {connect} from "react-redux";
import {toggleSuccessApplyToJobModal} from "../../../redux/job/actions";
import {JOB_APPLY_LINK_TYPE_EXTERNAL, JOB_APPLY_LINK_TYPE_INTERNAL} from "../../../constants";

class ApplySuccessfulModal extends React.Component {

    render() {
        const {toggleSuccessApplyToJobModal, job} = this.props;

        return (
            <Modal isOpen={true} toggle={() => toggleSuccessApplyToJobModal()} size={"sm"} centered={true}
                   contentClassName={"b-r-20 bg-dark-gray"}>
                <ModalBody className={"d-flex flex-column text-center p-5"}>
                    <div className={"d-flex flex-column align-items-center px-lg-5"}>
                        <img src={rocket} alt={"referral-complete-icon"} width={128} height={128}
                             className={"object-contain"}/>
                        {job.apply_link_type === JOB_APPLY_LINK_TYPE_INTERNAL && (
                            <span className={"font-weight-bold f-25 m-t-40 mb-2"}>You did it!</span>
                        )}
                        {job.apply_link_type === JOB_APPLY_LINK_TYPE_EXTERNAL && (
                            <>
                                <span className={"font-weight-bold f-25 m-t-40 mb-2"}>You did it, <b>PLEASE WAIT</b>!</span>
                                <span>We are redirect you to the application form!</span>
                                <span>If not redirected, <b><a href={job.apply_link}>click here</a></b>!</span>
                                <br/>
                            </>
                        )}
                        <span className={"mb-5"}>If hired you’ll get the bounty of
                            <span className={"purple-text"}> {job.currency} {job.bounty}</span>
                        </span>
                        <u><a href={"/"} className={"text-info"}>How does bounty system works?</a></u>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    toggleSuccessApplyToJobModal,
};

const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ApplySuccessfulModal)
