import React, {Fragment, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter, Switch, Route} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import store from './store/index'
import App from './App';
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import {routes} from './router/route'
import ConfigDB from './config'
import {useHistory} from "react-router";

const Root = (props) => {
    const [anim, setAnim] = useState("");
    const animation = ConfigDB.data.router_animation || 'fade'
    const abortController = new AbortController();
    useEffect(() => {
        setAnim(animation)
        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;
        return function cleanup() {
            abortController.abort();
        }
    }, [abortController, animation]);

    function ScrollToTop() {
        const history = useHistory()
        useEffect(() => {
            const listenToPageChange = history.listen((location, action) => {
                if (action !== 'POP') {
                    window.scrollTo(0, 0)
                }
            })
            return () => listenToPageChange()
        }, [])
        return (null)
    }

    return (
        <Fragment>
            <Provider store={store}>
                <BrowserRouter basename={`/`}>
                    <ScrollToTop />
                    <Switch>
                        <Fragment>
                            <App>
                                <TransitionGroup>
                                    {routes.map(({path, Component, params}) => (
                                        <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                                            {({match}) => (
                                                <CSSTransition
                                                    in={match != null}
                                                    timeout={0}
                                                    // classNames={anim}
                                                    unmountOnExit
                                                >
                                                   <Component routeParams={match ? match.params : {}} {...params}/>
                                                </CSSTransition>
                                            )}
                                        </Route>
                                    ))}
                                </TransitionGroup>
                            </App>
                        </Fragment>
                    </Switch>
                </BrowserRouter>
            </Provider>
        </Fragment>
    )
}
ReactDOM.render(<Root/>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
