import {
    DECREMENT_JOB_APPLICANTS_LIST_PAGE,
    INCREMENT_JOB_APPLICANTS_LIST_PAGE,
    LOAD_JOB_APPLICANTS,
    RESET_JOB_APPLICANTS_LIST,
    SELECT_JOB_TO_SEARCH_APPLICANTS,
    TOGGLE_APPLICATION_FORM_MODAL,
    TOGGLE_APPLICATION_UPDATE_MODAL,
    UNSELECT_JOB_TO_SEARCH_APPLICANTS,
    GET_JOB_APPLICATION_DETAILS,
    UPDATE_JOB_APPLICATION,
    SEARCH_COMPANY_TO_SEARCH_APPLICANTS,
    SELECT_COMPANY_TO_SEARCH_APPLICANTS,
    UNSELECT_COMPANY_TO_SEARCH_APPLICANTS,
    LOAD_FULL_JOBS_LIST_TO_SEARCH_APPLICANTS,
    SET_SORTING_CONDITION_FOR_JOB_APPLICATIONS
} from "../action_types";
import {toast} from "react-toastify";

const initial_state = {
    list: [],
    loading: false,
    page: 1,
    sorting: {
        field: null,
        direction: null
    },
    total_pages: 0,
    total_results: 0,
    error: false,
    filters: {
        job: {
            options: [],
            searching: false,
            selected_jobs: []
        },
        company: {
            options: [],
            searching: false,
            selected_companies: []
        }
    },
    selected_application_form: {
        opened: false,
        data: null,
        person: null
    },
    update_application_modal: {
        opened: false,
        data: null
    }
};

export default (state = initial_state, action) => {
    let page;
    let list;
    let selectedJobsToSearchForApplicants;
    let selectedCompaniesToSearchForApplicants;
    let selectedApplicationForm;
    let updateApplicationForm;

    switch (action.type) {
        case LOAD_JOB_APPLICANTS:
            return {...state, loading: true};
        case LOAD_JOB_APPLICANTS.concat("_SUCCESS"):
            list = [...state.list];

            return {
                ...state,
                loading: false,
                total_pages: action.payload.meta.total_pages,
                total_results: action.payload.meta.total_results,
                list: list.concat(action.payload.data),
            };
        case LOAD_JOB_APPLICANTS.concat("_FAILURE"):
            return {
                ...state,
                error: true,
                loading: false
            }
        case INCREMENT_JOB_APPLICANTS_LIST_PAGE:
            page = state.page;
            if (page === state.total_pages) {
                return {...state};
            }

            page++;

            return {...state, page: page};
        case DECREMENT_JOB_APPLICANTS_LIST_PAGE:
            page = state.page;
            if (page === 1) {
                return {...state};
            }

            page--;

            return {...state, page: page};
        case RESET_JOB_APPLICANTS_LIST:
            return {...state, page: 1, list: [], total_pages: 0, total_results: 0};
        case LOAD_FULL_JOBS_LIST_TO_SEARCH_APPLICANTS:
            return {...state, filters: {...state.filters, job: {...state.filters.job, searching: true}}};
        case LOAD_FULL_JOBS_LIST_TO_SEARCH_APPLICANTS.concat("_SUCCESS"):
            return {
                ...state,
                filters: {...state.filters, job: {...state.filters.job, options: action.payload.data, searching: false}}
            };
        case SELECT_JOB_TO_SEARCH_APPLICANTS:
            selectedJobsToSearchForApplicants = [action.payload];
            // selectedJobsToSearchForApplicants.push(action.payload);

            return {
                ...state,
                filters: {
                    ...state.filters,
                    job: {...state.filters.job, selected_jobs: selectedJobsToSearchForApplicants}
                }
            };
        case UNSELECT_JOB_TO_SEARCH_APPLICANTS:
            selectedJobsToSearchForApplicants = state.filters.job.selected_jobs;
            selectedJobsToSearchForApplicants.splice(action.payload, 1);

            return {
                ...state,
                filters: {
                    ...state.filters,
                    job: {...state.filters.job, selected_jobs: selectedJobsToSearchForApplicants}
                }
            };
        case SEARCH_COMPANY_TO_SEARCH_APPLICANTS:
            return {...state, filters: {...state.filters, company: {...state.filters.company, searching: true}}};
        case SEARCH_COMPANY_TO_SEARCH_APPLICANTS.concat("_SUCCESS"):
            return {
                ...state,
                filters: {...state.filters, company: {...state.filters.company, options: action.payload.data, searching: false}}
            };
        case SEARCH_COMPANY_TO_SEARCH_APPLICANTS.concat("_FAILURE"):
            return {...state, filters: {...state.filters, company: {...state.filters.company, searching: false}}};
        case SELECT_COMPANY_TO_SEARCH_APPLICANTS:
            selectedCompaniesToSearchForApplicants = state.filters.company.selected_companies;
            selectedCompaniesToSearchForApplicants.push(action.payload);

            return {
                ...state,
                filters: {
                    ...state.filters,
                    company: {...state.filters.company, selected_companies: selectedCompaniesToSearchForApplicants}
                }
            };
        case UNSELECT_COMPANY_TO_SEARCH_APPLICANTS:
            selectedCompaniesToSearchForApplicants = state.filters.company.selected_companies;
            selectedCompaniesToSearchForApplicants.splice(action.payload, 1);

            return {
                ...state,
                filters: {
                    ...state.filters,
                    job: {
                        ...state.filters.job,
                        selected_jobs: []
                    },
                    company: {
                        ...state.filters.company,
                        selected_companies: selectedCompaniesToSearchForApplicants
                    }
                }
            };
        case TOGGLE_APPLICATION_FORM_MODAL:
            selectedApplicationForm = state.selected_application_form;
            let person = null;
            let data = null;

            if (!selectedApplicationForm.opened) {
                data = action.payload;
                person = action.payload.person;
            }

            return {
                ...state,
                selected_application_form: {
                    ...selectedApplicationForm,
                    opened: selectedApplicationForm.opened === false,
                    person: person,
                    data: data
                }
            };
        case TOGGLE_APPLICATION_UPDATE_MODAL:
            updateApplicationForm = state.update_application_modal;

            return {
                ...state,
                update_application_modal: {
                    ...updateApplicationForm,
                    opened: updateApplicationForm.opened === false,
                    data: action.payload
                }
            };
        case UPDATE_JOB_APPLICATION.concat("_SUCCESS"):
            const newApplication = action.payload.data;
            list = state.list;
            updateApplicationForm = state.update_application_modal;
            toast.success("Applicant Saved");

            for (var i = 0; i < list.length; i++) {
                if(list[i].id == newApplication.id){
                    list[i] = newApplication;

                    console.log("newApplication", newApplication);
                }
            }

            return {
                ...state,
                list: [...list],
                update_application_modal: {
                    ...updateApplicationForm,
                    opened: updateApplicationForm.opened === false,
                }
            };
        case GET_JOB_APPLICATION_DETAILS:
            selectedApplicationForm = state.selected_application_form;

            return {
                ...state,
                selected_application_form: {
                    ...selectedApplicationForm,
                    loading: true
                }
            };
        case GET_JOB_APPLICATION_DETAILS.concat("_SUCCESS"):
            selectedApplicationForm = state.selected_application_form;

            return {
                ...state,
                selected_application_form: {
                    ...selectedApplicationForm,
                    loading: false,
                    person: action.payload.data,
                }
            };
        case SET_SORTING_CONDITION_FOR_JOB_APPLICATIONS:
            return {...state, sorting: action.payload};
        default:
            return {...state};
    }
}
