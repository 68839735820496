import React from 'react';
import {Button, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {connect} from "react-redux";
import {
    deleteEmail,
    deleteLocalEmail,
    saveCommunityEmail,
    saveContactedEmail,
    saveNotContactedMemberEmail,
    updateCampaignEmailOrder,
} from "../../../redux/campaign/actions";
import {
    CAMPAIGN_EMAIL_PLACEHOLDER_COMPANY_NAME,
    CAMPAIGN_EMAIL_PLACEHOLDER_SIGNATURE,
    CAMPAIGN_EMAIL_TYPE_COMMUNITY,
    CAMPAIGN_EMAIL_TYPE_CONTACTED_MEMBER,
    CAMPAIGN_EMAIL_TYPE_NOT_CONTACTED_MEMBER,
    CAMPAIGN_STATUS_MESSAGES_SENT,
    CAMPAIGN_STATUS_SENT_TO_MAIL_MARKETING_TOOL,
} from "../../../constants";

class CampaignEmail extends React.Component {
    constructor(props) {
        super(props);
        this.reactQuillRef = null

        this.state = {
            has_errors: false,
            is_email_open: this.props.index <= 0 || this.props.email.temp_id, // Only the first email is open, the others are collapsed
            preview_modal_open: false,
            toggleEmailIsOpenState: () => {
                this.setState({
                    is_email_open: !this.state.is_email_open
                })
            },
            togglePreviewEmail: () => {
                this.setState({
                    preview_modal_open: !this.state.preview_modal_open
                })
            }
        }
    }

    componentDidMount() {
        const {email} = this.props;
        const {subject, body} = email;
        this.setState({
            has_errors: subject === "" || body === "",
        })
    }

    placeholder(quillEditor, value) {
        const{companyData, placeholders} = this.props;

        if(value) {
            switch (value) {
                case CAMPAIGN_EMAIL_PLACEHOLDER_COMPANY_NAME:
                    value = companyData.name;
                    break;
                case CAMPAIGN_EMAIL_PLACEHOLDER_SIGNATURE:
                    value = `{{${CAMPAIGN_EMAIL_PLACEHOLDER_SIGNATURE}}}`
                    break;
                default:
                    value = placeholders.filter(placeholder => placeholder.name === value)[0].token
                    break;
            }

            const cursorPosition = quillEditor.getSelection().index;
            quillEditor.insertText(cursorPosition, value);
            quillEditor.setSelection(cursorPosition + value.length);
        }
    }

    modules = {
        toolbar: {
            container:
                [
                    [{'placeholder': [CAMPAIGN_EMAIL_PLACEHOLDER_COMPANY_NAME, CAMPAIGN_EMAIL_PLACEHOLDER_SIGNATURE, ...this.props.placeholders.map(placeholder => placeholder.name)]}],
                    [{'header': [1, 2, 3, 4, 5, 6, false]}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'color': []}, {'background': []}],
                    [{'align': []}],
                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                    ['link', 'image'],
                    ['clean']
                ],
            handlers: {
                placeholder: (value) => this.placeholder(
                    this.reactQuillRef.getEditor(),
                    value,
                )
            }
        }
    }

    onEmailFormChange(value, fieldName) {
        const {email, index, type} = this.props;

        // Make QuillJs input empty
        value = value.replace("<p><br></p>", "");
        email[fieldName] = value;

        let hasErrors = Object.entries(email).some(([field, value]) => {
            return value === null || value === "";
        });

        this.setState({
            has_errors: hasErrors,
        });

        if ((value.trim().length) && hasErrors === false) {
            email.order = index + 1;
            this.updateEmail(email, type);
        }
    }

    handleOrderChange(direction) {
        const {emails, index, updateCampaignEmailOrder} = this.props;
        let increment = 0;
        if(direction === 'up') {
            increment = -1;
        }
        else {
            increment = 1;
        }

        const tempEmail = emails[index];
        emails[index] = emails[index + increment]
        emails[index + increment] = tempEmail;
        let emailIdsByPosition = emails.filter((email) => email.id).map((email) => {
            return email.id;
        })
        updateCampaignEmailOrder(emailIdsByPosition);
    }

    updateTimeout = null;

    updateEmail(email, type) {
        const {saveCommunityEmail, saveContactedEmail, saveNotContactedMemberEmail} = this.props;

        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
        }

        this.updateTimeout = setTimeout(() => {
            switch (type) {
                case CAMPAIGN_EMAIL_TYPE_COMMUNITY:
                    saveCommunityEmail(email);
                    return;
                case CAMPAIGN_EMAIL_TYPE_CONTACTED_MEMBER:
                    saveContactedEmail(email);
                    return;
                case CAMPAIGN_EMAIL_TYPE_NOT_CONTACTED_MEMBER:
                    saveNotContactedMemberEmail(email);
                    return;
                default:
                    return;
            }
        }, 500)
    }


    printEmailContent() {
        const {email, placeholders} = this.props;
        let parsedEmail = email.body;
        placeholders.forEach(placeholder => {
            // Skip placeholders in double quotes so it doesn't mess with link elements
            if(!parsedEmail.includes(`"${placeholder.token}"`)) {
                parsedEmail = parsedEmail.replaceAll(placeholder.token, `<b class="grey-background"><i>${placeholder.token}</i></b>`);
            }
        })
        return (<div dangerouslySetInnerHTML={{__html: parsedEmail}} />)
    }

    renderPreviewEmailModal() {
        const {email, index} = this.props;
        const {preview_modal_open, togglePreviewEmail} = this.state;

        return (
            <Modal isOpen={preview_modal_open}>
                <ModalHeader toggle={() => togglePreviewEmail()}>
                    Email-{index + 1} Preview
                </ModalHeader>
                <ModalBody>
                    <div className={"border p-2"} style={{minHeight: "130px"}}>
                        {email.body && this.printEmailContent()}
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    canShowEditOptions() {
        const {selected_campaign} = this.props;

        return (selected_campaign.status != CAMPAIGN_STATUS_SENT_TO_MAIL_MARKETING_TOOL && selected_campaign.status != CAMPAIGN_STATUS_MESSAGES_SENT)
    }

    render() {
        const {key, index, email, deleteEmail, type, deleteLocalEmail, isFirst, isLast} = this.props;
        const {has_errors, is_email_open, toggleEmailIsOpenState, togglePreviewEmail} = this.state;

        return (
            <div key={key} className={"pb-0 px-4 mb-3"}>
                {this.renderPreviewEmailModal()}
                <hr/>
                <div className={"d-flex justify-content-between align-items-center"}>
                <div className={"d-flex align-items-center"}>
                    <h5 className={"font-weight-bold mb-0"}>- Email {index + 1}</h5>
                    <Button color={"white"} className={"purple-text"} onClick={() => {
                        togglePreviewEmail();
                    } }>Preview</Button>
                </div>
                {this.canShowEditOptions() && (<div className={"d-flex align-items-center"}>
                    <Button color={"white"} className={"text-danger"} onClick={() => {
                        if (window.confirm("Are you sure you want to delete this email?")) {
                            if(!email.id) {
                                deleteLocalEmail(email.temp_id, type);
                                return;
                            }
                            deleteEmail(email.id, type);
                        }
                    }}>Delete email</Button>
                    {email.id && <div className={"d-flex flex-column"}>
                        {!isFirst && <Button className={"mb-1"} color={"white"} onClick={() => {
                            this.handleOrderChange("up")
                        }}><i className="fa fa-chevron-up"/></Button>}

                        {!isLast && <Button color={"white"} onClick={() => {
                            this.handleOrderChange("down")
                        }}><i className="fa fa-chevron-down"/></Button>}

                    </div>}
                    <Button color={"white"} className={"purple-text"} onClick={() => {
                        toggleEmailIsOpenState();
                    }}>
                        {is_email_open ? "Collapse" : "Open"}
                    </Button>
                </div>)}
                </div>
                <hr/>
                {this.canShowEditOptions() && is_email_open &&
                (<form className={`needs-validation ${has_errors ? "was-validated" : ""}`}>
                    <div>
                        <Label className="d-block">
                            Subject:
                        </Label>

                        <div className="faq-form m-b-10">
                            <Input className="form-control" type={"text"}
                                   required
                                   defaultValue={email.subject}
                                   onChange={(e) => this.onEmailFormChange(e.target.value, "subject")}
                                   placeholder={"Subject"}/>
                        </div>
                    </div>
                    <div>
                        <Label className="d-block">
                            Body:
                        </Label>

                        <div className="faq-form m-b-10">
                            <ReactQuill className={`email-body-input-control ${has_errors ? "invalid" : ""}`}
                                        ref={(el) => { this.reactQuillRef = el }}
                                        defaultValue={email.body}
                                        onChange={(value, delta, source) => {
                                            if(source === "api") {
                                                return;
                                            }
                                            this.onEmailFormChange(value, "body")
                                        }}
                                        modules={this.modules}/>
                        </div>
                    </div>
                </form>)}
            </div>
        )
    }
}

const mapDispatchToProps = {
    saveCommunityEmail,
    saveContactedEmail,
    saveNotContactedMemberEmail,
    deleteLocalEmail,
    deleteEmail,
    updateCampaignEmailOrder,
}

const mapStateToProps = (state, ownProps) => ({
    selected_campaign: state.campaign.selected.campaign,
    companyData: state.campaign.selected.campaign.company,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignEmail)