import React, {Fragment, useRef} from "react";
import {Badge, Col, Container, Row} from "reactstrap";
import {connect} from "react-redux";
import MapDistributionReport from "../dashboard/components/map_distribution";
import CommunityTotalsReport from "../dashboard/components/community_totals";
import {
    clearSelectedNetwork,
    clearSelectedReport,
    loadGlobalCommunityTotals,
    loadGlobalMapDistribution
} from "../../redux/dashboard/actions";
import DiscoverImage from "../../assets/images/discover_image.png";
import ConnectImage from "../../assets/images/connect_image.png";
import SelectImage from "../../assets/images/select_image.png";
import {
    loadCommunities,
    resetCommunitiesPage,
} from "../../redux/community/actions";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {useHistory} from "react-router";
import {resetAllTopicFilters, searchTopic, selectTopicToFilter} from "../../redux/filters/actions";

class LandingPage extends React.Component {
    loadGlobalInsights() {
        const {
            clearSelectedReport,
            clearSelectedNetwork,
            loadGlobalMapDistribution,
            loadGlobalCommunityTotals
        } = this.props;

        clearSelectedReport();
        clearSelectedNetwork();
        loadGlobalMapDistribution();
        loadGlobalCommunityTotals();
    }

    componentDidMount() {
        this.loadGlobalInsights();
    }

    render() {
        const {
            loadCommunities,
            searchTopic,
            selectTopicToFilter,
            resetAllTopicFilters,
            topicFilters,
            resetCommunitiesPage,
            topics
        } = this.props;
        const {searching, options} = topicFilters;

        return (
            <Fragment>
                <div className={"m-lg-n5"}>
                    <Container fluid={true} className={"bg-blue pb-lg-0 px-lg-5 pt-lg-5"}>
                        <Row className={"align-items-center p-lg-5"}>
                            <Col lg={"6"} md={"12"} className="main-header p-lg-5">
                                <div className={"mb-3"}>
                                    <h1 className={"font-weight-bold yellow-text"}>The world’s most engaged communities,
                                        Ranked.</h1>
                                    <h6 className={"text-white"}>We use AI and Big Data to map & rank the world most
                                        engaged engaged communities to help you build tailored, long lasting, authentic
                                        relationships with your customers and stakeholders</h6>
                                </div>
                                <div className={"landing-search form-group"}>
                                    <TypeaheadComponent searching={searching}
                                                        searchTopic={searchTopic}
                                                        options={options}
                                                        resetAllTopicFilters={resetAllTopicFilters}
                                                        selectTopicToFilter={(selectedTopic) => {
                                                            selectTopicToFilter(selectedTopic);
                                                            resetCommunitiesPage();
                                                        }}
                                                        loadCommunities={loadCommunities}/>
                                </div>
                                <div className={"d-flex flex-wrap"}>
                                    {topics && topics.map((topic, key) => {
                                        return (<BadgeComponent key={key}
                                                                topic={topic}
                                                                resetAllTopicFilters={resetAllTopicFilters}
                                                                selectTopicToFilter={selectTopicToFilter}
                                                                loadCommunities={loadCommunities}/>)
                                    })}
                                    <span className={"text-white pt-1"}>and <strong>68.000</strong> more...</span>
                                </div>
                            </Col>
                            <Col lg={"6"} md={"12"} className={"d-flex justify-content-center align-items-center p-3"}>
                                <div className={"w-100"}>
                                    <iframe className={"p-2 bg-light"} width={"100%"} style={{minHeight: "315px"}}
                                            src="https://www.youtube.com/embed/1OOYJrKISuc"
                                            title="Discover The Community Atlas" frameBorder="0"
                                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container fluid={true} style={{paddingTop: "10px"}}>
                    <Row className={"pt-5"}>
                        <Col sm={"12"} md={"4"} className={"d-flex flex-column justify-content-end p-4"}>
                            <img src={DiscoverImage} alt={"discover"} width={"250px"} className={"m-auto"}/>
                            <h2 className={"text-uppercase font-weight-bold text-center orange-text"}>Discover</h2>
                            <span className={"blue-text"}>Explore how 364000 communities are shaping the geography on over 180 countries, on over 68000 topics of interest, on multiple online platforms and much more.</span>
                        </Col>
                        <Col sm={"12"} md={"4"} className={"d-flex flex-column justify-content-end p-4"}>
                            <img src={SelectImage} alt={"select"} width={"250px"} className={"m-auto"}/>
                            <h2 className={"text-uppercase font-weight-bold text-center orange-text"}>Select</h2>
                            <span className={"blue-text"}>Explore in depths community profiles, and find the most active ones through our engagement rankings.</span>
                        </Col>
                        <Col sm={"12"} md={"4"} className={"d-flex flex-column justify-content-end p-4"}>
                            <img src={ConnectImage} alt={"connect"} width={"250px"} className={"m-auto"}/>
                            <h2 className={"text-uppercase font-weight-bold text-center orange-text"}>Connect</h2>
                            <span className={"blue-text"}>Get in touch with hundreds of community leaders with a couple of clicks with our contact automation service so you can focus on what really matters.</span>
                        </Col>
                    </Row>
                </Container>
                <Container fluid={true} style={{paddingTop: "10px"}}>
                    <Row>
                        <Col className={"pt-5 pb-4"}>
                            <h5 className={"dark-blue-text text-center"}>We tracked communities in more than <span className={"font-weight-bold orange-text"}>180 countries</span></h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MapDistributionReport mapId={"landing-map"}/>
                        </Col>
                    </Row>

                    <Row className={'mt-5'}>
                        <Col>
                            <CommunityTotalsReport/>
                        </Col>
                    </Row>
                </Container>
            </Fragment>)
    }
}

const mapStateToProps = (state, ownProps) => ({
    topicFilters: state.filters.topic_filters,
    topics: state.community.landing_page_topics.list,
});

const mapDispatchToProps = {
    clearSelectedReport,
    clearSelectedNetwork,
    loadGlobalMapDistribution,
    loadGlobalCommunityTotals,
    searchTopic,
    selectTopicToFilter,
    resetAllTopicFilters,
    resetCommunitiesPage,
    loadCommunities
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingPage)

const TypeaheadComponent = (props) => {
    const ref = useRef();
    const history = useHistory();

    return (
        <Fragment>
            <AsyncTypeahead
                id="search-for-topic"
                isLoading={props.searching}
                labelKey="name"
                minLength={3}
                onSearch={props.searchTopic}
                options={props.options}
                placeholder="Start typing to explore thousands of communities"
                renderMenuItemChildren={(option, props) => {
                    return (
                        <Fragment>
                            <span>{option.name}</span>
                        </Fragment>
                    )
                }}
                onChange={(selected) => {
                    if (selected.length > 0) {
                        props.resetAllTopicFilters();
                        props.selectTopicToFilter(selected[0]);
                        props.loadCommunities();
                        history.push("/directory?kf=1"); // kf query parameter is to keep the filters for topic filter component
                    }

                    ref.current.clear();
                }}
                ref={ref}
            />
        </Fragment>
    );
};

const BadgeComponent = (props) => {
    const history = useHistory();
    return (
        <Badge onClick={() => {
            props.resetAllTopicFilters();
            props.selectTopicToFilter(props.topic);
            props.loadCommunities();
            history.push("/directory?kf=1") // kf query parameter is to keep the filters for topic filter component
        }}
               color={"big-grey pointer"}>{props.topic.name}</Badge>
    )
}