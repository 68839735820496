import React from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import {Chart} from "react-google-charts";

class CategoryDistributionReport extends React.Component {
    buildCategoriesListForPieChart() {
        const {report} = this.props;

        var chartData = [];

        report.forEach(function (category) {
            chartData.push([category.n, category.p]);
        });

        return chartData;
    }

    render() {
        const {report} = this.props;

        if (!report) {
            return null;
        }

        var chartData = [["Category", "Percentage"]].concat(this.buildCategoriesListForPieChart());

        return (
            <Card>
                <CardHeader>
                    <h5>
                        Categories Distribution
                    </h5>
                </CardHeader>
                <CardBody className="chart-block">
                    <div className="chart-overflow" id="pie-chart1">
                        <Chart
                            width={"100%"}
                            height={"380px"}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={chartData}
                            options={{
                                legend: 'none',
                                pieSliceText: 'label'
                            }}
                        />
                    </div>
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    report: state.dashboard.insights.category_distribution.data
});

export default connect(
    mapStateToProps,
    null
)(CategoryDistributionReport)
