import React from 'react';
import {Button, Card, CardBody, CardHeader, Container, Label, Modal, ModalBody} from 'reactstrap'
import {connect} from "react-redux";

import {subscribeToJobBountiesViaCTA, toggleSubscribeToJobsModal} from '../../../redux/job/actions';
import IdeaImage from "../../../assets/images/idea.svg";
import IdeaEnvelopImage from "../../../assets/images/idea_envelop.svg";
import {PRIVACY_POLICY_LINK, TOS_LINK} from "../../../constants";
import {isEmail} from "../../../utils";

class SubscribeJobBountiesCardCTA extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subscriber: {
                full_name: '',
                email: '',
                accepted_term_and_conditions: false
            },
            toggleAcceptedTermsOfUse: () => {
                this.setState({
                    subscriber: {
                        ...this.state.subscriber,
                        accepted_term_and_conditions: !this.state.subscriber.accepted_term_and_conditions
                    },
                });
            },
        };
    }

    renderModal() {
        const {subscriber, toggleAcceptedTermsOfUse} = this.state;
        const {subscribeToJobBountiesViaCTA, toggleSubscribeToJobsModal} = this.props;

        return (
            <Modal isOpen={true} size={"lg"} toggle={toggleSubscribeToJobsModal} centered={true}
                   contentClassName={"b-r-20 bg-dark-gray"}>
                <ModalBody>
                    <Container className={"d-flex flex-column flex-sm-row p-3"}>
                        <div className={"p-4 d-flex justify-content-center"}>
                            <img src={IdeaEnvelopImage} width={100}/>
                        </div>
                        <div className={"d-flex flex-column justify-content-center align-items-center align-items-sm-start"}>
                            <h4 className={"font-weight-extra-bold mb-2"}>Get fresh bounties</h4>
                            <span className={"mb-2"}>Get immediate update when new bounties are posted!</span>

                            <div className={"d-flex mb-2"}>
                                <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder="Name and surname"
                                    defaultValue={subscriber.full_name}
                                    onChange={(e) => {
                                        this.setState({
                                            "subscriber": {
                                                ...subscriber,
                                                full_name: e.target.value
                                            }
                                        });
                                    }}
                                />
                                <input
                                    type="email"
                                    className={"form-control ml-2"}
                                    placeholder="Your email"
                                    defaultValue={subscriber.email}
                                    onChange={(e) => {
                                        this.setState({
                                            "subscriber": {
                                                ...subscriber,
                                                email: e.target.value
                                            }
                                        });
                                    }}
                                />
                            </div>

                            <div className="faq-form d-flex mb-2">
                                <div id="terms-of-use"
                                     className={"size-20 checkbox-container " + (subscriber.accepted_term_and_conditions ? "active full" : "")}
                                     onClick={() => {
                                         toggleAcceptedTermsOfUse();
                                     }}>
                                    <i className={"fa fa-check"}/>
                                </div>
                                <Label htmlFor="terms-of-use"
                                       className={`ml-2 mb-0 ${!subscriber.accepted_term_and_conditions ? "text-danger" : ""}`}>
                                    You agree to the&nbsp;
                                    <u><a href={TOS_LINK} target={"_blank"} rel={"noopener noreferrer"}
                                          className={"text-info"}>terms of use</a></u> and&nbsp;
                                    <u><a href={PRIVACY_POLICY_LINK} target={"_blank"} rel={"noopener noreferrer"}
                                          className={"text-info"}>privacy policy</a></u>
                                </Label>
                            </div>

                            <Button color={"green"} className={"full-width"} onClick={() => {
                                if (
                                    !subscriber.full_name.trim() ||
                                    !isEmail(subscriber.email.trim()) ||
                                    !subscriber.accepted_term_and_conditions
                                ) {
                                    return;
                                }

                                subscribeToJobBountiesViaCTA(subscriber);
                            }}>
                                <b>NOTIFY ME OF NEW BOUNTIES</b>
                            </Button>
                        </div>
                    </Container>
                </ModalBody>
            </Modal>
        );
    }

    render() {
        const {toggleSubscribeToJobsModal, subscribe} = this.props;

        return (
            <>
                {subscribe.modal_open && this.renderModal()}

                <Card>
                    <CardHeader className={"text-center p-2 pt-4 border-bottom-0"}>
                        <img src={IdeaImage} width="70px"/>
                        <h5>Bounty newsletter</h5>
                    </CardHeader>
                    <CardBody className={"d-flex flex-column pt-1 pb-4"}>
                        <span className={"mb-3"}>Get immediate notification whenever a new job bounty is added to the community. </span>
                        <Button color={"green"} onClick={toggleSubscribeToJobsModal}>NOTIFY ME</Button>
                    </CardBody>
                </Card>
            </>
        );
    }
}

const mapDispatchToProps = {
    subscribeToJobBountiesViaCTA,
    toggleSubscribeToJobsModal
};

const mapStateToProps = (state, ownProps) => ({
    subscribe: state.job.subscribe
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeJobBountiesCardCTA)

