import React from "react";
import {FormGroup, Form, Input} from "reactstrap";
import {connect} from "react-redux";
import {
    loadCommunities,
    setFreeSearchForCommunity,
} from '../../../redux/community/actions';
import {Redirect} from "react-router-dom";

class GlobalSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search_for_community: false,
            setSearchForCommunity: (value) => {
                this.setState({
                    search_for_community: value,
                })
            },
            searchValue: "",
            setSearchValue: (value) => {
                this.setState({
                    searchValue: value
                })
            }
        };
    }

    handleCommunitySearch() {
        const {loadCommunities, setFreeSearchForCommunity} = this.props;
        const {setSearchForCommunity, searchValue, setSearchValue} = this.state;

        if (searchValue.length > 3) {
            setFreeSearchForCommunity(searchValue);
            loadCommunities();
            setSearchForCommunity(true);
            setSearchValue("");
        }
    }

    render() {
        const {search_for_community, setSearchForCommunity, searchValue, setSearchValue} = this.state;

        if(search_for_community) {
            setSearchForCommunity(false);
            return (<Redirect to={"/directory"}/>)
        }

        return (
            <Form className="form-inline global-search" onSubmit={(e) => {
                e.preventDefault();
                this.handleCommunitySearch();
            }}>
                <FormGroup className={"align-items-baseline"}>
                    <Input type={"text"} placeholder="Search community..." value={searchValue}
                           onChange={(e) => setSearchValue(e.target.value)}/>
                    <button className={"btn"} onClick={() => this.handleCommunitySearch()}>
                        <i className="icofont icofont-search"/>
                    </button>
                </FormGroup>
            </Form>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    communityNameFilter: state.community.name_filter.value
});

const mapDispatchToProps = {
    setFreeSearchForCommunity,
    loadCommunities,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalSearch)