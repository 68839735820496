import React from 'react';
import {connect} from 'react-redux'
import {Button, Col, Label, Row} from 'reactstrap';
import {toast} from 'react-toastify';
import {registerUser} from "../../redux/user/actions";
import {PRIVACY_POLICY_LINK, TOS_LINK} from "../../constants";

class RegisterForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            acceptedTermsOfUse: false,
            toggleAcceptedTermsOfUse: () => {
                this.setState({
                    acceptedTermsOfUse: !this.state.acceptedTermsOfUse
                })
            },
            hasErrors: false,
        }
    }

    onFirstnameChange(value) {
        this.setState({
            firstname: value
        })
    }

    onLastnameChange(value) {
        this.setState({
            lastname: value
        })
    }

    onEmailChange(value) {
        this.setState({
            email: value
        })
    }

    onPasswordChange(value) {
        this.setState({
            password: value
        })
    }

    onRegister() {
        const {firstname, lastname, email, password, acceptedTermsOfUse} = this.state;
        const {registerUser, isRedeeming} = this.props;

        if (!firstname.trim() ||!lastname.trim() ||!email.trim() || !password.trim() || !acceptedTermsOfUse) {
            this.setState({hasErrors: true});
            toast.warn("Please add all the data");
            return;
        }

        this.setState({hasErrors: false});
        registerUser(firstname, lastname, email, password, isRedeeming);
    }

    render() {
        const {registering} = this.props;
        const {hasErrors, acceptedTermsOfUse, toggleAcceptedTermsOfUse} = this.state;

        return (
            <div>
                <Row>
                    <Col>
                        <input type="text" className="form-control" placeholder={"Firstname"}
                               onChange={(e) => this.onFirstnameChange(e.target.value)}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <input type="text" className="form-control" placeholder={"Lastname"}
                               onChange={(e) => this.onLastnameChange(e.target.value)}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <input type="email" className="form-control" placeholder={"Email"}
                               onChange={(e) => this.onEmailChange(e.target.value)}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <input type="password" className="form-control" placeholder={"Password"}
                               onChange={(e) => this.onPasswordChange(e.target.value)}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <div className="faq-form m-b-10 d-flex">
                            <div id="terms-of-use" className={"size-20 checkbox-container " + (acceptedTermsOfUse ? "active full" : "")}
                                 onClick={() => {
                                     toggleAcceptedTermsOfUse();
                                 }}>
                                <i className={"fa fa-check"}/>
                            </div>
                            <Label htmlFor="terms-of-use" className={`ml-2 ${hasErrors && !acceptedTermsOfUse ? "text-danger" : ""}`}>
                                You agree to the&nbsp;
                                <u><a href={TOS_LINK} target={"_blank"} rel={"noopener noreferrer"} className={"text-info"}>terms of use</a></u> and&nbsp;
                                <u><a href={PRIVACY_POLICY_LINK} target={"_blank"} rel={"noopener noreferrer"} className={"text-info"}>privacy policy</a></u>
                            </Label>
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col className={"text-center"}>
                        <Button color={"green"} onClick={() => {
                            if (registering) {
                                return;
                            }

                            this.onRegister();
                        }}>
                            {registering ? "REGISTERING..." : "REGISTER"}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = {registerUser};

const mapStateToProps = (state, ownProps) => ({
    registering: state.user.registering
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterForm);
