import {
    DECREMENT_JOB_APPLICANTS_LIST_PAGE,
    GET_JOB_APPLICATION_DETAILS,
    INCREMENT_JOB_APPLICANTS_LIST_PAGE,
    LOAD_FULL_JOBS_LIST_TO_SEARCH_APPLICANTS,
    LOAD_JOB_APPLICANTS,
    RESET_JOB_APPLICANTS_LIST,
    SEARCH_COMPANY_TO_SEARCH_APPLICANTS,
    SELECT_COMPANY_TO_SEARCH_APPLICANTS,
    SELECT_JOB_TO_SEARCH_APPLICANTS,
    SET_SORTING_CONDITION_FOR_JOB_APPLICATIONS,
    TOGGLE_APPLICATION_FORM_MODAL,
    TOGGLE_APPLICATION_UPDATE_MODAL,
    UNSELECT_COMPANY_TO_SEARCH_APPLICANTS,
    UNSELECT_JOB_TO_SEARCH_APPLICANTS,
    UPDATE_JOB_APPLICATION
} from "../action_types";

export function loadJobApplicants() {
    return {
        type: LOAD_JOB_APPLICANTS,
        api: true
    }
}

export function incrementJobApplicantsListPage() {
    return {
        type: INCREMENT_JOB_APPLICANTS_LIST_PAGE
    }
}

export function decrementJobApplicantsListPage() {
    return {
        type: DECREMENT_JOB_APPLICANTS_LIST_PAGE
    }
}

export function resetJobApplicants() {
    return {
        type: RESET_JOB_APPLICANTS_LIST
    }
}

export function loadFullJobsListToSearchApplicants() {
    return {
        type: LOAD_FULL_JOBS_LIST_TO_SEARCH_APPLICANTS,
        api: true
    }
}

export function selectJobToSearchApplicants(job) {
    return {
        type: SELECT_JOB_TO_SEARCH_APPLICANTS,
        payload: job
    }
}

export function unselectJobToSearchApplicants(index) {
    return {
        type: UNSELECT_JOB_TO_SEARCH_APPLICANTS,
        payload: index
    }
}

export function toggleApplicationFormModal(application) {
    return {
        type: TOGGLE_APPLICATION_FORM_MODAL,
        payload: application
    }
}

export function toggleApplicationUpdateModal(application) {
    return {
        type: TOGGLE_APPLICATION_UPDATE_MODAL,
        payload: application
    }
}

export function getJobApplicationDetails(id) {
    return {
        type: GET_JOB_APPLICATION_DETAILS,
        api: true,
        payload: id
    }
}

export function updateJobApplication(applicantId, data) {
    return {
        type: UPDATE_JOB_APPLICATION,
        api: true,
        payload: {
            applicant_id: applicantId,
            data: data
        }
    }
}

export function searchCompaniesToSearchApplicants(q) {
    return {
        type: SEARCH_COMPANY_TO_SEARCH_APPLICANTS,
        api: true,
        payload: {
            q
        }
    }
}

export function selectCompanyToSearchApplicants(company) {
    return {
        type: SELECT_COMPANY_TO_SEARCH_APPLICANTS,
        payload: company
    }
}

export function unselectCompanyToSearchApplicants(index) {
    return {
        type: UNSELECT_COMPANY_TO_SEARCH_APPLICANTS,
        payload: index
    }
}

export function setSortingConditionForJobApplications(field, direction) {
    return {
        type: SET_SORTING_CONDITION_FOR_JOB_APPLICATIONS,
        payload: {
            field,
            direction
        }
    }
}