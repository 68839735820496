import React from 'react';
import {Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,} from 'reactstrap';
import {connect} from "react-redux";
import {toast} from "react-toastify";

class CustomFieldModal extends React.Component {
    plainChoiceOption = {
        title: ""
    };

    constructor(props) {
        super(props)

        this.state = props.customField ? props.customField : {
            title: "",
            description: "",
            type: null,
            choice_options: [],
            expanded: false,
            multiple: false,
            required: false
        };
    }

    addOption() {
        const {choice_options} = this.state;
        choice_options.push(Object.assign({}, this.plainChoiceOption));

        this.setState({choice_options})
    }

    removeOption(index) {
        const {choice_options} = this.state;
        choice_options.splice(index, 1);

        this.setState({choice_options})
    }

    updateChoiceOption(value, index) {
        const {choice_options} = this.state;
        choice_options[index].title = value;

        this.setState({choice_options})
    }

    addNewField() {
        // validate info
        if(!this.state.title.trim() || !this.state.description.trim() || !this.state.type){
            toast.error("Please fill all required fields");
            return;
        }

        if(this.state.type === "choice"){
            if(this.state.choice_options.length < 2){
                toast.error("Choice fields need at least 2 options");
                return;
            }

            for (var i = 0; i < this.state.choice_options.length; i++) {
                var choiceOption = this.state.choice_options[i];
                if(!choiceOption.title.trim()){
                    toast.error("Choice Options need titles");
                    return;
                }
            }
        }

        this.props.onNewCustomFieldAdded(this.state);
        this.props.closeModal();
    }

    render() {
        const {closeModal} = this.props;

        return (
            <Modal isOpen={true}>
                <ModalHeader>
                    Create Field
                </ModalHeader>
                <ModalBody>
                    <Row className={"mb-1"}>
                        <Col>
                            <Label className="f-15">
                                Title *
                            </Label>
                            <input type="text"
                                   required={true}
                                   className="form-control" defaultValue={this.state.title}
                                   onChange={(e) => this.setState({"title": e.target.value})}/>
                        </Col>
                    </Row>
                    <Row className={"mb-1"}>
                        <Col>
                            <Label className="f-15">
                                Description *
                            </Label>
                            <input type="text"
                                   required={true}
                                   className="form-control" defaultValue={this.state.description}
                                   onChange={(e) => this.setState({"description": e.target.value})}/>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col>
                            <Label className="f-15">
                                Type *
                            </Label>
                            <select defaultValue={this.state.type}
                                    onChange={(e) => this.setState({"type": e.target.value})}
                                    className="form-control"
                                    required
                            >
                                <option>Choose type</option>
                                <option value={"text"}>Text</option>
                                <option value={"textarea"}>Textarea</option>
                                <option value={"number"}>Number</option>
                                <option value={"date"}>Date</option>
                                <option value={"choice"}>Choice</option>
                                <option value={"file"}>File</option>
                                <option value={"info"}>Info</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className={"mb-1"}>
                        <Col>
                            <input type="checkbox"
                                   id={"custom_field_required"}
                                   required={true}
                                   defaultValue={this.state.required}
                                   onChange={(e) => this.setState({"required": !!e.target.value})}/>
                            <Label className="f-15 ml-1" for={"custom_field_required"}>
                                Required
                            </Label>
                        </Col>
                    </Row>
                    {this.state.type === "choice" && (
                        <>
                            <Row className={"mb-1"}>
                                <Col xs={6}>
                                    <input type="checkbox"
                                           id={"custom_field_expanded"}
                                           required={true}
                                           defaultValue={this.state.expanded}
                                           onChange={(e) => this.setState({"expanded": !!e.target.value})}/>
                                    <Label className="f-15 ml-1" for={"custom_field_expanded"}>
                                        Expanded
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    <input type="checkbox"
                                           id={"custom_field_multiple"}
                                           required={true}
                                           defaultValue={this.state.multiple}
                                           onChange={(e) => this.setState({"multiple": !!e.target.value})}/>
                                    <Label className="f-15 ml-1" for={"custom_field_multiple"}>
                                        Multiple
                                    </Label>
                                </Col>
                            </Row>
                            <Row className={"mb-1"}>
                                <Col>
                                    <Label>Options</Label>
                                </Col>
                            </Row>
                            <Row className={"mb-1"}>
                                <Col>
                                    {this.state.choice_options.map((option, index) => <Row key={"choice_option_" + index}>
                                        <Col className={"d-flex mb-1"}>
                                            <input
                                                defaultValue={option.title}
                                                className={"form-control"}
                                                onChange={(e) => this.updateChoiceOption(e.target.value, index)}
                                            />
                                            <Button onClick={() => this.removeOption()} className={"ml-2"} color={"secondary"}>Remove</Button>
                                        </Col>
                                    </Row>)}
                                </Col>
                            </Row>
                            <Row className={"mb-1"}>
                                <Col className={"text-center"}>
                                    <Button color={"primary"} onClick={() => this.addOption()}>Add option</Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        closeModal();
                    }}>Close</Button>
                    <Button className={"text-white"} color="green" onClick={() => {
                        this.addNewField()
                    }}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapDispatchToProps = {};

const mapStateToProps = (state, ownProps) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomFieldModal)
