import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label,
    Row,
} from 'reactstrap';
import LocationFilter from "../community/community_filters/location_filter";
import TopicFilter from "../community/community_filters/topic_filter";
import JobCard from "./components/job_card";
import DisplayResultButton from "../common/job_board/display_result_button";
import NumberFormatter from "../utils/number_formatter";
import ShareJobModal from "../common/job_board/share_job_modal";
import {
    getReferrerDetails,
    incrementJobListPage,
    loadJobs,
    resetJobBoard,
    setJobBoardToken,
    setSelectedCommunity,
    toggleRemoteJobFilter,
    toggleMyCompanyJobFilter
} from "../../redux/job/actions";
import BountySplitModal from "./components/bounty_split_modal";
import LogoComponent from "../common/helpers/logo_component";
import ShareIcon from "../../assets/images/share_icon.svg";
import CopyButton from "../common/job_board/copy_button";
import {Link, Redirect} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import {
    getCommunityToRedeem,
    setCommunityRedeemToken,
    toggleCommunityRedeemModal
} from "../../redux/community/actions";
import CommunityRedeemModal from "../common/job_board/community_redeem_modal";
import HandsTogetherImage from "../../assets/images/hands_together.svg";
import SubscribeJobBountiesCardCTA from "../common/job_board/subscribe_job_bounties_cta";
import Slack from "../../assets/images/social_profiles/slack.svg";
// import Telegram from "../../assets/images/social_profiles/telegram.svg";
import Discord from "../../assets/images/social_profiles/discord.svg";
import ConnectSolanaWalletComponent from "../common/solana/ConnectSolanaWalletComponent";

class JobBoardList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bountySplitModalOpen: false,
            toggleBountySplitModal: () => {
                this.setState({
                    bountySplitModalOpen: !this.state.bountySplitModalOpen
                })
            },
            shareJobModal: {
                open: false,
                job: null,
            },
            toggleShareJobModal: (job) => {
                let shareJobModal = Object.assign({}, this.state.shareJobModal);
                shareJobModal.open = !this.state.shareJobModal.open;
                shareJobModal.job = job;
                this.setState({
                    shareJobModal: shareJobModal
                })
            },
            isDropdownOpen: false,
            toggleDropdown: () => {
                this.setState({
                    isDropdownOpen: !this.state.isDropdownOpen
                })
            }
        };
    }

    loadInitialJobPage() {
        const {loadJobs, resetJobBoard} = this.props;
        resetJobBoard();
        loadJobs()
    }

    componentDidMount() {
        const {getReferrerDetails, setJobBoardToken, redeem_token} = this.props;
        const token = this.props.routeParams.token;
        const communityRedeemToken = this.props.routeParams.community_redeem_token;

        if (token) {
            setJobBoardToken(token);
            getReferrerDetails(token);
            this.loadInitialJobPage();
        } else if (communityRedeemToken && !redeem_token) {
            this.props.setCommunityRedeemToken(communityRedeemToken);
            this.props.getCommunityToRedeem();
        }
    }

    calculateBountyPortion(value) {
        return 100 - value;
    }

    loadJobsTimeout;

    loadMoreJobs() {
        const {incrementJobListPage, loadJobs, jobs} = this.props;
        const {page, total_pages} = jobs;

        if (this.loadJobsTimeout) {
            clearTimeout(this.loadJobsTimeout);
        }

        this.loadJobsTimeout = setTimeout(() => {
            if (page < total_pages) {
                incrementJobListPage();
                loadJobs();
            }
        }, 200);
    }

    renderRemoteFilter() {
        const {jobs, toggleRemoteJobFilter} = this.props;

        return (
            <div className="faq-form m-b-10 d-flex">
                <div className={"size-20 checkbox-container " + (jobs.filters.is_remote ? "active full" : "")}
                     onClick={() => {
                         toggleRemoteJobFilter();
                         this.loadInitialJobPage();
                     }}>
                    <i className={"fa fa-check"}/>
                </div>
                <Label className={"ml-2"} onClick={() => {
                    toggleRemoteJobFilter();
                    this.loadInitialJobPage();
                }}>
                    Remote
                </Label>
            </div>
        )
    }

    renderMyJobFilter() {
        const {jobs, toggleMyCompanyJobFilter} = this.props;

        return (
            <div className="faq-form m-b-10 d-flex">
                <div className={"size-20 checkbox-container " + (jobs.filters.my_jobs ? "active full" : "")}
                     onClick={() => {
                         toggleMyCompanyJobFilter();
                         this.loadInitialJobPage();
                     }}>
                    <i className={"fa fa-check"}/>
                </div>
                <Label className={"ml-2"} onClick={() => {
                    toggleMyCompanyJobFilter();
                    this.loadInitialJobPage();
                }}>
                    My Jobs
                </Label>
            </div>
        )
    }

    renderSelectedCommunityCard() {
        const {jobs, user, setSelectedCommunity, loadJobs, resetJobBoard} = this.props;
        const {selected_community} = jobs;
        const {isDropdownOpen, toggleDropdown} = this.state;

        return (
            <Card
                className={"d-flex flex-column flex-sm-row align-items-center p-4 flex-wrap mb-4 card justify-content-center justify-content-sm-start card"}>
                <LogoComponent logo={selected_community.logo} width={200} height={100} className={"logo mr-3 b-r-15"}/>
                <div className={"d-flex flex-column align-items-center align-items-sm-start"}>
                    <h4>You are currently managing the Job Board page of:</h4>
                    <h3 className={"font-weight-bold"}>{selected_community.name}</h3>
                    <div className={"d-flex"}>
                        {selected_community.cgi_id && (
                            <a target={"_blank"} rel={"noopener noreferrer"}
                               href={`/community/${selected_community.id}`}
                               className={"btn btn-outline-green b-r-10 mr-3 f-w-800"}>SEE PROFILE</a>
                        )}

                        {user.communities.length > 1 && <Dropdown isOpen={isDropdownOpen} toggle={() => {
                            toggleDropdown();
                        }}>
                            <DropdownToggle tag={"button"} className={"btn btn-outline-green b-r-10 pointer"}>
                                CHANGE COMMUNITY
                            </DropdownToggle>
                            <DropdownMenu className={'bg-gray'}>
                                {user.communities.map((community) => (
                                    <DropdownItem key={'community-' + community.id}
                                                  tag={"span"} className={"action-item pointer"}
                                                  onClick={() => {
                                                      setSelectedCommunity(community);
                                                      resetJobBoard();
                                                      loadJobs(community.job_referral_token.token);
                                                  }}>{community.name}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>}
                    </div>
                </div>
            </Card>
        )
    }

    renderJobBoardHeaderSection() {
        const {jobs} = this.props;
        const {selected_community} = jobs;
        const {job_referral_token} = selected_community;
        const {toggleBountySplitModal} = this.state;

        const bountyListLink = `${window.location.origin}/jobs/${selected_community.job_referral_token.token}`;

        return (
            <Card className={"d-flex flex-column p-lg-5 p-3 bg-dark-gray text-center mb-4"}>
                <div>
                    <div
                        className={"d-flex justify-content-center align-items-center mb-3 text-left flex-column flex-lg-row"}>
                        <img src={ShareIcon} alt={"share-icon"} width={100} height={100} className={"mr-3"}/>
                        <div className={"w-75"}>
                            <h3 className={"f-w-900 f-27"}>Share the link and get rewards</h3>
                            <div className={"mb-2"}>
                                <div>
                                    <span>When anyone is successfully hired through this Job Board, by applying or being referred, the community will get a share of the reward. </span>
                                    <u><a className={"green-text"}
                                          href="https://absorbing-periwinkle-352.notion.site/Job-bounties-c4861516955e4fc3a07f4e69e2d13d3c">How
                                        does it work?</a></u>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={"d-flex flex-column flex-lg-row justify-content-center align-items-center px-lg-5 mb-2"}>
                        <span
                            className={"mb-1 f-19 mr-2"}>The <strong>revenue share</strong> for the <strong>community</strong> is set to:</span>
                        <DisplayResultButton
                            color={"teal"}
                            displaySize={"md"}
                            displayText={`${this.calculateBountyPortion(job_referral_token.referral_bounty_portion)}%`}
                            bold={true}
                            displayColor={"purple"}
                            buttonLabel={"CHANGE"}
                            handleClick={() => {
                                toggleBountySplitModal()
                            }}/>
                    </div>
                    <div className={"mb-2"}>
                        <i>For every <strong>$ <NumberFormatter value={"5000"}/></strong> of bounty reward assigned, the
                            community will get <span
                                className={"purple-text"}>$ <NumberFormatter
                                value={Math.round(5000 * (this.calculateBountyPortion(job_referral_token.referral_bounty_portion) / 100))}/></span></i>
                    </div>
                    <div className={"d-flex flex-column px-lg-5"}>
                        <CopyButton
                            color={"teal"}
                            displayText={bountyListLink.substr(0, 70) + "..."}
                            displayColor={"green"}
                            buttonIcon={"copy"}
                            buttonLabel={"COPY"}
                            handleClick={() => {
                                if (navigator.clipboard !== undefined) {
                                    navigator.clipboard.writeText(bountyListLink);
                                }
                            }}/>
                    </div>
                </div>
            </Card>
        )
    }

    renderConnectSocialsCard() {
        const {jobs} = this.props;
        const {selected_community} = jobs;

        return (
            <Card>
                <CardBody className={"p-4"}>
                    <h5 className={"f-w-900 m-b-15"}>Connect your channels!</h5>
                    <div className={"m-b-15"}>
                        <span className={"whitespace-pre-line"}>
                            The Community Atlas chat integrations will <b>automatically</b> share in your community channels all the new relevant job offers!
                        </span>
                        <br/>
                        <br/>
                        <span>
                            Inform your members in a quick and seamless way, get them hired and collect bounties!
                        </span>
                    </div>
                    <div className={"d-flex justify-content-center m-b-15"}>
                        <div className={`${selected_community.connected_slack ? "social-connected" : ""}`}>
                            <img className={"mx-2"} src={Slack} width={40} height={40} alt={"slack"}/>
                        </div>
                        <div className={`${selected_community.connected_discord ? "social-connected" : ""}`}>
                            <img className={"mx-2"} src={Discord} width={40} height={40} alt={"discord"}/>
                        </div>
                        {/*<div className={`${selected_community.connected_telegram ? "social-connected" : ""}`}>*/}
                        {/*    <img className={"mx-2"} src={Telegram} width={40} height={40} alt={"telegram"} />*/}
                        {/*</div>*/}
                    </div>
                    <div className={"d-flex justify-content-center"}>
                        <Link to={`/community/edit/${selected_community.id}#integration`} className={"w-100"}>
                            <Button color={"green"} className={"w-100 b-r-10"}>CONNECT</Button>
                        </Link>
                    </div>
                </CardBody>
            </Card>
        )
    }

    renderConnectSolanaWallet() {
        return (
            <Card>
                <CardBody className={"p-4"}>
                    <h5 className={"f-w-900 m-b-15"}>Connect your wallet!</h5>
                    <div className={"m-b-15"}>
                        <span className={"whitespace-pre-line"}>
                            To access additional blockchain tracked offers connect your Phantom wallet.
                        </span>
                    </div>
                    <div className={"d-flex justify-content-center"}>
                        <ConnectSolanaWalletComponent forCommunity={true} />
                    </div>
                </CardBody>
            </Card>
        )
    }

    renderReferrerDetails() {
        const {referrer_details} = this.props;
        const {data} = referrer_details;

        if (referrer_details.loading) {
            return (
                <Card className={"justify-content-center align-items-center p-4 mb-4"}>
                    <h3>Loading...</h3>
                </Card>
            )
        }

        if (!referrer_details.loading && referrer_details.data) {
            return (
                <>
                    <Card className={"flex-column flex-sm-row align-items-center p-4 mb-4"}>
                        <LogoComponent logo={data.community.logo} width={200} height={100} className={"logo b-r-15"}/>
                        <div
                            className={"d-flex flex-column justify-content-center align-items-center align-items-sm-start ml-4"}>
                            <h4>You are currently viewing the Job Board page of:</h4>
                            <h3 className={"font-weight-bold"}>{data.community.name}</h3>
                        </div>
                    </Card>
                    <Card className={"flex-row align-items-center p-4 mb-4 mt-4"} style={{"backgroundColor": "#F2F2F2"}}>
                        <img src={HandsTogetherImage} width={200} height={100} className={"logo b-r-15"}/>

                        <div className={"d-flex flex-column justify-content-center align-items-start"}>
                            <h3 className={"font-weight-bold"}>Refer and get rewards</h3>
                            <span>Refer a friend or apply yourself to have a chance of winning the bounty. Bounties are cash rewards for those who refer a talent that is hired. When referring friend, you can also split the reward!</span>
                            <a href="https://absorbing-periwinkle-352.notion.site/Job-bounties-c4861516955e4fc3a07f4e69e2d13d3c">How
                                does it work?</a>
                        </div>
                    </Card>
                </>
            )
        }
    }

    renderError() {
        return (
            <Card key={"job-list-loading-item"} className={"p-5 justify-content-center align-items-center mb-4"}>
                <span className={"f-18"}>There was an error loading jobs</span>
            </Card>
        )
    }

    renderLoading() {
        return (
            <Card key={"job-list-loading-item"} className={"p-5 justify-content-center align-items-center mb-4"}>
                <span className={"f-18"}>Loading...</span>
            </Card>
        )
    }

    renderNoResult() {
        return (
            <Card className={"p-5 justify-content-center align-items-center mb-5"}>
                <span className="f-18 py-5">There are no Job Offers that match your filter criteria.</span>
            </Card>
        )
    }

    renderJobList() {
        const {jobs} = this.props;
        const {toggleShareJobModal} = this.state;

        return (
            jobs.list.map((job, index) =>
                <JobCard job={job} key={`${index}-${job.id}`}
                         shareJobModalOpen={() => {
                             toggleShareJobModal(job);
                         }}/>
            )
        )
    }

    render() {
        const {user, user_token, jobs, routeParams, referrer_details, job_board_token, redeem_token} = this.props;
        const {bountySplitModalOpen, toggleBountySplitModal, shareJobModal, toggleShareJobModal} = this.state;
        const {page, total_pages, total_results, loading, list, error} = jobs;
        const token = this.props.routeParams.token;
        const communityRedeemToken = this.props.routeParams.community_redeem_token;

        if ((!user_token && (!job_board_token && !token) && (!communityRedeemToken && !redeem_token))) {
            return (<Redirect to={"/"}/>);
        }

        return (
            <Fragment>
                <CommunityRedeemModal/>
                {bountySplitModalOpen && (<BountySplitModal toggleModal={toggleBountySplitModal}/>)}
                {shareJobModal.open && (<ShareJobModal job={shareJobModal.job}
                                                       token={shareJobModal.job.referral_token.token}
                                                       toggleShareJobModal={toggleShareJobModal}/>)}
                <Breadcrumb title="Job Offers" subTitle="Filter by your interests"/>
                <Container fluid={true}>
                    <Row>
                        <Col xl="3">
                            <Card>
                                <CardHeader className={'p-4'}>
                                    <div className={"d-flex justify-content-between align-items-center"}>
                                        <h5 className="mb-0">Filter by:</h5>
                                    </div>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <LocationFilter isGlobal={true} onLocationSelected={() => {
                                        this.loadInitialJobPage();
                                    }} onLocationUnselected={() => {
                                        this.loadInitialJobPage();
                                    }}/>
                                    {this.renderRemoteFilter()}
                                    <TopicFilter load={() => {
                                        this.loadInitialJobPage();
                                    }}/>
                                    {user && user.is_company && this.renderMyJobFilter()}
                                </CardBody>
                            </Card>
                            {!user && <SubscribeJobBountiesCardCTA/>}
                            {user && user.communities.length && jobs.selected_community && this.renderConnectSocialsCard()}
                            {user && user.communities.length && jobs.selected_community && this.renderConnectSolanaWallet()}
                        </Col>
                        <Col xl="9">
                            {user && jobs.selected_community && this.renderSelectedCommunityCard()}
                            {user && jobs.selected_community && this.renderJobBoardHeaderSection()}
                            {!user && routeParams.token && referrer_details.data && referrer_details.data.type === 1 && this.renderReferrerDetails()}
                            {((!loading && error) || (!user && list.length === 0 && error)) && this.renderError()}
                            {!loading && !error && list.length === 0 && this.renderNoResult()}
                            <InfiniteScroll
                                pageStart={page}
                                loadMore={() => {
                                    this.loadMoreJobs();
                                }}
                                hasMore={page < total_pages && !loading}
                                loader={this.renderLoading()}
                            >
                                {list && this.renderJobList()}
                            </InfiniteScroll>

                            {total_results > 0 && <div className={"my-5 pl-3 purple-text"}>
                                <span>Total results: <b><NumberFormatter value={total_results}/></b></span>
                            </div>}
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    loadJobs,
    resetJobBoard,
    incrementJobListPage,
    getReferrerDetails,
    setSelectedCommunity,
    setJobBoardToken,
    toggleRemoteJobFilter,
    toggleMyCompanyJobFilter,
    setCommunityRedeemToken,
    getCommunityToRedeem,
    toggleCommunityRedeemModal,
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    user_token: state.user.token,
    jobs: state.job,
    referrer_details: state.job.referrer_details,
    job_board_token: state.job.job_board_token,
    redeem_community: state.community.redeem.community,
    redeem_token: state.community.redeem_token,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobBoardList)
