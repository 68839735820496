import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom'
import {MoreHorizontal, AlignCenter} from "react-feather";
import GlobalSearch from "./global_search";
import CompanyImage from "../../../assets/images/company.svg";
import PeopleImage from "../../../assets/images/people.svg";
import useWindowSize from "../hooks/use_window_size";

const Header = (props) => {
    const [navmenu, setNavmenu] = useState(false);
    const history = useHistory();
    const [sidebar, setSidebar] = useState("iconsidebar-menu");
    const [width, height] = useWindowSize();

    const openCloseSidebar = (sidebartoggle) => {
        if (sidebartoggle === "iconsidebar-menu") {
          setSidebar("iconbar-mainmenu-close")
          document.querySelector(".iconsidebar-menu").classList.add('iconbar-mainmenu-close');
          document.querySelector(".iconsidebar-menu").classList.remove('iconbar-second-close');
        } 
        else if(sidebartoggle === "iconbar-mainmenu-close") {
          setSidebar("iconsidebar-menu")
          document.querySelector(".iconsidebar-menu").classList.add('iconbar-second-close');
          document.querySelector(".iconsidebar-menu").classList.remove('iconbar-mainmenu-close');
        }
      }

    const Navmenuhideandshow = () => {
        if(navmenu){
          setNavmenu(!navmenu)
          document.querySelector('.nav-menus').classList.add('open')
        }
        else{
          setNavmenu(!navmenu)
          document.querySelector('.nav-menus').classList.remove('open')
        }
      }

    const isMobileScreen = () => {
        return (width > 100 && width < 991);
    }

    //full screen function
    // const goFull = () => {
    //     if ((document.fullScreenElement && document.fullScreenElement !== null) ||
    //         (!document.mozFullScreen && !document.webkitIsFullScreen)) {
    //         if (document.documentElement.requestFullScreen) {
    //             document.documentElement.requestFullScreen();
    //         } else if (document.documentElement.mozRequestFullScreen) {
    //             document.documentElement.mozRequestFullScreen();
    //         } else if (document.documentElement.webkitRequestFullScreen) {
    //             document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    //         }
    //     } else {
    //         if (document.cancelFullScreen) {
    //             document.cancelFullScreen();
    //         } else if (document.mozCancelFullScreen) {
    //             document.mozCancelFullScreen();
    //         } else if (document.webkitCancelFullScreen) {
    //             document.webkitCancelFullScreen();
    //         }
    //     }
    // }

    return (
        <div className="page-main-header">
            <div className="main-header-right">
                <div className="main-header-left text-center">
                    <div className="logo-wrapper"><a href="https://thecommunityatlas.com" target="_blank" rel="noopener noreferrer"><img
                        src={require("../../../assets/images/logo/logo.png")} alt=""/></a></div>
                </div>
                <div className="mobile-sidebar d-block d-lg-none">
                    <div className="media-body text-left switch-sm">
                    <label className="switch mb-0"><AlignCenter className="font-primary" onClick={() => openCloseSidebar(sidebar)} /></label>
                    </div>
                </div>

                    {props.currentUser && (
                        <div className="nav-right col pull-right right-menu">
                            <ul className="nav-menus d-none d-lg-flex">
                                <li>
                                    <GlobalSearch/>
                                </li>

                                <li>
                                    <a className="purple-text" href="" onClick={() => {
                                        props.onLogout();
                                        history.push("/");
                                    }}>Logout</a>
                                </li>
                            </ul>
                            <div className="d-lg-none pull-right">
                                <li>
                                    <a className="purple-text" href="" onClick={() => {
                                        props.onLogout();
                                        history.push("/");
                                    }}>Logout</a>
                                </li>
                            </div>
                        </div>
                    )}

                    {!props.currentUser && (
                        <div className="nav-right col pull-right right-menu">
                            <ul className="nav-menus text-center">
                                {/*has d-lg-inline-block also*/}
                                <li className="d-none">
                                    {/*<GlobalSearch/>*/}
                                </li>
                                <li className={"p-lg-3"}><a href="/community/9149457" target="_blank" rel="noopener noreferrer"
                                    className={"dark-text"}>SEE A SAMPLE REPORT</a></li>
                                <li className={"p-lg-3"}><a href="https://calendly.com/the-community-atlas/platform-demo" target="_blank" rel="noopener noreferrer"
                                    className={"dark-text"}>BOOK A DEMO</a></li>
                                <li className={"p-lg-3"}><Link to="/login" className={"dark-text"} onClick={Navmenuhideandshow}>LOGIN</Link></li>
                                <li className={"p-0 m-r-10 m-l-10"}><a
                                    // href="https://docs.google.com/forms/d/e/1FAIpQLSe8tZC4f5wPAmWm09fMsTj-5PSj-m7HcvQrC_0CY9oO33ZS3g/viewform"
                                    // href="https://app.wedo.community/wedo/register?sid=901486"
                                    // href="https://thecommunityatlas.com/en/pricingatlas/"
                                    href="https://calendly.com/the-community-atlas/platform-demo"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`${!isMobileScreen() ? "border border-info" : ""} p-md-2 rounded d-flex`}><img className="d-none d-lg-inline-block" src={CompanyImage}
                                                                                    style={{"width": "30px"}}/> <span
                                    className={"m-l-10 font-weight-bold"}>REGISTER AS <br/> A COMPANY</span></a></li>
                                <li className={"p-0"}>
                                    <a
                                        href="https://docs.google.com/forms/d/e/1FAIpQLScPUm5LtNqm_XkmK5pZj9bIk7Kxx5fVDYXvw_-p7JxiwS5YQQ/viewform"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`${!isMobileScreen() ? "border border-info" : ""} p-md-2 rounded d-flex`}><img className="d-none d-lg-inline-block" src={PeopleImage}
                                                                                                                                  style={{"width": "30px"}}/><span
                                        className={"m-l-10 font-weight-bold"}>REGISTER AS <br/> A COMMUNITY</span></a>
                                </li>
                            </ul>
                            <div className="d-lg-none mobile-toggle pull-right" onClick={Navmenuhideandshow}><MoreHorizontal/></div>
                        </div>
                    )}

                <script id="result-template" type="text/x-handlebars-template">
                    <div className="ProfileCard u-cf">
                        <div className="ProfileCard-avatar"><i className="pe-7s-home"></i></div>
                        <div className="ProfileCard-details">
                            <div className="ProfileCard-realName"></div>
                        </div>
                    </div>
                </script>
                <script id="empty-template" type="text/x-handlebars-template">
                    <div className="EmptyMessage">Your search turned up 0 results. This most likely means the backend is
                        down, yikes!
                    </div>
                </script>
            </div>
        </div>
    );
};

export default Header;
