const systemMessage = {
    1: {
        company: [
            "Your request is pending.", 
            "You will get notified as soon as the community accepts your request.",
        ],
        community: [
            "This user wants to chat with you, and will only be able to contact you again if you accept the conversation."
        ]
    },
    2: {
        company: [
            "YOUR CONTACT REQUEST HAS BEEN ACCEPTED",
            "You can now freely chat with this community.",
            "Say hi and present yourself!"
        ],
        community: [
            "You can now freely chat with this contact.",
            "Say hi and present yourself!"
        ]
    },
    3: {
        company: [
            "YOUR CONTACT REQUEST HAS BEEN DECLINED",
            "You can't contact this community for the next 30 days."
        ],
        community: [
            "The contact has been denied.",
            "This user cannot contact you for the next 30 days.",
            "You can re-accept this conversation anytime by clicking the button below."
        ]
    },
}

export default systemMessage;