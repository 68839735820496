import {
    UPLOAD_COMMUNITY_IMAGE_FILE,
    UPLOAD_COMMUNITY_SERVICE_IMAGE_FILE,
    UPLOAD_USER_PROFILE_IMAGE_FILE
} from "../action_types";

export function uploadCommunityImageFile(file) {
    return {
        type: UPLOAD_COMMUNITY_IMAGE_FILE,
        api: true,
        payload: {
            file
        }
    }
}

export function uploadCommunityServiceImageFile(file) {
    return {
        type: UPLOAD_COMMUNITY_SERVICE_IMAGE_FILE,
        api: true,
        payload: {
            file
        }
    }
}

export function uploadUserProfileImageFile(file) {
    return {
        type: UPLOAD_USER_PROFILE_IMAGE_FILE,
        api: true,
        payload: {
            file
        }
    }
}
