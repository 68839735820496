import React from "react";
import BlurredImage1 from "../../../assets/images/blurred_image_1.png";
import BlurredImage2 from "../../../assets/images/blurred_image_2.png";
import BlurredImage3 from "../../../assets/images/blurred_image_3.png";
import BlurredImage4 from "../../../assets/images/blurred_image_4.png";
import BlurredImage5 from "../../../assets/images/blurred_image_5.png";

function BlurredGraph(props) {
    const images = {
        "1": BlurredImage1,
        "2": BlurredImage2,
        "3": BlurredImage3,
        "4": BlurredImage4,
        "5": BlurredImage5
    };

    if (!props.image || !images[props.image]) {
        return null;
    }

    return (
        <div style={{
            ...(props.withoutMargin ? {} : {"padding": "40px"})
        }}>

            <img src={images[props.image]} style={{
                "width": "100%"
            }}/>
        </div>
    );
}


export default BlurredGraph;
