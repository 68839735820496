import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router";
import axios from 'axios';
import Atlas from "../../assets/images/logo/logo_no_text.png";
import Meetup from '../../assets/images/social_profiles/meetup.svg';
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import ConfigDB from "../../config";

function OAuthMeetup() {
    const search = useLocation().search;
    const [state, setState] = useState('loading');

    const params = new URLSearchParams(search);
    const code = params.get('code');
    const communityId = params.get("state");
    const accessToken = params.get("access_token");

    const renderLoading = () => {
        return (
            <div className={"d-flex flex-column align-items-center"}>
                <div className={"my-2"}>
                    <i className={"fa fa-spinner fa-spin fa-3x"} />
                </div>
                <h3>We are connecting you with Meetup</h3>
            </div>
        )
    }

    const renderInformation = (label, icon, iconColor) => {
        return (
            <div className={"d-flex flex-column align-items-center"}>
                <div className={"my-3"}>
                    <div className={`icon-circle ${iconColor}`}>
                        <i className={`fa fa-${icon} fa-2x text-${iconColor}`} />
                    </div>
                </div>
                <h3 className={"mb-3 text-center"}>{label}</h3>
                <Link className={"btn btn-primary"} to={"/"}>Go to Directory</Link>
            </div>
        )
    }

    const renderSuccess = () => {
        return renderInformation('Successfully connected your community with Meetup', 'check', 'success');
    }

    const renderError = () => {
        return renderInformation('There was an error connecting Meetup with your Community', 'times', 'danger');
    }

    return (
        <Container>
            <Row>
                <Col className={"py-5"}>
                    <Card>
                        <CardBody>
                            <div className={"d-flex justify-content-center align-items-center p-5"}>
                                <img src={Atlas} width={125} className={"img-fluid"} alt={"atlas-logo"}/>
                                <i className={"fa fa-arrows-h fa-3x px-3"} />
                                <img src={Meetup} width={125} className={"img-fluid"} alt={"meetup-logo"}/>
                            </div>
                            {state === 'loading' && renderLoading()}
                            {state === 'success' && renderSuccess()}
                            {state === 'error' && renderError()}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default OAuthMeetup