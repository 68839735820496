import React, {Fragment, useRef} from "react";
import {ChevronDown} from "react-feather";
import {Typeahead} from "react-bootstrap-typeahead";

class DashboardFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    searchOption(criteria) {
        const {options} = this.props;

        if (criteria.length > 0) {
            return options.filter((option) => {
                return option.name.toLowerCase().indexOf(criteria.toLowerCase()) > -1;
            });
        }

        return options;
    };

    render() {
        const {open} = this.state;

        return (
            <div className="faq-form">
                <TypeaheadComponent
                    searchOption={(criteria) => {
                        this.searchOption(criteria);
                    }}
                    placeholder={this.props.selectedOption.name}
                    options={this.props.options}
                    selectOption={(selectedOption) => {
                        this.setState({
                            open: false
                        });

                        this.props.onSelection(selectedOption);
                    }}
                    isOpen={open}
                    open={(value) => {
                        this.setState({
                            open: value
                        });
                    }}
                />
            </div>
        );
    }
}

const TypeaheadComponent = (props) => {
    const ref = useRef();

    return (
        <Fragment>
            <Typeahead
                id="search-for-option"
                labelKey="name"
                minLength={1}
                open={props.isOpen}
                onFocus={() => {
                    props.open(true);
                }}
                onBlur={() => {
                    props.open(false);
                }}
                onSearch={(criteria) => {
                    props.searchOption(criteria);
                }}
                isLoading={false}
                options={props.options}
                placeholder={props.placeholder}
                renderMenuItemChildren={(option, props) => {
                    return (
                        <Fragment>
                            <span>{option.name}</span>
                        </Fragment>
                    )
                }}
                onChange={(selected) => {
                    if (selected.length > 0) {
                        props.selectOption(selected[0])
                    }

                    ref.current.clear();
                    ref.current.blur();
                }}
                ref={ref}
            />
            <ChevronDown className="search-icon" onClick={() => {
                if (ref.current.isMenuShown) {
                    ref.current.blur();
                } else {
                    ref.current.focus();
                }
            }}/>
        </Fragment>
    );
};

export default DashboardFilter;
