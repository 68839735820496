import React from "react";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {createWelcomeMessage} from "../../../redux/channel/actions";
import {toggleContactCommunityModal} from "../../../redux/community/actions";

class ContactCommunityModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: "",
            charLimit: 1000,
        };
    }

    handleMessage(e) {
        this.setState({
            message: e.target.value.slice(0, this.state.charLimit),
        })
    }

    render() {
        const {community, createWelcomeMessage, customerData, contact_community_modal_is_open, toggleContactCommunityModal} = this.props;
        const {message, charLimit} = this.state;
        const creditsToStartConversation = 1;

        return(
            <Modal isOpen={contact_community_modal_is_open} toggle={toggleContactCommunityModal}>
                <ModalHeader>Write your invitation request</ModalHeader>
                <ModalBody>
                    <span>Let the community know who you are and how they can benefit from a partnership with you.</span>
                    <textarea
                        style={{resize: "none"}}
                        rows="10"
                        className="form-control my-3 p-3"
                        placeholder={"Ex: We are looking for a community like yours because..."}
                        value={message}
                        onChange={(e) => {
                            this.handleMessage(e);
                        }}/>
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between">
                            {customerData && <span>Using <b>{creditsToStartConversation}</b> of <b>{customerData.credits}</b> remaining Credits</span>}
                            <span>{charLimit - message.length}/1000</span>
                        </div>
                        <div>
                            {creditsToStartConversation > customerData.credits &&(<span className="text-danger">*You don't have enough credits to start the conversation</span>)}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="white" onClick={toggleContactCommunityModal}>
                        CANCEL
                    </Button>
                    <Button className="text-white" color="green" disabled={creditsToStartConversation > customerData.credits} onClick={() => {
                        if (message != null && message.length > 0) {
                            createWelcomeMessage(community.id, message);
                            this.setState({
                                message: "",
                            })
                            toggleContactCommunityModal();
                        }
                    }}>
                        SEND
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

const mapDispatchToProps = {
    createWelcomeMessage,
    toggleContactCommunityModal,
};

const mapStateToProps = (state, ownProps) => ({
    customerData: state.customer.data,
    contact_community_modal_is_open: state.community.contact_community_modal_is_open
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactCommunityModal);