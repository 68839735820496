import React from 'react';
import {Button, Label, Modal, ModalBody, ModalHeader,} from 'reactstrap';
import {connect} from "react-redux";
import {
    JOB_APPLICATION_STATUS_APPLIED,
    JOB_APPLICATION_STATUS_HIRED,
    JOB_APPLICATION_STATUS_INVITED_FOR_INTERVIEW,
    JOB_APPLICATION_STATUS_REJECTED,
    JOB_APPLICATION_STATUS_REJECTED_BY_ADMIN,
    JOB_APPLICATION_STATUS_REVIEWED_BY_ADMIN,
    JOB_APPLICATION_STATUS_SENT_OFFER
} from "../../../constants";
import {ModalFooter} from "react-bootstrap";
import {toast} from "react-toastify";
import {updateJobApplication} from "../../../redux/job_applicants/actions";


class UpdateApplicationModal extends React.Component {
    constructor(props) {
        super(props);

        const {update_application_modal} = props;
        const applicant = update_application_modal.data;

        this.state = {
            status: applicant.status,
            admin_comment: applicant.admin_comment,
            admin_score: applicant.admin_score,
            company_comment: applicant.company_comment,
            company_score: applicant.company_score,
            hiring_date: applicant.hiring_date
        };
    }

    saveApplicant() {
        const {status, company_comment, hiring_date} = this.state;
        const {updateJobApplication, update_application_modal} = this.props;

        if (company_comment && company_comment.trim().length === 0 && status >= JOB_APPLICATION_STATUS_REJECTED) {
            toast.error("Please add a review for the candidate.")
            return;
        }

        if (status === JOB_APPLICATION_STATUS_HIRED && !hiring_date) {
            toast.error("Please add a hiring date.")
            return;
        }

        updateJobApplication(update_application_modal.data.id, this.state);
    }

    render() {
        const {status} = this.state;
        const {update_application_modal, toggleModal, user} = this.props;
        const applicant = update_application_modal.data;

        return (
            <Modal isOpen={true} size={"sm"} centered={true} contentClassName={"b-r-20 bg-dark-gray"}>
                <ModalHeader className={"justify-content-center py-5 p-relative custom-close-btn"} toggle={toggleModal}>
                    <span className={"font-weight-bold f-25"}>{applicant.person.full_name}</span> <span
                    className={"f-10 mb-3"}>({applicant.job.title})</span>
                </ModalHeader>
                <ModalBody className={"p-0"}>
                    {user && !user.is_super_admin && (
                        <div className="p-5">
                            <h4>System Review</h4>
                            <div className="m-b-10">
                                <Label>Score</Label> <br/>
                                <b>{applicant.admin_score}%</b>
                            </div>
                            <div className="m-b-10">
                                <Label>Review</Label> <br/>
                                <b>{applicant.admin_comment}</b>
                            </div>
                        </div>
                    )}
                    {user && user.is_super_admin && (
                        <div className="p-5">
                            <h4>System Review</h4>
                            <div className="m-b-10">
                                <Label>Score(0-100)</Label>
                                <input className="form-control" type={"number"}
                                       defaultValue={applicant.admin_score}
                                       onChange={(e) => this.setState({"admin_score": parseInt(e.target.value)})}
                                />
                            </div>
                            <div>
                                <Label>Review</Label>
                                <textarea
                                    rows={5}
                                    className="form-control"
                                    defaultValue={applicant.admin_comment}
                                    onChange={(e) => this.setState({"admin_comment": e.target.value})}
                                ></textarea>
                            </div>
                        </div>
                    )}
                    <hr className={"m-0"} />
                    <div className="p-5">
                        <h4>Company Review</h4>
                        <div className="m-b-10">
                            <Label>Status</Label>
                            <select className={"form-control"} defaultValue={applicant.status} onChange={(event) => {
                                var newStatus = event.target.value;

                                this.setState({
                                    "status": parseInt(newStatus)
                                })
                            }}>
                                {user.is_super_admin && <option value={JOB_APPLICATION_STATUS_APPLIED}>Applied</option>}
                                {user.is_super_admin && <option value={JOB_APPLICATION_STATUS_REJECTED_BY_ADMIN}>Rejected By Admin</option>}
                                <option value={JOB_APPLICATION_STATUS_REVIEWED_BY_ADMIN}>{user.is_super_admin ? "Reviewed By Admin" : "Applied"}</option>
                                <option
                                    value={JOB_APPLICATION_STATUS_REJECTED}>Rejected {user.is_super_admin && "by company"}</option>
                                <option
                                    value={JOB_APPLICATION_STATUS_INVITED_FOR_INTERVIEW}>Invited for Interview
                                </option>
                                <option
                                    value={JOB_APPLICATION_STATUS_SENT_OFFER}>Sent offer
                                </option>
                                <option
                                    value={JOB_APPLICATION_STATUS_HIRED}>Hired
                                </option>
                            </select>
                        </div>
                        <div className="m-b-10">
                            <Label>Score(0-100)</Label>
                            <input className="form-control" type={"number"}
                                   defaultValue={applicant.company_score}
                                   onChange={(e) => this.setState({"company_score": parseInt(e.target.value)})}
                            />
                        </div>
                        <div className="m-b-10">
                            <Label>Review</Label>
                            <textarea
                                rows={5}
                                className="form-control"
                                defaultValue={applicant.company_comment}
                                onChange={(e) => this.setState({"company_comment": e.target.value})}
                            ></textarea>
                        </div>
                        {status === JOB_APPLICATION_STATUS_HIRED && (
                            <div className="m-b-10">
                                <Label>Hiring Date</Label>
                                <input className="form-control" type={"date"}
                                       defaultValue={applicant.hiring_date}
                                       onChange={(e) => this.setState({"hiring_date": e.target.value})}
                                />
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color={"dark-blue"} className={"flex-grow-1"} size={"lg"}
                            onClick={() => this.saveApplicant()}>SAVE</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    updateJobApplication
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    update_application_modal: state.job_applicants.update_application_modal
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateApplicationModal)
