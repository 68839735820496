import React from 'react';
import {Modal, ModalBody, ModalHeader,} from 'reactstrap';
import {connect} from "react-redux";
import {applyToJob, getJobApplicationFormDetails} from "../../../redux/job/actions";
import {getJobApplicationDetails} from "../../../redux/job_applicants/actions";

class SelectedApplicationModal extends React.Component {
    componentDidMount() {
        const {selected_application_form, getJobApplicationDetails} = this.props;

        getJobApplicationDetails(selected_application_form.data.id);
    }

    parseLink(link) {
        if(link.startsWith("http://") || link.startsWith("https://")) {
            return link;
        } else {
            return "//"+link;
        }
    }

    render() {
        const {selected_application_form, toggleModal} = this.props;
        const job = selected_application_form.data.job;
        const {loading, person} = selected_application_form;

        return (
            <Modal isOpen={true} size={"sm"} centered={true} contentClassName={"b-r-20 bg-dark-gray"}>
                <ModalHeader className={"justify-content-center py-5 p-relative custom-close-btn"} toggle={toggleModal}>
                    <span className={"font-weight-bold f-25"}>{person.full_name}</span> <span
                    className={"f-10 mb-3"}>({job.title})</span>
                </ModalHeader>
                <ModalBody className={"p-0"}>
                    {loading && (
                        <div className="text-center p-5">
                            <h4 className="m-0">Loading...</h4>
                        </div>
                    )}

                    {person && !loading && (
                        <table className={"table"}>
                            <tbody>
                            <tr>
                                <th>Full name</th>
                                <td>{person.full_name}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{person.email}</td>
                            </tr>
                            <tr>
                                <th>Gender</th>
                                <td>{person.gender}</td>
                            </tr>
                            <tr>
                                <th>Birthday</th>
                                <td>{person.birthday}</td>
                            </tr>
                            <tr>
                                <th>Current location</th>
                                <td>{person.current_location}</td>
                            </tr>
                            <tr>
                                <th>Current role</th>
                                <td>{person.current_role}</td>
                            </tr>
                            <tr>
                                <th>Phone number</th>
                                <td>{person.phone_number}</td>
                            </tr>
                            <tr>
                                <th>Biography</th>
                                <td>{person.biography}</td>
                            </tr>
                            <tr>
                                <th>Years of experience</th>
                                <td>{person.years_of_experience}</td>
                            </tr>
                            <tr>
                                <th>CV</th>
                                <td>{person.cv && <a target="_blank" rel="noopener noreferrer" href={(this.parseLink(person.cv.url))}>View</a>}</td>
                            </tr>
                            <tr>
                                <th>Linkedin Profile</th>
                                <td>{person.linkedin_profile_url && <a target="_blank" rel="noopener noreferrer" href={person.linkedin_profile_url}>View</a>}</td>
                            </tr>
                            <tr>
                                <th>Github Profile</th>
                                <td>{person.github_profile && <a target="_blank" rel="noopener noreferrer" href={person.github_profile}>View</a>}</td>
                            </tr>
                            <tr>
                                <th>Other URL</th>
                                <td>{person.other_url && <a target="_blank" rel="noopener noreferrer" href={this.parseLink(person.other_url)}>View</a>}</td>
                            </tr>
                            {person.custom_fields && person.custom_fields.map((field) => {
                                return <tr>
                                    <th>{field.name}</th>
                                    {field.type === "file" && <td><a target="_blank" rel="noopener noreferrer" href={field.value}>View</a> &nbsp; <a href={field.value} download>Download</a></td>}
                                    {field.type !== "file" && <td>{field.value}</td>}
                                </tr>;
                            })}
                            </tbody>
                        </table>
                    )}
                </ModalBody>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    applyToJob,
    getJobApplicationFormDetails,
    getJobApplicationDetails
};

const mapStateToProps = (state, ownProps) => ({
    selected_application_form: state.job_applicants.selected_application_form
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedApplicationModal)
