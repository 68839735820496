import React from 'react';
import {connect} from 'react-redux';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import {verifyAlreadyContactedCommunities} from "../../../redux/campaign/actions";

class CreateCampaignModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            new_campaign: {
                message: "",
                selected_list_id: 0,
            },
            selectedList: {
                total_communities: 0,
            },
            charLimit: 1000,
        };
    }

    componentDidMount() {
        const {list} = this.props;
        const {new_campaign} = this.state;
        if(list) {
            this.setState({
                new_campaign: {
                    ...new_campaign,
                    selected_list_id: list.id,
                },
                selectedList: list,
            })
        }
    }

    render() {
        const {community_lists, verifyAlreadyContactedCommunities, closeModal, list, credits} = this.props;
        const {new_campaign, selectedList} = this.state;

        return (
            <Modal isOpen={true}>
                <ModalHeader>
                    Create Campaign
                </ModalHeader>
                <ModalBody>
                    <select className={"form-control"}
                            value={new_campaign.selected_list_id}
                            onChange={(e) => {
                                this.setState({
                                    new_campaign: {
                                        ...new_campaign,
                                        selected_list_id: Number.parseInt(e.target.value)
                                    },
                                    selectedList: community_lists.filter((list) => list.id === e.target.value)[0],
                                });
                            }}
                    >
                        {list && <option key={list.id} value={list.id}>{list.name}</option>}
                        {!list && <option value={0} key={0}>Choose a list</option>}

                        {!list && community_lists.filter((list) => list.total_communities > 0).map((list) => {
                            return (<option key={list.id} value={list.id}>{list.name}</option>);
                        })}}
                    </select>
                    <br />
                    <textarea
                        style={{resize: "none"}}
                        rows="10"
                        className="form-control my-3 p-3"
                        value={new_campaign.message}
                        placeholder={"Message"}
                        onChange={(e) =>{
                        this.setState({
                            new_campaign: {
                                ...new_campaign,
                                message: e.target.value.slice(0, this.state.charLimit),
                            }
                        })
                    }} />
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between">
                            {selectedList && <span>Using <b>{selectedList.total_communities}</b> of <b>{credits}</b> remaining Credits</span>}
                            <span>{this.state.charLimit - new_campaign.message.length}/1000</span>
                        </div>
                        <div>
                            {selectedList.total_communities > credits &&(<span className="text-danger">*You don't have enough credits to create the campaign</span>)}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {closeModal();}}>Close</Button>
                    <Button disabled={selectedList.total_communities > credits} className={"text-white"} color="green" onClick={() => {
                        if (new_campaign.selected_list_id !== 0 && new_campaign.message) {
                            verifyAlreadyContactedCommunities(new_campaign);
                            closeModal();
                        }
                    }}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapDispatchToProps = {verifyAlreadyContactedCommunities};

const mapStateToProps = (state, ownProps) => ({
    community_lists: state.community_list.list,
    credits: state.customer.data.credits,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateCampaignModal)
