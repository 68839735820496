// Campaign constants
export const CAMPAIGN_EMAIL_TYPE_COMMUNITY = "community";
export const CAMPAIGN_EMAIL_TYPE_CONTACTED_MEMBER = "contacted_member";
export const CAMPAIGN_EMAIL_TYPE_NOT_CONTACTED_MEMBER = "not_contacted_member";
export const CAMPAIGN_EMAIL_PLACEHOLDER_COMPANY_NAME = "Company Name";
export const CAMPAIGN_EMAIL_PLACEHOLDER_SIGNATURE = "Signature";

export const CAMPAIGN_STATUS_CANCELLED = 0;
export const CAMPAIGN_STATUS_WAITING = 1;
export const CAMPAIGN_STATUS_IN_PROGRESS = 2;
export const CAMPAIGN_STATUS_READY = 3;
export const CAMPAIGN_STATUS_COMPLETED = 4;
export const CAMPAIGN_STATUS_FETCHING_EMAILS = 5;
export const CAMPAIGN_STATUS_FETCHED_EMAILS = 6;
export const CAMPAIGN_STATUS_SENT_TO_MAIL_MARKETING_TOOL = 7;
export const CAMPAIGN_STATUS_MESSAGES_SENT = 8;

// Campaign create
export const CAMPAIGN_CONTACT_MODE_ALL = 1;
export const CAMPAIGN_CONTACT_MODE_ONLY_PREV_CONTACTED = 2;
export const CAMPAIGN_CONTACT_MODE_ONLY_NOT_PREV_CONTACTED = 3;

// List
export const MAX_NUMBER_OF_COMMUNITIES_TO_ADD_ENTIRE_SEARCH = 10000;

// Jobs
export const MAX_DAYS_FOR_NEW_JOB_BADGE = 3;

// Service
export const PRICE_TYPE_FIXED = "1";
export const PRICE_TYPE_RANGE = "2";
export const PRICE_TYPE_PRIVATE = "3";
export const RECURRENCE_TYPE_MONTHLY = "1";
export const RECURRENCE_TYPE_YEARLY = "2";

// Terms of Service And Privacy
export const TOS_LINK = "https://app.wetipp.com/the-community-atlas/pages/terms-of-service-2";
export const PRIVACY_POLICY_LINK = "https://app.wetipp.com/the-community-atlas/pages/privacy-policy-6";

// Regex
export const YOUTUBE_VIDEO_LINK_REGEX = "^https:\/\/?(www\\.youtube\\.com|youtu\\.?be)\\/((watch\\?v=)?([a-zA-Z0-9]{11}))(&.*)*$"
export const MEETUP_GROUP_PROFILE_REGEX = "^https:\/\/[w]{3}\.meetup\.[com]{3}\/[a-z].*$"
export const EMAIL_REGEX = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$"
export const LINKEDIN_REGEX = "^https:\\/\\/[a-z]{2,3}\\.linkedin\\.[a-z]{2,3}\\/in\\/[a-z].*$"
// export const GITHUB_REGEX = "^https:\\/\\/[a-z]{2,3}\\.github\\.[a-z]{2,3}\\/[a-z].*$"
export const GITHUB_REGEX = "^(?:https?:\\/\\/)?(?:www\\.)?github\\.[a-zA-Z0-9./]+$"

export const OAUTH_STATUS_CONNECTING = 1;
export const OAUTH_STATUS_CONNECTED = 2;
export const OAUTH_STATUS_ERROR = 3;

export const JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE = 1;
export const JOB_APPLICATION_FORM_FIELD_OPTIONAL = 2;
export const JOB_APPLICATION_FORM_FIELD_REQUIRED = 3;

export const JOB_APPLY_LINK_TYPE_EXTERNAL = 1;
export const JOB_APPLY_LINK_TYPE_INTERNAL = 2;

export const JOB_APPLICATION_STATUS_APPLIED = 1;
export const JOB_APPLICATION_STATUS_REJECTED_BY_ADMIN = 2;
export const JOB_APPLICATION_STATUS_REVIEWED_BY_ADMIN = 3;
export const JOB_APPLICATION_STATUS_REJECTED = 4;
export const JOB_APPLICATION_STATUS_INVITED_FOR_INTERVIEW = 5;
export const JOB_APPLICATION_STATUS_SENT_OFFER = 6;
export const JOB_APPLICATION_STATUS_HIRED = 7;

export const GENDER_MALE = "m";
export const GENDER_FEMALE = "f";
export const GENDER_OTHER = "o";

export const PAYMENT_TYPE_FIAT = 1;
export const PAYMENT_TYPE_CRYPTO = 2;