import {
    CHANGE_PASSWORD,
    LOAD_USER,
    LOGIN_USER,
    LOGOUT_USER,
    RECOVER_PASSWORD,
    REGISTER_USER,
    SAVE_NEW_PASSWORD,
    SET_USER_AUTH_TOKEN,
    UPDATE_USER
} from "../action_types";

export function loadUser() {
    return {
        type: LOAD_USER,
        api: true
    }
}

export function updateUser(user) {
    return {
        type: UPDATE_USER,
        api: true,
        payload: user,
        fetch_safe: true,
    }
}

export function loginUser(email, password, is_redeeming) {
    return {
        type: LOGIN_USER,
        api: true,
        payload: {
            email,
            password,
            is_redeeming
        }
    }
}

export function registerUser(firstname, lastname, email, password, is_redeeming) {
    return {
        type: REGISTER_USER,
        api: true,
        payload: {
            firstname,
            lastname,
            email,
            password,
            is_redeeming
        }
    }
}

export function logoutUser() {
    return {
        type: LOGOUT_USER,
    }
}

export function requestRecoverPassword(email) {
    return {
        type: RECOVER_PASSWORD,
        api: true,
        payload: {
            email
        }
    }
}

export function saveNewPassword(password, repeatPassword, token) {
    return {
        type: SAVE_NEW_PASSWORD,
        api: true,
        payload: {
            password,
            repeat_password: repeatPassword,
            token
        }
    }
}

export function changePassword(fields) {
    return {
        type: CHANGE_PASSWORD,
        api: true,
        payload: {
            old_password: fields.current_password,
            new_password: fields.new_password,
            repeat_password: fields.repeat_password,
        }
    }
}

export function setUserAuthToken(token) {
    return {
        type: SET_USER_AUTH_TOKEN,
        payload: token
    }
}
