import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import Apexchart from "react-apexcharts";
import ConfigDB from "../../../config"
import BlurredPieChart from "../../../assets/images/blured_pie_chart.svg"
import BlurredLineChart from "../../../assets/images/blured_line_chart.svg"
import Blur from '../../common/helpers/blur'
import NumberFormatter from "../../utils/number_formatter";

function generatePieChart(series, labels) {
    return {
        series: series,
        options: {
            chart: {
                width: 200,
                type: "donut",
            },
            labels: labels,
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '50%'
                    }
                }
            },
            legend: {
                show: false
            },
            colors: [ConfigDB.data.color.primary_color, ConfigDB.data.color.grey_color],
        },
    };
}

function BlurredPieChartImage() {
    return (<img src={BlurredPieChart} width={200}/>);
}

function BlurredLineChartImage() {
    return (<img src={BlurredLineChart} width={"100%"}/>);
}

function GenerateApexChart(props) {
    const config = generatePieChart(props.series, props.labels);

    return (
        <Apexchart
            options={config.options}
            series={config.series}
            type="donut"
            width={200}
        />
    );
}

function CombinedEventsChart(props) {
    const {community} = props;

    const mergedGraphsOfEvents = {
        options: {
            stroke: {
                curve: 'smooth'
            },
            labels: Object.keys(community.event_insights.progressive),
            colors: ['#008FFB', '#FF4560'],
            yaxis: {
                title: {
                    text: 'Attendees',
                },
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + " attendees";
                        }

                        return y;
                    }
                }
            },
        },
        series: [{
            name: 'Attendees',
            type: 'line',
            data: Object.values(community.event_insights.progressive)
        }, {
            name: 'Attendees trending line',
            type: 'line',
            data: Object.values(community.event_insights.average)
        }]
    };

    return (
        <Apexchart
            options={mergedGraphsOfEvents.options}
            series={mergedGraphsOfEvents.series}
            height={300}
        />
    );
}

function CommunityOfflineEngagement(props) {
    const {community} = props;
    var currentYear = (new Date()).getFullYear();

    function UseBlur(localProps) {
        const {community} = props;
        return (<Blur radius={community.public === "true" ? "0" : "5px"}>{localProps.children}</Blur>);
    }

    return (
        <Card>
            <CardBody className={"pt-4 pb-2"}>
                <Row>
                    <Col xs={12} sm={12} md={6}>
                        <h4 className={"grey-text"}><b>OFFLINE ENGAGEMENT</b></h4>
                        <h6 className={"m-b-0 m-t-30"}><b>Number of
                            events({currentYear}): <UseBlur><strong><NumberFormatter
                                value={community.current_year_events}/></strong></UseBlur></b></h6>
                        <small>Number of events({currentYear - 1}): <UseBlur><b><NumberFormatter
                            value={community.last_year_events}/></b></UseBlur></small>
                        <h6 className={"m-t-10 m-b-10"}><b>Average event
                            participants({currentYear}): <UseBlur><strong>{community.avg_participants_per_event}</strong></UseBlur></b>
                        </h6>
                        <h6><b>Average events per
                            month({currentYear}): <UseBlur><strong>{community.avg_events_per_month}</strong></UseBlur></b>
                        </h6>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <Row>
                            <Col className={"text-center"} xs={12} sm={12} md={6} lg={4}>
                                <h5>Paid VS <br/> Free Events</h5>
                                <div style={{"width": 200, "margin": "0 auto"}}>
                                    {community.public === "true" && (<GenerateApexChart
                                            series={[parseFloat(community.percentage_of_free_events), parseFloat(community.percentage_of_paid_events)]}
                                            labels={["Free", "Paid"]}/>
                                    ) || (<BlurredPieChartImage/>)}
                                </div>
                            </Col>
                            <Col className={"text-center"} xs={12} sm={12} md={6} lg={4}>
                                <h5>Online VS <br/> Offline Events</h5>
                                <div style={{"width": 200, "margin": "0 auto"}}>
                                    {community.public === "true" && (<GenerateApexChart
                                            series={[parseFloat(community.percentage_of_online_events), parseFloat(community.percentage_of_offline_events)]}
                                            labels={["Online", "Offline"]}/>
                                    ) || (<BlurredPieChartImage/>)}
                                </div>
                            </Col>
                            <Col className={"text-center"} xs={12} sm={12} md={6} lg={4}>
                                <h5>Average % <br/> of attendees</h5>
                                <div style={{"width": 200, "margin": "0 auto"}}>
                                    {community.public === "true" && (
                                        <GenerateApexChart
                                            series={[parseFloat(community.avg_percentage_of_attendees), parseFloat(100 - community.avg_percentage_of_attendees)]}
                                            labels={["Attendees", "Non attendees"]}/>
                                    ) || (<BlurredPieChartImage/>)}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {community.public === "true" && (<CombinedEventsChart community={community}/>) || (
                            <BlurredLineChartImage/>)}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default CommunityOfflineEngagement;
