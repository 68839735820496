import React from "react";
import {Label} from "reactstrap";
import {connect} from "react-redux";
import {
    loadCommunities,
    resetCommunitiesPage,
    toggleNewCommunitiesFilter,
} from '../../../redux/community/actions';

class NewCommunitiesFilter extends React.Component {
    render() {
        const {loadCommunities, resetCommunitiesPage, newCommunityFilter, toggleNewCommunitiesFilter} = this.props;

        return (
            <div>
                <div className="faq-form m-b-10 d-flex">
                    <div className={"size-20 checkbox-container " + (newCommunityFilter ? "active full" : "")}
                         onClick={() => {
                            toggleNewCommunitiesFilter(!newCommunityFilter);
                            resetCommunitiesPage();
                            loadCommunities();
                         }}>
                        <i className={"fa fa-check"}/>
                    </div>
                    <Label className={"ml-2"}>
                        Added in the last 30 days
                    </Label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    newCommunityFilter: state.community.list_filters.new_communities_filter,
});

const mapDispatchToProps = {
    toggleNewCommunitiesFilter,
    resetCommunitiesPage,
    loadCommunities
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewCommunitiesFilter)