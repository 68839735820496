import React from "react";
import {Label} from "reactstrap";
import {connect} from "react-redux";
import {
    loadCommunities,
    resetCommunitiesPage,
    toggleWithVerifiedAdminsFilter,
} from '../../../redux/community/actions';

class WithVerifiedAdminsFilter extends React.Component {
    render() {
        const {loadCommunities, resetCommunitiesPage, withVerifiedAdmins, toggleWithVerifiedAdminsFilter} = this.props;

        return (
            <div>
                <div className="faq-form m-b-10 d-flex">
                    <div className={"size-20 checkbox-container " + (withVerifiedAdmins ? "active full" : "")}
                         onClick={() => {
                             toggleWithVerifiedAdminsFilter();
                             resetCommunitiesPage();
                             loadCommunities();
                         }}>
                        <i className={"fa fa-check"}/>
                    </div>
                    <Label className={"ml-2"}>
                        With verified admins
                    </Label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    withVerifiedAdmins: state.community.list_filters.with_verified_admins,
});

const mapDispatchToProps = {
    toggleWithVerifiedAdminsFilter,
    resetCommunitiesPage,
    loadCommunities
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithVerifiedAdminsFilter)