import React from "react";
import {Badge, Card, CardBody, CardHeader} from "reactstrap";
import ShowMoreComponent from "../../common/helpers/show_more_component";

function CommunityDescription(props) {
    const {community} = props;
    const MAX_TOPICS_BEFORE_SLICE = 24;

    function buildTopicsList(community) {
        return community.topics.slice(0, MAX_TOPICS_BEFORE_SLICE).map((topic) => {
            return (<Badge color={"primary"} key={community.id + "-" + topic.id}>#{topic.name}</Badge>);
        });
    }

    function buildTheRestOfTopicsList(community) {
        return community.topics.slice(MAX_TOPICS_BEFORE_SLICE, community.topics.length).map((topic) => {
            return (<Badge color={"primary"} key={community.id + "-" + topic.id}>#{topic.name}</Badge>);
        });
    }

    return (
        <Card>
            <CardHeader className={"pt-4 pb-4"}>
                <h4 className={"m-0 grey-text"}><b>DETAILED DESCRIPTION</b></h4>
            </CardHeader>

            <CardBody>
                <div>
                    <span>{community.description}</span>
                </div>
                <br/>
                <div>
                    {buildTopicsList(community)}
                    {community.topics.length > MAX_TOPICS_BEFORE_SLICE &&
                    (<ShowMoreComponent openText={"Show less tags"} closedText={"Show more tags"}>
                        {buildTheRestOfTopicsList(community)}
                    </ShowMoreComponent>)}
                </div>
            </CardBody>
        </Card>
    );
}

export default CommunityDescription;
