import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, Input, Label,
    Row,
    Table,
} from 'reactstrap';
import {
    clearSolanaTransactionsList,
    getSolanaTransactionsList,
    incrementSolanaTransactionsList
} from "../../redux/solana/actions";
import JobApplicationStatusViewer from "../common/job_board/job_application_status_viewer";
import InfiniteScroll from "react-infinite-scroller";

class SolanaTransactionsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            wallet_filter: ""
        };
    }

    componentDidMount() {
        const {list, loading} = this.props;
        const {getSolanaTransactionsList, clearSolanaTransactionsList} = this.props;

        if (list.length === 0 && loading === false) {
            clearSolanaTransactionsList();
            getSolanaTransactionsList();
        }
    }

    renderTable() {
        const {list, loading} = this.props;
        return (
            <div className="table-responsive">
                <Table hover>
                    <thead>
                    <tr>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <span>Date</span>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <span>Type of action</span>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <span>Community</span>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <span>Job</span>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <span>Person Wallet</span>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <span>Community Wallet</span>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <span>Referrer Wallet</span>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <span>Bounty</span>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <span>Referral Status</span>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <span>Job Application Status</span>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <span>See in explorer</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderList()}
                    {!loading && list.length === 0 && this.renderNoResult()}
                    </tbody>
                </Table>
            </div>
        );
    }

    parseTransactions(transactionList) {
        const parseCommunityTransaction = (transaction) => {
            return {
                "id": transaction.id+"-"+transaction.community_id,
                "timestamp": transaction.timestamp,
                "date": transaction.date,
                "type": "community",
                "community_name": transaction.community_name,
                "community_wallet": transaction.community_wallet,
                "solana_payment_transaction_id": transaction.community_payment_transaction_id,
                "job_id": transaction.job_id,
                "job_title": transaction.job_title,
                "person_wallet": null,
                "person_account_key": null,
                "bounty": transaction.community_bounty,
                "currency": transaction.currency,
                "referrer_wallet": null,
                "job_application_status": transaction.job_application_status
            };
        }

        var list = [];

        transactionList.forEach((transaction) => {
            list.push(transaction);

            if (transaction.community_id) {
                list.push(parseCommunityTransaction(transaction));
            }
        });

        return list;
    }


    renderList() {
        const {list} = this.props;
        const tableRows = [];

        this.parseTransactions(list).forEach((transaction) => {
            tableRows.push(
                <tr key={transaction.id + "-" + transaction.type}>
                    <td>
                        <div style={{"width": "100px"}}>
                            {transaction.date}
                        </div>
                    </td>
                    <td>{this.renderActionType(transaction)}</td>
                    <td>{transaction.community_name}</td>
                    <td>{transaction.job_title}</td>
                    <td>{transaction.person_wallet}</td>
                    <td>{transaction.community_wallet}</td>
                    <td>{transaction.referrer_wallet}</td>
                    <td>{transaction.bounty} {transaction.currency}</td>
                    <td>
                        {transaction.solana_payment_transaction_id && (
                            <a
                                target="_blank"
                                href={`https://explorer.solana.com/tx/${transaction.solana_payment_transaction_id}?cluster=mainnet-beta`}>
                                <span className={"badge bg-primary text-white"}>
                                    PAID <i className={"fa fa-link"}></i>
                                </span>
                            </a>
                        )}

                        {!transaction.solana_payment_transaction_id && (
                            <span className={"text-success"}>Saved on chain</span>
                        )}
                    </td>
                    <td><JobApplicationStatusViewer status={transaction.job_application_status} user={null}/></td>
                    <td>
                        {transaction.person_account_key && (
                            <a
                                target="_blank"
                                href={`https://explorer.solana.com/address/${transaction.person_account_key}?cluster=mainnet-beta`}>
                                Link
                            </a>
                        )}
                    </td>
                </tr>
            );
        });

        return tableRows;
    }

    renderActionType(transaction) {
        if (transaction.type === "referral") {
            if (!transaction.referrer_wallet) {
                return "Referred by Community";
            } else {
                return "Referred";
            }
        } else if (transaction.type === "community") {
            return "Community";
        } else {
            return "Application";
        }
    }

    renderLoading() {
        return (
            <div className="w-100 p-absolute d-flex flex-column align-items-center purple-text communities-loader">
                <div className={"my-2"}>
                    <i className={"fa fa-spinner fa-spin fa-3x"}/>
                </div>
                <h4 className="m-0">Loading...</h4>
            </div>
        );
    }

    renderNoResult() {
        return (
            <tr>
                <td colSpan={11}>
                    <div className="text-center p-5 mt-5">
                        <h5 className="m-0 mt-3 purple-text">No transactions found!</h5>
                    </div>
                </td>
            </tr>
        )
    }

    loadMoreJobTransactions() {
        const {incrementSolanaTransactionsList, getSolanaTransactionsList} = this.props;
        const {wallet_filter} = this.state;

        incrementSolanaTransactionsList();
        getSolanaTransactionsList(wallet_filter);
    }

    render() {
        const {page, no_more_results, loading} = this.props;
        const {clearSolanaTransactionsList, getSolanaTransactionsList} = this.props;

        return (
            <Fragment>
                <Breadcrumb title="Referral Chain" subTitle="Filter by your interests"/>
                <Container fluid={true}>
                    <Row>
                        <Col xl="2">
                            <Card>
                                <CardHeader className={'p-4'}>
                                    <h5 className="mb-0">Filter by:</h5>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <div>
                                        <Label className="d-block">
                                            Wallet id:
                                        </Label>

                                        <div className="faq-form m-b-10">
                                            <Input type={"text"}
                                                   placeholder={"Search here..."}
                                                   onChange={(e) => {
                                                       let value = e.target.value;

                                                       this.setState({
                                                           wallet_filter: value
                                                       })

                                                       if (value.length === 0) {
                                                           clearSolanaTransactionsList();
                                                           getSolanaTransactionsList();
                                                       } else if (value.length > 40) {
                                                           clearSolanaTransactionsList();
                                                           getSolanaTransactionsList(value);
                                                       }
                                                   }}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="10">
                            <Card>
                                <CardBody>
                                    {loading && this.renderLoading()}

                                    <InfiniteScroll
                                        pageStart={page}
                                        loadMore={() => {
                                            this.loadMoreJobTransactions();
                                        }}
                                        hasMore={!no_more_results && !loading}
                                    >
                                        {this.renderTable()}
                                    </InfiniteScroll>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {getSolanaTransactionsList, incrementSolanaTransactionsList, clearSolanaTransactionsList};

const mapStateToProps = (state, ownProps) => ({
    filters: state.solana.transactions.filters,
    list: state.solana.transactions.list,
    loading: state.solana.transactions.loading,
    no_more_results: state.solana.transactions.no_more_results
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SolanaTransactionsPage)
