import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Popover,
    PopoverBody,
    Row,
    Table,
} from 'reactstrap';
import {
    decrementCommunitiesPage,
    incrementCommunitiesPage,
    loadCommunities,
    resetCommunitiesPage,
    setMinMembersConditionForCommunities
} from '../../redux/community/actions'
import CommunityCategories from "../common/helpers/community_categories";
import LocationFilter from "../community/community_filters/location_filter";
import CategoryFilter from "../community/community_filters/category_filter";
import TopicFilter from "../community/community_filters/topic_filter";
import {Link} from "react-router-dom";
import UseBlurOnCommunity from "../community/components/use_blur_on_community";
import OrderCommunitiesList from "../community/components/order_communities_list";
import AddToListModal from "../community/components/add_to_community_list_modal";
import {PlusCircle} from "react-feather";
import {searchCommunityList} from "../../redux/community_list/actions";
import SocialMediaFilter from "../community/community_filters/social_media_filter";
import NumberFormatter from "../utils/number_formatter";
import {toast} from 'react-toastify';
import ScaleBuilder from "./utilities/scale_builder";
import CommunityTypeFilter from "./community_filters/community_type_filter";
import CommunityNetworkFilter from "./community_filters/community_network_filter";
import NewCommunitiesFilter from "./community_filters/new_communities_filter";
import ExcludeTopicFilter from "./community_filters/exclude_topic_filter";
import ShowMoreComponent from "../common/helpers/show_more_component";
import MemberNumberSlider from "./community_filters/member_number_slider";
import {MAX_NUMBER_OF_COMMUNITIES_TO_ADD_ENTIRE_SEARCH} from "../../constants";
import CommunityNameFilter from "./community_filters/community_name_filter";
import RedeemedCommunityFilter from "./community_filters/redeemed_community_filter";
import CommunityWithVerifiedAdminFilter from "./community_filters/community_with_verified_admin_filter";

class CommunityListPage extends React.Component {
    constructor(props) {
        super(props);
        this.closeAddToListModal = this.closeAddToListModal.bind(this);
        this.state = {
            select_all_communities: false,
            selected_community_ids_to_add_in_list: [],
            add_community_to_list_modal_is_open: false,
            add_all_communities_to_list: false,
            selected_existing_list_ids: [],
            add_all_popover_open: false,
        };
    }

    setSelectedCommunityIdsToAddInList(ids) {
        this.setState({
            selected_community_ids_to_add_in_list: ids
        });
    }

    clearTemporaryData() {
        this.setState({
            selected_community_ids_to_add_in_list: [],
            select_all_communities: false,
            selected_existing_list_ids: []
        });
    }

    addCommunityIdToAddToList(id) {
        if (this.state.selected_community_ids_to_add_in_list.indexOf(id) > -1) {
            return;
        }

        var selectedCommunityIdsToAddInList = this.state.selected_community_ids_to_add_in_list;
        selectedCommunityIdsToAddInList.push(id);

        this.setState({
            selected_community_ids_to_add_in_list: selectedCommunityIdsToAddInList
        });
    }

    removeCommunityIdFromAddToList(id) {
        var index = this.state.selected_community_ids_to_add_in_list.indexOf(id);

        if (index > -1) {
            var selectedCommunityIdsToAddInList = this.state.selected_community_ids_to_add_in_list;
            selectedCommunityIdsToAddInList.splice(index, 1);

            this.setState({
                selected_community_ids_to_add_in_list: selectedCommunityIdsToAddInList
            });
        }
    }

    setAddAllCommunitiesToList(value) {
        this.setState({
            add_all_communities_to_list: value
        });
    }

    showAddCommunityToListModal(isAddingAllCommunitiesToList) {
        if (!isAddingAllCommunitiesToList && this.state.selected_community_ids_to_add_in_list.length === 0) {
            toast.warn("Please select one or more communities!");
            return;
        }

        this.setState({
            add_community_to_list_modal_is_open: true
        });
    }

    toggleCommunityInList(communityId) {
        if (this.state.selected_community_ids_to_add_in_list.indexOf(communityId) > -1) {
            this.removeCommunityIdFromAddToList(communityId)
        } else {
            this.addCommunityIdToAddToList(communityId)
        }
    }

    communityIsChecked(communityId) {
        return this.state.selected_community_ids_to_add_in_list.indexOf(communityId) > -1;
    }

    closeAddToListModal() {
        this.setState({
            add_community_to_list_modal_is_open: false,
            selected_community_ids_to_add_in_list: [],
            select_all_communities: false,
            selected_existing_list_ids: []
        })
        this.setAddAllCommunitiesToList(false);
    }

    componentDidMount() {
        const {loadCommunities, communityList, communitiesLoading} = this.props;

        if (communityList.length === 0 && communitiesLoading === false) {
            loadCommunities();
        }
    }

    componentWillUnmount() {
        this.closeAddToListModal();
    }

    loadInitial() {
        const {loadCommunities, resetCommunitiesPage} = this.props;

        resetCommunitiesPage();
        loadCommunities();
    }

    isNewCommunity(community) {
        let differenceInTime = new Date().getTime() - new Date(community.created_at).getTime();
        let differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return differenceInDays <= 30;
    }

    renderTable() {
        const {currentUser, filterForNetworks, communitiesLoading, totalCommunities} = this.props;
        const {add_all_popover_open} = this.state;

        return (
            <div className="table-responsive">
                <Table hover>
                    <thead>
                    <tr>
                        <th scope="col" className={'border-top-0 border-bottom-0'}/>
                        <th scope="col" className={'border-top-0 border-bottom-0 p-t-0'} colSpan={7}>
                            {currentUser && !currentUser.is_community && !filterForNetworks && (
                                <div className={"d-flex align-items-center"}>
                                    {this.buildSelectAllComponent()}
                                    <div className={"d-flex purple-text text-center pointer align-items-center"}

                                    >
                                        <PlusCircle width={50} color={"#7B28CD"}
                                                    onClick={() => {
                                                        if (!communitiesLoading) {
                                                            this.showAddCommunityToListModal();
                                                        }
                                                    }}
                                        /> | <span id="addEntireSearchToList"
                                                   className={`pl-2 ${totalCommunities > MAX_NUMBER_OF_COMMUNITIES_TO_ADD_ENTIRE_SEARCH ? "cursor-disabled" : ""}`}
                                                   onMouseEnter={() => {
                                                       this.setState({add_all_popover_open: true})
                                                   }}
                                                   onMouseLeave={() => {
                                                       this.setState({add_all_popover_open: false})
                                                   }}
                                                   onClick={() => {
                                                       if (totalCommunities <= MAX_NUMBER_OF_COMMUNITIES_TO_ADD_ENTIRE_SEARCH && totalCommunities > 0 && !communitiesLoading) {
                                                           this.clearTemporaryData();
                                                           this.setAddAllCommunitiesToList(true);
                                                           this.showAddCommunityToListModal(true);
                                                       }
                                                   }}
                                    >Add <NumberFormatter value={totalCommunities}/> results to list</span>
                                        <Popover
                                            placement="top"
                                            isOpen={add_all_popover_open && totalCommunities > MAX_NUMBER_OF_COMMUNITIES_TO_ADD_ENTIRE_SEARCH}
                                            target="addEntireSearchToList"
                                        >
                                            <PopoverBody>
                                                Lists can contain up to {<NumberFormatter
                                                value={MAX_NUMBER_OF_COMMUNITIES_TO_ADD_ENTIRE_SEARCH}/>} communities.
                                            </PopoverBody>
                                        </Popover>
                                    </div>
                                </div>
                            )}
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" className={'border-top-0 border-bottom-0'}/>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <div className={"d-flex"}>
                                <div>Name</div>
                                <OrderCommunitiesList currentField={"name"}/>
                            </div>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Tags</th>
                        {!filterForNetworks && (
                            <th scope="col" className={'border-top-0 border-bottom-0'}>Location</th>)}
                        {filterForNetworks &&
                        (<th scope="col" className={'border-top-0 border-bottom-0'}>
                            <div className={"d-flex"}>
                                <div>Communities</div>
                                <OrderCommunitiesList currentField={"nr_of_communities"}/>
                            </div>
                        </th>)}
                        {filterForNetworks &&
                        (<th scope="col" className={'border-top-0 border-bottom-0'}>
                            <div className={"d-flex"}>
                                <div>Countries</div>
                                <OrderCommunitiesList currentField={"nr_of_countries"}/>
                            </div>
                        </th>)}
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <div className={"d-flex"}>
                                <div>Members</div>
                                <OrderCommunitiesList currentField={"total_members"}/>
                            </div>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <div className={"d-flex"}>
                                <div>Global engagement</div>
                                <OrderCommunitiesList currentField={"overall_ranking"}/>
                            </div>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <div className={"d-flex"}>
                                <div>Online engagement</div>
                                <OrderCommunitiesList currentField={"online_ranking"}/>
                            </div>
                        </th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>
                            <div className={"d-flex"}>
                                <div>Offline engagement</div>
                                <OrderCommunitiesList currentField={"offline_ranking"}/>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderCommunitiesList()}
                    </tbody>
                </Table>
                {communitiesLoading && this.renderLoading()}
            </div>
        );
    }

    renderCommunitiesList() {
        const {communityList, currentUser, filterForNetworks} = this.props;
        const tableRows = [];

        if (communityList.length) {
            communityList.forEach((community) => {
                tableRows.push(<tr key={community.community_id}>
                    <td className={"d-flex flex-column justify-content-center border-top-0 p-t-20"}>
                        {currentUser && !currentUser.is_community && !filterForNetworks && (
                            <div
                                className={"size-20 checkbox-container " + (this.communityIsChecked(community.community_id) ? "active full" : "")}
                                onClick={() => {
                                    this.toggleCommunityInList(community.community_id);
                                }}
                            >
                                <i className={"fa fa-check"}/>
                            </div>
                        )}
                    </td>
                    <td>
                        <Link to={"/community/" + community.community_id} className={"green-cta"}>
                            {this.isNewCommunity(community) && (<Badge color={"info"}>NEW</Badge>)}{" "}
                            <u><b>{community.community_name}</b></u>
                        </Link>
                    </td>
                    <td>
                        <CommunityCategories community={community}/>
                    </td>
                    {community.country && community.city
                        ? (<td>{community.country + ", " + community.city}</td>)
                        : !filterForNetworks ? (<td/>) : null}
                    {filterForNetworks && (<td><UseBlurOnCommunity
                        community={community}>
                        <span><NumberFormatter value={community.nr_of_communities}/></span>
                    </UseBlurOnCommunity></td>)}
                    {filterForNetworks && (<td><UseBlurOnCommunity
                        community={community}>
                        <span><NumberFormatter value={community.nr_of_countries}/></span>
                    </UseBlurOnCommunity></td>)}
                    <td><UseBlurOnCommunity
                        community={community}>
                        <span><NumberFormatter value={community.total_members}/></span>
                    </UseBlurOnCommunity></td>
                    <td>
                        <UseBlurOnCommunity
                            community={community}>
                            <ScaleBuilder value={community.overall_ranking} community={community}/>
                        </UseBlurOnCommunity>
                    </td>
                    <td>
                        <UseBlurOnCommunity
                            community={community}>
                            <ScaleBuilder value={community.online_ranking} community={community}/>
                        </UseBlurOnCommunity>
                    </td>
                    <td>
                        <UseBlurOnCommunity
                            community={community}>
                            <ScaleBuilder value={community.offline_ranking} community={community}/>
                        </UseBlurOnCommunity>
                    </td>
                    {currentUser && !currentUser.is_community && (
                        <td className={"fixed-column"}>
                            <div className={"purple-text text-center pointer"} style={{"width": "60px"}}
                                 onClick={() => {
                                     this.setSelectedCommunityIdsToAddInList([community.community_id]);
                                     setTimeout(() => {
                                         this.showAddCommunityToListModal();
                                     }, 300)
                                 }}>
                                <PlusCircle width={50} color={"#7B28CD"}/>
                                <span>Add to list</span>
                            </div>
                        </td>
                    )}

                </tr>);
            });

            return tableRows;
        }

        return null;
    }

    renderLoading() {
        return (
            <div className="w-100 p-absolute d-flex flex-column align-items-center purple-text communities-loader">
                <div className={"my-2"}>
                    <i className={"fa fa-spinner fa-spin fa-3x"}/>
                </div>
                <h4 className="m-0">Loading...</h4>
            </div>
        );
    }

    renderNoResult() {
        return (
            <div className="text-center p-5 mt-5">
                <h5 className="m-0 mt-3 purple-text">There are no communities that match your filter criteria.</h5>
            </div>
        )
    }

    buildSelectAllComponent() {
        var totalSelectedCommunities = this.state.selected_community_ids_to_add_in_list.length;
        const {communityList, communitiesLoading, filterForNetworks} = this.props;

        const selectAllCommunities = () => {
            if (!filterForNetworks) {
                this.setState({
                    selected_community_ids_to_add_in_list: communityList.map((community) => community.community_id)
                })
            }
        };

        const unselectAllCommunities = () => {
            this.setState({
                selected_community_ids_to_add_in_list: []
            })
        };

        const toggleSelectAllCommunityInList = () => {
            if (totalSelectedCommunities === 0) {
                selectAllCommunities();
                this.setState({
                    select_all_communities: true
                });
            } else {
                unselectAllCommunities();
                this.setState({
                    select_all_communities: false
                });
            }
        };

        return (
            <div
                className={"size-20 checkbox-container " + (this.state.select_all_communities ? " active full " : "") + (!this.state.select_all_communities && totalSelectedCommunities > 0 ? " active half-full " : "")}
                onClick={() => {
                    if (!communitiesLoading && !filterForNetworks) {
                        toggleSelectAllCommunityInList();
                    }
                }}
            >
                <i className={"fa fa-check"}></i>
                <b>-</b>
            </div>
        );
    }

    render() {
        const {
            communitiesLoading,
            totalPages,
            currentPage,
            totalCommunities,
            decrementCommunitiesPage,
            incrementCommunitiesPage,
            loadCommunities,
            currentUser,
            filterForNetworks,
            min_members,
            setMinMembersConditionForCommunities,
            resetCommunitiesPage
        } = this.props;
        const {add_community_to_list_modal_is_open} = this.state;

        return (
            <Fragment>
                <Breadcrumb title="Community Analytics" subTitle="Filter by your interests"/>
                <Container fluid={true}>
                    <Row>
                        <Col xl="3">
                            <Card>
                                <CardHeader className={'p-4'}>
                                    <h5 className="mb-0">Filter by:</h5>
                                    {currentUser && (<CommunityTypeFilter/>)}
                                </CardHeader>
                                <CardBody className="p-4">
                                    <CommunityNameFilter/>
                                    <LocationFilter isGlobal={true} onLocationSelected={() => {
                                        this.loadInitial()
                                    }} onLocationUnselected={() => {
                                        this.loadInitial()
                                    }}/>
                                    <CategoryFilter/>
                                    <TopicFilter load={() => {
                                        this.loadInitial()
                                    }}/>
                                    {currentUser && <SocialMediaFilter/>}
                                    {currentUser &&
                                    (<ShowMoreComponent openText={"Close Advanced Search"}
                                                        closedText={"Advanced Search"}>
                                        {!filterForNetworks && (<CommunityNetworkFilter/>)}
                                        {currentUser.is_super_admin && <RedeemedCommunityFilter />}
                                        {currentUser.is_super_admin && <CommunityWithVerifiedAdminFilter />}
                                        <NewCommunitiesFilter/>
                                        <ExcludeTopicFilter/>
                                        <MemberNumberSlider
                                            min_value={0}
                                            max_value={100000}
                                            label={"Min. Members: "}
                                            value={min_members}
                                            action={setMinMembersConditionForCommunities}
                                        />
                                    </ShowMoreComponent>)}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="9">
                            <Card>
                                <CardBody>
                                    {/* Modal */}
                                    {add_community_to_list_modal_is_open && <AddToListModal
                                        inActivityDetail={false}
                                        idsToAddToList={this.state.selected_community_ids_to_add_in_list}
                                        add_all_communities_to_list={this.state.add_all_communities_to_list}
                                        closeModal={this.closeAddToListModal}/>}
                                    {this.renderTable()}
                                    {!communitiesLoading && totalCommunities === 0 && this.renderNoResult()}
                                </CardBody>
                                <CardFooter className={"d-flex"}>
                                    {totalPages <= 1 ? null : (
                                        <Pagination
                                            className="pagination-primary"
                                        >
                                            <PaginationItem disabled={currentPage === 0 || communitiesLoading}>
                                                <PaginationLink onClick={() => {
                                                    decrementCommunitiesPage();
                                                    loadCommunities();
                                                    this.clearTemporaryData();
                                                }}>Previous</PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink
                                                    href="#javascript">{currentPage + 1}/{totalPages}</PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                disabled={currentPage + 1 === totalPages || communitiesLoading}>
                                                <PaginationLink onClick={() => {
                                                    incrementCommunitiesPage();
                                                    loadCommunities();
                                                    this.clearTemporaryData();
                                                }}>Next</PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    )}

                                    <div className={"pt-2 pl-3 purple-text"}>
                                        <span>Total results: <b><NumberFormatter value={totalCommunities}/></b></span>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    loadCommunities,
    resetCommunitiesPage,
    incrementCommunitiesPage,
    decrementCommunitiesPage,
    searchCommunityList,
    setMinMembersConditionForCommunities,
};

const mapStateToProps = (state, ownProps) => ({
    communityList: state.community.list,
    communitiesLoading: state.community.loading,
    totalCommunities: state.community.total_results,
    totalPages: state.community.total_pages,
    currentPage: state.community.page,
    search: state.community_list.search,
    currentUser: state.user.data,
    filterForNetworks: state.community.list_filters.filter_for_networks,
    min_members: state.community.members_range.min_members,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityListPage)
