import React from "react";
import {MapPin} from "react-feather";
import {connect} from "react-redux";
import {Badge} from 'reactstrap';
import user_avatar from "../../../assets/images/user_avatar.png"

class ChannelListItem extends React.Component {
    render() {
        const {currentUser, channels, isInHeader, id, search} = this.props;
        let channel;
        if(search.param === ""){
            channel = channels[id];
        }else {
            channel = search.list[id];
        }

        if(channel){
        return (
            <div className={`d-flex ${isInHeader && "d-md-block"}`}>
                {!currentUser.is_community && (
                    <img src={channel.community.logo !== null ? channel.community.logo.url : user_avatar}
                         className="rounded-circle user-image"
                         alt="user-logo"/>)}
                {currentUser.is_community && (
                    <img src={channel.company.logo !== null ? channel.company.logo.url : user_avatar}
                         className="rounded-circle user-image"
                         alt="user-logo"/>
                )}
                <div className="about d-flex flex-column">
                    {!currentUser.is_community && <div className="name my-auto">{channel.community.name}</div>}
                    {currentUser.is_community && <div className="name my-auto">{channel.company.name}</div>}
                    <div className={"status"}>
                        {isInHeader && !currentUser.is_community &&
                            (
                                <div className="digits d-flex align-items-end">
                                    <MapPin color={"red"} width={15}/>
                                    <span className="pl-1"><b>{channel.community.country}, {channel.community.city}</b></span>
                                </div>
                            )
                        }
                        {!isInHeader && channel.status === 1 && (<span className="text-muted">Pending Request</span>)}
                        {!isInHeader && channel.status === 3 && !currentUser.is_community && (<span className="text-muted">Request Declined</span>)}

                        {!isInHeader && channel.status === 3 && currentUser.is_community && (<span className="text-muted">Request Denied</span>)}
                    </div>
                </div>
                {!isInHeader && channel.total_unread_messages !== 0  && (<div className="align-self-center ml-auto">
                <Badge className="custom-channel-badge" color="primary">{channel.total_unread_messages}</Badge>
                </div>)}
            </div>
        )}
        return(<div></div>)
    }
}

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.data,
    currentChannel: state.channel.current_channel,
    channels: state.channel.list.channels,
    search: state.channel.search_channels
});

export default connect(
    mapStateToProps,
)(ChannelListItem);
