import {LOAD_CUSTOMER, UPDATE_CUSTOMER, LOAD_SUBSCRIPTIONS, UPDATE_CUSTOMER_COUNTRY} from "../action_types";

const initial_state = {
    data: null,
    subscriptions: [],
    loading: false
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case LOAD_CUSTOMER:
            return {...state, loading: true};
        case LOAD_CUSTOMER.concat("_SUCCESS"):
            return {...state, loading: false, data: action.payload.data};
        case LOAD_CUSTOMER.concat("_FAILURE"):
            return {...state, loading: false};
        case UPDATE_CUSTOMER.concat("_SUCCESS"):
            return {...state, token: action.payload.data.token};
        case LOAD_SUBSCRIPTIONS.concat("_SUCCESS"):
            return {...state, subscriptions: action.payload.data};
        case UPDATE_CUSTOMER_COUNTRY:
            const customer = Object.assign({}, state.data);
            customer.country = action.payload;

            return {...state, data: customer};
        default:
            return {...state};
    }
}
