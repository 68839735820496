import React, {Fragment, useRef} from "react";
import {Badge, Label} from "reactstrap";
import {connect} from "react-redux";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {
    loadCommunities,
    resetCommunitiesPage,
    searchTopicsToExcludeFromCommunitySearch,
    selectTopicToExcludeFromCommunitySearch,
    unselectTopicToExcludeFromCommunitySearch,
} from '../../../redux/community/actions';

class ExcludeTopicFilter extends React.Component {
    buildListOfSelectedTopics() {
        const {excludeTopicFilters, unselectTopicToExcludeFromCommunitySearch, loadCommunities, resetCommunitiesPage} = this.props;
        const {selected_topics} = excludeTopicFilters;

        return selected_topics.map((topic) => {
            return (
                <Badge className={"pointer"} color={"info"} key={topic.id + topic.type} onClick={() => {
                    unselectTopicToExcludeFromCommunitySearch(topic);
                    resetCommunitiesPage();
                    loadCommunities();
                }}>{topic.name}{topic.country ? " Area - " + topic.country : ''} x</Badge>
            );
        })
    }

    render() {
        const {loadCommunities, searchTopicsToExcludeFromCommunitySearch, selectTopicToExcludeFromCommunitySearch, excludeTopicFilters, resetCommunitiesPage} = this.props;
        const {searching, options} = excludeTopicFilters;

        return (
            <div>
                <Label className="d-block">
                    Exclude Tag:
                </Label>

                <div className="faq-form m-b-10">
                    <TypeaheadComponent searching={searching}
                                        searchTopicsToExcludeFromCommunitySearch={searchTopicsToExcludeFromCommunitySearch}
                                        options={options}
                                        selectTopicToExcludeFromCommunitySearch={(selectedTopic) => {
                                            selectTopicToExcludeFromCommunitySearch(selectedTopic);
                                            resetCommunitiesPage();
                                        }}
                                        loadCommunities={loadCommunities}/>
                </div>

                <div className="m-b-10">
                    {this.buildListOfSelectedTopics()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    excludeTopicFilters: state.community.excluded_topic_filters
});

const mapDispatchToProps = {
    searchTopicsToExcludeFromCommunitySearch,
    selectTopicToExcludeFromCommunitySearch,
    unselectTopicToExcludeFromCommunitySearch,
    resetCommunitiesPage,
    loadCommunities
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExcludeTopicFilter)

const TypeaheadComponent = (props) => {
    const ref = useRef();

    return (
        <Fragment>
            <AsyncTypeahead
                id="search-for-exclude-topic"
                isLoading={props.searching}
                labelKey="name"
                minLength={3}
                onSearch={props.searchTopicsToExcludeFromCommunitySearch}
                options={props.options}
                placeholder="Type here..."
                renderMenuItemChildren={(option, props) => {
                    return (
                        <Fragment>
                            <span>{option.name}</span>
                        </Fragment>
                    )
                }}
                onChange={(selected) => {
                    if (selected.length > 0) {
                        props.selectTopicToExcludeFromCommunitySearch(selected[0]);
                        props.loadCommunities();
                    }

                    ref.current.clear();
                }}
                ref={ref}
            />
        </Fragment>
    );
};
