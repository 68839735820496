import {
    APPLY_TO_JOB,
    CHANGE_BOUNTY_PORTION,
    DECREMENT_JOB_LIST_PAGE, GET_JOB_APPLICATION_FORM_DETAILS,
    GET_JOB_DETAILS,
    GET_REFERRER_DETAILS,
    INCREMENT_JOB_LIST_PAGE,
    LOAD_JOBS,
    REFER_JOB,
    RESET_JOB_BOARD,
    SET_JOB_BOARD_TOKEN,
    SET_JOB_TOKEN,
    SET_SELECTED_COMMUNITY,
    SUBSCRIBE_TO_JOB_BOUNTIES,
    SUBSCRIBE_TO_JOB_BOUNTIES_VIA_CTA,
    TOGGLE_APPLY_TO_JOB_MODAL,
    TOGGLE_MY_COMPANY_JOB_FILTER,
    TOGGLE_REFER_JOB_MODAL,
    TOGGLE_REMOTE_JOB_FILTER,
    TOGGLE_SUBSCRIBE_TO_JOBS_MODAL,
    TOGGLE_SUCCESS_APPLY_TO_JOB_MODAL,
    TOGGLE_SUCCESS_REFER_JOB_MODAL,
    UPDATE_JOB_APPLICATION_FORM_DATA,
    SAVE_JOB_APPLICATION_FORM
} from "../action_types";

export function loadJobs(token) {
    return {
        type: LOAD_JOBS,
        api: true,
        payload: token ? token : ''
    }
}

export function incrementJobListPage() {
    return {
        type: INCREMENT_JOB_LIST_PAGE
    }
}

export function decrementJobListPage() {
    return {
        type: DECREMENT_JOB_LIST_PAGE
    }
}

export function getJobDetails(token) {
    return {
        type: GET_JOB_DETAILS,
        api: true,
        payload: token
    }
}

export function changeBountyPortions(token, bounty_portion) {
    return {
        type: CHANGE_BOUNTY_PORTION,
        api: true,
        payload: {
            token: token,
            bounty_portion: bounty_portion,
        }
    }
}

export function setSelectedCommunity(community) {
    return {
        type: SET_SELECTED_COMMUNITY,
        payload: community
    }
}

export function getReferrerDetails(token) {
    return {
        type: GET_REFERRER_DETAILS,
        api: true,
        payload: token
    }
}

export function setJobBoardToken(token) {
    return {
        type: SET_JOB_BOARD_TOKEN,
        payload: token
    }
}

export function setJobToken(token) {
    return {
        type: SET_JOB_TOKEN,
        payload: token,
    }
}

export function referJob(data) {
    return {
        type: REFER_JOB,
        api: true,
        payload: data
    }
}

export function toggleReferJobModal() {
    return {
        type: TOGGLE_REFER_JOB_MODAL
    }
}

export function toggleSuccessReferJobModal(data) {
    return {
        type: TOGGLE_SUCCESS_REFER_JOB_MODAL,
        payload: data
    }
}

export function applyToJob(data) {
    return {
        type: APPLY_TO_JOB,
        api: true,
        payload: data
    }
}

export function toggleApplyToJobModal() {
    return {
        type: TOGGLE_APPLY_TO_JOB_MODAL
    }
}

export function toggleSuccessApplyToJobModal() {
    return {
        type: TOGGLE_SUCCESS_APPLY_TO_JOB_MODAL
    }
}

export function resetJobBoard() {
    return {
        type: RESET_JOB_BOARD
    }
}

export function toggleRemoteJobFilter() {
    return {
        type: TOGGLE_REMOTE_JOB_FILTER
    }
}

export function toggleMyCompanyJobFilter() {
    return {
        type: TOGGLE_MY_COMPANY_JOB_FILTER
    }
}

export function subscribeToJobBountiesAfterReferringSomeone(subscriber) {
    return {
        type: SUBSCRIBE_TO_JOB_BOUNTIES,
        api: true,
        payload: {
            subscriber,
            origin: "referral"
        }
    }
}

export function subscribeToJobBountiesViaCTA(subscriber) {
    return {
        type: SUBSCRIBE_TO_JOB_BOUNTIES_VIA_CTA,
        api: true,
        payload: {
            subscriber,
            origin: "cta"
        }
    }
}

export function toggleSubscribeToJobsModal() {
    return {
        type: TOGGLE_SUBSCRIBE_TO_JOBS_MODAL
    }
}

export function getJobApplicationFormDetails(id) {
    return {
        type: GET_JOB_APPLICATION_FORM_DETAILS,
        api: true,
        payload: id
    }
}

export function updateJobApplicationFormData(data) {
    return {
        type: UPDATE_JOB_APPLICATION_FORM_DATA,
        payload: data
    }
}

export function saveJobApplicationForm(jobId, data) {
    return {
        type: SAVE_JOB_APPLICATION_FORM,
        api: true,
        payload: {
            job_id: jobId,
            application_form: data
        }
    }
}