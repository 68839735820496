import React from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import Chart from "react-google-charts";
import BlurredGraph from "./blurred_graph";

class EngagementDistributionReport extends React.Component {

    renderMap() {
        const {report} = this.props;

        const prepareScale = (value) => {
            return (100 - value) / 10;
        };

        const prepareData = (report) => {
            var dataSet = report.map((community) => {
                return [
                    '',
                    prepareScale(parseFloat(community.onr)),
                    prepareScale(parseFloat(community.ofr)),
                    community.n,
                    parseInt(community.t)
                ];
            });

            return [["ID", "Online engagement ranking", "Offline engagement ranking", "Name", "Total members"]].concat(dataSet);
        };
        const dataSet = prepareData(report);

        const options = {
            hAxis: {title: "Online engagement ranking", logScale: true, viewWindowMode: "pretty"},
            vAxis: {title: "Offline engagement ranking", logScale: true, viewWindowMode: "pretty"},
            legend: {
                position: "none"
            },
            tooltip: {
                trigger: 'selection'
            },
        };

        return (
            <Chart
                width={"100%"}
                height={"700px"}
                chartType="BubbleChart"
                loader={<div className={"text-center p-5"}>Loading Chart</div>}
                data={dataSet}
                options={options}
            />
        );
    }

    render() {
        const {report} = this.props;

        if (!report) {
            return null;
        }

        return (
            <Card>
                <CardHeader>
                    <h5>Engagement Distribution</h5>
                </CardHeader>
                <CardBody className={'pl-0 pr-0 pt-0'}>
                    {report.public === false ? <BlurredGraph image={3}/> : this.renderMap()}
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    report: state.dashboard.insights.engagement_distribution.data,
});

export default connect(
    mapStateToProps,
    null
)(EngagementDistributionReport)
