import React, {Fragment} from 'react';
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from "reactstrap";
import {connect} from "react-redux";
import CampaignBlack from "../../assets/images/campaign.svg";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import NumberFormatter from "../utils/number_formatter";
import {
    decrementCampaignsPage,
    incrementCampaignsPage,
    loadCampaigns,
    cancelCampaign,
    closeConfirmContactedCommunitiesModal
} from "../../redux/campaign/actions";
import CreateCampaignModal from "../common/campaign/create_campaign_modal";
import {toast} from "react-toastify";
import ConfirmContactedCommunitiesModal from "../common/campaign/confirm_contacted_communities_modal";
import {
    CAMPAIGN_STATUS_CANCELLED,
    CAMPAIGN_STATUS_COMPLETED,
    CAMPAIGN_STATUS_FETCHED_EMAILS,
    CAMPAIGN_STATUS_FETCHING_EMAILS,
    CAMPAIGN_STATUS_IN_PROGRESS,
    CAMPAIGN_STATUS_MESSAGES_SENT,
    CAMPAIGN_STATUS_READY,
    CAMPAIGN_STATUS_SENT_TO_MAIL_MARKETING_TOOL,
    CAMPAIGN_STATUS_WAITING
} from "../../constants";

class CampaignsListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            create_campaign_modal_open: false,
            campaign_warning_modal_open: false,
            toggleCreateCampaignModal: () => {
                this.setState({
                    create_campaign_modal_open: !this.state.create_campaign_modal_open,
                })
            },
            opened_dropdowns: [],
        };
    }

    toggleDropdown(campaign) {
        const {opened_dropdowns} = this.state;

        var position = opened_dropdowns.indexOf(campaign.id);

        if (position > -1) {
            opened_dropdowns.splice(position, 1);
        } else {
            opened_dropdowns.push(campaign.id);
        }

        this.setState({
            opened_dropdowns: opened_dropdowns
        })
    }

    renderLoading() {
        return (
            <tr>
                <td className="text-center p-5" colSpan={5}>
                    <h4 className="m-0">Loading campaigns</h4>
                </td>
            </tr>
        );
    }

    renderNoResults() {
        const {community_lists} = this.props;

        return (
            <tr>
                <td className="text-center p-5" colSpan={5}>
                    <img src={CampaignBlack} width={50}/>
                    <br/>
                    <br/>
                    <span>No campaigns have been created yet</span>
                    <br/>
                    <u><span className={"pointer purple-text"} onClick={() => {
                        if (community_lists.length === 0) {
                            toast.warn("You need to create Community Lists first!")
                            return;
                        }

                        this.state.toggleCreateCampaignModal();
                    }}>Build your first campaign</span></u>
                </td>
            </tr>
        );
    }

    renderTable() {
        const {campaigns, user, cancelCampaign} = this.props;
        const {opened_dropdowns} = this.state;
        const tableRows = [];

        if (campaigns.length) {
            campaigns.forEach((campaign) => {
                tableRows.push(<tr key={campaign.id}>
                    <td>
                        {user.is_super_admin && campaign.status === CAMPAIGN_STATUS_COMPLETED && (
                            <Link to={"/campaigns/" + campaign.id + "/validate"} className={"green-cta"}>
                                <u><b>{campaign.name}</b></u>
                            </Link>
                        )}

                        {campaign.status !== CAMPAIGN_STATUS_COMPLETED && <span>{campaign.name}</span>}
                    </td>
                    <td><NumberFormatter value={campaign.total_communities}/></td>
                    <td>
                        <Link to={"/lists/" + campaign.community_list_id} className={"green-cta"}>
                            <u><b>{campaign.community_list_name}</b></u>
                        </Link>
                    </td>
                    <td>
                        <span>{this.buildStatusField(campaign, user)}</span>
                    </td>
                    <td>
                        {campaign.status != CAMPAIGN_STATUS_CANCELLED && (
                            <Dropdown isOpen={opened_dropdowns.indexOf(campaign.id) > -1} toggle={() => {
                                this.toggleDropdown(campaign);
                            }}>
                                <DropdownToggle tag={"div"} className={"pointer"}>
                                    . . .
                                </DropdownToggle>
                                <DropdownMenu className={"report-actions-dropdown"}>
                                    {user.is_super_admin &&
                                    [
                                        CAMPAIGN_STATUS_READY,
                                        CAMPAIGN_STATUS_COMPLETED,
                                        CAMPAIGN_STATUS_FETCHING_EMAILS,
                                        CAMPAIGN_STATUS_FETCHED_EMAILS,
                                        CAMPAIGN_STATUS_SENT_TO_MAIL_MARKETING_TOOL,
                                        CAMPAIGN_STATUS_MESSAGES_SENT
                                    ].indexOf(Number.parseInt(campaign.status)) > -1 && (
                                        <DropdownItem>
                                            <Link to={"/campaigns/" + campaign.id + "/validate"}
                                                  className={"green-cta d-block"}>
                                                <span>Validate</span>
                                            </Link>
                                        </DropdownItem>)}
                                    {campaign.status <= CAMPAIGN_STATUS_COMPLETED && <DropdownItem
                                        tag={"span"}
                                        className={"action-item"}
                                        onClick={() => {
                                            cancelCampaign(campaign.id);
                                        }}
                                    >Cancel</DropdownItem>}
                                </DropdownMenu>
                            </Dropdown>)}
                    </td>
                </tr>);
            });

            return tableRows;
        }

        return null;
    }

    buildStatusField(campaign, user) {
        const status = parseInt(campaign.status);

        if(!user.is_super_admin && status === CAMPAIGN_STATUS_CANCELLED) {
            return "Cancelled";
        } else if (!user.is_super_admin && status <= CAMPAIGN_STATUS_FETCHED_EMAILS) {
            return "Contact gathering in process";
        }

        switch (status) {
            case CAMPAIGN_STATUS_CANCELLED:
                return "Cancelled";
            case CAMPAIGN_STATUS_WAITING:
                return "Waiting to start";
            case CAMPAIGN_STATUS_IN_PROGRESS:
                return "Analysis in progress";
            case CAMPAIGN_STATUS_READY:
                return "Ready to validate";
            case CAMPAIGN_STATUS_COMPLETED:
                return "Completed";
            case CAMPAIGN_STATUS_FETCHING_EMAILS:
                return "Fetching Emails";
            case CAMPAIGN_STATUS_FETCHED_EMAILS:
                return "Fetched Emails";
            case CAMPAIGN_STATUS_SENT_TO_MAIL_MARKETING_TOOL:
                return "Sent to mail marketing tool";
            case CAMPAIGN_STATUS_MESSAGES_SENT:
                return "Messages sent";
            default:
                return "";
        }
    }

    render() {
        const {
            loading,
            campaigns,
            user,
            campaign,
            incrementCampaignsPage,
            decrementCampaignsPage,
            loadCampaigns,
            isLoadingUser,
            open_modal_to_verify_already_contacted_communities,
            closeConfirmContactedCommunitiesModal,
        } = this.props;
        const {toggleCreateCampaignModal} = this.state;

        if ((!isLoadingUser && !user) || (user && user.is_community)) {
            return (<Redirect to={"/"}/>);
        }

        return (
            <Fragment>
                {this.state.create_campaign_modal_open &&
                <CreateCampaignModal closeModal={() => {this.setState({create_campaign_modal_open: false})}}/>}
                {open_modal_to_verify_already_contacted_communities && <ConfirmContactedCommunitiesModal closeModal={() => {closeConfirmContactedCommunitiesModal()}}/>}
                <Breadcrumb title="Your campaigns" hideBreadcrumbRight={false} actions={() => {
                    return (
                        <Button color={"green"} onClick={() => {
                            toggleCreateCampaignModal();
                        }}>
                            Create a new campaign
                        </Button>
                    );
                }}/>

                <Container fluid={true}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table hover>
                                            <thead>
                                            <tr>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Name</div>
                                                    </div>
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    Communities
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    Source
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    Status
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {campaigns.length > 0 && this.renderTable()}
                                            {loading ? this.renderLoading() : null}
                                            {!loading && campaigns.length === 0 ? this.renderNoResults() : null}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                                <CardFooter className="d-flex">
                                    {campaign.total_pages <= 1 ? null : (
                                        <Pagination className="pagination-primary">
                                            <PaginationItem disabled={campaign.page === 1}>
                                                <PaginationLink onClick={() => {
                                                    decrementCampaignsPage();
                                                    loadCampaigns();
                                                }}>
                                                    Previous
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#javascript">
                                                    {campaign.page}/{campaign.total_pages}
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem disabled={
                                                campaign.page === campaign.total_pages
                                            }>
                                                <PaginationLink
                                                    onClick={() => {
                                                        incrementCampaignsPage();
                                                        loadCampaigns();
                                                    }}>
                                                    Next
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    )}
                                    <div className={"pt-2 pl-3 purple-text"}>
                                    <span>
                                    Total results:
                                    <b>
                                        <NumberFormatter
                                            value={campaign.total_results}
                                        ></NumberFormatter>
                                    </b>
                                    </span>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {incrementCampaignsPage, decrementCampaignsPage, loadCampaigns, cancelCampaign, closeConfirmContactedCommunitiesModal};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    isLoadingUser: state.user.loading,
    community_lists: state.community_list.list,
    campaigns: state.campaign.list,
    campaign: state.campaign,
    loading: state.campaign.loading,
    open_modal_to_verify_already_contacted_communities: state.campaign.new_campaign.open_modal_to_verify_already_contacted_communities,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignsListPage)

