import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {
    clearCommunityRedeemToken,
    getCommunityToRedeem,
    redeemCommunity,
    setCommunityRedeemToken
} from "../../redux/community/actions";
import {
    Button,
    Col,
    Container,
    Row,
} from "reactstrap";
import {Redirect} from "react-router";
import {MapPin} from "react-feather";
import Perks from "../../assets/images/perks.jpeg";
import RecruitmentBounties from "../../assets/images/recruitment_bounties.jpg";
import Sponsors from "../../assets/images/sponsors.jpg";
import CommunityRedeemAuthenticationModal from "./components/community_redeem_authentication_tabs";

class RedeemPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
        }
    }

    componentDidMount() {
        const {saved_redeem_token} = this.props;
        // load the community profile
        if(this.props.routeParams.token && this.props.routeParams.token !== saved_redeem_token) {
            this.props.setCommunityRedeemToken(this.props.routeParams.token);
            this.props.getCommunityToRedeem();
        }
    }

    renderLoading() {
        return(
            <div className={"text-center p-5"}>
                <h4>Loading Community...</h4>
            </div>)
    }

    renderInvalidRedeemLink() {
        return(
            <div className={"text-center p-5"}>
                <h4>Invalid Redeem Link</h4>
            </div>
        )
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        })
    }

    handleCommunityRedeem() {
        const {currentUser, redeemCommunity, clearCommunityRedeemToken} = this.props;
        if(!currentUser) {
            this.toggleModal();
            return;
        }
        redeemCommunity();
        clearCommunityRedeemToken();
    }

    render() {
        const {redeem} = this.props;
        const {loading, community, done} = redeem;
        const logoUrl = community && community.logo ? community.logo.url : null;
        const {isModalOpen} = this.state;

        if (done) {
            return (<Redirect to={"/community/edit/" + community.id}/>);
        }

        return (
            <Fragment>
                <CommunityRedeemAuthenticationModal isModalOpen={isModalOpen} toggleModal={() => this.toggleModal()} />
                {loading && this.renderLoading()}
                {!loading && !community && this.renderInvalidRedeemLink()}
                {!loading && community && (<div>
                    <div className={"m-lg-n5"}>
                        <Container fluid={true} className={"bg-blue pb-lg-0 px-lg-5 pt-lg-5"}>
                            <Row className={"align-items-center p-lg-5"}>
                                <Col lg={"6"} md={"12"} className="main-header p-lg-5 text-white">
                                    <div className={"mb-3"}>
                                        <h1 className={"font-weight-bold"}>Welcome <span className={"yellow-text"}>{community.name}</span>!</h1>
                                    </div>
                                    <div className={"d-inline-flex justify-content-start align-items-center border p-3"} style={{borderRadius: "10px"}}>
                                        <div className={"logo"} style={{
                                            backgroundColor: logoUrl ? "#ffffff" : "#1E2AA5",
                                            backgroundImage: "url(" + logoUrl + ")",
                                            backgroundPosition: "center",
                                            backgroundSize: "cover",
                                            width: "150px",
                                            height: "85px",
                                            borderRadius: "20px",
                                        }}/>
                                        <div className={"pl-3"}>
                                            <h5 className={"m-b-5"}>{community.name.toUpperCase()}</h5>
                                            <div className={"d-flex m-b-5"}>
                                                <MapPin color={"red"}/>
                                                <span className={"pl-2"}><b>{community.country}, {community.city}</b></span>
                                            </div>
                                            <a href={`/community/${community.id}`} target="_blank" rel="noopener noreferrer" className={"btn btn-md bg-white green-text m-t-5"}>See full profile page</a>
                                        </div>
                                    </div>
                                    <h5 className={"mt-2"}><b>The Community Atlas</b> is the world's largest <b>database of communities</b>. We connect <b>great companies</b> to <b>awesome communities</b>.</h5>
                                    <h5 className={"my-4"}><b>From this page you can redeem your community profile</b></h5>
                                    <Button size={"lg"} color={"info"} onClick={() => {
                                        this.handleCommunityRedeem();
                                    }}>REDEEM YOUR COMMUNITY PROFILE</Button>
                                </Col>
                                <Col lg={"6"} md={"12"} className={"d-flex justify-content-center align-items-center p-3"}>
                                    <div className={"w-100"}>
                                        <iframe className={"p-2 bg-light"} width={"100%"} style={{minHeight: "315px"}}
                                                src="https://www.youtube.com/embed/1OOYJrKISuc"
                                                title="Discover The Community Atlas" frameBorder="0"
                                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen/>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container fluid={true} style={{paddingTop: "10px"}}>
                        <Row className={"pt-md-5 mt-5"}>
                            <Col className={"text-center"}>
                                <h4 className={"font-weight-bold orange-text"}>BUILD A SUSTAINABLE COMMUNITY</h4>
                                <span className={"blue-text"}>Our goal is to help you find high quality opportunities so you can you better serve your members and make your work sustainable.</span>
                            </Col>
                        </Row>
                        <Row className={"pt-5"}>
                            <Col sm={"12"} md={"4"} className={"d-flex flex-column justify-content-start p-4 text-center"}>
                                <img src={RecruitmentBounties} alt={"bounties"} width={"100%"} className={"pb-2"} style={{borderRadius: "15px"}}/>
                                <h4 className={"text-uppercase font-weight-bold orange-text mt-1"}>Recruitment Bounties</h4>
                                <span className={"blue-text"}>Earn up to 5000$ per recruited members from highly selected job offers</span>
                            </Col>
                            <Col sm={"12"} md={"4"} className={"d-flex flex-column justify-content-start p-4 text-center"}>
                                <img src={Sponsors} alt={"sponsors"} width={"100%"} className={"pb-2"} style={{borderRadius: "15px"}}/>
                                <h4 className={"text-uppercase font-weight-bold orange-text mt-1"}>Partnerships & Sponsors</h4>
                                <span className={"blue-text"}>Connect to companies that want to support your community or sponsor your events</span>
                            </Col>
                            <Col sm={"12"} md={"4"} className={"d-flex flex-column justify-content-start p-4 text-center"}>
                                <img src={Perks} alt={"perks"} width={"100%"} className={"pb-2"} style={{borderRadius: "15px"}}/>
                                <h4 className={"text-uppercase font-weight-bold orange-text mt-1"}>Perks</h4>
                                <span className={"blue-text"}>Get notified of amazing discounts tailored for your community</span>
                            </Col>
                        </Row>
                        <Row className={"py-3"}>
                            <Col className={"text-center"}>
                                <h5 className={"blue-text"}>... and more!</h5>
                            </Col>
                        </Row>
                    </Container>
                    <div className={"mx-lg-n5"}>
                        <Container fluid={true} className={"bg-gray pb-lg-0 px-lg-5"}>
                            <Row className={"align-items-center p-lg-5 p-4"}>
                                <Col className={"text-center"}>
                                    <h4 className={"orange-text font-weight-bold"}>REDEEM YOUR COMMUNITY PROFILE ON ATLAS</h4>
                                    <span className={"blue-text"}>Become the administrator of your community profile</span>
                                </Col>
                            </Row>
                            <Row className={"align-items-center"}>
                                <Col lg={"5"} className={"d-flex justify-content-center justify-content-lg-end p-3"}>
                                    <div className={"logo"} style={{
                                        backgroundColor: logoUrl ? "#ffffff" : "#1E2AA5",
                                        backgroundImage: "url(" + logoUrl + ")",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        width: "250px",
                                        height: "120px",
                                        borderRadius: "20px",
                                        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                                    }}/>
                                </Col>
                                <Col lg={"7"}>
                                    <div className={"d-flex flex-column orange-text"}>
                                        <h4 className={"font-weight-bold"}>1. Redeem your profile: <Button color={"info"} onClick={() => {
                                            this.handleCommunityRedeem();
                                        }}>CLICK HERE</Button></h4>
                                        <h4 className={"font-weight-bold"}>2. Link your social accounts & complete your profile</h4>
                                        <h4 className={"font-weight-bold"}>3. Connect to great opportunities</h4>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={"pt-3"}>
                                <Col className={"text-center"}>
                                    <h5 className={"blue-text"}>It's free!</h5>
                                </Col>
                            </Row>
                            <Row className={"pt-3 pb-5"}>
                                <Col className={"d-flex flex-column text-center"}>
                                    <span><b>You are not the community leader?</b></span>
                                    <span>If you believe another person should redeem this account, please contact us at <a href="mailto:support@thecommunityatlas.com">support@thecommunityatlas.com</a></span>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>)}
            </Fragment>
        );
    }
}

const mapDispatchToProps = {getCommunityToRedeem, redeemCommunity, setCommunityRedeemToken, clearCommunityRedeemToken};

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.data,
    redeem: state.community.redeem,
    saved_redeem_token: state.community.redeem_token,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedeemPage)
