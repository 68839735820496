import React from "react";
import {Button, Col, Label, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import CommunityServiceImageUploader from "../../common/helpers/community_service_image_uploader";
import ImagePreview from "../../common/helpers/image_preview";
import {connect} from "react-redux";
import {createService, editService, toggleAddServiceModal} from "../../../redux/community/actions";
import currencies from "../../utils/country_currency.json";
import {
    PRICE_TYPE_FIXED,
    PRICE_TYPE_PRIVATE,
    PRICE_TYPE_RANGE,
    RECURRENCE_TYPE_MONTHLY,
    RECURRENCE_TYPE_YEARLY,
    YOUTUBE_VIDEO_LINK_REGEX
} from "../../../constants";

class AddServiceModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nameCharLimit: 45,
            service_image: null,
            has_errors: false,
            setHasErrors: (value) => {
                this.setState({
                    has_errors: value,
                })
            },
            has_video_link: false,
            toggleHasVideo: () => {
                let serviceCopy = Object.assign({}, this.state.service);

                if(this.state.has_video_link) {
                    serviceCopy.video_link = "";
                }

                this.setState({
                    has_video_link: !this.state.has_video_link,
                    service: serviceCopy,
                })
            },
            is_creating: true,
            service: {
                name: "",
                description: "",
                category: {
                    id: "-1"
                },
                image: null,
                price_type: PRICE_TYPE_PRIVATE,
                video_link: "",
                fixed_price: 0,
                min_price: 0,
                max_price: 0,
                currency: "USD",
                recurrent: false,
                recurrence_type: 1,
            }
        };
    }

    componentDidMount() {
        const {service} = this.props;

        if(service) {
            this.setState({
                service: service,
                service_image: service.image,
                is_creating: false,
                has_video_link: !!service.video_link,
            })
        }
    }

    onServicePropertyChange(property, value, required) {
        const {service} = this.state;
        let serviceCopy = Object.assign({}, service);
        serviceCopy[property] = value;

        this.setState({
            service: serviceCopy,
        })
    }

    onCategoryChange(categoryId) {
        const {categories} = this.props;
        const {service} = this.state;

        let category = categories.filter((category) => Number(category.id) === Number(categoryId))[0];
        let serviceCopy = Object.assign({}, service);
        serviceCopy.category = category;

        this.setState({
            service: serviceCopy,
        })
    }

    setRecurrent(checked) {
        const {service} = this.state;

        let serviceCopy = Object.assign({}, service);
        serviceCopy.recurrent = checked;

        this.setState({
            service: serviceCopy,
        })
    }

    hasEmptyRequiredFields() {
        const {service} = this.state;
        const optional_fields = ["video_link", "fixed_price", "min_price", "max_price"];

        return Object.entries(service).some(([field, value]) => {
            if(typeof value === "string" && (optional_fields.includes(field) === false)) {
                return value.trim().length === 0;
            }
            return false;
        });
    }

    hasValidVideoLink() {
        const {service} = this.state;

        return service.video_link.match(YOUTUBE_VIDEO_LINK_REGEX);
    }

    submitServiceCreationForm() {
        const {createService, toggleAddServiceModal, updateCommunity, editService} = this.props;
        const {service, setHasErrors, is_creating, has_video_link} = this.state;

        if(this.hasEmptyRequiredFields() || service.category.id === "-1" || (has_video_link && !this.hasValidVideoLink())) {
            setHasErrors(true);
            return;
        }

        setHasErrors(false);
        if(is_creating) {
            createService(service);
        } else {
            editService(service);
        }
        updateCommunity();
        toggleAddServiceModal();
    }

    render() {
        const {toggleAddServiceModal, is_open, categories} = this.props;
        const {service_image, service, has_video_link, toggleHasVideo, has_errors, is_creating} = this.state;

        return(
            <Modal isOpen={is_open} contentClassName={"p-2"}>
                <ModalHeader>
                    {is_creating ? "Add new service" : "Edit service"}
                </ModalHeader>
                <ModalBody>
                    <form className={`needs-validation ${has_errors ? "was-validated" : ""}`}>
                        <Row className={"m-b-10 justify-content-center"}>
                            <CommunityServiceImageUploader onNewImage={(image) => {
                                this.setState({
                                    service_image: image
                                });

                                this.onServicePropertyChange('image', image);
                            }}>
                                <ImagePreview logo={service_image}/>
                            </CommunityServiceImageUploader>
                        </Row>
                        <Row className={"m-b-10"}>
                            <Col>
                                <span className={"green-text f-15 f-w-800 pointer my-2"} onClick={() => {
                                    toggleHasVideo();
                                }}>{has_video_link ? "- Remove video" : "+ Add a video"}</span>
                            </Col>
                        </Row>
                        {has_video_link && <Row className={"m-b-10"}>
                            <Col>
                                <Label className="f-15">
                                    Add video link
                                </Label>
                                <input type="text"
                                       required={has_video_link}
                                       pattern={YOUTUBE_VIDEO_LINK_REGEX}
                                       className="form-control" value={service.video_link} placeholder={"Use a Youtube link"}
                                       onChange={(e) => {
                                           this.onServicePropertyChange('video_link', e.target.value, false);
                                       }}/>
                            </Col>
                        </Row>}
                        <Row className={"m-b-10"}>
                            <Col>
                                <div className={"d-flex justify-content-between"}>
                                    <Label className="d-block f-15">
                                        Service title*
                                    </Label>
                                    <Label className="d-block f-15">
                                        {service.name.length}/{this.state.nameCharLimit}
                                    </Label>
                                </div>
                                <input type="text" className="form-control" value={service.name} required placeholder={"Set a title to your service"}
                                       onChange={(e) => {
                                           let value = e.target.value.slice(0, this.state.nameCharLimit);
                                           this.onServicePropertyChange('name', value, true);
                                       }}/>
                            </Col>
                        </Row>
                        <Row className={"m-b-10"}>
                            <Col>
                                <Label className="d-block f-15">
                                    Category*
                                </Label>
                                <select className={'form-control'}
                                        value={service.category.id}
                                        required
                                        onChange={(e) => {
                                            this.onCategoryChange(e.target.value);
                                        }}>
                                    {service.category.id === "-1" && <option value={""} key={"default-option"}>Select a category</option>}
                                    {categories.map((category) => <option value={category.id} key={"service-category-"+category.id}>{category.name}</option>)}
                                </select>
                            </Col>
                        </Row>
                        <Row className={"m-b-10"}>
                            <Col>
                                <Label className="d-block f-15">
                                    Service description*
                                </Label>
                                <textarea className="form-control b-r-5"
                                          rows={6}
                                          required
                                          value={service.description}
                                          placeholder={"Describe what you can offer and how valuable it is."}
                                          onChange={(e) => {
                                              this.onServicePropertyChange('description', e.target.value, true);
                                          }}/>
                            </Col>
                        </Row>
                        <Row className={"m-b-10"}>
                            <Col>
                                <div className={"m-b-10 d-flex align-items-center"}>
                                    <Label className="d-block f-15">
                                        Set a price <span className={"grey-text"}>(optional)</span>
                                    </Label>
                                    <select className={'form-control m-l-10 w-25'}
                                            name={"currency"}
                                            disabled={service.price_type === PRICE_TYPE_PRIVATE}
                                            value={service.currency}
                                            onChange={(e) => {
                                                this.onServicePropertyChange('currency', e.target.value, true);
                                            }}>
                                        {Object.entries(currencies).map(([key]) => (
                                            <option value={key} key={key}>{key}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className={"m-l-5"}>
                                    <div className={"d-flex flex-column flex-lg-row align-items-start m-b-10"}>
                                        <div className={"radio d-flex align-items-center"}>
                                            <input type="radio" checked={service.price_type === PRICE_TYPE_FIXED} id="price" name={"price_type"} value={PRICE_TYPE_FIXED} onChange={(e) => {
                                                this.onServicePropertyChange('price_type', e.target.value, false);
                                            }} />
                                            <Label htmlFor="price" className={"d-flex m-r-10"}>Price is:</Label>
                                            <input className={"form-control w-50"} name={"fixed_price"} value={service.fixed_price} type={"number"} min={1} required={service.price_type === PRICE_TYPE_FIXED} disabled={service.price_type !== PRICE_TYPE_FIXED} onChange={(e) => {
                                                this.onServicePropertyChange('fixed_price', e.target.value, service.price_type === PRICE_TYPE_FIXED);
                                            }}/>
                                        </div>
                                        <div className={"checkbox d-flex align-items-center"}>
                                            <input type={"checkbox"} id={"recurs"}
                                                   checked={Number(service.recurrent)}
                                                   disabled={service.price_type !== PRICE_TYPE_FIXED}
                                                   onChange={(e) => {
                                                       this.setRecurrent(e.target.checked);
                                                   }} />
                                            <Label htmlFor="recurs" className={"d-flex m-r-10"}>Recurs</Label>
                                            <select className={'form-control m-l-10'}
                                                    name={"recurrence_type"}
                                                    required={service.price_type === PRICE_TYPE_FIXED && Number(service.recurrent)}
                                                    disabled={service.price_type !== PRICE_TYPE_FIXED || !Number(service.recurrent)}
                                                    value={service.recurrence_type}
                                                    onChange={(e) => {
                                                        this.onServicePropertyChange('recurrence_type', e.target.value, true);
                                                    }}>
                                                <option value={RECURRENCE_TYPE_MONTHLY} key={"recurrence-"+RECURRENCE_TYPE_MONTHLY}>Monthly</option>
                                                <option value={RECURRENCE_TYPE_YEARLY} key={"recurrence-"+RECURRENCE_TYPE_YEARLY}>Yearly</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className={"d-flex flex-column flex-lg-row m-b-10 align-items-lg-center"}>
                                        <div className={"radio d-flex flex-grow-1"}>
                                            <input type="radio" id="range" checked={service.price_type === PRICE_TYPE_RANGE} name={"price_type"} value={PRICE_TYPE_RANGE} onChange={(e) => {
                                                this.onServicePropertyChange('price_type', e.target.value, false);
                                            }}/>
                                            <Label htmlFor="range" className={"d-flex m-r-10 text-nowrap"}>Price ranges from: </Label>
                                        </div>
                                        <div className={"d-flex align-items-center"}>
                                            <input className={"form-control w-25"} name={"min_price"} value={service.min_price} min={1} type={"number"} required={service.price_type === PRICE_TYPE_RANGE} disabled={service.price_type !== PRICE_TYPE_RANGE} onChange={(e) => {
                                                this.onServicePropertyChange('min_price', e.target.value, service.price_type === PRICE_TYPE_RANGE);
                                            }}/>
                                            <span className={"mx-2"}>To: </span>
                                            <input className={"form-control w-25"} name={"max_price"} value={service.max_price} min={1} type={"number"} required={service.price_type === PRICE_TYPE_RANGE} disabled={service.price_type !== PRICE_TYPE_RANGE} onChange={(e) => {
                                                this.onServicePropertyChange('max_price', e.target.value, service.price_type === PRICE_TYPE_RANGE);
                                            }}/>
                                        </div>
                                    </div>

                                    <div className={"radio d-flex align-items-center m-b-10"}>
                                        <input type="radio" id="private" checked={service.price_type === PRICE_TYPE_PRIVATE} name={"price_type"} value={PRICE_TYPE_PRIVATE} onChange={(e) => {
                                            this.onServicePropertyChange('price_type', e.target.value, false);
                                        }}/>
                                        <Label htmlFor="private">Price is private</Label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={"m-b-10 justify-content-center align-items-center flex-column flex-lg-row"}>
                            <Button color={"green"} className={"m-5"} onClick={() => this.submitServiceCreationForm()}>{is_creating ? "SAVE AND ADD NEW SERVICE" : "SAVE"}</Button>
                            <Button color={"outline-green"} className={"m-5"} onClick={() => toggleAddServiceModal()}>CANCEL</Button>
                        </Row>
                    </form>
                </ModalBody>
            </Modal>
        )
    }
}

const mapDispatchToProps = {
    toggleAddServiceModal,
    createService,
    editService,
};

const mapStateToProps = (state, ownProps) => ({
    is_open: state.community.edit_community.modal.is_open,
    service: state.community.edit_community.modal.service,
    categories: state.community.service_category.list,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddServiceModal);