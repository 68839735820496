import {
    RESET_ALL_LOCATION_FILTERS,
    RESET_ALL_TOPIC_FILTERS,
    SEARCH_LOCATION_TO_FILTER,
    SEARCH_TOPIC_TO_FILTER,
    SELECT_LOCATION_TO_FILTER,
    SELECT_TOPIC_TO_FILTER,
    UNSELECT_LOCATION_TO_FILTER,
    UNSELECT_TOPIC_TO_FILTER,
} from "../action_types";

const initial_state = {
    location_filters: {
        searching: false,
        options: [],
        selected_locations: []
    },
    topic_filters: {
        searching: false,
        options: [],
        selected_topics: []
    },
}

export default (state = initial_state, action) => {
    let locationFilters;
    let topicFilters;

    switch (action.type) {
        case SEARCH_LOCATION_TO_FILTER:
            locationFilters = Object.assign({}, state.location_filters);
            locationFilters.searching = true;
            return {...state, location_filters: locationFilters};
        case SEARCH_LOCATION_TO_FILTER.concat("_SUCCESS"):
            locationFilters = Object.assign({}, state.location_filters);
            locationFilters.searching = false;
            locationFilters.options = action.payload.data;

            return {...state, location_filters: locationFilters};
        case SEARCH_LOCATION_TO_FILTER.concat("_FAILURE"):
            locationFilters = Object.assign({}, state.location_filters);
            locationFilters.searching = false;
            return {...state, location_filters: locationFilters};
        case SELECT_LOCATION_TO_FILTER:
            locationFilters = Object.assign({}, state.location_filters);

            // check if location is currently selected
            var selectedLocation = action.payload.location;
            if (locationFilters.selected_locations.filter((location) => {
                return location.id === selectedLocation.id && location.type === selectedLocation.type;
            }).length > 0) {
                return state;
            }

            locationFilters.selected_locations.push(selectedLocation);
            return {...state, location_filters: locationFilters};
        case UNSELECT_LOCATION_TO_FILTER:
            locationFilters = Object.assign({}, state.location_filters);
            var unselectedLocation = action.payload.location;

            for (var i = 0; i < locationFilters.selected_locations.length; i++) {
                var location = locationFilters.selected_locations[i];
                if (location.id === unselectedLocation.id && location.type === unselectedLocation.type) {
                    locationFilters.selected_locations.splice(i, 1);
                }
            }

            return {...state, location_filters: locationFilters};
        case RESET_ALL_LOCATION_FILTERS:
            locationFilters = Object.assign({}, state.location_filters);
            locationFilters.searching = false;
            locationFilters.options = [];
            locationFilters.selected_locations = [];
            return {...state, location_filters: locationFilters};
        case SEARCH_TOPIC_TO_FILTER:
            topicFilters = Object.assign({}, state.topic_filters);
            topicFilters.searching = true;
            return {...state, topic_filters: topicFilters};
        case SEARCH_TOPIC_TO_FILTER.concat("_SUCCESS"):
            topicFilters = Object.assign({}, state.topic_filters);
            topicFilters.searching = false;
            topicFilters.options = action.payload.data;

            return {...state, topic_filters: topicFilters};
        case SEARCH_TOPIC_TO_FILTER.concat("_FAILURE"):
            topicFilters = Object.assign({}, state.topic_filters);
            topicFilters.searching = false;
            return {...state, topic_filters: topicFilters};
        case SELECT_TOPIC_TO_FILTER:
            topicFilters = Object.assign({}, state.topic_filters);

            // check if topic is currently selected
            var selectedTopic = action.payload.topic;
            if (topicFilters.selected_topics.filter((topic) => {
                return topic.id === selectedTopic.id && topic.type === selectedTopic.type;
            }).length > 0) {
                return state;
            }

            topicFilters.selected_topics.push(selectedTopic);
            return {...state, page: 0, topic_filters: topicFilters};
        case UNSELECT_TOPIC_TO_FILTER:
            topicFilters = Object.assign({}, state.topic_filters);
            var unselectedTopic = action.payload.topic;

            for (let i = 0; i < topicFilters.selected_topics.length; i++) {
                var topic = topicFilters.selected_topics[i];
                if (topic.id === unselectedTopic.id && topic.type === unselectedTopic.type) {
                    topicFilters.selected_topics.splice(i, 1);
                }
            }

            return {...state, page: 0, topic_filters: topicFilters};
        case RESET_ALL_TOPIC_FILTERS:
            topicFilters = Object.assign({}, state.topic_filters);
            topicFilters = {
                searching: false,
                options: [],
                selected_topics: []
            };

            return {...state, topic_filters: topicFilters};
        default:
            return {...state};
    }
}