import React from 'react';
import {
    Button, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import NumberFormatter from "../../utils/number_formatter";
import {connect} from "react-redux";
import {applyToJob, getJobApplicationFormDetails, toggleApplyToJobModal} from "../../../redux/job/actions";
import {
    EMAIL_REGEX,
    GENDER_FEMALE,
    GENDER_MALE, GENDER_OTHER,
    GITHUB_REGEX,
    JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE,
    JOB_APPLICATION_FORM_FIELD_REQUIRED,
    LINKEDIN_REGEX,
    PAYMENT_TYPE_CRYPTO,
    PRIVACY_POLICY_LINK,
    TOS_LINK
} from "../../../constants";
import TextCustomFields from "./custom_fields/fields/text_custom_field";
import NumberCustomFields from "./custom_fields/fields/number_custom_field";
import InfoCustomFields from "./custom_fields/fields/info_custom_field";
import FileCustomFields from "./custom_fields/fields/file_custom_field";
import TextAreaCustomFields from "./custom_fields/fields/textarea_custom_field";
import DateCustomFields from "./custom_fields/fields/date_custom_field";
import ChoiceCustomFields from "./custom_fields/fields/choice_custom_field";
import {toast} from "react-toastify";
import ConfigDB from "../../../config";
import FileUpload from "../helpers/file_upload";
import ConnectSolanaWalletComponent from "../solana/ConnectSolanaWalletComponent";

class InternalApplyToJobModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accepted_terms_of_use: false,
            application_form_data: {
                full_name: null,
                email: null,
                custom_fields: {}
            },
            has_errors: false,
            toggleAcceptedTermsOfUse: () => {
                this.setState({
                    accepted_terms_of_use: !this.state.accepted_terms_of_use
                })
            },
            updateForm: (data) => {
                let applicationFormData = Object.assign({}, this.state.application_form_data);
                Object.entries(data).forEach(([key, value]) => {
                    if (applyToJob.hasOwnProperty(key)) {
                        applyToJob[key] = value;
                    }
                })
                this.setState({
                    application_form_data: applicationFormData
                })
            },
        };
    }

    componentDidMount() {
        const {job, getJobApplicationFormDetails} = this.props;

        getJobApplicationFormDetails(job.id);
    }

    onFormChange(value, field) {
        const {application_form_data} = this.state;
        application_form_data[field] = value;

        this.setState({
            application_form_data
        })
    }

    onFileFormChange(file, field) {
        const {application_form_data} = this.state;

        if (file && file.size / 1048576 <= ConfigDB.data.upload_file_size) {
            application_form_data[field] = file;

            this.setState({
                application_form_data
            })
        } else {
            toast.warn(`File must be max ${ConfigDB.data.upload_file_size}MB`);
        }
    }

    isNameValid(value) {
        return value && value.split(" ").length >= 2;
    }

    isEmailValid(value) {
        var regex = new RegExp(EMAIL_REGEX);
        return value && regex.test(value);
    }

    isLinkedinValid(value) {
        var regex = new RegExp(LINKEDIN_REGEX);
        return value && regex.test(value);
    }

    isGithubValid(value) {
        var regex = new RegExp(GITHUB_REGEX);
        return value && regex.test(value);
    }

    fieldIsRequired(fieldSetting) {
        return fieldSetting === JOB_APPLICATION_FORM_FIELD_REQUIRED;
    }

    fieldIsRequiredAndMissing(fieldSetting, fieldValue) {
        return fieldSetting === JOB_APPLICATION_FORM_FIELD_REQUIRED && !fieldValue;
    }

    isValidForm() {
        const {solana, job} = this.props;
        const {application_form} = this.props;
        const {application_form_data, accepted_terms_of_use} = this.state;

        var hasErrors = false;

        if (!accepted_terms_of_use) {
            hasErrors = true;
        }

        if (!this.isNameValid(application_form_data.full_name)) {
            toast.error("Name is required!")
            return false;
        }

        if (!this.isEmailValid(application_form_data.email)) {
            toast.error("Email is invalid!")
            return false;
        }

        if (this.fieldIsRequired(application_form.ask_linkedin_profile)) {
            if (!this.isLinkedinValid(application_form_data.linkedin_profile)) {
                toast.error("Linkedin is invalid!")
                return false;
            }
        }

        if (this.fieldIsRequired(application_form.ask_github_profile)) {
            if (!this.isGithubValid(application_form_data.github_profile)) {
                toast.error("Github is invalid!")
                return false;
            }
        }

        if (this.fieldIsRequiredAndMissing(application_form.ask_current_role, application_form_data.current_role)) {
            toast.error("Current Role is required!")
            return false;
        }

        if (this.fieldIsRequiredAndMissing(application_form.ask_birthday, application_form_data.birthday)) {
            toast.error("Birthday is required!")
            return false;
        }

        if (this.fieldIsRequiredAndMissing(application_form.ask_other_url, application_form_data.other_url)) {
            toast.error("Other Url is required!")
            return false;
        }

        if (this.fieldIsRequiredAndMissing(application_form.ask_current_location, application_form_data.current_location)) {
            toast.error("Current Location required!")
            return false;
        }

        if (this.fieldIsRequiredAndMissing(application_form.ask_gender, application_form_data.gender)) {
            toast.error("Gender is required!")
            return false;
        }

        if (this.fieldIsRequiredAndMissing(application_form.ask_phone_number, application_form_data.phone_number)) {
            toast.error("Phone Number is required!")
            return false;
        }

        if (this.fieldIsRequiredAndMissing(application_form.ask_biography, application_form_data.biography)) {
            toast.error("Biography is required!")
            return false;
        }

        if (this.fieldIsRequiredAndMissing(application_form.ask_years_of_experience, application_form_data.years_of_experience)) {
            toast.error("Years Of Experience is required!")
            return false;
        }

        if (this.fieldIsRequiredAndMissing(application_form.ask_cv, application_form_data.cv)) {
            toast.error("CV is required!")
            return false;
        }

        application_form.custom_fields.map((field) => {
            if (field.required && !application_form_data.custom_fields[field.id]) {
                toast.error(field.title + " is required!")
                hasErrors = true;
            }
        });

        if (job.payment_type === PAYMENT_TYPE_CRYPTO && !solana.wallet_public_key) {
            toast.error("Please connect a Solana Wallet!");
            hasErrors = true;
        }

        this.setState({
            has_errors: hasErrors,
        });

        return !hasErrors;
    }

    handleApplication() {
        const {solana} = this.props;
        const {applyToJob, toggleApplyToJobModal} = this.props;
        const {application_form_data} = this.state;

        if (!this.isValidForm()) {
            return;
        }

        application_form_data.wallet_public_key = solana.wallet_public_key;

        applyToJob(application_form_data);
        toggleApplyToJobModal();
    }

    onCustomFieldFormChange(fieldId, value) {
        const {application_form_data} = this.state;
        application_form_data.custom_fields[fieldId] = value;

        this.setState({
            application_form_data
        })
    }

    renderCustomFields() {
        const {application_form} = this.props;

        return application_form.custom_fields.map((customField, index) => {
            switch (customField.type) {
                case "text":
                    return <TextCustomFields customField={customField} key={"custom-field-" + customField.id}
                                             onValueChange={(value) => this.onCustomFieldFormChange(customField.id, value)}/>
                case "number":
                    return <NumberCustomFields customField={customField} key={"custom-field-" + customField.id}
                                               onValueChange={(value) => this.onCustomFieldFormChange(customField.id, value)}/>
                case "info":
                    return <InfoCustomFields customField={customField} key={"custom-field-" + customField.id}
                                             onValueChange={(value) => this.onCustomFieldFormChange(customField.id, value)}/>
                case "file":
                    return <FileCustomFields customField={customField} key={"custom-field-" + customField.id}
                                             onValueChange={(value) => this.onCustomFieldFormChange(customField.id, value)}/>
                case "textarea":
                    return <TextAreaCustomFields customField={customField} key={"custom-field-" + customField.id}
                                                 onValueChange={(value) => this.onCustomFieldFormChange(customField.id, value)}/>
                case "date":
                    return <DateCustomFields customField={customField} key={"custom-field-" + customField.id}
                                             onValueChange={(value) => this.onCustomFieldFormChange(customField.id, value)}/>
                case "choice":
                    return <ChoiceCustomFields customField={customField} key={"custom-field-" + customField.id}
                                               onValueChange={(value) => this.onCustomFieldFormChange(customField.id, value)}/>
                default:
                    return "";
            }
        });
    }

    render() {
        const {job, toggleApplyToJobModal, application_form, loading} = this.props;
        const {accepted_terms_of_use, toggleAcceptedTermsOfUse, has_errors, application_form_data} = this.state;

        return (
            <Modal isOpen={true} size={"sm"} centered={true} contentClassName={"b-r-20 bg-dark-gray"}>
                <ModalHeader className={"justify-content-center py-5 p-relative custom-close-btn"}
                             toggle={toggleApplyToJobModal}>
                    <div className={"d-flex align-items-center"}>
                        <div className={"d-flex flex-column px-3 text-center"}>
                            <span className={"font-weight-bold f-25 mb-3"}>Apply for the role</span>
                            <span className={"f-16 mb-3"}>If hired you’ll get the bounty of
                                <span className={"purple-text"}> {job.currency} <NumberFormatter
                                    value={job.bounty}/></span>
                            </span>
                            <span>{job.title} at <strong>{job.company.name}</strong></span>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {!application_form && loading && (
                        <div className="text-center p-5">
                            <h4 className="m-0">Loading...</h4>
                        </div>
                    )}

                    {!application_form && !loading && (
                        <div className="text-center p-5">
                            <h4 className="m-0">Invalid Application Form</h4>
                        </div>
                    )}

                    {application_form && !loading && job.payment_type === PAYMENT_TYPE_CRYPTO && (
                        <div className="px-3">
                            <ConnectSolanaWalletComponent/>
                        </div>
                    )}

                    {application_form && !loading && (
                        <div className={"p-3"}>
                            <div className={"mb-5"}>
                                <div className="m-b-10">
                                    <Label>Full name <span className={"text-danger"}>*</span></Label>
                                    <input className="refer-modal-input form-control" type={"text"}
                                           defaultValue={application_form_data.full_name}
                                           onChange={(e) => this.onFormChange(e.target.value, 'full_name')}
                                           pattern=".*\s.*"
                                    />
                                </div>
                                <div className="m-b-10">
                                    <Label>Email <span className={"text-danger"}>*</span></Label>
                                    <input className="refer-modal-input form-control" type={"email"}
                                           defaultValue={application_form_data.email}
                                           pattern={EMAIL_REGEX}
                                           onChange={(e) => this.onFormChange(e.target.value, 'email')}
                                    />
                                </div>
                                {application_form.ask_birthday !== JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE && (
                                    <div className="m-b-10">
                                        <Label>Birthday {application_form.ask_birthday === JOB_APPLICATION_FORM_FIELD_REQUIRED &&
                                            <span className={"text-danger"}>*</span>}</Label>
                                        <input className="refer-modal-input form-control" type={"date"}
                                               defaultValue={application_form_data.birthday}
                                               onChange={(e) => this.onFormChange(e.target.value, 'birthday')}
                                        />
                                    </div>
                                )}
                                {application_form.ask_linkedin_profile !== JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE && (
                                    <div className="m-b-10">
                                        <Label>Linkedin
                                            Profile {application_form.ask_linkedin_profile === JOB_APPLICATION_FORM_FIELD_REQUIRED &&
                                                <span className={"text-danger"}>*</span>}</Label>
                                        <input className="refer-modal-input form-control" type={"text"}
                                               defaultValue={application_form_data.linkedin_profile}
                                               pattern={LINKEDIN_REGEX}
                                               onChange={(e) => this.onFormChange(e.target.value, 'linkedin_profile')}
                                        />
                                    </div>
                                )}
                                {application_form.ask_github_profile !== JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE && (
                                    <div className="m-b-10">
                                        <Label>Github
                                            Profile {application_form.ask_github_profile === JOB_APPLICATION_FORM_FIELD_REQUIRED &&
                                                <span className={"text-danger"}>*</span>}</Label>
                                        <input className="refer-modal-input form-control" type={"text"}
                                               defaultValue={application_form_data.github_profile}
                                               pattern={GITHUB_REGEX}
                                               onChange={(e) => this.onFormChange(e.target.value, 'github_profile')}
                                               placeholder={"Github Profile"}
                                        />
                                    </div>
                                )}
                                {application_form.ask_other_url !== JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE && (
                                    <div className="m-b-10">
                                        <Label>Other
                                            Url {application_form.ask_other_url === JOB_APPLICATION_FORM_FIELD_REQUIRED &&
                                                <span className={"text-danger"}>*</span>}</Label>
                                        <input className="refer-modal-input form-control" type={"text"}
                                               defaultValue={application_form_data.other_url}
                                               onChange={(e) => this.onFormChange(e.target.value, 'other_url')}
                                        />
                                    </div>
                                )}
                                {application_form.ask_current_role !== JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE && (
                                    <div className="m-b-10">
                                        <Label>Current
                                            Role {application_form.ask_current_role === JOB_APPLICATION_FORM_FIELD_REQUIRED &&
                                                <span className={"text-danger"}>*</span>}</Label>
                                        <input className="refer-modal-input form-control" type={"text"}
                                               defaultValue={application_form_data.current_role}
                                               onChange={(e) => this.onFormChange(e.target.value, 'current_role')}
                                               placeholder={"Current Role"}
                                        />
                                    </div>
                                )}
                                {application_form.ask_current_location !== JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE && (
                                    <div className="m-b-10">
                                        <Label>Current
                                            Location {application_form.ask_current_location === JOB_APPLICATION_FORM_FIELD_REQUIRED &&
                                                <span className={"text-danger"}>*</span>}</Label>
                                        <input className="refer-modal-input form-control" type={"text"}
                                               defaultValue={application_form_data.current_location}
                                               onChange={(e) => this.onFormChange(e.target.value, 'current_location')}
                                        />
                                    </div>
                                )}
                                {application_form.ask_gender !== JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE && (
                                    <div className="m-b-10">
                                        <Label>Gender {application_form.ask_gender === JOB_APPLICATION_FORM_FIELD_REQUIRED &&
                                            <span className={"text-danger"}>*</span>}</Label>
                                        <select className={"refer-modal-input form-control"}
                                                defaultValue={application_form_data.gender}
                                                onChange={(e) => this.onFormChange(e.target.value, 'gender')}
                                        >
                                            <option value="">Choose gender</option>
                                            <option value={GENDER_MALE}>Male</option>
                                            <option value={GENDER_FEMALE}>Female</option>
                                            <option value={GENDER_OTHER}>Other</option>
                                        </select>
                                    </div>
                                )}
                                {application_form.ask_phone_number !== JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE && (
                                    <div className="m-b-10">
                                        <Label>Phone
                                            Number {application_form.ask_phone_number === JOB_APPLICATION_FORM_FIELD_REQUIRED &&
                                                <span className={"text-danger"}>*</span>}</Label>
                                        <input className="refer-modal-input form-control" type={"text"}
                                               defaultValue={application_form_data.phone_number}
                                               onChange={(e) => this.onFormChange(e.target.value, 'phone_number')}
                                        />
                                    </div>
                                )}
                                {application_form.ask_biography !== JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE && (
                                    <div className="m-b-10">
                                        <Label>Biography {application_form.ask_biography === JOB_APPLICATION_FORM_FIELD_REQUIRED &&
                                            <span className={"text-danger"}>*</span>}</Label>
                                        <input className="refer-modal-input form-control" type={"text"}
                                               defaultValue={application_form_data.biography}
                                               onChange={(e) => this.onFormChange(e.target.value, 'biography')}
                                               placeholder={"Tell us about you!"}
                                        />
                                    </div>
                                )}
                                {application_form.ask_years_of_experience !== JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE && (
                                    <div className="m-b-10">
                                        <Label>Years of
                                            experience {application_form.ask_years_of_experience === JOB_APPLICATION_FORM_FIELD_REQUIRED &&
                                                <span className={"text-danger"}>*</span>}</Label>
                                        <input className="refer-modal-input form-control" type={"number"}
                                               min={0}
                                               defaultValue={application_form_data.years_of_experience}
                                               onChange={(e) => this.onFormChange(e.target.value, 'years_of_experience')}
                                        />
                                    </div>
                                )}
                                {application_form.ask_cv !== JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE && (
                                    <div className="m-b-10">
                                        <Label>CV <small>{"PDF files, max " + ConfigDB.data.upload_file_size + "MB"}</small> {application_form.ask_cv === JOB_APPLICATION_FORM_FIELD_REQUIRED &&
                                            <span className={"text-danger"}>*</span>}</Label>
                                        <FileUpload className="refer-modal-input"
                                                    onFileChoose={(file) => this.onFileFormChange(file, 'cv')}/>
                                    </div>
                                )}
                                {this.renderCustomFields()}
                            </div>
                            <div className="faq-form m-b-10 d-flex">
                                <div id="terms-of-use"
                                     className={"size-20 checkbox-container " + (accepted_terms_of_use ? "active full" : "")}
                                     onClick={() => {
                                         toggleAcceptedTermsOfUse();
                                     }}>
                                    <i className={"fa fa-check"}/>
                                </div>
                                <Label htmlFor="terms-of-use"
                                       className={`ml-2 ${has_errors && !accepted_terms_of_use ? "text-danger" : ""}`}>
                                    You agree to the&nbsp;
                                    <u><a href={TOS_LINK} target={"_blank"} rel={"noopener noreferrer"}
                                          className={"text-info"}>terms of use</a></u> and&nbsp;
                                    <u><a href={PRIVACY_POLICY_LINK} target={"_blank"} rel={"noopener noreferrer"}
                                          className={"text-info"}>privacy policy</a></u>
                                </Label>
                            </div>
                        </div>
                    )}
                </ModalBody>
                {application_form && (
                    <ModalFooter className={"justify-content-center p-3"}>
                        <Button color={"dark-blue"} className={"flex-grow-1"} size={"lg"}
                                onClick={() => this.handleApplication()}>APPLY</Button>
                    </ModalFooter>
                )}
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    applyToJob,
    toggleApplyToJobModal,
    getJobApplicationFormDetails
};

const mapStateToProps = (state, ownProps) => ({
    application_form: state.job.application_form.data,
    loading: state.job.application_form.loading,
    solana: state.solana
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalApplyToJobModal)
