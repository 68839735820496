import React from 'react';
import {connect} from "react-redux";
import {setWalletFound, setWalletPublicKey} from "../../../redux/solana/actions";

class InitSolanaWalletComponent extends React.Component {

    async checkIfWalletIsConnected() {
        const {solana} = window;
        const {setWalletFound, setWalletPublicKey} = this.props;

        if (solana?.isPhantom) {
            setWalletFound(true);

            try {
                const response = await solana.connect({
                    onlyIfTrusted: true
                });
                
                setWalletPublicKey(response.publicKey.toString());
            } catch (err) {
                console.error(err)
            }

            return null;
        }

        setWalletFound(false);
        setWalletPublicKey(null);
    }

    componentDidMount() {
        setTimeout(() => {
            this.checkIfWalletIsConnected();
        }, 1000);
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = {setWalletFound, setWalletPublicKey};

const mapStateToProps = (state, ownProps) => ({
    wallet: state.solana
});

export default connect(mapStateToProps, mapDispatchToProps)(InitSolanaWalletComponent)