import React from 'react';
import {Button} from 'reactstrap'
import {connect} from "react-redux";

import {subscribeToJobBountiesAfterReferringSomeone} from '../../../redux/job/actions';

class SubscribeJobBountiesCard extends React.Component {
    renderSubscribed() {
        return (
            <Button color={"success"} outline={true} className={"d-flex justify-content-center align-items-center"}>
                <b className={"mr-3"}>BOUNTY NOTIFICATION ACTIVE</b>
                <i className="fa fa-check-circle"></i>
            </Button>
        );
    }

    renderNotSubscribed() {
        const {subscriber, subscribeToJobBountiesAfterReferringSomeone} = this.props;
        
        return (
            <Button color={"green"} onClick={() => {
                subscribeToJobBountiesAfterReferringSomeone(subscriber);
            }}>
                <b>NOTIFY ME OF NEW BOUNTIES</b>
            </Button>
        );
    }

    render() {
        const {subscribed} = this.props;

        if(subscribed){
            return this.renderSubscribed();
        }

        return this.renderNotSubscribed();
    }
}

const mapDispatchToProps = {
    subscribeToJobBountiesAfterReferringSomeone,
};

const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeJobBountiesCard)

