import React, {Fragment, useRef} from "react";
import {Badge, Label} from "reactstrap";
import {connect} from "react-redux";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {
    loadCommunities,
    resetCommunitiesPage,
    searchForCommunityNetwork,
    selectCommunityNetworkToFilterForCommunity,
    unselectCommunityNetworkToFilterForCommunity
} from '../../../redux/community/actions';

class CommunityNetworkFilter extends React.Component {
    buildListOfSelectedCommunities() {
        const {communityNetworkFilters, unselectCommunityNetworkToFilterForCommunity, loadCommunities, resetCommunitiesPage} = this.props;
        const {selected_community_networks} = communityNetworkFilters;

        return selected_community_networks.map((community) => {
            return (
                <Badge className={"pointer"} color={"info"} key={community.id+"_network"} onClick={() => {
                    unselectCommunityNetworkToFilterForCommunity(community);
                    resetCommunitiesPage();
                    loadCommunities();
                }}>{community.name} x</Badge>
            );
        })
    }

    render() {
        const {loadCommunities, searchForCommunityNetwork, selectCommunityNetworkToFilterForCommunity, communityNetworkFilters, resetCommunitiesPage} = this.props;
        const {searching, options} = communityNetworkFilters;

        return (
            <div>
                <Label className="d-block">
                    Network:
                </Label>

                <div className="faq-form m-b-10">
                    <TypeaheadComponent searching={searching}
                                        searchForCommunityNetwork={searchForCommunityNetwork}
                                        options={options}
                                        selectCommunity={(selectedCommunity) => {
                                            selectCommunityNetworkToFilterForCommunity(selectedCommunity);
                                            resetCommunitiesPage();
                                            loadCommunities();
                                        }}
                    />
                </div>

                <div className="m-b-10">
                    {this.buildListOfSelectedCommunities()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    communityNetworkFilters: state.community.community_network_filters
});

const mapDispatchToProps = {
    searchForCommunityNetwork,
    selectCommunityNetworkToFilterForCommunity,
    unselectCommunityNetworkToFilterForCommunity,
    resetCommunitiesPage,
    loadCommunities
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityNetworkFilter)

const TypeaheadComponent = (props) => {
    const ref = useRef();

    return (
        <Fragment>
            <AsyncTypeahead
                id="search-for-community-network"
                isLoading={props.searching}
                labelKey="name"
                minLength={3}
                onSearch={props.searchForCommunityNetwork}
                options={props.options}
                placeholder="Type here..."
                renderMenuItemChildren={(option, props) => {
                    return (
                        <Fragment>
                            <span>{option.name}</span>
                        </Fragment>
                    )
                }}
                onChange={(selected) => {
                    if (selected.length > 0) {
                        props.selectCommunity(selected[0]);
                    }

                    ref.current.clear();
                }}
                ref={ref}
            />
        </Fragment>
    );
};
