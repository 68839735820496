import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Container, Popover, PopoverBody,
    Row,
} from 'reactstrap';
import NumberFormatter from "../utils/number_formatter";
import Coin from "../../assets/images/coin.svg";
import SolanaLogo from "../../assets/images/solana-logo.png";
import InfoCard from "../common/job_board/info_card";
import bountyIcon from "../../assets/images/bounty_icon.svg";
import shareIcon from "../../assets/images/share_icon.svg";
import applyIcon from "../../assets/images/apply_icon.svg";
import ShareJobModal from "../common/job_board/share_job_modal";
import ReferJobModal from "../common/job_board/refer_job_modal";
import ReferralCompleteModal from "../common/job_board/referral_complete_modal";
import {ArrowDown, ArrowUp} from "react-feather";
import {Link, Redirect} from "react-router-dom";
import {
    getJobDetails,
    getReferrerDetails,
    setJobToken,
    toggleApplyToJobModal,
    toggleReferJobModal
} from "../../redux/job/actions";
import LogoComponent from "../common/helpers/logo_component";
import ExternalApplyToJobModal from "../common/job_board/external_apply_to_job_modal";
import ApplySuccessfulModal from "../common/job_board/apply_successful_modal";
import {toggleCommunityRedeemModal} from "../../redux/community/actions";
import CommunityRedeemModal from "../common/job_board/community_redeem_modal";
import {JOB_APPLY_LINK_TYPE_EXTERNAL, JOB_APPLY_LINK_TYPE_INTERNAL, PAYMENT_TYPE_FIAT} from "../../constants";
import InternalApplyToJobModal from "../common/job_board/internal_apply_to_job_modal";
import SolanaWalletPopoverComponent from "../common/solana/SolanaWalletPopoverComponent";

class JobDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bountySplitModalOpen: false,
            toggleBountySplitModal: () => {
                this.setState({
                    bountySplitModalOpen: !this.state.bountySplitModalOpen
                })
            },
            shareJobModalOpen: false,
            toggleShareJobModal: () => {
                this.setState({
                    shareJobModalOpen: !this.state.shareJobModalOpen
                })
            },
            sectionCardsCollapsed: {
                about_company: true,
                about_role: true,
                bounty_terms: true,
            },
            toggleCollapseSectionCard: (section) => {
                let sectionCards = Object.assign({}, this.state.sectionCardsCollapsed);
                sectionCards[section] = !sectionCards[section];
                this.setState({
                    sectionCardsCollapsed: sectionCards
                })
            },
            what_is_a_bounty_header_popover_open: false,
            what_is_a_bounty_footer_popover_open: false,
        };
    }

    componentDidMount() {
        const {setJobToken, getReferrerDetails, getJobDetails} = this.props;
        const token = this.props.routeParams.token;

        setJobToken(token);
        getJobDetails(token);
        getReferrerDetails(token);
    }

    whatIsABountyHeaderPopoverTimeout;
    handleHeaderPopoverClose() {
        if(this.whatIsABountyHeaderPopoverTimeout) {
            clearTimeout(this.whatIsABountyHeaderPopoverTimeout);
        }

        this.whatIsABountyHeaderPopoverTimeout = setTimeout(() => {
            this.setState({what_is_a_bounty_header_popover_open: false})
        }, 500);
    }

    whatIsABountyFooterPopoverTimeout;
    handleFooterPopoverClose() {
        if(this.whatIsABountyFooterPopoverTimeout) {
            clearTimeout(this.whatIsABountyFooterPopoverTimeout);
        }

        this.whatIsABountyFooterPopoverTimeout = setTimeout(() => {
            this.setState({what_is_a_bounty_footer_popover_open: false})
        }, 500);
    }

    renderJobSalary() {
        const {job} = this.props;
        const {min_salary, max_salary} = job;

        if(min_salary && max_salary) {
            return (<h4>{job.min_salary} {job.currency} - {job.max_salary} {job.currency}</h4>);
        } else if(min_salary) {
            return (<h4>{job.min_salary} {job.currency}</h4>);
        } else if(max_salary) {
            return (<h4>{job.max_salary} {job.currency}</h4>);
        }

        return "";
    }

    renderInvalidJob() {
        return (
            <div className={"d-flex align-items-center justify-content-center p-5"}>
                <h3>Invalid job</h3>
            </div>
        )
    }

    renderLoading() {
        return (
            <Card className={"align-items-center justify-content-center"}>
                <h3>Loading...</h3>
            </Card>
        )
    }

    renderBounty() {
        const {what_is_a_bounty_header_popover_open} = this.state;
        const { job } = this.props;

        if(job.payment_type === PAYMENT_TYPE_FIAT){
            return (
                <div className={"d-flex align-items-center mr-2"}>
                    <img src={Coin} width={37} height={37} className={"object-contain mr-2"}
                         alt={"Coin"}/>
                    <h4 className={"mr-2 mb-0"}>BOUNTY <strong>{job.currency} <NumberFormatter
                        value={job.bounty}/></strong></h4>
                    <div className={"text-circle pointer mr-2"}
                         onMouseEnter={() => {
                             this.setState({what_is_a_bounty_header_popover_open: true})
                         }}
                         onMouseOver={() => {
                             clearTimeout(this.whatIsABountyHeaderPopoverTimeout);}
                         }
                         onMouseLeave={() => {
                             this.handleHeaderPopoverClose();
                         }}>
                        <span id="jobHeaderExplanation" className={"f-18"}>?</span>
                    </div>
                    <Popover
                        placement="top"
                        isOpen={what_is_a_bounty_header_popover_open}
                        target="jobHeaderExplanation"
                    >
                        <PopoverBody className={"bg-dark-gray d-flex flex-column"}
                                     onMouseEnter={() => {
                                         this.setState({what_is_a_bounty_header_popover_open: true})
                                     }}
                                     onMouseOver={() => {
                                         clearTimeout(this.whatIsABountyHeaderPopoverTimeout);}
                                     }
                                     onMouseLeave={() => {
                                         this.handleHeaderPopoverClose();
                                     }}>
                            <span>A Bounty is a cash reward that is split between the referrer and the applicant, in case the applicant is hired.</span>
                            <u><a href="https://absorbing-periwinkle-352.notion.site/Job-bounties-c4861516955e4fc3a07f4e69e2d13d3c" className={"green-text"}>Learn more</a></u>
                        </PopoverBody>
                    </Popover>
                </div>
            );
        } else {
            return (
                <>
                    <img src={SolanaLogo} width={25} height={25} className={"object-contain ml-2"} alt={"Solana"} />
                    <h6 className={"font-weight-bold ml-2 mb-0 text-phantom"}>{job.currency} <NumberFormatter value={job.bounty} /> Blockchain tracked bounty</h6>
                    <SolanaWalletPopoverComponent />
                </>
            );
        }
    }

    renderJobHeader() {
        const {user, customer, job, toggleReferJobModal, toggleApplyToJobModal, redeem_token, toggleCommunityRedeemModal} = this.props;
        const {toggleShareJobModal} = this.state;

        return (
            <Row>
                <Col>
                    <Card className={"p-3 p-lg-5 d-flex flex-column"}>
                        <div className={"d-flex flex-column"}>
                            <div className={"d-flex mb-3 flex-column flex-lg-row"}>
                                <LogoComponent logo={job.company.logo} width={161} height={161} className={'b-r-10 mr-3'}/>
                                <div className={"d-flex flex-column"}>
                                    <h1 className={"font-weight-bold my-2"}>{job.title}</h1>
                                    {this.renderJobSalary()}
                                    <h5 className={"font-weight-bold"}>{job.company.name}</h5>
                                    <span>{job.location}</span>
                                </div>
                            </div>
                            {job.is_referral && <Row className={"mb-2"}>
                                <span className={"text-success f-18"}><i
                                    className="icofont icofont-megaphone-alt icofont-lg"/> You have been referred for this job</span>
                            </Row>}
                            <Row className={"flex-wrap align-items-center"}>
                                {((user && !job.is_referral) || (!user && redeem_token)) && <Button color={"teal"} size={"md"} className={"mr-2"}
                                         onClick={() => {
                                             if(!user && redeem_token) {
                                                 toggleCommunityRedeemModal();
                                                 return;
                                             }

                                             toggleShareJobModal();
                                         }}>
                                    <i className={"icofont icofont-share icofont-lg d-none d-lg-inline-block mr-3"}/>
                                    <span>SHARE</span>
                                </Button>}
                                <Button color={"dark-blue"} size={"md"} className={"mr-2"}
                                        onClick={() => {
                                            if(!user && redeem_token) {
                                                toggleCommunityRedeemModal();
                                                return;
                                            }

                                            toggleApplyToJobModal();
                                        }}>
                                    <i className={"icofont icofont-check-circled icofont-lg d-none d-lg-inline-block mr-3"}/>
                                    <span>{job.is_referral ? "ACCEPT AND APPLY" : "APPLY"}</span>
                                </Button>
                                {!job.is_referral && <Button color={"red"} size={"md"} className={"mr-2"}
                                         onClick={() => {
                                             if(!user && redeem_token) {
                                                 toggleCommunityRedeemModal();
                                                 return;
                                             }

                                             toggleReferJobModal();
                                         }}>
                                    <i className={"icofont icofont-people icofont-lg d-none d-lg-inline-block mr-3"}/>
                                    <span>REFER</span>
                                </Button>}
                                {(user && (user.is_super_admin || (user.is_company && job.company.id === customer.id))) &&
                                    <Link to={"/jobs/application-form/"+job.id} target={"_blank"}>
                                        <Button color={"primary"} size={"md"} className={"mr-2"}>
                                            <span>APPLICATION FORM</span>
                                        </Button>
                                    </Link>
                                }
                                {this.renderBounty()}
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        )
    }

    renderJobBody() {
        const {user, job, toggleReferJobModal, toggleApplyToJobModal, redeem_token, toggleCommunityRedeemModal} = this.props;
        const {sectionCardsCollapsed, toggleCollapseSectionCard} = this.state;

        return (
            <Row>
                <Col className={"flex-column"}>
                    {job.is_referral
                        ? (<InfoCard color={"dark-blue"}
                                     hasButton={true}
                                     buttonClick={() => toggleApplyToJobModal()}
                                     description={"This job offer has a bounty that you can win if you get hired."}
                                     buttonLabel={"ACCEPT AND APPLY"}>
                            <img src={applyIcon} alt={"apply-icon"}/>
                            <div className={"d-flex align-items-center"}>
                                <img src={Coin} width={37} height={37} className={"object-contain"} alt={"Coin"}/>
                                <h4 className={"ml-3 mb-0 font-weight-bold"}>BOUNTY <strong>{job.currency} <NumberFormatter value={job.bounty}/></strong></h4>
                            </div>
                        </InfoCard>)
                        : (<InfoCard color={"red"}
                                   hasButton={true}
                                   buttonClick={() => {
                                       if(!user && redeem_token) {
                                           toggleCommunityRedeemModal();
                                           return;
                                       }

                                       toggleReferJobModal();
                                   }}
                                   description={"You know the right person for this job offer? Share the profile to have a chance of collecting the bounty!"}
                                   buttonLabel={"REFER"}>
                            <img src={bountyIcon} alt={"bounty-icon"}/>
                            <div className={"d-flex align-items-center"}>
                                <img src={Coin} width={37} height={37} className={"object-contain"} alt={"Coin"}/>
                                <h4 className={"ml-3 mb-0 font-weight-bold"}>BOUNTY <strong>{job.currency} <NumberFormatter value={job.bounty}/></strong></h4>
                            </div>
                        </InfoCard>)}
                    <Card>
                        <CardHeader>
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <h3 className={"font-weight-bold"}>About the Company</h3>
                                <div onClick={() => toggleCollapseSectionCard('about_company')}>
                                    {sectionCardsCollapsed.about_company ? <ArrowDown/> : <ArrowUp/>}
                                </div>
                            </div>
                        </CardHeader>
                        <Collapse isOpen={sectionCardsCollapsed.about_company}>
                            <CardBody className={"text-preview-container"}>
                                <div dangerouslySetInnerHTML={{__html: job.company.description}} />
                            </CardBody>
                        </Collapse>
                    </Card>
                    <Card>
                        <CardHeader>
                            <div className={"d-flex justify-content-between align-items-center"}>
                                <h3 className={"font-weight-bold"}>About the Role</h3>
                                <div onClick={() => toggleCollapseSectionCard('about_role')}>
                                    {sectionCardsCollapsed.about_role ? <ArrowDown/> : <ArrowUp/>}
                                </div>
                            </div>
                        </CardHeader>
                        <Collapse isOpen={sectionCardsCollapsed.about_role}>
                            <CardBody className={"text-preview-container"}>
                                <div dangerouslySetInnerHTML={{__html: job.description}} />
                            </CardBody>
                        </Collapse>
                    </Card>
                    { job.bounty_terms && (
                        <Card>
                            <CardHeader>
                                <div className={"d-flex justify-content-between align-items-center"}>
                                    <h3 className={"font-weight-bold"}>Bounty Terms</h3>
                                    <div onClick={() => toggleCollapseSectionCard('bounty_terms')}>
                                        {sectionCardsCollapsed.bounty_terms ? <ArrowDown/> : <ArrowUp/>}
                                    </div>
                                </div>
                            </CardHeader>
                            <Collapse isOpen={sectionCardsCollapsed.bounty_terms}>
                                <CardBody className={"text-preview-container"}>
                                    <div dangerouslySetInnerHTML={{__html: job.bounty_terms}} />
                                </CardBody>
                            </Collapse>
                        </Card>
                    )}
                </Col>
            </Row>
        )
    }

    renderJobFooter() {
        const {user, job, toggleReferJobModal, toggleApplyToJobModal, redeem_token, toggleCommunityRedeemModal} = this.props;
        const {toggleShareJobModal, what_is_a_bounty_footer_popover_open} =  this.state;

        return (
            <Row>
                <Col>
                    <Card className={"text-center d-flex flex-column align-items-center px-2 py-3"}>
                        <span className={"mb-2"}>This Job Position has a bounty on it</span>
                        <div className={"d-flex align-items-center mb-3"}>
                            <img src={Coin} width={37} height={37} className={"object-contain"} alt={"Coin"}/>
                            <h4 className={"ml-3 mb-0 font-weight-bold purple-text"}><strong>{job.currency} <NumberFormatter
                                value={job.bounty}/></strong></h4>
                            <div className={"text-circle pointer ml-3"}
                                 onMouseEnter={() => {
                                     this.setState({what_is_a_bounty_footer_popover_open: true})
                                 }}
                                 onMouseOver={() => {
                                     clearTimeout(this.whatIsABountyFooterPopoverTimeout);}
                                 }
                                 onMouseLeave={() => {
                                     this.handleFooterPopoverClose();
                                 }}>
                                <span id="jobFooterExplanation" className={"f-18"}>?</span>
                            </div>
                            <Popover
                                placement="top"
                                isOpen={what_is_a_bounty_footer_popover_open}
                                target="jobFooterExplanation"
                            >
                                <PopoverBody className={"bg-dark-gray d-flex flex-column"}
                                             onMouseEnter={() => {
                                                 this.setState({what_is_a_bounty_footer_popover_open: true})
                                             }}
                                             onMouseOver={() => {
                                                 clearTimeout(this.whatIsABountyFooterPopoverTimeout);}
                                             }
                                             onMouseLeave={() => {
                                                 this.handleFooterPopoverClose();
                                             }}>
                                    <span>A Bounty is a cash reward that is split between the referrer and the applicant, in case the applicant is hired.</span>
                                    <u><a href="https://absorbing-periwinkle-352.notion.site/Job-bounties-c4861516955e4fc3a07f4e69e2d13d3c" className={"green-text"}>Learn more</a></u>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <Container fluid={true}>
                            <Row>
                                {((user && !job.is_referral) || (!user && redeem_token)) && <Col className={"d-flex"} md={"6"} lg={""}>
                                    <InfoCard color={"teal"}
                                              className={"pointer w-100"}
                                              cardClick={() => {
                                                  if(!user && redeem_token) {
                                                      toggleCommunityRedeemModal();
                                                      return;
                                                  }

                                                  toggleShareJobModal();
                                              }}
                                              description={"Share a tracked link on social media if the contacted person comes from it, you’ll get the bounty!"}>
                                        <img src={shareIcon} alt={"share-icon"}/>
                                        <div className={"d-flex align-items-center"}>
                                            <h4 className={"mb-0 font-weight-bold"}>SHARE</h4>
                                        </div>
                                    </InfoCard>
                                </Col>}
                                <Col className={"d-flex"} md={"6"} lg={""}>
                                    <InfoCard color={"dark-blue"}
                                              className={`${job.is_referral ? "" : "pointer"} w-100`}
                                              hasButton={job.is_referral}
                                              buttonClick={() => toggleApplyToJobModal()}
                                              cardClick={() => {
                                                  if(!user && redeem_token) {
                                                      toggleCommunityRedeemModal();
                                                      return;
                                                  }

                                                  if (!job.is_referral) {
                                                      toggleApplyToJobModal()
                                                  }
                                              }}
                                              description={job.is_referral
                                                  ? "This job offer has a bounty that you can win if you successfully apply."
                                                  : "Apply by yourself. If you’re the right person, the bounty will be awarded to you."}
                                              buttonLabel={"APPLY"}>
                                        <img src={applyIcon} alt={"apply-icon"}/>
                                        <div className={"d-flex align-items-center"}>
                                            <h4 className={"mb-0 font-weight-bold"}>APPLY</h4>
                                        </div>
                                    </InfoCard>
                                </Col>
                                {!job.is_referral && <Col className={"d-flex"} md={"6"} lg={""}>
                                    <InfoCard color={"red"}
                                               className={"pointer w-100"}
                                               cardClick={() => {
                                                   if(!user && redeem_token) {
                                                       toggleCommunityRedeemModal();
                                                       return;
                                                   }

                                                   toggleReferJobModal();
                                               }}
                                               description={"You know the right person for this job offer? Share the profile to have a chance of collecting the bounty!"}
                                               buttonLabel={"REFER"}>
                                        <img src={bountyIcon} alt={"bounty-icon"}/>
                                        <div className={"d-flex align-items-center"}>
                                            <h4 className={"mb-0 font-weight-bold"}>REFER</h4>
                                        </div>
                                    </InfoCard>
                                </Col>}
                            </Row>
                        </Container>
                    </Card>
                </Col>
            </Row>
        )
    }

    render() {
        const {user, user_token, loading, job, job_board_token, job_token, refer_modal_open, success_refer_modal, apply_to_job_modal_open, success_apply_to_job_modal_open, redeem_token} = this.props;
        const {
            shareJobModalOpen,
            toggleShareJobModal,
        } = this.state;

        if(!loading && !job && !user_token && job_token) {
            return (<Redirect to={"/"} />);
        }

        return (
            <Fragment>
                <CommunityRedeemModal />
                {shareJobModalOpen && <ShareJobModal job={job} token={job_token} toggleShareJobModal={toggleShareJobModal}/>}
                {apply_to_job_modal_open && job.apply_link_type === JOB_APPLY_LINK_TYPE_EXTERNAL && <ExternalApplyToJobModal job={job} />}
                {apply_to_job_modal_open && job.apply_link_type === JOB_APPLY_LINK_TYPE_INTERNAL && <InternalApplyToJobModal job={job} />}
                {success_apply_to_job_modal_open && <ApplySuccessfulModal job={job} />}
                {refer_modal_open && <ReferJobModal job={job}/>}
                {success_refer_modal.open && <ReferralCompleteModal />}

                {!loading && job &&
                (<div>
                    <Breadcrumb title={"Job details"} hideBreadcrumbRight={true} actions={() => {
                        if(user || job_board_token) {
                            return (
                                <Link to={`/jobs/${job_board_token ? job_board_token : ""}`} className={"btn btn-green text-white"}>
                                    Go to Bounties
                                </Link>
                            );
                        } else if(!user && redeem_token) {
                            return (
                                <Link to={"/redeem"} className={"btn btn-green text-white"}>
                                    Go to Bounties
                                </Link>
                            );
                        }
                    }}/>
                    <Container fluid={true}>
                        {this.renderJobHeader()}
                    </Container>
                    <Container className={"narrow-container"}>
                        {this.renderJobBody()}
                    </Container>
                    <Container fluid={true}>
                        {this.renderJobFooter()}
                    </Container>
                </div>)}
                {loading && this.renderLoading()}
                {!loading && !job && this.renderInvalidJob()}
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    getJobDetails,
    getReferrerDetails,
    setJobToken,
    toggleReferJobModal,
    toggleApplyToJobModal,
    toggleCommunityRedeemModal,
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    customer: state.customer.data,
    loading: state.job.selected_job.loading,
    job: state.job.selected_job.data,
    refer_modal_open: state.job.selected_job.refer_modal_open,
    success_refer_modal: state.job.selected_job.success_refer_modal,
    apply_to_job_modal_open: state.job.selected_job.apply_to_job_modal_open,
    success_apply_to_job_modal_open: state.job.selected_job.success_apply_to_job_modal_open,
    job_board_token: state.job.job_board_token,
    job_token: state.job.job_token,
    redeem_token: state.community.redeem_token,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobDetails)
