import React from 'react';
import Atlas from "../../assets/images/logo/logo_no_text.png";
import Slack from '../../assets/images/social_profiles/slack.svg';
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {connectSlack, setSlackStatus} from "../../redux/oauth/actions";
import {OAUTH_STATUS_CONNECTED, OAUTH_STATUS_CONNECTING, OAUTH_STATUS_ERROR} from "../../constants";

class OAuthSlack extends React.Component {
    componentDidMount() {
        const {setSlackStatus, connectSlack} = this.props;
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const communityId = params.get("community_id");

        if(!code || !communityId){
            setSlackStatus(OAUTH_STATUS_ERROR);
        } else {
            connectSlack(code, communityId)
        }
    }

    renderLoading() {
        return (
            <div className={"d-flex flex-column align-items-center"}>
                <div className={"my-2"}>
                    <i className={"fa fa-spinner fa-spin fa-3x"} />
                </div>
                <h3>We are connecting you with Slack</h3>
            </div>
        )
    }

    renderInformation = (label, icon, iconColor) => {
        return (
            <div className={"d-flex flex-column align-items-center"}>
                <div className={"my-3"}>
                    <div className={`icon-circle ${iconColor}`}>
                        <i className={`fa fa-${icon} fa-2x text-${iconColor}`} />
                    </div>
                </div>
                <h3 className={"mb-3 text-center"}>{label}</h3>
                <Link className={"btn btn-primary"} to={"/"}>Go to Directory</Link>
            </div>
        )
    }

    renderSuccess() {
        return this.renderInformation('Successfully connected your community with Slack', 'check', 'success');
    }

    renderError() {
        return this.renderInformation('There was an error connecting Slack with your Community', 'times', 'danger');
    }

    render () {
        const {slack} = this.props;

        return (
            <Container>
                <Row>
                    <Col className={"py-5"}>
                        <Card>
                            <CardBody>
                                <div className={"d-flex justify-content-center align-items-center p-5"}>
                                    <img src={Atlas} width={125} className={"img-fluid"} alt={"atlas-logo"}/>
                                    <i className={"fa fa-arrows-h fa-3x px-3"} />
                                    <img src={Slack} width={125} className={"img-fluid"} alt={"slack-logo"}/>
                                </div>
                                {slack.status === OAUTH_STATUS_CONNECTING && this.renderLoading()}
                                {slack.status === OAUTH_STATUS_CONNECTED && this.renderSuccess()}
                                {slack.status === OAUTH_STATUS_ERROR && this.renderError()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapDispatchToProps = {connectSlack, setSlackStatus};

const mapStateToProps = (state, ownProps) => ({
    slack: state.oauth.slack,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OAuthSlack)