import React from "react";
import {Label} from "reactstrap";
import {connect} from "react-redux";
import {
    loadCommunities,
    resetCommunitiesPage,
    toggleRedeemedCommunityFilter,
} from '../../../redux/community/actions';

class RedeemedCommunityFilter extends React.Component {
    render() {
        const {loadCommunities, resetCommunitiesPage, redeemedCommunityFilter, toggleRedeemedCommunityFilter} = this.props;

        return (
            <div>
                <div className="faq-form m-b-10 d-flex">
                    <div className={"size-20 checkbox-container " + (redeemedCommunityFilter ? "active full" : "")}
                         onClick={() => {
                             toggleRedeemedCommunityFilter();
                             resetCommunitiesPage();
                             loadCommunities();
                         }}>
                        <i className={"fa fa-check"}/>
                    </div>
                    <Label className={"ml-2"}>
                        Redeemed communities
                    </Label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    redeemedCommunityFilter: state.community.list_filters.redeemed_community_filter,
});

const mapDispatchToProps = {
    toggleRedeemedCommunityFilter,
    resetCommunitiesPage,
    loadCommunities
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedeemedCommunityFilter)