import React from 'react';
import {Button} from 'reactstrap'

class DisplayResultButton extends React.Component {

    render() {
        const {color, handleClick, displaySize, displayColor, displayText, buttonIcon, buttonLabel, bold} = this.props;
        const displayClasses = `${displayColor}-text mb-0 ${bold ? 'font-weight-bold' : ''} px-2 px-lg-3`

        return (
            <div className={`d-flex align-items-center p-1 rounded rounded-pill bg-${color}`}>
                <span className={"flex-grow-1 p-2 rounded rounded-pill bg-white"} style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "78%"
                }}>
                    {(!displaySize || displaySize === 'sm') && <span className={displayClasses}>{displayText}</span>}
                    {displaySize === 'md' && <h5 className={displayClasses}>{displayText}</h5>}
                    {displaySize === 'lg' && <h3 className={displayClasses}>{displayText}</h3>}
                </span>
                <Button onClick={handleClick}
                        className={"rounded rounded-pill custom-input-btn mx-auto px-3 border-0"}
                        color={color}>
                    <span className={"f-18 d-flex align-items-center"}>
                        {buttonIcon && <i className={`fa fa-${buttonIcon}`}/>}&nbsp;{buttonLabel}</span>
                </Button>
            </div>
        );
    }
}

export default DisplayResultButton;
