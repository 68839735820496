import React from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader} from "reactstrap";

class AverageCommunityReport extends React.Component {

    generateScalarValue(globalAverage, singularValue, color) {
        var maxValue = Math.max(globalAverage, singularValue);
        var globalWidth = Math.round((globalAverage / maxValue) * 100);
        var singularWidth = Math.round((singularValue / maxValue) * 100);

        var marginRight = (singularWidth < 50) ? (singularValue.toString().length - 1) * 16 : 0;
        var textColor = marginRight > 0 ? "#000000" : "#ffffff";

        return (
            <div className={"average-scalar-report"}>
                <div className={"global-container"} style={{"width": globalWidth + "%"}}>
                    <span>World average: {globalAverage}</span>
                    <div className={"global-value"} style={{"backgroundColor": color + "7a"}}></div>
                </div>
                <div className={"singular-value"} style={{"backgroundColor": color, "width": singularWidth + "%"}}><span
                    style={{"marginRight": "-" + marginRight + "px", "color": textColor}}>{singularValue}</span></div>
            </div>
        );
    }

    render() {
        const {report} = this.props;
        if (!report) {
            return null;
        }

        const {global, singular} = report;
        if (!global || (global && global.is_global_view)) {
            return null;
        }

        return (
            <Card>
                <CardHeader>
                    <h5>AVERAGE COMMUNITY:</h5>
                </CardHeader>
                <CardBody className={"p-5"}>
                    <div className={"d-flex flex-column"}>
                        <h4 className={"m-0"}>Members:</h4>
                        {this.generateScalarValue(Math.round(global.avg_members), Math.round(singular.avg_members), "#FFA300")}
                    </div>
                    <div className={"d-flex flex-column mt-3"}>
                        <h4 className={"m-0"}>Monthly events:</h4>
                        {this.generateScalarValue(global.avg_monthly_events_per_community, singular.avg_monthly_events_per_community, "#FFD205")}
                    </div>
                    <div className={"d-flex flex-column mt-3"}>
                        <h4 className={"m-0"}>Average event participants:</h4>
                        {this.generateScalarValue(global.avg_event_participants, singular.avg_event_participants, "#00D2DC")}
                    </div>
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    report: state.dashboard.insights.average_parameters.data,
});

export default connect(
    mapStateToProps,
    null
)(AverageCommunityReport)
