import React from "react";

class ChannelMessageDateItem extends React.Component{
    render(){
        const {date} = this.props;
        var formatedDate = date.toLocaleDateString("en-US", {month: "long", day: "numeric"});
        return(
            <span className="align-self-center mb-4 text-center badge badge-pill badge-primary">{formatedDate}</span>
        )
    }
}

export default ChannelMessageDateItem;