import React from "react";
import {Button, Card, CardBody, CardHeader, Table} from "reactstrap";
import Meetup from "../../../assets/images/social_profiles/meetup.svg";
import Facebook from "../../../assets/images/social_profiles/facebook.svg";
import Twitter from "../../../assets/images/social_profiles/twitter.svg";
import Instagram from "../../../assets/images/social_profiles/instagram.svg";
import Bitly from "../../../assets/images/social_profiles/bitly.svg";
import Discord from "../../../assets/images/social_profiles/discord.svg";
import Flickr from "../../../assets/images/social_profiles/flickr.svg";
import Github from "../../../assets/images/social_profiles/github.svg";
import GoogleDocs from "../../../assets/images/social_profiles/google-drive.svg";
import GooglePlus from "../../../assets/images/social_profiles/google-plus.svg";
import Linkedin from "../../../assets/images/social_profiles/linkedin.svg";
import Medium from "../../../assets/images/social_profiles/medium.svg";
import Reddit from "../../../assets/images/social_profiles/reddit.svg";
import Slack from "../../../assets/images/social_profiles/slack.svg";
import Telegram from "../../../assets/images/social_profiles/telegram.svg";
import Youtube from "../../../assets/images/social_profiles/youtube.svg";
import {connect} from "react-redux";
import {editCommunity, editCommunityLinks} from "../../../redux/community/actions";
import {MEETUP_GROUP_PROFILE_REGEX} from "../../../constants";

class CommunityLinksManagements extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            has_valid_link: false,
            setHasValidLink: (value) => {
                this.setState({
                    has_valid_link: value,
                })
            },
            selected_profile_type: "facebook_page_profiles",
            selected_profile_url: "",
            social_medias: {
                facebook_page_profiles: {
                    label: "Facebook Page",
                    logo: Facebook,
                },
                facebook_group_profiles: {
                    label: "Facebook Group",
                    logo: Facebook,
                },
                twitter_profiles: {
                    label: "Twitter",
                    logo: Twitter,
                },
                instagram_profiles: {
                    label: "Instagram",
                    logo: Instagram,
                },
                bitly_profiles: {
                    label: "Bitly",
                    logo: Bitly,
                },
                discord_profiles: {
                    label: "Discord",
                    logo: Discord,
                },
                flickr_profiles: {
                    label: "Flickr",
                    logo: Flickr,
                },
                github_profiles: {
                    label: "Github",
                    logo: Github,
                },
                google_docs_profiles: {
                    label: "Google Docs",
                    logo: GoogleDocs,
                },
                google_plus_profiles: {
                    label: "Google Plus",
                    logo: GooglePlus,
                },
                linkedin_profiles: {
                    label: "Linkedin",
                    logo: Linkedin,
                },
                medium_profiles: {
                    label: "Medium",
                    logo: Medium,
                },
                reddit_profiles: {
                    label: "Reddit",
                    logo: Reddit,
                },
                slack_profiles: {
                    label: "Slack",
                    logo: Slack,
                },
                telegram_profiles: {
                    label: "Telegram",
                    logo: Telegram,
                },
                youtube_profiles: {
                    label: "YouTube",
                    logo: Youtube,
                }
            }
        };

        this.savingTimeout = null;
    }

    saveTheCommunity(community) {
        const {isCreating, checkForValidLink} = this.props;
        const {setHasValidLink} = this.state;
        if(isCreating) {
            setHasValidLink(checkForValidLink());
            return;
        }

        if (this.savingTimeout) {
            clearTimeout(this.savingTimeout);
        }
        const {editCommunity, editCommunityLinks} = this.props;

        editCommunity(community);

        setTimeout(() => {
            editCommunityLinks(community);
        }, 400);
    }

    generateSocialPageProfile(selected_profile_type, logo, label) {
        const {community} = this.props;
        const {has_valid_link} = this.state;
        const $this = this;

        if(community[selected_profile_type]) {
            return community[selected_profile_type].map(function (profile) {
                return (
                    <tr key={profile.id ? profile.id : profile.temp_id}>
                        <td width="60" className={"text-center"}>
                            <img src={logo} width="40" alt={"social-profile-logo"}/>
                        </td>
                        <td width="150" className={"text-left pt-4"}>
                            <span>{label}: </span>
                        </td>
                        <td>
                            <input type="text" required={!has_valid_link} className="form-control" style={{"height": "40px"}}
                                   value={profile.url} onChange={(e) => {
                                profile.url = e.target.value;
                                $this.saveTheCommunity(community);
                            }}/>
                        </td>
                        <td width="150" className={"text-center pt-4"}>
                            <Button color={"white"} className={"purple-text font-weight-normal"}
                                    onClick={() => $this.removeCommunitySocialProfilePage(profile, selected_profile_type)}>Remove</Button>
                        </td>
                    </tr>
                );
            });
        }
    }

    generateMeetupProfile() {
        const {community, isCreating, is_existing_meetup_community} = this.props;
        const {has_valid_link} = this.state;
        const $this = this;

        return (
            <tr>
                <td width="60" className={"text-center"}>
                    <img src={Meetup} width="40"/>
                </td>
                <td width="150" className={"text-left pt-4"}>
                    <span>Meetup:</span>
                </td>
                <td>
                    <input type="text" required={!has_valid_link} className={`form-control m-b-10 ${has_valid_link ? "no-validate" : ""}`} style={{"height": "40px"}}
                           pattern={MEETUP_GROUP_PROFILE_REGEX}
                           placeholder={"https://www.meetup.com/your-community-name"}
                           value={community.meetup_url} onChange={(e) => {
                        community.meetup_url = e.target.value;
                        $this.saveTheCommunity(community);
                    }}/>
                    <div className={"invalid-feedback"}>
                        <span>Community must have at least one social media or Meetup link</span>
                    </div>
                    {isCreating && is_existing_meetup_community && <span className={"text-danger"}>
                        This community already exists
                    </span>}
                </td>
                <td width="150"></td>
            </tr>
        );
    }

    addNewLink() {
        const {community} = this.props;
        const {selected_profile_type, selected_profile_url} = this.state;

        if (!selected_profile_type || selected_profile_url.length === 0) {
            return;
        }

        var date = new Date();
        const newLink = {
            temp_id: date.getTime(),
            url: selected_profile_url
        };

        if(community[selected_profile_type]) {
            community[selected_profile_type].push(newLink);
        }

        // save community
        this.saveTheCommunity(community);
        this.setState({
            selected_profile_type: "facebook_page_profiles",
            selected_profile_url: "",
        });
    }

    removeCommunitySocialProfilePage(page, selected_profile_type) {
        const {community} = this.props;

        for (var i = 0; i < community[selected_profile_type].length; i++) {
            const profile = community[selected_profile_type][i];

            if (profile.id === page.id || (page.temp_id && page.temp_id === profile.temp_id)) {
                community[selected_profile_type].splice(i, 1);
            }
        }

        // save community
        this.saveTheCommunity(community);
    }

    removeMeetupProfile() {
        const {community} = this.props;
        community.meetup_url = null;

        // save community
        this.saveTheCommunity(community);
    }

    render() {
        const {isCreating} = this.props;
        const {social_medias, has_valid_link} = this.state;

        return (
            <Card>
                <CardHeader>
                    <h5 className="mb-0">LINKS</h5>
                    <span> Data in the Community page will be automatically extracted, elaborated and aggregated from the links you will provide  in this section.</span>
                    {isCreating && <span>(At least 1 social link is required)</span>}
                </CardHeader>

                <CardBody>
                    <Table className={"links-management-table"}>
                        <tbody>
                        {Object.entries(social_medias).map(([key, value]) => (
                            this.generateSocialPageProfile(key, value.logo, value.label)
                        ))}
                        {!isCreating && this.generateMeetupProfile()}
                        </tbody>
                    </Table>

                    <Table className={"links-management-table"}>
                        <tbody>
                        <tr>
                            <td width="210" className={"text-left pb-0 pl-4"}>
                                <span>Add new link</span>
                            </td>
                        </tr>
                        <tr>
                            <td width="200" className={"text-left"}>
                                <select className={'form-control no-validate'} value={this.state.selected_profile_type}
                                        onChange={(e) => this.setState({selected_profile_type: e.target.value})}>
                                    {Object.entries(social_medias).map(([key, value]) => (
                                        <option value={key} key={key}>{value.label}</option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <input type="text" required={!has_valid_link} className={`form-control ${has_valid_link ? "no-validate" : ""}`} style={{"height": "40px"}}
                                       value={this.state.selected_profile_url}
                                       onChange={(e) => this.setState({selected_profile_url: e.target.value})}/>
                            </td>
                            <td width="150" className={"text-center"}>
                                <Button color={"green"} onClick={() => this.addNewLink()}>
                                    Add
                                </Button>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    {isCreating && <Table className={"links-management-table"}>
                        <tbody>
                        {this.generateMeetupProfile()}
                        </tbody>
                    </Table>}
                </CardBody>
            </Card>
        );
    }
}

const mapDispatchToProps = {editCommunity, editCommunityLinks};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    community: state.community.edit_community.data,
    is_existing_meetup_community: state.community.edit_community.is_existing_meetup_community,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityLinksManagements)
