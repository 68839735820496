import React from "react";


function NumberFormatter(props) {
    const number = props.value;
    var formattedNumber = number;

    try {
        formattedNumber = number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } catch (e) {
    }

    return (<span>{formattedNumber}</span>);
}

export default NumberFormatter;
