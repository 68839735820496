import React from "react";

class LogoComponent extends React.Component {
    render() {
        const {logo, width, height, className} = this.props;
        const logoUrl = logo ? logo.url : "";

        return(
            <div className={className} style={{
                width: `${width}px`,
                height: `${height}px`,
                backgroundColor: logoUrl ? "#ffffff" : "#1E2AA5",
                backgroundImage: "url(" + logoUrl + ")",
                backgroundPosition: "center",
                backgroundSize: "cover"
            }}/>
        )
    }
}

export default LogoComponent;