import React from 'react';
import {connect} from "react-redux";
import {setWalletFound, setWalletPublicKey} from "../../../redux/solana/actions";
import {Popover, PopoverBody} from "reactstrap";

class SolanaWalletPopoverComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            solana_popover_opened: false
        };
    }

    toggleSolanaPopover() {
        const {solana_popover_opened} = this.state;

        this.setState({
            solana_popover_opened: solana_popover_opened === false
        });
    }

    render() {
        const {solana_popover_opened} = this.state;
        return <>
            <div className={"text-circle pointer ml-3 text-phantom border-phantom"}
                 onMouseEnter={() => {
                     this.toggleSolanaPopover();
                 }}
                 onMouseLeave={() => {
                     this.toggleSolanaPopover();
                 }}>
                <span id="jobHeaderExplanation" className={"f-18"}>?</span>
            </div>
            <Popover
                placement="top"
                isOpen={solana_popover_opened}
                target="jobHeaderExplanation"
            >
                <PopoverBody className={"bg-dark-gray d-flex flex-column"}
                             onMouseEnter={() => {
                                 this.toggleSolanaPopover();
                             }}
                             onMouseLeave={() => {
                                 this.toggleSolanaPopover();
                             }}>
                    <span>Aplications for this job offer are tracked over blockchain.</span>
                    <span>Connect your <a className={"green-text"} href="https://phantom.app/download" target="_blank">Phantom Wallet</a> to apply.</span>
                </PopoverBody>
            </Popover>
        </>;
    }
}

export default SolanaWalletPopoverComponent;