import React from "react";
import {Badge, Label} from "reactstrap";
import {connect} from "react-redux";
import {
    loadCommunities,
    resetCommunitiesPage,
    selectSocialToSearchForCommunity,
    unselectSocialToSearchForCommunity
} from '../../../redux/community/actions';

class SocialMediaFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            social_medias: [
                {
                    name: "Facebook Page",
                    value: "facebook_page"
                },
                {
                    name: "Facebook Group",
                    value: "facebook_group"
                },
                {
                    name: "Twitter",
                    value: "twitter"
                },
                {
                    name: "Instagram",
                    value: "instagram"
                },
                {
                    name: "Bitly",
                    value: "bitly"
                },
                {
                    name: "Discord",
                    value: "discord"
                },
                {
                    name: "Flickr",
                    value: "flickr"
                },
                {
                    name: "Github",
                    value: "github"
                },
                {
                    name: "Google Docs",
                    value: "google_docs"
                },
                {
                    name: "Google Plus",
                    value: "google_plus"
                },
                {
                    name: "Linkedin",
                    value: "linkedin"
                },
                {
                    name: "Medium",
                    value: "medium"
                },
                {
                    name: "Reddit",
                    value: "reddit"
                },
                {
                    name: "Slack",
                    value: "slack"
                },
                {
                    name: "Telegram",
                    value: "telegram"
                },
                {
                    name: "YouTube",
                    value: "youtube"
                }
            ]
        };
    }

    buildListOfSelectedSocials() {
        const {social_medias} = this.state;
        const {loadCommunities, unselectSocialToSearchForCommunity, socialMediaFilters, resetCommunitiesPage} = this.props;
        const {selected_socials} = socialMediaFilters;

        return selected_socials.map((social) => {
            return (
                <Badge className={social.disabled ? "" : "pointer"} color={"info"} key={social.value} onClick={() => {
                    if (social.disabled) {
                        return;
                    }

                    social.selected = false;
                    unselectSocialToSearchForCommunity(social);
                    resetCommunitiesPage();
                    loadCommunities();

                    // mark social as not selected
                    this.setState({
                        social_medias: social_medias.map(function (s) {
                            if (s.value == social.value) {
                                s.selected = false;
                            }

                            return s;
                        })
                    });
                }}>{social.name} {social.disabled ? "" : "x"}</Badge>
            );
        })
    }

    handleNewSelectedValue(value) {
        const {social_medias} = this.state;
        const {selectSocialToSearchForCommunity, resetCommunitiesPage, loadCommunities} = this.props;

        for (var i = 0; i < social_medias.length; i++) {
            var socialMedia = social_medias[i];
            if (socialMedia.value === value) {
                socialMedia.selected = true;
                selectSocialToSearchForCommunity(socialMedia);
                resetCommunitiesPage();
                loadCommunities();

                this.setState({
                    social_medias: social_medias
                });
                return;
            }
        }
    }

    render() {
        const {social_medias} = this.state;

        return (
            <div>
                <Label className="d-block">
                    Social Medias:
                </Label>

                <div className="faq-form m-b-10">
                    <select className={"form-control"} value={""}
                            onChange={(e) => this.handleNewSelectedValue(e.target.value)}>
                        <option value="">Choose a social media</option>

                        {social_medias.filter(function (social_media) {
                            return !social_media.selected;
                        }).map((social_media) => {
                            return (<option key={social_media.value}
                                            value={social_media.value}>{social_media.name}</option>);
                        })}
                    </select>
                </div>

                <div className="m-b-10">
                    {this.buildListOfSelectedSocials()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    socialMediaFilters: state.community.social_media_filters
});

const mapDispatchToProps = {
    selectSocialToSearchForCommunity,
    unselectSocialToSearchForCommunity,
    resetCommunitiesPage,
    loadCommunities
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SocialMediaFilter)
