import React from 'react';
import {connect} from "react-redux";
import logo from "../../../assets/images/logo/favicon.png";
import systemMessage from "../../utils/system_messages";

class SystemMessage extends React.Component{

    renderSystemMessages(){
const {currentUser, type} = this.props;
        

        const messageList = [];

        systemMessage[type][currentUser.is_company ? "company" : "community"].forEach((message, index) => {
                messageList.push(<span key={"system-message-"+index} className={"text-left"}>{message}</span>)
        });

        return (messageList);
    }

    render(){

        return(
            <li className={"message d-flex flex-column text-right"}>
                <img className="rounded-circle chat-user-img img-30" src={logo} alt={"system-logo"} />
                <div className="message-data text-left">System Message</div>
                {this.renderSystemMessages()}
            </li>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.data,
});

export default connect(
    mapStateToProps,
)(SystemMessage);