import Blur from "../../common/helpers/blur";
import React from "react";

function UseBlurOnCommunity(props) {
    if (props.community.public !== "true") {
        return (<Blur radius={"5px"}>{props.children}</Blur>);
    }

    return props.children;
}

export default UseBlurOnCommunity;
