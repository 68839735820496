import {useLayoutEffect, useState} from "react";

export default function useWindowSize(wrapper){
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    if (wrapper === "horizontal_sidebar") {
        if (size[0] > 100 && size[0] < 991) {
            document.querySelector(".page-wrapper").className = 'page-wrapper default';
            document.querySelector(".page-body-wrapper").className = 'page-body-wrapper default';
        } else {
            document.querySelector(".page-wrapper").className = 'page-wrapper horizontal_sidebar';
            document.querySelector(".page-body-wrapper").className = 'page-body-wrapper horizontal_sidebar';
        }

    }

    return size;
};