import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import {changePassword, updateUser} from '../../redux/user/actions'
import {updateCountry, updateCustomer, cancelSubscriptionRenew} from '../../redux/customer/actions'
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
    Table,
    Button,
    ButtonGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
} from 'reactstrap';
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import ImageUploader from "../common/helpers/image_uploader";
import ImagePreview from "../common/helpers/image_preview";
import UserProfileImageUploader from "../common/helpers/user_profile_image_uploader";


class ProfilePage extends React.Component {
    constructor(props) {
        super(props);

        this.updateTimeout = null;

        this.state = {
            active_tab: "1",
            setActiveTab: (id) => {
                this.setState({
                    active_tab: id
                })
            },
            modal_state: false,
            toggleModal: () => {
                this.setState({
                    modal_state: !this.state.modal_state,
                })
            },
            new_profile_logo: null,
            fields: {
                current_password: "",
                new_password: "",
                repeat_password: "",
            },
            error: {
                new_password: false,
                repeat_password: false,
            },
            hasErrors: false,
        };
    }

    renderLoading() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Loading...</h4>
            </div>
        );
    }

    updateTheUser(user) {
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
        }

        this.updateTimeout = setTimeout(() => {
            this.props.updateUser(user);
        }, 400)
    }

    updateTheCustomer(customer) {
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
        }

        this.updateTimeout = setTimeout(() => {
            this.props.updateCustomer(customer);
        }, 400)
    }

    onFirstnameChange(value) {
        const {user} = this.props;

        if (value.trim().length) {
            user.firstname = value;
            this.updateTheUser(user);
        }
    }

    onLastnameChange(value) {
        const {user} = this.props;

        if (value.trim().length) {
            user.lastname = value;
            this.updateTheUser(user);
        }
    }

    onCustomerChange(value, fieldName, required) {
        const {customer} = this.props;

        customer[fieldName] = value;

        let hasErrors = Object.entries(customer).some(([field, value]) => {
            // italian_electronic_tax_id is checked only for Italy
            if((customer.country !== "IT" && field === "italian_electronic_tax_id") || (field === "logo")){
                return false;
            }

            return value === null || value === "";
        });

        this.setState({
            hasErrors: hasErrors,
        });

        if ((!required || (required && value.trim().length)) && hasErrors === false) {
            customer[fieldName] = value;
            this.updateTheCustomer(customer);
        }
    }

    generateCountryList() {
        let countryList = [];

        countryList.push(<option value="" key="empty-country" disabled="disabled">Select a Country</option>)
        Object.keys(this.props.countries).map((code) => {
            countryList.push(<option value={code} key={code}>{this.props.countries[code]}</option>);
        });

        return countryList;
    }

    generateRenewalText(subscription) {
        if(subscription.status === 1){
            return "License will be renewed on";
        } else if(subscription.status === 2){
            return "License will end on";
        } else if(subscription.status === 3){
            return "License has ended on";
        }

        return null;
    }

    generateLicenseList() {
        const {subscriptions, cancelSubscriptionRenew} = this.props;

        function getStatusLabel(status) {
            switch (status) {
                case 1:
                    return "ACTIVE";
                case 2:
                    return "RENEWAL CANCELED";
                case 3:
                    return "INACTIVE";
                default:
                    return null;
            }
        }

        return subscriptions.map((subscription) => {
            return (
                <tr key={subscription.id} className="license">
                    <td style={{width: "300px"}}>
                        <p className="name m-b-0">{subscription.name}</p>
                        <p className="description m-b-0">{subscription.description}</p>
                    </td>
                    <td className={"text-center"}>
                        <p className="price pt-2">EUR {subscription.price}/ yr</p>
                    </td>
                    <td className="renew text-center">
                        <span>{this.generateRenewalText(subscription)}:</span>
                        <br/>
                        <p><b>{subscription.renewal_date}</b></p>
                    </td>
                    <td className="status text-center">
                        <span>STATUS:</span> <br/>
                        <p><b>{getStatusLabel(subscription.status)}</b></p>
                    </td>
                    {subscription.status === 1 && (
                        <td className="delete-link text-center">
                            <p onClick={() => {
                                if(window.confirm("Are you sure?")){
                                    cancelSubscriptionRenew(subscription.id);
                                }
                            }}>DELETE SUBSCRIPTION</p>
                        </td>
                    )}
                </tr>
            );
        });
    }

    generateCommunitiesList() {
        const { communities } = this.props.user;

        return communities.map((community) => {
            return (
                <tr key={community.id}>
                    <td className={'align-middle'}>
                        <b><u><Link to={"/community/"+community.id}>{community.name}</Link></u></b>
                    </td>
                    <td className={"text-right"}>
                        <ButtonGroup className={"d-flex flex-column flex-lg-row align-items-center justify-content-end"}>
                            {community.cgi_id && (
                                <Link to={"/community/"+community.id} className={"mr-2 mb-2"}>
                                    <Button color={"light"}>VIEW</Button>
                                </Link>
                            )}
                            <Link to={"/community/edit/"+community.id} className={"mr-2 mb-2"}>
                                <Button color={"green"}>EDIT</Button>
                            </Link>
                        </ButtonGroup>
                    </td>
                </tr>
            );
        });
    }

    resetFields() {
        this.setState({
            fields: {
                current_password: "",
                new_password: "",
                repeat_password: "",
            },
            error: {
                new_password: false,
                repeat_password: false,
            }
        })
    }

    handleOnBlur(e, field) {
        const {error, fields} = this.state;
        let errorCopy = Object.assign({}, error);
        errorCopy[field] = e.target.value.length < 8;
        errorCopy["repeat_password"] = !!(fields.repeat_password && fields.new_password !== fields.repeat_password);

        this.setState({
            error: errorCopy,
        })
    }

    handleChange(e, field) {
        const{fields, error} = this.state;
        let fieldsCopy = Object.assign({}, fields);
        let errorCopy = Object.assign({}, error);
        fieldsCopy[field] = e.target.value;

        if(e.target.value.length >= 8) {
            errorCopy[field] = false;
            this.setState({
                error: errorCopy,
            })
        }

        this.setState({
            fields: fieldsCopy,
            error: errorCopy,
        });
    }

    renderModal() {
        const {changePassword} = this.props;
        const {modal_state, toggleModal, fields, error} = this.state;

        return (
            <Modal isOpen={modal_state}>
                <ModalHeader>
                    Change Password
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs="12" sm="3"><span>Current password</span></Col>
                        <Col xs="12" sm="9">
                            <input type="password" className="form-control" placeholder="Current password" value={fields.current_password}
                                    onChange={(e) => {this.handleChange(e, "current_password")}}/>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="12" sm="3"><span>New password</span></Col>
                        <Col xs="12" sm="9">
                            <input type="password" className="form-control" placeholder="New password" value={fields.new_password}
                                    onChange={(e) => {this.handleChange(e, "new_password")}}
                                    onBlur={(e) => {this.handleOnBlur(e, "new_password")}}/>
                            {error.new_password && <span className="text-danger">*Password should be 8 characters or longer!</span>}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs="12" sm="3"><span>Repeat password</span></Col>
                        <Col xs="12" sm="9">
                            <input type="password" className="form-control" placeholder="Repeat password" value={fields.repeat_password}
                                    onChange={(e) => {this.handleChange(e, "repeat_password")}}
                                    onBlur={(e) => {this.handleOnBlur(e, "repeat_password")}}/>
                            {error.repeat_password && <span className="text-danger">*The password repeat does not match!</span>}
                        </Col>
                    </Row>
                    <br />
                    <Link to={"/request-recover-password"}>Forgot password?</Link>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                            toggleModal();
                            this.resetFields();
                    }}>Close</Button>
                    <Button className={"text-white"} color="green" onClick={() => {
                        // Check if there are object values which are true for error or empty for fields
                        if (Object.values(error).indexOf(true) > -1 || Object.values(fields).indexOf("") > -1) {
                            return;
                        }
                        changePassword(fields);
                        toggleModal();
                        this.resetFields();
                    }}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }


    renderProfileTabsHeader() {
        const {user} = this.props;
        const {active_tab, setActiveTab} = this.state;

        return (
            <Nav className="outline-nav nav-primary d-flex align-items-center mb-0" tabs>
                <NavItem className={"flex-fill"}>
                    <NavLink
                        className={`f-20 f-w-800 p-4 ${active_tab === "1" ? "active" : ""} pointer`}
                        onClick={() => setActiveTab("1")}
                    >PERSONAL DATA</NavLink>
                </NavItem>
                <NavItem className={"flex-fill"}>
                    <NavLink
                        className={`f-20 f-w-800 p-4 ${active_tab === "2" ? "active" : ""} pointer`}
                        onClick={() => setActiveTab("2")}
                    >BILLING DATA</NavLink>
                </NavItem>
                {user.communities.length && <NavItem className={"flex-fill"}>
                    <NavLink
                        className={`f-20 f-w-800 p-4 ${active_tab === "3" ? "active" : ""} pointer`}
                        onClick={() => setActiveTab("3")}
                    >YOUR COMMUNITIES</NavLink>
                </NavItem>}
            </Nav>
        )
    }

    renderTabBodyContent() {
        const {active_tab} = this.state;

        return (
            <>
                <TabContent activeTab={active_tab}>
                    <TabPane className="fade show" tabId="1">
                        {this.renderPersonalDataTab()}
                    </TabPane>
                </TabContent>
                <TabContent activeTab={active_tab}>
                    <TabPane className="fade show" tabId="2">
                        {this.renderBillingDataTab()}
                    </TabPane>
                </TabContent>
                <TabContent activeTab={active_tab}>
                    <TabPane className="fade show" tabId="3">
                        {this.renderYourCommunitiesTab()}
                    </TabPane>
                </TabContent>
            </>
        )
    }

    renderPersonalDataTab() {
        const {user} = this.props;
        const {toggleModal} = this.state;

        return (
            <>
                <Row>
                    <Col xs="12" sm="3"><span>Name</span></Col>
                    <Col xs="12" sm="9">
                        <input type="text" className="form-control" defaultValue={user.firstname}
                               onChange={(e) => this.onFirstnameChange(e.target.value)}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs="12" sm="3"><span>Surname</span></Col>
                    <Col xs="12" sm="9">
                        <input type="text" className="form-control" defaultValue={user.lastname}
                               onChange={(e) => this.onLastnameChange(e.target.value)}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs="12" sm="3"><span>Email</span></Col>
                    <Col xs="12" sm="9">
                        <input type="text" className="form-control" defaultValue={user.email}
                               disabled={true}/>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs="12" sm="3"><span>Password</span></Col>
                    <Col xs="12" sm="9">
                        <Button onClick={toggleModal} color="primary">Change Password</Button>
                    </Col>
                </Row>
                <br/>
            </>
        )
    }

    renderBillingDataTab() {
        const {user, customer} = this.props;
        const {hasErrors, editCustomerDisabled} = this.state;

        return (
            <>
                <Row>
                    <Col>
                        <form className={`needs-validation ${hasErrors ? "was-validated" : ""}`}>
                            <div>
                                <Row>
                                    <Col xs="12" sm="3"><span>Name</span></Col>
                                    <Col xs="12" sm="9">
                                        <input type="text" className="form-control" defaultValue={customer.name}
                                               disabled={editCustomerDisabled}
                                               required
                                               onChange={(e) => this.onCustomerChange(e.target.value, 'name', true)}/>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col xs="12" sm="3"><span>Description</span></Col>
                                    <Col xs="12" sm="9">
                                        <textarea className="form-control" defaultValue={customer.description}
                                                  rows={7}
                                                  disabled={editCustomerDisabled}
                                                  required
                                                  onChange={(e) => this.onCustomerChange(e.target.value, 'description', true)}/>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col xs="12" sm="3"><span>Country</span></Col>
                                    <Col xs="12" sm="9">
                                        <select value={customer.country ? customer.country : ""}
                                                onChange={(e) => this.onCustomerChange(e.target.value, 'country', true)}
                                                className="form-control"
                                                required
                                                disabled={editCustomerDisabled}>
                                            {this.generateCountryList()}
                                        </select>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col xs="12" sm="3"><span>Address</span></Col>
                                    <Col xs="12" sm="9">
                                        <input type="text" className="form-control" defaultValue={customer.address}
                                               disabled={editCustomerDisabled}
                                               required
                                               onChange={(e) => this.onCustomerChange(e.target.value, 'address', true)}/>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col xs="12" sm="3"><span>VAT</span></Col>
                                    <Col xs="12" sm="9">
                                        <input type="text" className="form-control" defaultValue={customer.vat}
                                               disabled={editCustomerDisabled}
                                               required
                                               onChange={(e) => this.onCustomerChange(e.target.value, 'vat', true)}/>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col xs="12" sm="3"><span>TAX Code</span></Col>
                                    <Col xs="12" sm="9">
                                        <input type="text" className="form-control" defaultValue={customer.tax_code}
                                               disabled={editCustomerDisabled}
                                               required
                                               onChange={(e) => this.onCustomerChange(e.target.value, 'tax_code', true)}/>
                                    </Col>
                                </Row>
                                {customer.country === "IT" && (
                                    <div>
                                        <br/>
                                        <Row>
                                            <Col xs="12"
                                                 sm="3"><span>Codice Destinatario per Fatturazione Elettronica</span></Col>
                                            <Col xs="12" sm="9">
                                                <input type="text" className="form-control"
                                                       defaultValue={customer.italian_electronic_tax_id}
                                                       disabled={editCustomerDisabled}
                                                       required
                                                       onChange={(e) => this.onCustomerChange(e.target.value, 'italian_electronic_tax_id', true)}/>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                <br/>
                            </div>
                        </form>
                    </Col>
                    {!user.is_community && <Col className={"px-5"}>
                        <div className={"m-b-10"}>
                            <span>Company profile image</span>
                        </div>
                        <UserProfileImageUploader isDisabled={editCustomerDisabled} onNewImage={(logo) => {
                            this.setState({
                                new_profile_logo: logo
                            });

                            this.onCustomerChange(logo, 'logo');
                        }}>
                            <ImagePreview
                                logo={this.state.new_profile_logo ? this.state.new_profile_logo : customer.logo}/>
                        </UserProfileImageUploader>
                    </Col>}
                </Row>
                {user.is_community === false && <div>
                    <CardHeader>
                        <h5 className="mb-0">AVAILABLE CREDITS:</h5>
                    </CardHeader>

                    <CardBody className="p-4">
                        <Row>
                            <Col xs="12" sm="3"><span>Credits</span></Col>
                            <Col xs="12" sm="9">
                                <input type="text" className="form-control" defaultValue={customer.credits}
                                       disabled={true}
                                       onChange={(e) => this.onCustomerChange(e.target.value, 'credits')}/>
                            </Col>
                        </Row>
                        <br/>
                    </CardBody>
                </div>}
                <div>
                    <CardHeader>
                        <h5 className="mb-0">YOUR LICENSE</h5>
                    </CardHeader>

                    <CardBody className="p-4">
                        <div className="table-responsive">
                            <Table>
                                <tbody>
                                {this.generateLicenseList()}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </div>
            </>
        )
    }

    renderYourCommunitiesTab() {
        return (
            <Table>
                <tbody>
                {this.generateCommunitiesList()}
                </tbody>
            </Table>
        )
    }

    renderBody() {
        return (
            <Card>
                <CardHeader className={"p-0 border-bottom-0"}>
                    {this.renderProfileTabsHeader()}
                </CardHeader>
                <CardBody>
                    {this.renderTabBodyContent()}
                </CardBody>
            </Card>
        )
    }

    render() {
        const {user, customer, loading} = this.props;

        if(!loading && !user){
            return (<Redirect to={"/"} />);
        }

        return (
            <Fragment>
                <Breadcrumb title="Profile Management"/>
                {this.renderModal()}
                {user && customer && this.renderBody()}
                {!user && loading ? this.renderLoading() : ""}
            </Fragment>
        );
    }
}

const mapDispatchToProps = {updateUser, updateCustomer, updateCountry, cancelSubscriptionRenew, changePassword};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    loading: state.user.loading,
    customer: state.customer.data,
    countries: state.utils.data.countries,
    subscriptions: state.customer.subscriptions
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePage)
