import React from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import Apexchart from "react-apexcharts";
import BlurredGraph from "./blurred_graph";

class SocialMediaDistributionReport extends React.Component {

    renderMap() {
        const {report} = this.props;

        return (
            <Apexchart
                options={{
                    chart: {
                        toolbar: {
                            show: false
                        }
                    },
                    xaxis: {
                        categories: Object.keys(report.data).map((key) => {
                            return key.toUpperCase();
                        })
                    }
                }}
                series={[{
                    name: 'Percentage',
                    data: Object.values(report.data),
                }]}
                type="radar"
                height={350}
            />
        );
    }

    render() {
        const {report} = this.props;

        if (!report) {
            return null;
        }

        return (
            <Card>
                <CardHeader>
                    <h5>MAIN SOCIAL NETWORKS PRESENCES(%)</h5>
                </CardHeader>
                <CardBody className={"p-0"}>
                    {report.public === false ? <BlurredGraph image={5}/> : this.renderMap()}
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    report: state.dashboard.insights.social_media_distribution.data,
});

export default connect(
    mapStateToProps,
    null
)(SocialMediaDistributionReport)
