import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {requestRecoverPassword} from "../../redux/user/actions";

class RequestRecoverPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: ''
        }
    }

    render() {
        const {loading, requestRecoverPassword} = this.props;

        return (
            <Fragment>
                <Container fluid={true}>
                    <Row className={"justify-content-center pt-5"}>
                        <Col xl="4" lg="4" md="8" sm="12" xs="12">
                            <Card>
                                <CardHeader className={'text-center'}>
                                    <h5 className="mb-0">RECOVER PASSWORD</h5>
                                </CardHeader>

                                <CardBody className="p-4">
                                    <Row>
                                        <Col>
                                            <input type="email" className="form-control" placeholder={"Email"}
                                                   value={this.state.email}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           email: e.target.value
                                                       });
                                                   }}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col className={"text-center"}>
                                            <Button color={"green"} onClick={() => {
                                                if(this.state.email.length){
                                                    requestRecoverPassword(this.state.email);
                                                    this.setState({
                                                        email: ''
                                                    });
                                                }
                                            }}>
                                                {loading ? "RECOVERING..." : "RECOVER"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {requestRecoverPassword};

export default connect(
    null,
    mapDispatchToProps
)(RequestRecoverPasswordPage);
