import React from "react";

function TextFormatter(props){

    function linkify(value) {
        var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        let match;
        const results = [];
        let lastIndex = 0;
        while (match = expression.exec(value)) {
          const link = match[0];
          var httpLink;
          if (lastIndex !== match.index) {
            const text = value.substring(lastIndex, match.index);
            results.push(
              <span key={results.length}>{text}</span>,
            );
          }
          if(!link.includes("http")){
              httpLink = "https://" + link;
          }
          results.push(
            <a key={results.length} href={httpLink ? httpLink : link} target="_blank" rel="noopener noreferrer">{link}</a>
          );
          lastIndex = match.index + link.length;
        }
        if (results.length === 0) {
          return value;
        }
        if (lastIndex !== value.length) {
          results.push(
            <span key={results.length}>{value.substring(lastIndex)}</span>,
          );
        }
        return results;
      }
      
    return(
        <span className={"whitespace-pre-line"}>{linkify(props.value)}</span>
    )
}

export default TextFormatter;