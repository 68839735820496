import React from "react";
import ConfigDB from "../../../config";
import {connect} from "react-redux";

class MapDistributionReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            map: null
        };

        this.circles = [];
    }

    componentDidMount() {
        if (!document.querySelector('[data-type="google-maps"]')) {
            var script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${ConfigDB.data.google_maps_api_key}&callback=initMap`;
            script.setAttribute("data-type", "google-maps");
            script.defer = true;
        }

        // Attach your callback function to the `window` object
        window.initMap = () => {
            // JS API is loaded and available
            this.setState({
                map: new window.google.maps.Map(document.getElementById(`${this.props.mapId ? this.props.mapId : "map"}`), {
                    zoom: 2,
                    minZoom: 3,
                    mapTypeId: "terrain",
                    disableDefaultUI: true,
                    center: {"lat": 40.008084, "lng": -39.076360},
                })
            })
        };

        // Append the 'script' element to 'head'
        if (!document.querySelector('[data-type="google-maps"]')) {
            document.head.appendChild(script);
        } else {
            window.initMap();
        }
    }

    moveMapToCenter() {
        if (!this.state.map) {
            return;
        }

        const center = new window.google.maps.LatLng(40.00808, -39.076360);
        // using global variable:
        this.state.map.panTo(center);
    }

    renderCircles() {
        const {report, locationIsSelected} = this.props;

        if (!report) {
            return;
        }

        this.circles.forEach((circle) => {
            circle.setMap(null);
        });
        this.circles = [];

        var bounds = new window.google.maps.LatLngBounds();

        report.forEach((location) => {
            const infowindow = new window.google.maps.InfoWindow({
                content: location.t.toString() + " " + (location.t == 1 ? "community" : "communities"),
            });

            const circle = new window.google.maps.Circle({
                strokeColor: "#7B28CD",
                // strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#7B28CD",
                fillOpacity: 0.3,
                map: this.state.map,
                clickable: true,
                center: {
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lng)
                },
                radius: Math.sqrt(location.t) * 3000,
            });

            circle.addListener("click", () => {
                infowindow.setPosition(circle.getCenter());
                infowindow.open(this.state.map);
            });

            bounds.union(circle.getBounds());

            this.circles.push(circle);
        });

        if (locationIsSelected) {
            this.state.map.fitBounds(bounds);
        } else {
            this.moveMapToCenter();
        }
    }

    render() {
        const {report} = this.props;

        if (this.state.map && report) {
            setTimeout(() => {
                this.renderCircles();
            }, 500);
        }

        return (
            <div id={`${this.props.mapId ? this.props.mapId : "map"}`} style={{"width": "100%", "height": "400px", "borderRadius": "40px"}} />
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locationIsSelected: state.dashboard.selected_continent != null || state.dashboard.selected_country != null,
    report: state.dashboard.insights.map_distribution.data
});

export default connect(
    mapStateToProps,
    null
)(MapDistributionReport)
