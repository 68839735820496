import PubNub from "pubnub";

var instanceObject = null;

function getInstance(uuid) {
    if(instanceObject){
        return instanceObject;
    }

    instanceObject = new PubNub({
        subscribeKey : process.env.REACT_APP_PUBNUB_SUBSCRIBER_KEY,
        uuid: uuid
    });

    return instanceObject;
}

export default {
    instance: getInstance
};