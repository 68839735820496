import React from "react";
import {Card, CardBody} from "reactstrap";
import Slack from "../../../assets/images/social_profiles/slack.svg";
import Discord from "../../../assets/images/social_profiles/discord.svg";
// import Telegram from "../../../assets/images/social_profiles/telegram.svg";
import OAuthIntegrationButton from "./oauth_integration_button";
import ConfigDB from "../../../config";
import {connect} from "react-redux";
import {disconnectSlack, disconnectDiscord} from "../../../redux/oauth/actions";

class OAuthIntegrationBanner extends React.Component {
    generateSlackLink(community_id) {
        return `https://slack.com/oauth/v2/authorize?scope=chat:write,incoming-webhook&user_scope=&redirect_uri=${window.location.origin}/oauth/verify-slack?community_id=${community_id}&client_id=${ConfigDB.data.slack.client_id}`;
    }

    generateTelegramLink(community_id) {
        return `https://t.me/${ConfigDB.data.telegram.bot_username}?startgroup=${community_id}`;
    }

    generateDiscordLink(community_id) {
        return `https://discord.com/api/oauth2/authorize?client_id=${ConfigDB.data.discord.client_id}&redirect_uri=${window.location.origin}/oauth/verify-discord&response_type=code&scope=webhook.incoming&state=${community_id}`;
    }

    render() {
        const {community, disconnectSlack, disconnectDiscord} = this.props;

        return (
            <Card>
                <CardBody className={"flex-column"}>
                    <div className={"d-flex flex-column m-b-20"}>
                        <h5 className="mb-0 f-w-800">JOB BOARD BOUNTIES INTEGRATION</h5>
                        <span>Add The Community Atlas chatbot to your channels. The bot will automatically inform your community when a new relevant Job Offer is published.</span>
                    </div>
                    <div className={"d-flex justify-content-center align-items-start"}>
                        <OAuthIntegrationButton socialName={"SLACK"}
                                                logo={Slack}
                                                href={this.generateSlackLink(community.id)}
                                                connected={community.connected_slack}
                                                disconnect={() => {
                                                    disconnectSlack(community.id);
                                                }}
                        />
                        <OAuthIntegrationButton socialName={"DISCORD"}
                                                logo={Discord}
                                                href={this.generateDiscordLink(community.id)}
                                                connected={community.connected_discord}
                                                disconnect={() => {
                                                    disconnectDiscord(community.id);
                                                }}
                        />
                        {/*<OAuthIntegrationButton socialName={"TELEGRAM"} logo={Telegram}*/}
                        {/*                        href={this.generateTelegramLink(community.id)} connected={community.connected_telegram}/>*/}
                    </div>
                </CardBody>
            </Card>
        )
    }
}

const mapDispatchToProps = {
    disconnectSlack,
    disconnectDiscord
};

export default connect(
    null,
    mapDispatchToProps
)(OAuthIntegrationBanner);