import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {clearCommunityRedeemToken, redeemCommunity, toggleCommunityRedeemModal} from "../../../redux/community/actions";
import CommunityRedeemAuthenticationModal from "../../community/components/community_redeem_authentication_tabs";

class CommunityRedeemModal extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
        };
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        })
    }

    handleCommunityRedeem() {
        const {currentUser, redeemCommunity, clearCommunityRedeemToken} = this.props;
        if(!currentUser) {
            this.toggleModal();
            return;
        }
        redeemCommunity();
        clearCommunityRedeemToken();
    }

    render() {
        const {redeem_community, redeem_token, community_redeem_modal_open, toggleCommunityRedeemModal} = this.props;
        const {isModalOpen} = this.state;

        if(!redeem_community || !redeem_token) {
            return null;
        }

        return (
            <div>
                <CommunityRedeemAuthenticationModal isModalOpen={isModalOpen} toggleModal={() => this.toggleModal()} />
                <Modal isOpen={community_redeem_modal_open} contentClassName={"b-r-20 bg-dark-gray"} centered={true} size={"sm"}>
                    <ModalHeader className={"p-relative custom-close-btn border-0"} toggle={toggleCommunityRedeemModal} />
                    <ModalBody className={"b-r-20 p-3"}>
                        <div className={"d-flex flex-column text-center justify-content-center align-items-center"}>
                        <span className={"f-17"}>Redeem the <span
                            className={"orange-text"}>{redeem_community.name}</span> community profile</span>
                            <span
                                className={"f-17"}>to <b>share</b> the <b>Job Offers</b> and <b>collect</b> the <b>Bounties</b>.</span>
                            <span className={"f-17 my-3"}>It's free!</span>
                            <Button className={"f-15 f-w-400 mt-1 mb-3"} color={"info"} onClick={() => {
                                this.handleCommunityRedeem();
                            }}>REDEEM COMMUNITY PROFILE</Button>
                            <u><Link to={"/redeem/" + redeem_token} onClick={() => toggleCommunityRedeemModal()} className={"green-text"}>Learn more</Link></u>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = {
    redeemCommunity,
    clearCommunityRedeemToken,
    toggleCommunityRedeemModal,
};

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.data,
    redeem_token: state.community.redeem_token,
    redeem_community: state.community.redeem.community,
    community_redeem_modal_open: state.community.community_redeem_modal_open,
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunityRedeemModal)