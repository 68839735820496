import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import Members from "../../../assets/images/members.svg";
import OnlineAudience from "../../../assets/images/online-audience.png";
import UseBlurOnCommunity from "./use_blur_on_community";
import NumberFormatter from "../../utils/number_formatter";
import FacebookPage from "../../../assets/images/facebook_page.png";
import FacebookGroups from "../../../assets/images/facebook_groups.png";
import BlurredIcon from "../../../assets/images/blurred_icon.svg";
import Twitter from "../../../assets/images/social_profiles/twitter.svg";
import Meetup from "../../../assets/images/social_profiles/meetup.svg";
import {Table} from "react-bootstrap";

function TotalAudience(props) {
    const {community} = props;

    function getTotalFacebookPagesAudience(community) {
        var sum = 0;
        community.facebook_page_profiles.map(page => sum += parseInt(page.followers));
        return sum;
    }

    function getTotalFacebookGroupsAudience(community) {
        var sum = 0;
        community.facebook_group_profiles.map(page => sum += parseInt(page.followers));
        return sum;
    }

    function getTotalTwitterAudience(community) {
        var sum = 0;
        community.twitter_profiles.map(page => sum += parseInt(page.followers));
        return sum;
    }

    return (
        <Card>
            <CardHeader className={"pt-4 pb-4 d-flex align-content-center"}>
                <h4 className={"m-b-0 m-r-5 grey-text"}><b>TOTAL AUDIENCE</b></h4>
            </CardHeader>

            <CardBody className={"p-0"}>
                <div className={"d-flex flex-row pt-3 pb-3 pl-5 pr-5 grey-background"}>
                    <img src={Members} width={60}/>
                    <div className={"d-flex flex-column pl-3"}>
                        <b>Total Offline audience</b>
                        <h5><UseBlurOnCommunity community={community}><b><NumberFormatter
                            value={community.total_members}/></b></UseBlurOnCommunity></h5>
                    </div>
                </div>
                <div className={"d-flex flex-row pt-3 pl-5 pr-5 mb-1"}>
                    <img src={Meetup} width={40}/>
                    <div className={"d-flex flex-column pl-3"}>
                        <b>Meetup Participants</b>
                        <h5><UseBlurOnCommunity community={community}><b><NumberFormatter
                            value={community.total_members}/></b></UseBlurOnCommunity></h5>
                    </div>
                </div>
                <div className={"d-flex flex-row pt-3 pl-5 pr-5 mb-3 grey-background"}>
                    <img src={OnlineAudience} height={55}/>
                    <div className={"d-flex flex-column pl-3"}>
                        <b>Total Online audience</b>
                        <UseBlurOnCommunity community={community}><h3><NumberFormatter
                            value={community.overall_online_audience}/></h3></UseBlurOnCommunity>
                    </div>
                </div>

                <div className={"d-flex flex-row pl-5 pr-5 pb-3"}>
                    <Table borderless>
                        <tbody>
                        <tr>
                            <td width={60} className={"p-0 pb-2"}>
                                <img src={community.public === "true" ? FacebookPage : BlurredIcon} width={40}/>
                            </td>
                            <td className={"pt-1"}>
                                <UseBlurOnCommunity community={community}>Facebook pages audience: <b><NumberFormatter
                                    value={getTotalFacebookPagesAudience(community)}/></b></UseBlurOnCommunity>
                            </td>
                        </tr>
                        <tr>
                            <td width={60} className={"p-0 pb-2"}>
                                <img src={community.public === "true" ? FacebookGroups : BlurredIcon} width={40}/>
                            </td>
                            <td className={"pt-1"}>
                                <UseBlurOnCommunity community={community}>Facebook groups audience: <b><NumberFormatter
                                    value={getTotalFacebookGroupsAudience(community)}/></b></UseBlurOnCommunity>
                            </td>
                        </tr>
                        <tr>
                            <td width={60} className={"p-0 pb-2"}>
                                <img src={community.public === "true" ? Twitter : BlurredIcon} width={40}/>
                            </td>
                            <td className={"pt-2"}>
                                <UseBlurOnCommunity community={community}>Twitter audience: <b><NumberFormatter
                                    value={getTotalTwitterAudience(community)}/></b></UseBlurOnCommunity>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>
    );
}

export default TotalAudience;
