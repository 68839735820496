import {
    ADD_COMMUNITIES_TO_LISTS,
    ADD_ALL_COMMUNITIES_TO_LISTS,
    CLEAR_LIST_DETAILS,
    CREATE_COMMUNITY_LIST,
    CREATE_COMMUNITY_LIST_AND_ADD_COMMUNITIES,
    DECREMENT_COMMUNITIES_OF_LIST_PAGE,
    DECREMENT_LISTS_PAGE,
    DELETE_COMMUNITY_LIST,
    INCREMENT_COMMUNITIES_OF_LIST_PAGE,
    INCREMENT_LISTS_PAGE,
    LOAD_COMMUNITIES_OF_LIST,
    LOAD_COMMUNITY_LISTS,
    LOAD_LIST_DETAILS,
    REMOVE_COMMUNITY_FROM_LIST,
    SEARCH_COMMUNITY_LIST,
    SET_SORTING_CONDITION_FOR_COMMUNITIES_OF_LIST,
    LOAD_LITE_COMMUNITY_LISTS,
    CREATE_COMMUNITY_LIST_AND_ADD_ALL_COMMUNITIES
} from "../action_types";

export function loadCommunityLists() {
    return {
        type: LOAD_COMMUNITY_LISTS,
        api: true,
        fetch_safe: true,
    }
}
export function loadLiteCommunityLists() {
    return {
        type: LOAD_LITE_COMMUNITY_LISTS,
        api: true,
        fetch_safe: true,
    }
}

export function addCommunitiesToLists(communityIds, listIds) {
    return {
        type: ADD_COMMUNITIES_TO_LISTS,
        api: true,
        payload: {
            community_ids: communityIds,
            list_ids: listIds
        }
    }
}

export function addAllCommunitiesToLists(listIds) {
    return {
        type: ADD_ALL_COMMUNITIES_TO_LISTS,
        api: true,
        payload: {
            list_ids: listIds
        }
    }
}

export function createCommunityList(name) {
    return {
        type: CREATE_COMMUNITY_LIST,
        api: true,
        payload: {
            name: name
        }
    }
}

export function createCommunityListAndAddCommunities(name, selectedCommunityIdsToAddInList) {
    return {
        type: CREATE_COMMUNITY_LIST_AND_ADD_COMMUNITIES,
        api: true,
        payload: {
            name: name,
            community_ids: selectedCommunityIdsToAddInList
        }
    }
}

export function createCommunityListAndAddAllCommunities(name, selectedCommunityIdsToAddInList) {
    return {
        type: CREATE_COMMUNITY_LIST_AND_ADD_ALL_COMMUNITIES,
        api: true,
        payload: {
            name: name,
            community_ids: selectedCommunityIdsToAddInList
        }
    }
}

export function loadCommunitiesOfList(id) {
    return {
        type: LOAD_COMMUNITIES_OF_LIST,
        api: true,
        payload: {
            id: id
        },
        fetch_safe: true,
    }
}

export function loadListDetails(id) {
    return {
        type: LOAD_LIST_DETAILS,
        api: true,
        payload: {
            id: id
        },
        fetch_safe: true,
    }
}

export function setSortingConditionForCommunitiesOfList(field, direction) {
    return {
        type: SET_SORTING_CONDITION_FOR_COMMUNITIES_OF_LIST,
        payload: {
            field,
            direction
        }
    }
}

export function removeCommunityFromList(communityId, listId) {
    return {
        type: REMOVE_COMMUNITY_FROM_LIST,
        api: true,
        payload: {
            community_id: communityId,
            list_id: listId
        }
    }
}

export function deleteCommunityList(listId) {
    return {
        type: DELETE_COMMUNITY_LIST,
        api: true,
        payload: {
            id: listId
        }
    }
}

export function incrementCommunitiesOfListPage() {
    return {
        type: INCREMENT_COMMUNITIES_OF_LIST_PAGE
    }
}

export function decrementCommunitiesOfListPage() {
    return {
        type: DECREMENT_COMMUNITIES_OF_LIST_PAGE
    }
}

export function incrementListsPage() {
    return {
        type: INCREMENT_LISTS_PAGE
    }
}

export function decrementListsPage() {
    return {
        type: DECREMENT_LISTS_PAGE
    }
}

export function clearListDetails() {
    return {
        type: CLEAR_LIST_DETAILS
    }
}

export function searchCommunityList(q) {
    return {
        type: SEARCH_COMMUNITY_LIST,
        api: true,
        payload: q
    }
}
