import {
    CLEAR_GLOBAL_INSIGHTS,
    LOAD_GLOBAL_INSIGHT_CATEGORY_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_COMMUNITY_TOTALS,
    LOAD_GLOBAL_INSIGHT_ENGAGEMENT_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_EVENTS_VS_PARTICIPANTS_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_LOCATION_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_MAP_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_SOCIAL_MEDIA_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_TOPIC_DISTRIBUTION,
    SELECT_CATEGORY_ON_DASHBOARD,
    SELECT_LOCATION_ON_DASHBOARD,
    SELECT_REPORT,
    CLEAR_SELECTED_REPORT,
    LOAD_GLOBAL_INSIGHT_AVERAGE_PARAMETERS,
    SELECT_COMMUNITY_NETWORK,
    CLEAR_SELECTED_COMMUNITY_NETWORK,
    LOAD_LANDING_PAGE_TOPICS
} from "../action_types";

export function selectLocationOnDashboard(location) {
    return {
        type: SELECT_LOCATION_ON_DASHBOARD,
        payload: location
    }
}

export function selectCategoryOnDashboard(category) {
    return {
        type: SELECT_CATEGORY_ON_DASHBOARD,
        payload: category
    }
}

export function loadGlobalMapDistribution() {
    return {
        type: LOAD_GLOBAL_INSIGHT_MAP_DISTRIBUTION,
        api: true,
        fetch_safe: true,
    }
}

export function loadGlobalCategoriesDistribution() {
    return {
        type: LOAD_GLOBAL_INSIGHT_CATEGORY_DISTRIBUTION,
        api: true,
        fetch_safe: true,
    }
}

export function loadGlobalLocationDistribution() {
    return {
        type: LOAD_GLOBAL_INSIGHT_LOCATION_DISTRIBUTION,
        api: true,
        fetch_safe: true,
    }
}

export function loadGlobalCommunityTotals() {
    return {
        type: LOAD_GLOBAL_INSIGHT_COMMUNITY_TOTALS,
        api: true,
        fetch_safe: true,
    }
}

export function loadGlobalSocialMediaDistribution() {
    return {
        type: LOAD_GLOBAL_INSIGHT_SOCIAL_MEDIA_DISTRIBUTION,
        api: true,
        fetch_safe: true,
    }
}

export function loadGlobalEngagementDistribution() {
    return {
        type: LOAD_GLOBAL_INSIGHT_ENGAGEMENT_DISTRIBUTION,
        api: true,
        fetch_safe: true,
    }
}

export function loadGlobalEventsVSParticipantsDistribution() {
    return {
        type: LOAD_GLOBAL_INSIGHT_EVENTS_VS_PARTICIPANTS_DISTRIBUTION,
        api: true,
        fetch_safe: true,
    }
}

export function loadGlobalTopicDistribution() {
    return {
        type: LOAD_GLOBAL_INSIGHT_TOPIC_DISTRIBUTION,
        api: true,
        fetch_safe: true,
    }
}

export function loadGlobalAverageParameters() {
    return {
        type: LOAD_GLOBAL_INSIGHT_AVERAGE_PARAMETERS,
        api: true,
        fetch_safe: true,
    }
}

export function selectReport(id) {
    return {
        type: SELECT_REPORT,
        payload: {id}
    }
}

export function clearSelectedReport() {
    return {
        type: CLEAR_SELECTED_REPORT,
    }
}

export function selectNetwork(id) {
    return {
        type: SELECT_COMMUNITY_NETWORK,
        payload: {id}
    }
}

export function clearSelectedNetwork() {
    return {
        type: CLEAR_SELECTED_COMMUNITY_NETWORK,
    }
}

export function clearGlobalInsights() {
    return {
        type: CLEAR_GLOBAL_INSIGHTS
    }
}

export function loadLandingPageTopics() {
    return {
        type: LOAD_LANDING_PAGE_TOPICS,
        api: true,
    }
}