import React, {Fragment, useRef} from "react";
import {Badge, Label} from "reactstrap";
import {connect} from "react-redux";
import {Typeahead} from "react-bootstrap-typeahead";
import {
    loadCommunities,
    resetCommunitiesPage,
    selectCategoryToSearchForCommunity,
    unselectCategoryToSearchForCommunity
} from '../../../redux/community/actions';

class CategoryFilter extends React.Component {
    buildListOfSelectedCategories() {
        const {categoryFilters, unselectCategoryToSearchForCommunity, loadCommunities, resetCommunitiesPage} = this.props;
        const {selected_categories} = categoryFilters;

        return selected_categories.map((category) => {
            return (
                <Badge className={"pointer"} color={"info"} key={category.id} onClick={() => {
                    unselectCategoryToSearchForCommunity(category);
                    resetCommunitiesPage();
                    loadCommunities();
                }}>{category.name} x</Badge>
            );
        })
    }

    render() {
        const {categories, loadCommunities, selectCategoryToSearchForCommunity, resetCommunitiesPage} = this.props;

        return (
            <div>
                <Label className="d-block">
                    Category:
                </Label>

                <div className="faq-form m-b-10">
                    <TypeaheadComponent categories={categories}
                                        selectCategoryToSearchForCommunity={(selectedCategory) => {
                                            selectCategoryToSearchForCommunity(selectedCategory);
                                            resetCommunitiesPage();
                                        }}
                                        loadCommunities={loadCommunities}/>
                </div>

                <div className="m-b-10">
                    {this.buildListOfSelectedCategories()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    categoryFilters: state.community.category_filters,
    categories: state.category.list
});

const mapDispatchToProps = {
    selectCategoryToSearchForCommunity,
    unselectCategoryToSearchForCommunity,
    resetCommunitiesPage,
    loadCommunities
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoryFilter)

const TypeaheadComponent = (props) => {
    const ref = useRef();
    return (
        <Fragment>
            <Typeahead
                id="search-for-category "
                labelKey="name"
                options={props.categories}
                placeholder="Choose here..."
                renderMenuItemChildren={(option, props) => {
                    return (
                        <Fragment>
                            <span>{option.name}</span>
                        </Fragment>
                    )
                }}
                onChange={(selected) => {
                    if (selected.length > 0) {
                        props.selectCategoryToSearchForCommunity(selected[0]);
                        props.loadCommunities();
                    }

                    ref.current.clear();
                }}
                ref={ref}
            />
        </Fragment>
    );
};
