import React from "react";
import {connect} from "react-redux";
import {selectCategoryOnDashboard} from "../../../redux/dashboard/actions";
import DashboardFilter from "./base_dasboard_filter";

class CategoriesFilter extends React.Component {
    render() {
        const {categories, selectedCategory, selectCategoryOnDashboard} = this.props;

        return (
            <DashboardFilter
                options={[{id: null, name: "All Categories"}].concat(categories)}
                selectedOption={selectedCategory}
                onSelection={(category) => {
                    selectCategoryOnDashboard(category);

                    this.props.onValueChanged();
                }}
            />
        );
    }
}

const mapDispatchToProps = {
    selectCategoryOnDashboard
};

const mapStateToProps = (state, ownProps) => ({
    categories: state.category.list,
    selectedCategory: state.dashboard.selected_category,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoriesFilter)
