import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import {Col, Container, Row} from 'reactstrap';
import {
    clearSelectedNetwork,
    clearSelectedReport,
    loadGlobalAverageParameters,
    loadGlobalCategoriesDistribution,
    loadGlobalCommunityTotals,
    loadGlobalEngagementDistribution,
    loadGlobalEventsVSParticipantsDistribution,
    loadGlobalLocationDistribution,
    loadGlobalMapDistribution,
    loadGlobalSocialMediaDistribution,
    loadGlobalTopicDistribution
} from "../../redux/dashboard/actions";
import LocationFilter from "./filters/location_filter";
import CategoryFilter from "./filters/category_filter";
import MapDistributionReport from "./components/map_distribution";
import CategoryDistributionReport from "./components/category_distribution";
import LocationDistributionReport from "./components/location_distribution";
import CommunityTotalsReport from "./components/community_totals";
import SocialMediaDistributionReport from "./components/social_media_distribution";
import OnlineEngagementReport from "./components/online_engagement";
import EngagementDistributionReport from "./components/engagement_distribution";
import EventsVSParticipantsDistributionReport from "./components/events_vs_participants_distribution";
import TopicDistributionReport from "./components/topic_distribution";
import CommunityOfflineEngagement from "./components/offline_engagement";
import AverageCommunityReport from "./components/average_community";

class DashboardPage extends React.Component {

    loadGlobalInsights() {
        const {clearSelectedReport, clearSelectedNetwork, loadGlobalMapDistribution, loadGlobalCategoriesDistribution, loadGlobalLocationDistribution, loadGlobalCommunityTotals, hasSelectedCategory, loadGlobalSocialMediaDistribution, loadGlobalEngagementDistribution, loadGlobalEventsVSParticipantsDistribution, loadGlobalTopicDistribution, loadGlobalAverageParameters} = this.props;

        clearSelectedReport();
        clearSelectedNetwork();
        loadGlobalMapDistribution();
        loadGlobalLocationDistribution();
        loadGlobalCommunityTotals();
        loadGlobalSocialMediaDistribution();
        loadGlobalEngagementDistribution();
        loadGlobalEventsVSParticipantsDistribution();
        loadGlobalTopicDistribution();
        loadGlobalAverageParameters();

        if (!hasSelectedCategory) {
            loadGlobalCategoriesDistribution();
        }
    }

    componentDidMount() {
        this.loadGlobalInsights();
    }

    buildCategoriesAndLocationsDistributions() {
        if (this.props.hasSelectedCategory) {
            return (
                <Row>
                    <Col>
                        <LocationDistributionReport/>
                    </Col>
                </Row>
            );
        }

        return (
            <Row>
                <Col xs={12} sm={5} md={4} lg={4} xl={4}>
                    <CategoryDistributionReport/>
                </Col>
                <Col xs={12} sm={7} md={8} lg={8} xl={8}>
                    <LocationDistributionReport/>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <Fragment>
                <Container fluid={true} style={{paddingTop: "10px"}}>
                    <div className={"global-filters p-0 m-3"}>
                        <div className={"p-2"}>
                            Filter by:
                        </div>
                        <div>
                            <LocationFilter
                                onValueChanged={() => {
                                    // update data
                                    this.loadGlobalInsights();
                                }}
                            />
                        </div>
                        <div>
                            <CategoryFilter
                                onValueChanged={() => {
                                    // update data
                                    this.loadGlobalInsights();
                                }}
                            />
                        </div>
                    </div>

                    <Row>
                        <Col>
                            <MapDistributionReport/>
                        </Col>
                    </Row>

                    <Row className={'mt-5'}>
                        <Col>
                            <CommunityTotalsReport/>
                        </Col>
                    </Row>

                    {this.buildCategoriesAndLocationsDistributions()}

                    {this.props.hasSelectedFilter && (
                        <Row>
                            <Col xs={12} sm={6}>
                                <AverageCommunityReport/>
                            </Col>
                            <Col xs={12} sm={6}>
                                <SocialMediaDistributionReport/>
                            </Col>
                            <Col>
                                <OnlineEngagementReport/>
                            </Col>
                        </Row>
                    )}

                    {this.props.hasSelectedFilter === false && (
                        <Row>
                            <Col xs={12} sm={6}>
                                <OnlineEngagementReport/>
                            </Col>
                            <Col xs={12} sm={6}>
                                <SocialMediaDistributionReport/>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col>
                            <CommunityOfflineEngagement/>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <EngagementDistributionReport/>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <EventsVSParticipantsDistributionReport/>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <TopicDistributionReport/>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    loadGlobalMapDistribution,
    loadGlobalCategoriesDistribution,
    loadGlobalLocationDistribution,
    loadGlobalCommunityTotals,
    loadGlobalSocialMediaDistribution,
    loadGlobalEngagementDistribution,
    loadGlobalEventsVSParticipantsDistribution,
    loadGlobalTopicDistribution,
    loadGlobalAverageParameters,
    clearSelectedReport,
    clearSelectedNetwork
};

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.data,
    locations: state.locations.list,
    dashboard: state.dashboard,
    hasSelectedCategory: state.dashboard.selected_category.id != null,
    hasSelectedFilter: state.dashboard.selected_category.id != null || state.dashboard.selected_location.id != null,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardPage)
