import React from 'react';
import {connect} from "react-redux";
import {addEmail, clearSelectedCampaign, getMailMarketingPlaceholders, loadCampaignDetails, loadCampaignEmails} from "../../redux/campaign/actions";
import CampaignDetailsHeader from "./components/campaign_details_header";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Collapse,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import CampaignEmail from "./components/campaign_email";
import {
    CAMPAIGN_EMAIL_TYPE_COMMUNITY,
    CAMPAIGN_EMAIL_TYPE_CONTACTED_MEMBER,
    CAMPAIGN_EMAIL_TYPE_NOT_CONTACTED_MEMBER,
    CAMPAIGN_STATUS_MESSAGES_SENT,
} from "../../constants";

class CampaignEmailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            default_message_modal_open: false,
            toggleDefaultMessageModal: () => {
                this.setState({
                    default_message_modal_open: !this.state.default_message_modal_open,
                })
            }
        }
    }

    componentDidMount() {
        const {campaign_emails} = this.props;
        const {initialized} = campaign_emails;

        if (!this.props.selected_campaign || this.props.selected_campaign.id !== this.props.routeParams.id) {
            this.props.clearSelectedCampaign();
            this.props.loadCampaignDetails(this.props.routeParams.id);
        }

        if (!initialized) {
            this.props.loadCampaignEmails(this.props.routeParams.id);
            this.props.getMailMarketingPlaceholders(this.props.routeParams.id);
        }
    }

    renderLoading() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Loading</h4>
            </div>
        );
    }

    renderInvalidCampaign() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Campaign not found</h4>
            </div>
        );
    }

    renderDefaultMessageButton() {
        const {toggleDefaultMessageModal} = this.state;
        return (
            <Button color={"primary"} onClick={() => {
                toggleDefaultMessageModal();
            }} className={"fixed-bottom-left-btn"}>Default Message</Button>
        );
    }

    renderDefaultMessageModal() {
        const {selected_campaign} = this.props;
        const {default_message_modal_open, toggleDefaultMessageModal} = this.state;
        return(
            <Modal isOpen={default_message_modal_open} toggle={toggleDefaultMessageModal}>
                <ModalHeader>
                    Default Message
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg={"9"}>
                            <span>{selected_campaign.default_message}</span>
                        </Col>
                        <Col lg={"3"}>
                            {navigator.clipboard !== undefined && <Button size={"md"} color={"info"} onClick={() => {
                                navigator.clipboard.writeText(selected_campaign.default_message)
                                toggleDefaultMessageModal();
                            }}><i className="icofont icofont-ui-clip-board"/> Copy</Button>}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }

    render() {
        const {selected_campaign, loading_campaign, campaign_emails, addEmail, placeholders} = this.props;
        const {community, contacted_member, not_contacted_member} = campaign_emails;

        if (loading_campaign || campaign_emails.loading) {
            return this.renderLoading();
        }

        if (!loading_campaign && !selected_campaign) {
            return this.renderInvalidCampaign();
        }

        return (
            <div>
                {this.renderDefaultMessageModal()}
                <CampaignDetailsHeader activeTab={"emails"}>
                    <Col>
                        <EmailSectionTemplate title={"Not Contacted Emails"} emails={not_contacted_member}
                                              type={CAMPAIGN_EMAIL_TYPE_NOT_CONTACTED_MEMBER} addEmail={addEmail}
                                              selected_campaign={selected_campaign} placeholders={placeholders}/>
                        <EmailSectionTemplate title={"Previously Contacted Emails"} emails={contacted_member}
                                              type={CAMPAIGN_EMAIL_TYPE_CONTACTED_MEMBER} addEmail={addEmail}
                                              selected_campaign={selected_campaign} placeholders={placeholders}/>
                        <EmailSectionTemplate title={"Community Emails"} emails={community}
                                              type={CAMPAIGN_EMAIL_TYPE_COMMUNITY} addEmail={addEmail}
                                              selected_campaign={selected_campaign} placeholders={placeholders}/>
                    </Col>
                    {this.renderDefaultMessageButton()}
                </CampaignDetailsHeader>
            </div>
        );
    }
}

const mapDispatchToProps = {
    clearSelectedCampaign,
    loadCampaignDetails,
    loadCampaignEmails,
    addEmail,
    getMailMarketingPlaceholders,
}

const mapStateToProps = (state, ownProps) => ({
    selected_campaign: state.campaign.selected.campaign,
    loading_campaign: state.campaign.selected.loading,
    campaign_emails: state.campaign.selected.emails,
    placeholders: state.campaign.selected.emails.placeholders,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignEmailsPage)

const EmailSectionTemplate = (props) => {
    const {title, emails, type, addEmail, selected_campaign, placeholders} = props;

    const handleCreateEmail = () => {
        const email = {
            subject: "",
            body: "",
            order: emails.length + 1,
            temp_id: new Date().getTime(),
        }
        addEmail(email, type);
    }

    const renderNoEmailCreated = () => {
        return (
            <div className={"text-center"}>
                <h4 className="m-0">No emails created yet.</h4>
            </div>
        )
    }

    return (
        <Card className={"border w-100"}>
            <CardHeader style={{"position": "sticky", "top": 0}} className={"p-4"}>
                <div className={"d-flex justify-content-between align-items-center"}>
                    <div className={"d-flex justify-content-start align-items-center"}>
                        <h3 className={"mb-0"}>{title}</h3>
                        <Badge color={'primary'} className={"text-white ml-3"}>N° of emails {emails.length}</Badge>
                    </div>
                </div>
            </CardHeader>
            <Collapse isOpen={true}>
                <CardBody className={"p-4"}>
                    {emails && emails.length === 0 && renderNoEmailCreated()}
                    {placeholders && placeholders.length > 0 && emails && emails.length > 0 &&
                    emails.sort((a, b) => a.order - b.order).map((email, index) => (
                        <CampaignEmail key={`${type}-${email.id ? email.id : email.temp_id}`}
                                       index={index}
                                       isFirst={index === 0}
                                       isLast={index === (emails.length - 1)}
                                       emails={emails}
                                       email={email}
                                       placeholders={placeholders}
                                       type={type}/>
                    ))}
                </CardBody>
            </Collapse>
            {selected_campaign.status != CAMPAIGN_STATUS_MESSAGES_SENT &&
            (<CardFooter className={"d-flex justify-content-center"}>
                <Button color={"primary"} onClick={() => {
                    handleCreateEmail()
                }}>Create email</Button>
            </CardFooter>)}
        </Card>
    )
}