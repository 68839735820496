import {
    UPLOAD_COMMUNITY_IMAGE_FILE,
    UPLOAD_COMMUNITY_SERVICE_IMAGE_FILE,
    UPLOAD_USER_PROFILE_IMAGE_FILE
} from "../action_types";

const initial_state = {
    community_image: null,
    community_service_image: null,
    user_profile_image: null,
    loading: false
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case UPLOAD_COMMUNITY_IMAGE_FILE:
            return {...state, loading: true, community_image: null};
        case UPLOAD_COMMUNITY_IMAGE_FILE.concat("_SUCCESS"):
            return {...state, loading: false, community_image: action.payload.data};
        case UPLOAD_COMMUNITY_IMAGE_FILE.concat("_FAILURE"):
            return {...state, loading: false, community_image: null};
        case UPLOAD_COMMUNITY_SERVICE_IMAGE_FILE:
            return {...state, loading: true, community_service_image: null};
        case UPLOAD_COMMUNITY_SERVICE_IMAGE_FILE.concat("_SUCCESS"):
            return {...state, loading: false, community_service_image: action.payload.data};
        case UPLOAD_COMMUNITY_SERVICE_IMAGE_FILE.concat("_FAILURE"):
            return {...state, loading: false, community_service_image: null};
        case UPLOAD_USER_PROFILE_IMAGE_FILE:
            return {...state, loading: true, user_profile_image: null};
        case UPLOAD_USER_PROFILE_IMAGE_FILE.concat("_SUCCESS"):
            return {...state, loading: false, user_profile_image: action.payload.data};
        case UPLOAD_USER_PROFILE_IMAGE_FILE.concat("_FAILURE"):
            return {...state, loading: false, user_profile_image: null};
        default:
            return {...state};
    }
}
