import {
    CANCEL_CAMPAIGN,
    CLOSE_CONFIRM_CONTACTED_COMMUNITIES_MODAL,
    CLEAR_SELECTED_CAMPAIGN,
    CONFIRM_CAMPAIGN_PROFILES,
    SET_CONTACT_MODE,
    CREATE_CAMPAIGN,
    DECREMENT_CAMPAIGN_COMMUNITIES_PAGE,
    DECREMENT_CAMPAIGNS_PAGE,
    INCREMENT_CAMPAIGN_COMMUNITIES_PAGE,
    INCREMENT_CAMPAIGNS_PAGE,
    INCREMENT_MEMBERS_OF_COMMUNITY_PAGE,
    LOAD_CAMPAIGN_COMMUNITIES,
    LOAD_CAMPAIGN_DETAILS,
    LOAD_CAMPAIGNS,
    LOAD_COMMUNITY_MEMBERS,
    TOGGLE_COMMUNITY_CONTAINER,
    TOGGLE_COMMUNITY_MEMBER_CONTAINER,
    TOGGLE_COMMUNITY_MEMBER_HIDDEN_PROFILES,
    TOGGLE_LINKEDIN_PROFILE_VERIFICATION,
    VERIFY_ALREADY_CONTACTED_COMMUNITIES,
    LOAD_CAMPAIGN_EMAILS,
    ADD_EMAIL,
    DELETE_EMAIL,
    SAVE_CONTACTED_MEMBER_EMAIL,
    SAVE_COMMUNITY_EMAIL,
    SAVE_NOT_CONTACTED_MEMBER_EMAIL,
    DELETE_LOCAL_EMAIL,
    UPDATE_CAMPAIGN_EMAIL_ORDER,
    SEND_TO_MAIL_MARKETING_TOOL,
    GET_MAIL_MARKETING_PLACEHOLDERS,
} from "../action_types";

export function loadCampaigns() {
    return {
        type: LOAD_CAMPAIGNS,
        api: true,
        fetch_safe: true,
    }
}

export function verifyAlreadyContactedCommunities(campaign) {
    return {
        type: VERIFY_ALREADY_CONTACTED_COMMUNITIES,
        api: true,
        payload: {
            list_id: campaign.selected_list_id,
            message: campaign.message,
        }
    }
}

export function setContactMode(value) {
    return {
        type: SET_CONTACT_MODE,
        payload: {
            contact_mode: value,
        }
    }
}

export function closeConfirmContactedCommunitiesModal() {
    return {
        type: CLOSE_CONFIRM_CONTACTED_COMMUNITIES_MODAL,
    }
}

export function createCampaign() {
    return {
        type: CREATE_CAMPAIGN,
        api: true
    }
}

export function cancelCampaign(campaignId) {
    return {
        type: CANCEL_CAMPAIGN,
        api: true,
        payload: {
            campaign_id: campaignId
        }
    }
}

export function incrementCampaignsPage() {
    return {
        type: INCREMENT_CAMPAIGNS_PAGE,
    }
}

export function decrementCampaignsPage() {
    return {
        type: DECREMENT_CAMPAIGNS_PAGE,
    }
}

export function clearSelectedCampaign() {
    return {
        type: CLEAR_SELECTED_CAMPAIGN,
    }
}

export function loadCampaignDetails(id) {
    return {
        type: LOAD_CAMPAIGN_DETAILS,
        api: true,
        payload: {
            id: id
        }
    }
}

export function loadCampaignCommunities(id) {
    return {
        type: LOAD_CAMPAIGN_COMMUNITIES,
        api: true,
        payload: {
            id: id
        }
    }
}

export function loadCampaignEmails(id) {
    return {
        type: LOAD_CAMPAIGN_EMAILS,
        api: true,
        payload: {
            id: id
        }
    }
}

export function addEmail(email, type) {
    return {
        type: ADD_EMAIL,
        payload: {
            email: email,
            type: type,
        }
    }
}

export function saveCommunityEmail(email) {
    return {
        type: SAVE_COMMUNITY_EMAIL,
        api: true,
        payload: {
            email: email
        }
    }
}

export function saveContactedEmail(email) {
    return {
        type: SAVE_CONTACTED_MEMBER_EMAIL,
        api: true,
        payload: {
            email: email
        }
    }
}

export function saveNotContactedMemberEmail(email) {
   return {
       type: SAVE_NOT_CONTACTED_MEMBER_EMAIL,
       api: true,
       payload: {
           email: email
       }
   }
}

export function updateCampaignEmailOrder(emails) {
    return {
        type: UPDATE_CAMPAIGN_EMAIL_ORDER,
        api: true,
        payload: {
            emails: emails,
        }
    }
}

export function deleteLocalEmail(temp_id, type) {
    return {
        type: DELETE_LOCAL_EMAIL,
        payload: {
            temp_id: temp_id,
            type: type
        }
    }
}

export function deleteEmail(id, type) {
    return {
        type: DELETE_EMAIL,
        api: true,
        payload: {
            id: id,
            type: type,
        }
    }
}

export function incrementCampaignCommunitiesPage() {
    return {
        type: INCREMENT_CAMPAIGN_COMMUNITIES_PAGE
    }
}

export function decrementCampaignCommunitiesPage() {
    return {
        type: DECREMENT_CAMPAIGN_COMMUNITIES_PAGE
    }
}

export function loadCommunityMembers(id, page) {
    return {
        type: LOAD_COMMUNITY_MEMBERS,
        api: true,
        payload: {
            community_id: id,
            page: page
        }
    }
}

export function incrementMembersOfCommunityPage(communityId) {
    return {
        type: INCREMENT_MEMBERS_OF_COMMUNITY_PAGE,
        payload: {
            community_id: communityId
        }
    }
}

export function toggleLinkedinProfileVerification(communityId, memberId, profileId) {
    return {
        type: TOGGLE_LINKEDIN_PROFILE_VERIFICATION,
        api: true,
        payload: {
            community_id: communityId,
            member_id: memberId,
            profile_id: profileId
        }
    }
}

export function toggleCommunityContainer(communityId) {
    return {
        type: TOGGLE_COMMUNITY_CONTAINER,
        payload: {
            id: communityId,
        }
    }
}

export function toggleCommunityMemberContainer(communityId, memberId) {
    return {
        type: TOGGLE_COMMUNITY_MEMBER_CONTAINER,
        payload: {
            community_id: communityId,
            member_id: memberId
        }
    }
}

export function toggleCommunityMemberHiddenProfiles(communityId, memberId) {
    return {
        type: TOGGLE_COMMUNITY_MEMBER_HIDDEN_PROFILES,
        payload: {
            community_id: communityId,
            member_id: memberId
        }
    }
}

export function confirmCampaignProfiles() {
    return {
        type: CONFIRM_CAMPAIGN_PROFILES,
        api: true
    }
}

export function sendToMailMarketingTool() {
    return {
        type: SEND_TO_MAIL_MARKETING_TOOL,
        api: true
    }
}

export function getMailMarketingPlaceholders(campaignId) {
    return {
        type: GET_MAIL_MARKETING_PLACEHOLDERS,
        api: true,
        payload: campaignId,
    }
}