import React from "react";
import {connect} from "react-redux";
import $ from "jquery";
import ConfigDB from "../../../config";
import {uploadFile} from '../../../redux/file/actions'


class ImageUploader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fileSizeError: false
        };
    }

    openFileChooser(e) {
        var input = $(e.target).parents(".image-chooser-container").find("input[type='file']");
        $(input).click();

        this.setState({
            fileSizeError: false
        });
    };

    onFileChange(e) {
        var files = e.target.files;
        const {uploadFile} = this.props;

        if(files.length > 0){
            var file = files[0];

            if(file.size/1048576 <= ConfigDB.data.upload_file_size) {
                uploadFile(file);
            } else {
                this.setState({
                    fileSizeError: true
                });
            }
        }
    }

    render() {
        const {isDisabled} = this.props;
        return (
            <div onClick={(e) => {
                if (!isDisabled) {
                    this.openFileChooser(e)
                }}} className={`image-chooser-container pointer ${isDisabled && "disabled"}`}>
                <input type="file" style={{"display": "none"}} accept="image/jpg, image/jpeg, image/gif, image/pjpeg, image/png" onChange={(e) => this.onFileChange(e)}/>
                {this.props.children}
                {this.state.fileSizeError && (<span className={"text-danger"}>File must be max {ConfigDB.data.upload_file_size}MB</span>)}
            </div>
        );
    }
}

export default connect(
    null,
    null
)(ImageUploader)
