import React from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import {Chart} from "react-google-charts";
import BlurredGraph from "./blurred_graph";

class TopicDistributionReport extends React.Component {

    renderMap() {
        const {report} = this.props;

        var chartData = [['Topic', 'Parent', "Total Communities"], ['Topics', null, 0]].concat(report.map(function (topic) {
            return [topic.n, 'Topics', topic.t]
        }));

        return (
            <div className="chart-overflow" id="pie-chart1">
                <Chart
                    width={"100%"}
                    height={"700px"}
                    chartType="TreeMap"
                    loader={<div>Loading Chart</div>}
                    data={chartData}
                    chartEvents={[
                        {
                            eventName: 'select',
                            callback: ({chartWrapper}) => {
                                const chart = chartWrapper.getChart();
                                chart.setSelection([]);
                            },
                        },
                    ]}
                    options={{
                        minColor: '#E9B9E9',
                        midColor: '#C0BFE9',
                        maxColor: '#69CE7F',
                        headerHeight: 15,
                        fontColor: 'black',
                        showScale: true,
                        maxDepth: 1,
                        maxPostDepth: 0
                    }}
                />
            </div>
        );
    }

    render() {
        const {report} = this.props;

        if (!report || report.length === 0) {
            return null;
        }

        return (
            <Card>
                <CardHeader>
                    <h5>
                        Topic Distribution
                    </h5>
                </CardHeader>
                <CardBody className="chart-block">
                    {report.public === false ? <BlurredGraph image={1}/> : this.renderMap()}
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    report: state.dashboard.insights.topic_distribution.data
});

export default connect(
    mapStateToProps,
    null
)(TopicDistributionReport)
