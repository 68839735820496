import React from 'react';
import {Button} from "reactstrap";

class OAuthIntegrationButton extends React.Component {
    renderButton() {
        const {connected, socialName, logo, disconnect} = this.props;

        return (
            <Button
                type={"button"}
                color={'dark-gray'}
                className={`${connected ? "connected" : ""}`}
                onClick={() => {
                    if(!connected){
                        return;
                    }
                    
                    disconnect();
                }}
            >
                <img src={logo} width={20} height={20} className={"mr-2"}
                     alt={"slack-logo"}/>{connected ? `${socialName} CONNECTED` : `Connect ${socialName}`}
            </Button>
        );
    }

    render() {
        const {connected, href} = this.props;

        return (
            <div className={"d-flex flex-column align-items-center mr-5"}>
                {!connected && (
                    <a target="_blank" rel={"noopener noreferrer"}
                       className={"d-inline-flex align-items-center justify-content-center mb-2"} href={href}>
                        {this.renderButton()}
                    </a>
                )}

                {connected && (
                    <>
                        {this.renderButton()}
                        <span>Click again to disconnect</span>
                    </>
                )}
            </div>
        );
    }
}

export default OAuthIntegrationButton;