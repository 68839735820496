import React from 'react';
import {
    Button, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import NumberFormatter from "../../utils/number_formatter";
import {connect} from "react-redux";
import {applyToJob, toggleApplyToJobModal} from "../../../redux/job/actions";
import {
    EMAIL_REGEX,
    LINKEDIN_REGEX,
    PAYMENT_TYPE_CRYPTO,
    PRIVACY_POLICY_LINK,
    TOS_LINK
} from "../../../constants";
import ConnectSolanaWalletComponent from "../solana/ConnectSolanaWalletComponent";
import {toast} from "react-toastify";

class ExternalApplyToJobModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            acceptedTermsOfUse: false,
            toggleAcceptedTermsOfUse: () => {
                this.setState({
                    acceptedTermsOfUse: !this.state.acceptedTermsOfUse
                })
            },
            apply_to_job: {
                full_name: "",
                email: "",
                linkedin_profile: "",
            },
            updateForm: (data) => {
                let applyToJob = Object.assign({}, this.state.apply_to_job);
                Object.entries(data).forEach(([key, value]) => {
                    if(applyToJob.hasOwnProperty(key)) {
                        applyToJob[key] = value;
                    }
                })
                this.setState({
                    apply_to_job: applyToJob
                })
            },
            hasErrors: false,
        };
    }

    componentDidMount() {
        const {referral_details} = this.props;
        const {updateForm} = this.state;

        if(referral_details.referral) {
            updateForm(referral_details.referral);
        }
    }

    onFormChange(value, field) {
        const {apply_to_job} = this.state;
        apply_to_job[field] = value;
    }

    isNameValid(value){
        return value.split(" ").length >= 2;
    }

    isEmailValid(value){
        var regex = new RegExp(EMAIL_REGEX);
        return regex.test(value);
    }

    isLinkedinValid(value){
        var regex = new RegExp(LINKEDIN_REGEX);
        return regex.test(value);
    }

    isValidForm() {
        const {solana, job} = this.props;
        const {apply_to_job} = this.state;
        const {acceptedTermsOfUse} = this.state;

        var hasErrors = false;

        if(!acceptedTermsOfUse){
            hasErrors = true;
        }

        if(!this.isNameValid(apply_to_job.full_name)){
            hasErrors = true;
        }

        if(!this.isEmailValid(apply_to_job.email)){
            hasErrors = true;
        }

        if(!this.isLinkedinValid(apply_to_job.linkedin_profile)){
            hasErrors = true;
        }

        if(job.payment_type === PAYMENT_TYPE_CRYPTO && !solana.wallet_public_key){
            toast.error("Please connect a Solana Wallet!");
            hasErrors = true;
        }

        this.setState({
            hasErrors: hasErrors,
        });

        return !hasErrors;
    }

    handleApplication() {
        const {solana} = this.props;
        const {applyToJob, toggleApplyToJobModal} = this.props;
        const {apply_to_job} = this.state;

        if(!this.isValidForm()) {
            return;
        }

        apply_to_job.wallet_public_key = solana.wallet_public_key;

        applyToJob(apply_to_job);
        toggleApplyToJobModal();
    }

    render() {
        const {job} = this.props;
        const {toggleApplyToJobModal} = this.props;
        const {acceptedTermsOfUse, toggleAcceptedTermsOfUse} = this.state;
        const {hasErrors, apply_to_job} = this.state;

        return (
            <Modal isOpen={true} size={"sm"} centered={true}
                   contentClassName={"b-r-20 bg-dark-gray"}>
                <ModalHeader className={"justify-content-center py-5 p-relative custom-close-btn"}
                             toggle={toggleApplyToJobModal}>
                    <div className={"d-flex align-items-center"}>
                        <div className={"d-flex flex-column px-3 text-center"}>
                            <span className={"font-weight-bold f-25 mb-3"}>Apply for the role</span>
                            <span className={"f-16 mb-3"}>If hired you’ll get the bounty of
                                <span className={"purple-text"}> {job.currency} <NumberFormatter value={job.bounty}/></span>
                            </span>
                            <span>{job.title} at <strong>{job.company.name}</strong></span>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {job.payment_type === PAYMENT_TYPE_CRYPTO && (
                        <div className="px-3">
                            <ConnectSolanaWalletComponent />
                        </div>
                    )}
                    <div className={"p-3"}>
                        <form className={`needs-validation ${hasErrors ? "was-validated" : ""}`}>
                            <div className={"mb-5"}>
                                <div className="m-b-10">
                                    <input className="refer-modal-input form-control" type={"text"}
                                           defaultValue={apply_to_job.full_name}
                                           onChange={(e) => this.onFormChange(e.target.value, 'full_name')}
                                           required
                                           pattern=".*\s.*"
                                           placeholder={"Name"}/>
                                </div>
                                <div className="m-b-10">
                                    <input className="refer-modal-input form-control" type={"email"}
                                           defaultValue={apply_to_job.email}
                                           pattern={EMAIL_REGEX}
                                           onChange={(e) => this.onFormChange(e.target.value, 'email')}
                                           required
                                           placeholder={"Email"}/>
                                </div>
                                <div className="m-b-10">
                                    <input className="refer-modal-input form-control" type={"text"}
                                           defaultValue={apply_to_job.linkedin_profile}
                                           pattern={LINKEDIN_REGEX}
                                           onChange={(e) => this.onFormChange(e.target.value, 'linkedin_profile')}
                                           required
                                           placeholder={"Linkedin Profile"}/>
                                </div>
                            </div>
                            <div className="faq-form m-b-10 d-flex">
                                <div id="terms-of-use" className={"size-20 checkbox-container " + (acceptedTermsOfUse ? "active full" : "")}
                                     onClick={() => {
                                         toggleAcceptedTermsOfUse();
                                     }}>
                                    <i className={"fa fa-check"}/>
                                </div>
                                <Label htmlFor="terms-of-use" className={`ml-2 ${hasErrors && !acceptedTermsOfUse ? "text-danger" : ""}`} >
                                    You agree to the&nbsp;
                                    <u><a href={TOS_LINK} target={"_blank"} rel={"noopener noreferrer"} className={"text-info"}>terms of use</a></u> and&nbsp;
                                    <u><a href={PRIVACY_POLICY_LINK} target={"_blank"} rel={"noopener noreferrer"} className={"text-info"}>privacy policy</a></u>
                                </Label>
                            </div>
                        </form>
                    </div>
                </ModalBody>
                <ModalFooter className={"justify-content-center p-3"}>
                    <Button color={"dark-blue"} className={"flex-grow-1"} size={"lg"} onClick={() => this.handleApplication()}>APPLY</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    applyToJob,
    toggleApplyToJobModal
};

const mapStateToProps = (state, ownProps) => ({
    referral_details: state.job.referrer_details.data,
    solana: state.solana
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalApplyToJobModal)
