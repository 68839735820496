import React from "react";
import {
    Badge,
    Button,
    Col,
    Media,
    InputGroup,
    InputGroupAddon
} from 'reactstrap';
import link_icon from "../../../assets/images/paper_clip.svg";
import {toast} from 'react-toastify';
import {connect} from "react-redux";
import ConfigDB from "../../../config";
import {loadMessages, sendMessage} from "../../../redux/channel/actions";

class ChannelInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            setContent: (messageContent) => {
                this.setState({
                    content: messageContent,
                });
            },
            fileSizeError: false,
            file: null,
            setFile: (newFile) => {
                this.setState({
                    file: newFile,
                })
            },
        };
    }

    componentWillUnmount() {
        this.state.setContent("");
        this.state.setFile(null);
    }

    onFileChange(e) {
        var files = e.target.files;
        const {setFile} = this.state;

        if (files.length > 0) {
            var file = files[0];

            if (file.size / 1048576 <= ConfigDB.data.upload_file_size) {
                setFile(file);
            } else {
                e.target.value = '';
                toast.warn(`File must be max ${ConfigDB.data.upload_file_size}MB`);
            }
        }
    }

    sendInputMessage() {
        const {sendMessage, selectedChannel, isDisabled} = this.props;
        const {content, setContent, file, setFile} = this.state;

        if ((content.trim() !== "" || file !== null) && !isDisabled) {
            sendMessage(selectedChannel.id, content.trim(), file);
            setContent("");
            setFile(null);
            this.fileInput.value = "";
        }
    }

    render() {
        const {isDisabled} = this.props;
        const {content, setContent, file, setFile} = this.state;
        return (
            <Col xl="12" className={`d-flex ${isDisabled && "disabled"}`}>
                <label htmlFor="upload-file"
                       className={`smiley-box my-0 d-flex align-items-center ${isDisabled && "disabled"}`}>
                    <div className="picker">
                        <Media
                            src={link_icon}
                            style={{width: "33px"}}
                            alt="link-image"
                        />
                        <input
                            disabled={isDisabled}
                            ref={ref => this.fileInput = ref}
                            onChange={(e) => this.onFileChange(e)}
                            accept=".jpg,.jpeg,.gif,.pjpeg,.png,.pdf"
                            type="file"
                            name="file"
                            id="upload-file"
                        />
                    </div>
                </label>
                <InputGroup className="text-box d-flex flex-row flex-nowrap">
                    <div className="flex-grow-1 position-relative">
                        {file &&
                        (<Badge className="position-absolute badge-position" color="purple">{file.name}
                            <span className="pl-2 pointer"
                                  onClick={() => {
                                      setFile(null);
                                      this.fileInput.value = "";
                                  }}
                            >X</span>
                        </Badge>)}
                        <textarea
                            disabled={isDisabled}
                            style={{resize: "none"}}
                            rows="1"
                            className="form-control input-txt-bx grey-border b-r-5 p-b-10 p-t-15"
                            placeholder="Type your message here..."
                            value={content}
                            onKeyPress={(e) => {
                                if(e.key === 'Enter' && !e.shiftKey)
                                    e.preventDefault()
                            }}
                            onChange={(e) =>
                                setContent(e.target.value)
                            }
                            onKeyDown={(e) => {
                                if (!e.shiftKey && e.key === "Enter") {
                                    this.sendInputMessage();
                                }
                            }}
                        />
                    </div>
                    <InputGroupAddon className="px-3" addonType="append">
                        <Button
                            disabled={isDisabled}
                            style={{borderRadius: "5px", color: "white"}}
                            color="green"
                            onClick={() => {
                                this.sendInputMessage();
                            }}
                        >
                            SEND
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </Col>
        )
    }
}

const mapDispatchToProps = {
    sendMessage,
    loadMessages
};

const mapStateToProps = (state, ownProps) => ({
    selectedChannel: state.channel.list.channels[state.channel.current_channel.id],
    currentChannel: state.channel.current_channel.new_message
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChannelInput);
