import React from 'react';
import {connect} from 'react-redux';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Table} from "reactstrap";
import {
    setContactMode,
    createCampaign,
} from "../../../redux/campaign/actions";
import {
    CAMPAIGN_CONTACT_MODE_ALL,
    CAMPAIGN_CONTACT_MODE_ONLY_NOT_PREV_CONTACTED,
    CAMPAIGN_CONTACT_MODE_ONLY_PREV_CONTACTED
} from "../../../constants";

class ConfirmContactedCommunitiesModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            listLimit: 10,
        };
    }

    renderProcessingData() {
        return (
            <div>
                <p>Processing data...</p>
            </div>
        )
    }

    renderContactedCommunities() {
        const {contacted_communities} = this.props;
        const {listLimit} = this.state;
        return (
            <div>
                <Table>
                    <thead>
                    <tr>
                        <th scope="col" className={'border-top-0'}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </th>
                        <th scope="col" className={'border-top-0'}>
                            Community
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {contacted_communities && contacted_communities.map((community, index) => {
                        if(index < listLimit){
                            return (<tr key={community.id}>
                                <td>
                                    {index + 1}.
                                </td>
                                <td>
                                    {community.name}
                                </td>
                            </tr>);
                        }
                    })}
                    {contacted_communities && contacted_communities.length > listLimit && <tr key={"more-contacted-communities"}>
                        <td className="text-center" colSpan={2}>
                            <Button color={"white"} onClick={() => {this.setState({listLimit: this.state.listLimit + 10})}}>
                                View {contacted_communities.length - listLimit} more communities.
                            </Button>
                        </td>
                    </tr>}
                    </tbody>
                </Table>
            </div>
        )
    }

    render() {
        const {open_modal_to_verify_already_contacted_communities, closeModal, contacted_communities, createCampaign, setContactMode} = this.props;

        return (
            <Modal isOpen={open_modal_to_verify_already_contacted_communities}>
                <ModalHeader toggle={() => {
                    closeModal();
                }}>Warning</ModalHeader>
                <ModalBody>
                    {open_modal_to_verify_already_contacted_communities && !contacted_communities ? this.renderProcessingData() :
                        (<div className={"text-center"}>
                            <p>{contacted_communities.length} communities have already been contacted</p>
                            <p>Please confirm to contact: </p>
                            <div className={"d-flex flex-column align-items-center"}>
                                <select className="custom-select w-50 mb-3" onChange={(e) => setContactMode(e.target.value)}>
                                    <option value={CAMPAIGN_CONTACT_MODE_ALL}>All communities</option>
                                    <option value={CAMPAIGN_CONTACT_MODE_ONLY_PREV_CONTACTED}>Only previously contacted</option>
                                    <option value={CAMPAIGN_CONTACT_MODE_ONLY_NOT_PREV_CONTACTED}>Only NOT previously contacted</option>
                                </select>
                                <Button color={"primary"} onClick={() => {
                                    if (window.confirm("Are you sure?")) {
                                        createCampaign();
                                        closeModal();
                                    }
                                }}>Confirm</Button>
                            </div>
                        </div>)}
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    {contacted_communities && contacted_communities.length > 0 && this.renderContactedCommunities()}
                </ModalFooter>
            </Modal>
        )
    }
}

const mapDispatchToProps = {createCampaign, setContactMode};

const mapStateToProps = (state, ownProps) => ({
    community_lists: state.community_list.list,
    credits: state.customer.data.credits,
    contacted_communities: state.campaign.new_campaign.contacted_communities.list,
    open_modal_to_verify_already_contacted_communities: state.campaign.new_campaign.open_modal_to_verify_already_contacted_communities,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmContactedCommunitiesModal)
