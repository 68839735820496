import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import MainCategory from "../../common/helpers/main_category";
import ScaleBarBuilder from "../utilities/scale_bar_builder";
import ScaleBuilder from "../utilities/scale_builder";

function renderCity(city) {
    if(city) {
        return (<span><b>Local</b>({city} Area)</span>)
    }
    return null;
}

function renderCountry(country) {
    if(country) {
        return (<span><b>National</b>({country})</span>)
    }
    return null;
}

function CommunityGlobalRankings(props) {
    const {community} = props;

    return (
        <Card>
            <CardHeader className={"pt-4 pb-4 d-flex align-content-center"}>
                <h4 className={"m-b-0 m-r-5 grey-text"}><b>ENGAGEMENT RANKING FOR</b></h4> <MainCategory
                withMargins={true} community={community}/>
            </CardHeader>

            <CardBody className={"p-0"}>
                <div className={"d-flex flex-column pt-3 pl-5 pr-5 pb-3"}>
                    <div className={"d-flex flex-row"}>
                        <div className={"no-wrap p-r-10"}><h6><b>Overall Engagement</b></h6></div>
                        <div className={"full-width"}>
                            <ScaleBarBuilder value={community.overall_worldwide_main_category_ranking}/>
                        </div>
                    </div>
                    <div className={"d-flex flex-row"}>
                        <h6><b>Ranking:</b></h6>
                    </div>
                    <div className={"d-flex flex-row grey-text"}>
                        <div className={"flex-grow-1 d-flex flex-column"}>
                            {renderCity(community.city)}
                            <b><ScaleBuilder value={community.city ? community.overall_city_main_category_ranking : null}/></b>
                        </div>
                        <div className={"flex-grow-1 d-flex flex-column"}>
                            {renderCountry(community.country)}
                            <b><ScaleBuilder
                                value={community.country ? community.overall_country_main_category_ranking : null}/></b>
                        </div>
                        <div className={"flex-grow-1 d-flex flex-column"}>
                            <b>Global</b>
                            <b><ScaleBuilder value={community.overall_worldwide_main_category_ranking}/></b>
                        </div>
                    </div>
                </div>
                <hr className={"m-0"}/>
                <div className={"d-flex flex-column pt-3 pl-5 pr-5 pb-3"}>
                    <div className={"d-flex flex-row"}>
                        <div className={"no-wrap p-r-10"}><h6><b>Offline Engagement</b></h6></div>
                        <div className={"full-width"}>
                            {community.public !== "false" && (<ScaleBarBuilder
                                value={community.offline_worldwide_main_category_ranking}/>)}
                        </div>
                    </div>
                    <div className={"d-flex flex-row"}>
                        <h6><b>Ranking:</b></h6>
                    </div>
                    <div className={"d-flex flex-row grey-text"}>
                        <div className={"flex-grow-1 d-flex flex-column"}>
                            {renderCity(community.city)}
                            <b><ScaleBuilder value={community.city ? community.offline_city_main_category_ranking : null}
                                             community={community}/></b>
                        </div>
                        <div className={"flex-grow-1 d-flex flex-column"}>
                            {renderCountry(community.country)}
                            <b><ScaleBuilder
                                value={community.country ? community.offline_country_main_category_ranking : null} community={community}/></b>
                        </div>
                        <div className={"flex-grow-1 d-flex flex-column"}>
                            <b>Global</b>
                            <b><ScaleBuilder value={community.offline_worldwide_main_category_ranking}
                                             community={community}/></b>
                        </div>
                    </div>
                </div>
                <hr className={"m-0"}/>
                <div className={"d-flex flex-column pt-3 pl-5 pr-5 pb-3"}>
                    <div className={"d-flex flex-row"}>
                        <div className={"no-wrap p-r-10"}><h6><b>Online Engagement</b></h6></div>
                        <div className={"full-width"}>
                            {community.public !== "false" && (<ScaleBarBuilder
                                value={community.online_worldwide_main_category_ranking}/>)}
                        </div>
                    </div>
                    <div className={"d-flex flex-row"}>
                        <h6><b>Ranking:</b></h6>
                    </div>
                    <div className={"d-flex flex-row grey-text"}>
                        <div className={"flex-grow-1 d-flex flex-column"}>
                            {renderCity(community.city)}
                            <b><ScaleBuilder value={community.city ? community.online_city_main_category_ranking : null} community={community}/></b>
                        </div>
                        <div className={"flex-grow-1 d-flex flex-column"}>
                            {renderCountry(community.country)}
                            <b><ScaleBuilder value={community.country ? community.online_country_main_category_ranking : null}
                                             community={community}/></b>
                        </div>
                        <div className={"flex-grow-1 d-flex flex-column"}>
                            <b>Global</b>
                            <b><ScaleBuilder
                                value={community.online_worldwide_main_category_ranking} community={community}/></b>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default CommunityGlobalRankings;
