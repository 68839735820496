import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {toggleContactCommunityModal, toggleServiceDetailsModal} from "../../../redux/community/actions";
import LogoComponent from "../../common/helpers/logo_component";
import {
    PRICE_TYPE_FIXED,
    PRICE_TYPE_PRIVATE,
    PRICE_TYPE_RANGE,
    RECURRENCE_TYPE_MONTHLY,
    RECURRENCE_TYPE_YEARLY
} from "../../../constants";
import NumberFormatter from "../../utils/number_formatter";

class ServiceDetailsModal extends React.Component {
    renderRecurrence(service) {
        switch (service.recurrence_type) {
            case RECURRENCE_TYPE_MONTHLY:
                return "paid monthly";
            case RECURRENCE_TYPE_YEARLY:
                return "paid yearly";
            default:
                return;
        }
    }

    renderPricing() {
        const {user, service} = this.props;

        switch (service.price_type) {
            case PRICE_TYPE_FIXED:
                return (
                    <div className={"d-flex flex-column text-center"}>
                        <span className={"f-30 f-w-900 dark-blue-text"}><NumberFormatter value={service.fixed_price}/> {service.currency}</span>
                        <span className={"f-15 grey-text"}>{Number(service.recurrent) ? this.renderRecurrence(service) : ""}</span>
                    </div>
                );
            case PRICE_TYPE_RANGE:
                return (
                    <div className={"d-flex flex-column text-center m-b-5"}>
                        <span className={"f-30 f-w-900 dark-blue-text"}><NumberFormatter value={service.min_price}/> - <NumberFormatter value={service.max_price}/> {service.currency}</span>
                    </div>
                );
            case PRICE_TYPE_PRIVATE:
                return (
                    <div className={"m-b-15"}>
                        <span className={"f-22 f-w-600 mb-3"}><i>{user && user.is_community ? "Pricing is private" : "Contact for pricing"}</i></span>
                    </div>
                );
            default:
                return null;
        }
    }

    render() {
        const {user, service, modal_open, toggleServiceDetailsModal, toggleContactCommunityModal} = this.props;

        if(!service) {
            return null;
        }

        let video_id = "";
        if(service.video_link) {
            let videoUrl = new URL(service.video_link);
            video_id = videoUrl.searchParams.get("v");
        }

        return (
            <Modal isOpen={modal_open} contentClassName={"b-r-40"}>
                <ModalHeader
                    toggle={() => toggleServiceDetailsModal()}
                    className={"justify-content-center py-5 p-relative custom-close-btn outside-the-modal border-0"} cssModule={{
                    "modal-title": "f-26 my-0 f-w-900"
                }}>
                    {service.name}
                </ModalHeader>
                <ModalBody className={"p-0 d-flex flex-column"}>
                    {service.video_link && <div>
                        <iframe src={`https://www.youtube.com/embed/${video_id}?modestbranding=1`}
                                title={service.name}
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{
                                    width: "100%",
                                    minHeight: "340px"
                                }}/>
                    </div>}
                    {!service.video_link && service.image && <div>
                        <LogoComponent logo={service.image} width={600} height={335} className={"object-contain m-b-20 w-100"}/>
                    </div>}
                    <div className={"d-flex flex-column px-4 pb-4"}>
                        <p className={"f-14 f-w-600 whitespace-pre-line"}>{service.description}</p>
                    </div>
                    <div className={"mt-auto text-center pt-0 px-4 pb-4"}>
                        {this.renderPricing()}
                    </div>
                </ModalBody>
                {user && !user.is_community && <ModalFooter className={"p-0 d-flex flex-column"}>
                    <Button className={"p-t-10 p-b-10 m-0 w-100 rounded-top-0 rounded-bottom-25"} color={"green"}
                             onClick={() => {
                                 toggleServiceDetailsModal();
                                 toggleContactCommunityModal();
                             }}>CONTACT THE COMMUNITY</Button>
                </ModalFooter>}
            </Modal>
        )
    }
}

const mapDispatchToProps = {
    toggleServiceDetailsModal,
    toggleContactCommunityModal,
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    service: state.community.community_service.selected,
    modal_open: state.community.community_service.modal_open,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceDetailsModal);