import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import {Table} from "react-bootstrap";
import Facebook from "../../../assets/images/social_profiles/facebook.svg";
import Twitter from "../../../assets/images/social_profiles/twitter.svg";
import {connect} from "react-redux";
import BlurredGraph from "./blurred_graph";
import NumberFormatter from "../../utils/number_formatter";

class OnlineEngagementReport extends React.Component {

    renderReport() {
        const {report} = this.props;
        const {total_audience, audience_per_social_media_type} = report;

        return (
            <div>
                <div className={"pl-5 pt-2"}>
                    <b>Overall online audience:</b>
                    <h3><NumberFormatter value={total_audience}/></h3>
                    <br/>
                    <b>Social media audience:</b>
                </div>
                <Table>
                    <tbody>
                    <tr>
                        <td className={"pl-5"} width={60}>
                            <img src={Facebook} width={50}/>
                        </td>
                        <td className={"pt-4"}>
                            Facebook pages audience: <b><NumberFormatter
                            value={audience_per_social_media_type.facebook_page}/></b>
                        </td>
                    </tr>
                    <tr>
                        <td className={"pl-5"}>
                            <img src={Facebook} width={50}/>
                        </td>
                        <td className={"pt-4"}>
                            Facebook groups audience: <b><NumberFormatter
                            value={audience_per_social_media_type.facebook_group}/></b>
                        </td>
                    </tr>
                    <tr>
                        <td className={"pl-5"}>
                            <img src={Twitter} width={50}/>
                        </td>
                        <td className={"pt-4"}>
                            Twitter audience: <b><NumberFormatter value={audience_per_social_media_type.twitter}/></b>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

    render() {
        const {report} = this.props;
        if (!report) {
            return null;
        }

        return (
            <Card>
                <CardHeader>
                    <h5>ONLINE ENGAGEMENT</h5>
                </CardHeader>

                <CardBody className={"p-0"}>
                    {report.public === false ? <BlurredGraph withoutMargin={true} image={4}/> : this.renderReport()}
                </CardBody>
            </Card>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    report: state.dashboard.insights.social_media_distribution.data,
});

export default connect(
    mapStateToProps,
    null
)(OnlineEngagementReport)
