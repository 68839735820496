import React from "react";
import {Col, Input, Label, Row} from "reactstrap";
import {connect} from "react-redux";
import InputRange from "react-input-range";
import {loadCommunities} from "../../../redux/community/actions";

class MemberNumberSlider extends React.Component {
    timeout
    debounceLoadCommunities() {
        const {loadCommunities} = this.props;

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            loadCommunities();
        }, 300);
    }

    render() {
        const {value, action, label, min_value, max_value} = this.props;

        return (
            <div>
                <Row className={"align-items-center"}>
                    <Col>
                        <Label className="d-block mb-0">
                            {label}
                        </Label>
                    </Col>
                    <Col>
                        <Input value={value}
                               type="number"
                               min={min_value}
                               max={max_value}
                               onChange={(e) => {
                                   // Set the value to 0 if its not a number
                                   if(isNaN(parseInt(e.target.value))) {
                                       action(0);
                                   } else if (e.target.value >= min_value && e.target.value <= max_value) {
                                       action(parseInt(e.target.value))
                                   }

                                   this.debounceLoadCommunities();
                               }}/>
                    </Col>
                </Row>

                <div className="faq-form my-3">
                    <InputRange
                        onChange={(e) => {
                            action(e);
                        }}
                        onChangeComplete={(e) => {
                            this.debounceLoadCommunities();
                        }}
                        minValue={min_value}
                        maxValue={max_value}
                        value={value}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    loadCommunities,
};

export default connect(
    null,
    mapDispatchToProps
)(MemberNumberSlider)