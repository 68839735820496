import React, {Fragment} from 'react';
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Media, Row,} from "reactstrap";
import INFO_BLACK from "../../assets/images/information_black.png";
import user_avatar from "../../assets/images/user_avatar.png";
import {connect} from "react-redux";
import ChannelHeader from "../common/channel/header";
import ChannelInput from "../common/channel/input";
import ChannelListItem from "../common/channel/channel_list_item";
import HumanMessage from "../common/channel/human_message_item";
import SystemMessage from "../common/channel/system_message_item";
import {
    addChannelToListOfChannels,
    clearSelectedChannel,
    confirmChannel,
    declineChannel,
    loadMessages,
    loadChannels,
    markChannelRead,
    selectCurrentChannel,
    searchChannels,
    incrementMessagesPage,
    incrementChannelsPage
} from "../../redux/channel/actions";
import ChannelMessageDateItem from '../common/channel/channel_message_date_item';

class Channels extends React.Component {

    componentWillUnmount() {
        this.props.clearSelectedChannel();
    }

    renderNoChannel() {
        return (
            <li className="mt-5" key={"Info-1"}>
                <div className="d-flex align-items-center flex-column text-center font-size-11">
                    <img src={INFO_BLACK} alt="" width={50}/>
                    <br/>
                    <span>To create a new chat,</span>
                    <span>click the contact button on a community profile</span>
                </div>
            </li>
        )
    }

    renderChannels(channelsList) {
        const {
            currentChannel,
            selectCurrentChannel,
            markChannelRead,
            list,
            addChannelToListOfChannels,
            loadMessages,
            loadChannels,
            incrementChannelsPage,
            search
        } = this.props;

        const channels = [];

        for (const [key, channel] of Object.entries(channelsList).sort((a,b) => b[1].updated_at > a[1].updated_at ? 1 : -1)) {
            channels.push(
                <li className={`clearfix channel px-3 py-2 ${currentChannel.id === channel.id ? "active" : ""}`}
                    key={"channel-"+channel.id}
                    onClick={() => {
                        if (currentChannel.id !== channel.id) {
                            selectCurrentChannel(channel.id);
                            if(channel.total_unread_messages !== 0){
                                markChannelRead(channel.id);
                            }
                            //If channel is selected from search and is not on the list of channels
                            const listOfChannels = list.channels;
                            if(!listOfChannels[channel.id]){
                                addChannelToListOfChannels(channel);
                                loadMessages();
                            }else if(channelsList[channel.id].messages.length === 0){
                                loadMessages();
                            }
                        }
                    }}
                >
                    <ChannelListItem id={channel.id} isInHeader={false}/>
                </li>
            )
        }

        if(Object.entries(channelsList).length < 3){
            channels.push(this.renderNoChannel());
        }

        if(!list.loading && list.load_more && search.param === ""){
            channels.push(<Button color="white" key={Object.entries(channelsList).length + 1}
            onClick={() => {
                incrementChannelsPage();
                loadChannels();
            }}>Load more</Button>)
        }

        if(list.loading){
            channels.push(
                <div key={Object.entries(channelsList).length + 2} className="d-flex justify-content-center">
                    <h6>Loading channels...</h6>
                </div>
            )
        }
        return channels;
    }

    renderNoMessages() {
        return (
            <div className="d-flex justify-content-center"></div>
        )
    }


    renderMessages() {
        const {
            selectedChannel,
            currentUser,
            currentChannel,
            incrementMessagesPage,
            loadMessages,
            confirmChannel,
            declineChannel
        } = this.props;

        const messages = [];

        if(currentChannel.loading){
            messages.push(
            <li key="loading-messages">
               <div className="d-flex justify-content-center">
                <h4>Loading messages...</h4>
                </div>
            </li>)
        }

        if(selectedChannel.load_more){
            messages.push(<Button color="light align-self-center mb-3" key={selectedChannel.messages.length + 1} onClick={()=>{
                incrementMessagesPage();
                loadMessages();
            }}>Load more</Button>)
        }

        let lastChannelGroupDate;
        selectedChannel.messages.forEach((message, index) => {
            var messageDate = new Date(0);
            messageDate.setUTCSeconds(message.created_at);
            if(index === 0 || (lastChannelGroupDate.getDate() < messageDate.getDate())){
                lastChannelGroupDate = messageDate;
                messages.push(<ChannelMessageDateItem date={lastChannelGroupDate} key={message.created_at} />)
            }
            {message.type === 1 && messages.push(<HumanMessage key={"message-"+message.id} date={messageDate} message={message} selectedChannel={selectedChannel} />)}
            {message.type === 2 && messages.push(<SystemMessage key={"message-"+message.id} type={message.system_message_type} />)}
        })

        if(currentUser.is_community && selectedChannel.status === 1) {
            messages.push(
                <li className="align-self-end mb-3" key={"btn-confirmation"}>
                    <Button className="px-5 font-weight-normal" color="success" onClick={() => {
                        confirmChannel(selectedChannel.id);
                    }}>ACCEPT</Button>
                    <Button className="ml-2 px-5 font-weight-normal" color="outline-danger" onClick={() => {
                        declineChannel(selectedChannel.id);
                    }}>DECLINE</Button>
                </li>
            )
        }

        if(currentUser.is_community && selectedChannel.status === 3) {
            messages.push(
                <li className="align-self-end mb-3" key={"btn-accept"}>
                    <Button className="px-5 font-weight-normal" color="outline-success" onClick={() => {
                        confirmChannel(selectedChannel.id);
                        loadChannels();
                    }}>ACCEPT</Button>
                </li>
            )
        }
        return messages;
    }

    render() {
        const {
            currentUser,
            customerData,
            channelsList,
            currentChannel,
            selectedChannel,
            search,
            searchChannels,
            searchedChannelsList,
        } = this.props;

        return (
            <Fragment>
                {currentUser ? (<Container className="pt-5 chat-main-container" fluid={true}>
                    <Row>
                        <Col sm="12" className="call-chat-sidebar">
                            <Card>
                                <CardBody className="chat-body px-0">
                                    <div className="chat-box">
                                        {/* Chat left side Start */}
                                        <div className="chat-left-aside">
                                            {!currentUser.is_community && (
                                                <div className="media px-3">
                                                    <Media
                                                        src={customerData && customerData.logo ? customerData.logo.url : user_avatar}
                                                        className="rounded-circle user-image"
                                                        alt="user-image"
                                                    />
                                                    <div className="about align-self-center">
                                                        <div className="name f-w-600">
                                                            {currentUser.full_name}
                                                        </div>
                                                        {/* <div className="name f-w-600">Your chat credits: <b
                                                        className="purple-text">0</b></div>
                                                    <Button style={{borderRadius: "10px"}}
                                                            className="py-0 px-1 font-weight-normal" color="green"
                                                            onClick={() => {
                                                                console.log("Credits not implemented");
                                                            }}
                                                    >MANAGE CREDITS</Button> */}
                                                    </div>
                                                </div>)}
                                            <div className="people-list">
                                                <div className="search mx-3">
                                                    <Form className="theme-form">
                                                        <FormGroup className="form-group">
                                                            <Input
                                                                className="btn-pill"
                                                                type="text"
                                                                placeholder="search"
                                                                value={search.param}
                                                                onChange={(e) => {
                                                                    searchChannels(e.target.value);
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                            {!search.searching && <i className="fa fa-search" />}
                                                            <div
                                                                style={{marginTop: "-5px"}}
                                                                className={`spinner-border Typeahead-spinner ${search.searching ? 'show' : ''}`}
                                                                role="status"><span
                                                                className="sr-only">Loading...</span>
                                                            </div>
                                                        </FormGroup>
                                                    </Form>
                                                </div>
                                                <div className={"list"}>
                                                    <ul className="d-flex flex-column">
                                                        {!channelsList.loading && Object.keys(channelsList).length === 0 && this.renderNoChannel()}
                                                        {!search.searching && search.param !== "" && Object.keys(searchedChannelsList).length === 0 && this.renderNoChannel()}
                                                        {Object.keys(channelsList).length > 0 && search.param === "" && this.renderChannels(channelsList)}
                                                        {Object.keys(searchedChannelsList).length > 0 && search.param !== "" && this.renderChannels(searchedChannelsList)}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Chat left side Ends */}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="call-chat-body">
                            <Card>
                                <CardBody className="p-0">
                                    <Row className="chat-box">
                                        {/* Chat right side start */}
                                        <Col className="px-3 chat-right-aside">
                                            {/* chat start */}
                                            <div className="chat">
                                                {/* chat-header start */}
                                                <ChannelHeader channel={selectedChannel} currentUser={currentUser}/>
                                                {/* chat-header end */}
                                                <div id="channel-history"
                                                     className="chat-history chat-msg-box custom-scrollbar">
                                                    <ul className="d-flex justify-content-center flex-column">
                                                        {/* {currentChannel.loading && this.renderLoadingMessages()} */}
                                                        {!currentChannel.loading && !selectedChannel && this.renderNoMessages()}
                                                        {selectedChannel && this.renderMessages()}
                                                    </ul>
                                                </div>
                                                {/* end chat-history */}
                                                {selectedChannel &&
                                                <div className="chat-message clearfix">
                                                    <Row className="d-flex justify-content-center">
                                                        <div className="mb-2">
                                                        </div>
                                                        <ChannelInput isDisabled={!selectedChannel.can_write_messages}/>
                                                    </Row>
                                                </div>}
                                                {/* end chat-message */}
                                                {/* chat end */}
                                                {/* Chat right side ends */}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>) : null}
            </Fragment>
        )
    }
}

const mapDispatchToProps = {
    loadChannels,
    confirmChannel,
    declineChannel,
    incrementChannelsPage,
    loadMessages,
    incrementMessagesPage,
    clearSelectedChannel,
    selectCurrentChannel,
    markChannelRead,
    searchChannels,
    addChannelToListOfChannels
};

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.data,
    customerData: state.customer.data,
    list: state.channel.list,
    channelsList: state.channel.list.channels,
    currentChannel: state.channel.current_channel,
    selectedChannel: state.channel.list.channels[state.channel.current_channel.id],
    searchedChannelsList: state.channel.search_channels.list,
    search: state.channel.search_channels
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Channels);
