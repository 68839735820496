import React from 'react';
import {Button, Col} from "reactstrap";
import {connect} from "react-redux";
import {
    clearSelectedCampaign,
    confirmCampaignProfiles,
    getMailMarketingPlaceholders,
    incrementCampaignCommunitiesPage,
    loadCampaignCommunities,
    loadCampaignDetails,
    loadCampaignEmails,
    sendToMailMarketingTool,
} from "../../redux/campaign/actions";
import CommunityDetails from "./components/community_details";
import CampaignDetailsHeader from "./components/campaign_details_header";
import {toast} from "react-toastify";
import {
    CAMPAIGN_STATUS_FETCHED_EMAILS,
    CAMPAIGN_STATUS_READY,
} from "../../constants";

class CampaignValidatePage extends React.Component {
    componentDidMount() {
        if(!this.props.selected_campaign || this.props.selected_campaign.id !== this.props.routeParams.id) {
            this.props.clearSelectedCampaign();
            this.props.loadCampaignDetails(this.props.routeParams.id);
            this.props.loadCampaignEmails(this.props.routeParams.id);
            this.props.getMailMarketingPlaceholders(this.props.routeParams.id);
        }
    }

    renderLoading() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Loading</h4>
            </div>
        );
    }

    renderInvalidCampaign() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Campaign not found</h4>
            </div>
        );
    }

    renderNoCommunities() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Campaign has no communities</h4>
            </div>
        );
    }

    renderListOfCommunities() {
        const {
            communities,
            communities_total_pages,
            communities_page,
            incrementCampaignCommunitiesPage,
            loadCampaignCommunities
        } = this.props;
        var communitiesList = [];

        for (var i = 0; i < communities.length; i++) {
            let community = communities[i];
            communitiesList.push(<CommunityDetails key={"community-details-" + community.id} id={community.id}/>);
        }

        if (communities_page < communities_total_pages) {
            communitiesList.push(
                <div className={"d-flex justify-content-center pb-5"}>
                    <Button onClick={() => {
                        incrementCampaignCommunitiesPage();
                        loadCampaignCommunities(this.props.routeParams.id);
                    }}>Load more communities</Button>
                </div>
            );
        }

        return communitiesList;
    }

    checkForEmptyEmailList() {
        const {campaign_emails} = this.props;

        return Object.values(campaign_emails).some((emailTypeEmails) => {
            return emailTypeEmails.length === 0;
        })
    }

    renderConfirmButton() {
        const {confirmCampaignProfiles} = this.props;

        return (
            <Button color={"green"} onClick={() => {
                if(this.checkForEmptyEmailList()) {
                    toast.error("Campaign needs emails");
                    return;
                }

                if (window.confirm("Are you sure? This will delete all not confirmed profiles.")) {
                    if (window.confirm("You serious?")) {
                        confirmCampaignProfiles();
                    }
                }
            }} className={"fixed-bottom-left-btn"}>Confirm Campaign</Button>
        );
    }

    renderSendToMailMarketingTool() {
        const {sendToMailMarketingTool} = this.props;

        return (
            <Button color={"green"} onClick={() => {
                if (window.confirm("Are you sure you want to send to mail marketing tool?")) {
                    if (window.confirm("You serious?")) {
                        sendToMailMarketingTool();
                    }
                }
            }} className={"fixed-bottom-left-btn"}>Send to mail marketing tool</Button>
        );
    }

    render() {
        const {selected_campaign, communities, loading_communities, loading_campaign} = this.props;

        if (loading_campaign) {
            return this.renderLoading();
        }

        if (!loading_campaign && !selected_campaign) {
            return this.renderInvalidCampaign();
        }

        return (<div>
                <CampaignDetailsHeader activeTab={"validate"}>
                    <Col>
                        {!loading_communities && communities.length === 0 && (this.renderNoCommunities())}
                        {this.renderListOfCommunities()}
                        {loading_communities && (this.renderLoading())}
                        {selected_campaign.status == CAMPAIGN_STATUS_READY && (this.renderConfirmButton())}
                        {selected_campaign.status == CAMPAIGN_STATUS_FETCHED_EMAILS && (this.renderSendToMailMarketingTool())}
                    </Col>
                </CampaignDetailsHeader>
        </div>)
    }
}

const mapDispatchToProps = {
    loadCampaignDetails,
    loadCampaignCommunities,
    incrementCampaignCommunitiesPage,
    clearSelectedCampaign,
    confirmCampaignProfiles,
    loadCampaignEmails,
    sendToMailMarketingTool,
    getMailMarketingPlaceholders,
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    selected_campaign: state.campaign.selected.campaign,
    communities: state.campaign.selected.communities.list,
    communities_page: state.campaign.selected.communities.page,
    communities_total_pages: state.campaign.selected.communities.total_pages,
    loading_communities: state.campaign.selected.communities.loading,
    loading_campaign: state.campaign.selected.loading,
    campaign_emails: state.campaign.selected.emails,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignValidatePage)

