import React from 'react';
import {connect} from 'react-redux';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import {
    addAllCommunitiesToLists,
    addCommunitiesToLists,
    createCommunityListAndAddAllCommunities,
    createCommunityListAndAddCommunities,
    searchCommunityList
} from "../../../redux/community_list/actions";

class AddToListModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            select_all_communities: false,
            selected_community_ids_to_add_in_list: [],
            new_list_name: '',
            selected_existing_list_ids: [],
            existing_list_name: null
        };
    }

    render() {
        const {
            addCommunitiesToLists, addAllCommunitiesToLists, createCommunityListAndAddCommunities, createCommunityListAndAddAllCommunities,
            fullCommunityLists, idsToAddToList, add_all_communities_to_list, inActivityDetail, closeModal
        } = this.props;
        const {selected_existing_list_ids, new_list_name, existing_list_name} = this.state;

        const toggleCommunityList = (listId) => {
            var currentPosition = selected_existing_list_ids.indexOf(listId);

            if (currentPosition > -1) {
                selected_existing_list_ids.splice(currentPosition, 1);
            } else {
                selected_existing_list_ids.push(listId);
            }

            this.setState({
                selected_existing_list_ids: selected_existing_list_ids
            });
        };

        const community = (inActivityDetail) ? "community" : "communities";

        return (
            <Modal isOpen={true}>
                <ModalHeader>
                    Add selected {community} to list
                </ModalHeader>
                <ModalBody>
                    <p className={"m-b-5"}>Create New List and add selected {community}</p>
                    <Row className={"m-b-5"}>
                        <Col xl={9} md={9} sm={9} xs={9}>
                            <input type="text" className="form-control" placeholder={"Add name here"} onChange={(e) => {
                                this.setState({
                                    new_list_name: e.target.value
                                });
                            }}/>
                        </Col>
                        <Col xl={3} md={3} sm={3} xs={3} className="pl-1 pl-lg-3">
                            <Button color={"green"} onClick={() => {
                                if (new_list_name.length > 0) {
                                    if (!add_all_communities_to_list) {
                                        createCommunityListAndAddCommunities(new_list_name, idsToAddToList);
                                    } else if (add_all_communities_to_list) {
                                        createCommunityListAndAddAllCommunities(new_list_name, idsToAddToList);
                                    }
                                    closeModal();
                                }
                            }}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                    <hr/>
                    <p className={"m-b-5"}>Add selectd {community} to existing lists</p>
                    <Row className={"m-b-5"}>
                        <Col xl={9} md={9} sm={9} xs={9}>
                            <input type="text" className="form-control" placeholder={"Search by name"}
                                   onChange={(e) => {
                                       this.setState({
                                           existing_list_name: e.target.value
                                       });
                                   }}/>

                            <div style={{"maxHeight": "300px", "overflowY": "auto"}}>
                                <Table>
                                    <tbody>
                                    {fullCommunityLists.filter((community) => {
                                        if (!existing_list_name) {
                                            return true;
                                        } else {
                                            return community.name.toLowerCase().indexOf(existing_list_name.toLowerCase()) > -1;
                                        }
                                    }).map((list) => {
                                        return (
                                            <tr key={list.id}>
                                                <td width={30} className={"border-top-0"}>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            toggleCommunityList(list.id)
                                                        }}/>
                                                </td>
                                                <td className={"border-top-0"}>{list.name}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col xl={3} md={3} sm={3} xs={3} className="pl-1 pl-lg-3">
                            <Button color="green" onClick={() => {
                                if (selected_existing_list_ids.length) {
                                    if (!add_all_communities_to_list) {
                                        addCommunitiesToLists(idsToAddToList, selected_existing_list_ids);
                                    } else if (add_all_communities_to_list) {
                                        addAllCommunitiesToLists(selected_existing_list_ids);
                                    }
                                    closeModal();
                                }
                            }}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        closeModal();
                    }}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    addCommunitiesToLists,
    addAllCommunitiesToLists,
    createCommunityListAndAddCommunities,
    createCommunityListAndAddAllCommunities,
    searchCommunityList
}

const mapStateToProps = (state, ownProps) => ({
    // fullCommunityLists: state.community_list.full_list,
    fullCommunityLists: state.community_list.list,
    communityList: state.community.list,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddToListModal)
