import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import Apexchart from "react-apexcharts";
import ConfigDB from "../../../config"
import BlurredPieChart from "../../../assets/images/blured_pie_chart.svg"
import BlurredLineChart from "../../../assets/images/blured_line_chart.svg"
import NumberFormatter from "../../utils/number_formatter";
import {connect} from "react-redux";
import Blur from "../../common/helpers/blur";

class CommunityOfflineEngagement extends React.Component {

    generateApexChart(series, labels) {
        const config = {
            series: series,
            options: {
                chart: {
                    width: 200,
                    type: "donut",
                },
                labels: labels,
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '50%'
                        }
                    }
                },
                legend: {
                    show: false
                },
                colors: [ConfigDB.data.color.primary_color, ConfigDB.data.color.grey_color],
            },
        };

        return (
            <Apexchart
                options={config.options}
                series={config.series}
                type="donut"
                width={200}
            />
        );
    }

    combinedEventsChart(event_insights) {
        const mergedGraphsOfEvents = {
            options: {
                stroke: {
                    curve: 'smooth'
                },
                labels: Object.keys(event_insights.progressive),
                colors: ['#008FFB', '#FF4560'],
                yaxis: {
                    title: {
                        text: 'Attendees',
                    },
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y + " attendees";
                            }

                            return y;
                        }
                    }
                },
            },
            series: [{
                name: 'Attendees',
                type: 'line',
                data: Object.values(event_insights.progressive)
            }, {
                name: 'Attendees trending line',
                type: 'line',
                data: Object.values(event_insights.average)
            }]
        };

        return (
            <Apexchart
                options={mergedGraphsOfEvents.options}
                series={mergedGraphsOfEvents.series}
                height={300}
            />
        );
    }

    blurredPieChartImage() {
        return (<img src={BlurredPieChart} width={200}/>);
    }

    blurredLineChartImage() {
        return (<img src={BlurredLineChart} width={"100%"}/>);
    }

    render() {
        const {data} = this.props;
        const report = data ? data.singular : null;
        if (!report) {
            return null;
        }

        try {
            if (report.events_timeline) {
                report.events_timeline = JSON.parse(report.events_timeline);
            }
        } catch (e) {
            return null;
        }


        var currentYear = (new Date()).getFullYear();

        function UseBlur(localProps) {
            const {report} = localProps;
            return (<Blur radius={report.public === "true" ? "0" : "5px"}>{localProps.children}</Blur>);
        }

        return (
            <Card>
                <CardBody className={"pt-4 pb-2"}>
                    <Row>
                        <Col xs={12} sm={12} md={6}>
                            <h4 className={"grey-text"}><b>OFFLINE ENGAGEMENT</b></h4>
                            <h6 className={"m-b-0 m-t-30"}><b>Number of
                                events({currentYear}): <UseBlur report={report}><strong><NumberFormatter
                                    value={report.current_year_events}/></strong></UseBlur></b></h6>
                            <small>Number of events({currentYear - 1}): <UseBlur report={report}><b><NumberFormatter
                                value={report.last_year_events}/></b></UseBlur></small>
                            <h6 className={"m-t-10 m-b-10"}><b>Average event
                                participants({currentYear}): <UseBlur
                                    report={report}><strong>{report.avg_event_participants}</strong></UseBlur></b>
                            </h6>
                            <h6><b>Average events per
                                month({currentYear}): <UseBlur
                                    report={report}><strong>{report.avg_monthly_events}</strong></UseBlur></b>
                            </h6>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                            <Row>
                                <Col className={"text-center"} xs={12} sm={12} md={6}>
                                    <h5>Paid VS <br/> Free Events</h5>
                                    <div style={{"width": 200, "margin": "0 auto"}}>
                                        {report.public === "true" && this.generateApexChart([parseFloat(report.percentage_of_free_events), parseFloat(report.percentage_of_paid_events)], ["Free", "Paid"]) || (this.blurredPieChartImage())}
                                    </div>
                                </Col>
                                <Col className={"text-center"} xs={12} sm={12} md={6}>
                                    <h5>Online VS <br/> Offline Events</h5>
                                    <div style={{"width": 200, "margin": "0 auto"}}>
                                        {report.public === "true" && this.generateApexChart([parseFloat(report.percentage_of_online_events), parseFloat(report.percentage_of_offline_events)], ["Online", "Offline"]) || (this.blurredPieChartImage())}
                                    </div>
                                </Col>
                                {/*<Col className={"text-center"} xs={12} sm={12} md={6} lg={4}>*/}
                                {/*    <h5>Average % <br/> of attendees</h5>*/}
                                {/*    <div style={{"width": 200, "margin": "0 auto"}}>*/}
                                {/*        {report.public === "true" && this.generateApexChart([parseFloat(report.avg_percentage_of_attendees), parseFloat(100 - report.avg_percentage_of_attendees)], ["Attendees", "Non attendees"])|| (this.blurredPieChartImage())}*/}
                                {/*    </div>*/}
                                {/*</Col>*/}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {report.public === "true" && this.combinedEventsChart(report.events_timeline) || (this.blurredLineChartImage())}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    data: state.dashboard.insights.average_parameters.data,
});

export default connect(
    mapStateToProps,
    null
)(CommunityOfflineEngagement)
