import React from "react";

import {connect} from "react-redux";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Collapse,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from "reactstrap";
import {
    incrementMembersOfCommunityPage,
    loadCommunityMembers,
    toggleCommunityContainer,
    toggleCommunityMemberContainer,
    toggleLinkedinProfileVerification,
    toggleCommunityMemberHiddenProfiles
} from "../../../redux/campaign/actions";
import {ArrowDown, ArrowUp, Linkedin} from "react-feather";
import CampaignWhite from "../../../assets/images/campaign_white.svg";

class CommunityDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image_preview_modal: {
                opened: false,
                first_img: null,
                second_image: null
            }
        };
    }

    componentDidMount() {
        const {community, loadCommunityMembers} = this.props;
        const initialized = community.members.initialized;

        if (!initialized) {
            loadCommunityMembers(community.id, community.members.page);
        }
    }

    toggleImagePreviewModal() {
        const {image_preview_modal} = this.state;
        const {opened} = image_preview_modal;

        this.setState({
            image_preview_modal: {
                ...image_preview_modal,
                opened: opened === false
            }
        })
    }

    renderNoMembers() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Community has no members</h4>
            </div>
        );
    }

    renderNoProfiles() {
        return (
            <tr className={"text-center p-5"}>
                <td colSpan={8}>
                    <h4 className="m-0">Member has no profiles</h4>
                </td>
            </tr>
        );
    }

    renderLoadMore() {
        const {community, incrementMembersOfCommunityPage, loadCommunityMembers} = this.props;

        return (
            <div className={"d-flex justify-content-center pt-3"}>
                <Button onClick={() => {
                    incrementMembersOfCommunityPage(community.id);
                    loadCommunityMembers(community.id, community.members.page);
                }}>Load more members</Button>
            </div>
        );
    }

    renderLinkedinProfile(member, profile) {
        const {community, toggleLinkedinProfileVerification, selected_campaign} = this.props;

        var openImagePreviewModal = (linkedinProfileImgUrl) => {
            this.setState({
                image_preview_modal: {
                    opened: true,
                    first_img: member.member.image_url,
                    second_image: linkedinProfileImgUrl
                }
            })
        };

        return (
            <tr key={"profile-" + profile.id}>
                <td>{profile.linkedin_name}</td>
                <td>{profile.linkedin_title}</td>
                <td width={130}>{member.member.image_url && (
                    <img src={member.member.image_url} width={70} height={70} loading="lazy"/>)}</td>
                <td width={130}>{profile.linkedin_image_url && (
                    <img src={profile.linkedin_image_url} width={70} height={70}
                         onClick={() => openImagePreviewModal(profile.linkedin_image_url)} loading="lazy"/>)}</td>
                <td width={130} className={"text-center"}>
                    <a href={profile.linkedin_url} target="_blank" rel="noopener noreferrer">
                        <Linkedin/>
                    </a>
                </td>
                <td width={130}>{profile.flagged ? "YES" : "NO"}</td>
                <td width={130}>{profile.check_manually ? "YES" : "NO"}</td>
                <td width={130}>{profile.image_similarity}</td>
                {selected_campaign.status == 3 && (
                    <td>
                        <div
                            className={"size-20 checkbox-container " + (profile.verified ? "active full" : "")}
                            onClick={() => {
                                toggleLinkedinProfileVerification(community.id, member.id, profile.id);
                            }}
                        >
                            <i className={"fa fa-check"}></i>
                        </div>
                    </td>
                )}
            </tr>
        );
    }

    filterProfilesToList(member) {
        var profiles;
        var hiddenProfiles = [];

        const validToShowProfile = function (profile) {
            return (profile.flagged === false && (profile.image_similarity >= 0.25 || !profile.image_similarity)) || profile.verified;
        };

        if (member.member.show_hidden_profiles) {
            profiles = member.profiles;
        } else {
            profiles = member.profiles.filter((profile) => {
                return validToShowProfile(profile);
            });
            hiddenProfiles = member.profiles.filter((profile) => {
                return validToShowProfile(profile) === false;
            })
        }

        return {
            profiles: profiles,
            hidden_profiles: hiddenProfiles
        }
    }

    renderMember(member) {
        const {community, toggleCommunityMemberContainer, toggleCommunityMemberHiddenProfiles, selected_campaign} = this.props;
        const isVerifiedAdmin = member.member.is_verified_admin;
        const isVerified = isVerifiedAdmin || member.profiles.filter((profile) => profile.verified).length > 0;
        const {profiles, hidden_profiles} = this.filterProfilesToList(member);

        const renderHiddenProfiles = () => {
            if(hidden_profiles.length > 0) {
                return <Badge color={'primary'} className={"text-white"} onClick={() => toggleCommunityMemberHiddenProfiles(community.id, member.member.id)}>X {hidden_profiles.length} hidden profiles</Badge>;
            }
        };

        return (
            <div className={`d-flex flex-column mb-3 community-member ${isVerified ? "verified" : ""}`}
                 key={"community-member-" + member.member.id}>
                <div className={"d-flex justify-content-between align-items-center pl-1 p-2"}>
                    <div className={"d-flex justify-content-start align-items-center"}>
                        {member.member.image_url && (
                            <img src={member.member.image_url} width={40} height={40} loading="lazy"/>)}
                        <h4 className={"ml-4 mb-0 mr-4"}>{member.member.name}</h4>
                        {isVerified && (<Badge color={'primary'} className={"text-white"}>VERIFIED</Badge>)}
                        {isVerifiedAdmin && (<Badge color={'success'} className={"text-white"}>ADMIN</Badge>)}
                    </div>

                    <div className={"d-flex align-items-center"}>
                        {renderHiddenProfiles()}
                        <div onClick={() => toggleCommunityMemberContainer(community.id, member.member.id)} className={"ml-1"}>
                            {member.member.container_is_open ? <ArrowDown/> : <ArrowUp/>}
                        </div>
                    </div>
                </div>
                <Collapse isOpen={member.member.container_is_open}>
                    <Table className={"mt-2"}>
                        <thead>
                        <tr>
                            <th>Linkedin Name</th>
                            <th>Linkedin Title</th>
                            <th width={130}>Meetup Image</th>
                            <th width={130}>Linkedin Image</th>
                            <th width={130} className={"text-center"}>Linkedin Profile</th>
                            <th width={130}>Mismatch</th>
                            <th width={130}>Check Manually</th>
                            <th width={130}>Image Similarity</th>
                            {selected_campaign.status == 3 && (<th width={100}>Is Verified</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {profiles.map((profile) => {
                            return this.renderLinkedinProfile(member, profile);
                        })}
                        {profiles.length === 0 && hidden_profiles.length === 0 && (this.renderNoProfiles())}
                        </tbody>
                    </Table>
                    <Modal isOpen={this.state.image_preview_modal.opened} toggle={() => this.toggleImagePreviewModal()}>
                        <ModalHeader toggle={() => this.toggleImagePreviewModal()}>Image Preview</ModalHeader>
                        <ModalBody>
                            <div className={"d-flex justify-content-center align-items-center"}>
                                <div className={"member-image-container"} style={{
                                    "backgroundImage": "url(" + this.state.image_preview_modal.first_img + ")"
                                }}></div>
                                <div style={{"width": "50px", "textAlign": "center"}}>{"<=>"}</div>
                                <div className={"member-image-container"} style={{
                                    "backgroundImage": "url(" + this.state.image_preview_modal.second_image + ")"
                                }}></div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={() => this.toggleImagePreviewModal()}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </Collapse>
            </div>
        );
    }

    renderLoading() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Loading members</h4>
            </div>
        );
    }

    render() {
        const {current_user, community, toggleCommunityContainer} = this.props;

        if (!current_user) {
            return null;
        }

        const totalProfiles = community.members.total_results;
        const loadingProfiles = community.members.loading;
        const members = community.members.list;
        const {page, total_pages} = community.members;
        const verifiedMembers = members.filter((member) => {
            return member.member.is_verified_admin || member.profiles.filter((profile) => profile.verified).length > 0;
        });

        return (
            <Card className={"border"}>
                <CardHeader style={{"position": "sticky", "top": 0}} className={"p-4"}>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <div className={"d-flex justify-content-start align-items-center"}>
                            <h3 className={"mb-0"}>{community.name}</h3>
                            <Badge color={'primary'} className={"text-white ml-3"}>{verifiedMembers.length}/{totalProfiles} verified members</Badge>
                            {/*<Badge color={'info'} className={"text-white ml-3"}><img src={CampaignWhite} width={15} alt={"campaign-logo"}/> Contacted before</Badge>*/}
                        </div>
                        <div onClick={() => toggleCommunityContainer(community.id)}>
                            {community.container_is_open ? <ArrowDown/> : <ArrowUp/>}
                        </div>
                    </div>
                </CardHeader>
                <Collapse isOpen={community.container_is_open}>
                    <CardBody className={"p-4"}>
                        {!loadingProfiles && members.length === 0 && this.renderNoMembers()}
                        {members.map((member) => this.renderMember(member))}
                        {!loadingProfiles && page < total_pages && this.renderLoadMore()}
                        {loadingProfiles && this.renderLoading()}
                    </CardBody>
                </Collapse>
                <CardFooter className={"d-flex"}>
                    <span>Total members: {members.length}/{totalProfiles}</span>
                </CardFooter>
            </Card>
        );
    }
}

const mapDispatchToProps = {
    loadCommunityMembers,
    incrementMembersOfCommunityPage,
    toggleLinkedinProfileVerification,
    toggleCommunityContainer,
    toggleCommunityMemberContainer,
    toggleCommunityMemberHiddenProfiles
};

const mapStateToProps = (state, ownProps) => {
    const communities = state.campaign.selected.communities.list;

    return {
        selected_campaign: state.campaign.selected.campaign,
        communities: communities,
        community: communities.find((com) => com.id == ownProps.id),
        current_user: state.user.data,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityDetails)
