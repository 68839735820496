import React from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody, Popover, PopoverBody,
} from 'reactstrap';
import Coin from "../../../assets/images/coin.svg";
import SolanaLogo from "../../../assets/images/solana-logo.png";
import NumberFormatter from "../../utils/number_formatter";
import {withRouter} from "react-router";
import LogoComponent from "../../common/helpers/logo_component";
import {connect} from "react-redux";
import {MAX_DAYS_FOR_NEW_JOB_BADGE, PAYMENT_TYPE_FIAT} from "../../../constants";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {toggleCommunityRedeemModal} from "../../../redux/community/actions";
import SolanaWalletPopoverComponent from "../../common/solana/SolanaWalletPopoverComponent";

dayjs.extend(relativeTime)

class JobCard extends React.Component {
    renderJobSalary() {
        const {job} = this.props;
        const {min_salary, max_salary} = job;

        if(min_salary && max_salary) {
            return (<span>({job.min_salary} {job.currency} - {job.max_salary} {job.currency})</span>);
        } else if(min_salary) {
            return (<span>({job.min_salary} {job.currency})</span>);
        } else if(max_salary) {
            return (<span>({job.max_salary} {job.currency})</span>);
        }

        return null;
    }

    isNewJob(created_at) {
        let jobDate = new Date(created_at);
        let dateNow = new Date();

        let differenceInTime = Math.abs(dateNow.getTime() - jobDate.getTime());
        let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

        return differenceInDays < MAX_DAYS_FOR_NEW_JOB_BADGE;
    }

    renderBounty() {
        const { job } = this.props;

        if(job.payment_type === PAYMENT_TYPE_FIAT){
            return (
                <>
                    <img src={Coin} width={20} height={20} className={"object-contain ml-2"} alt={"Coin"} />
                    <h6 className={"font-weight-bold ml-2 mb-0"}>{job.currency} <NumberFormatter value={job.bounty} /> Bounty</h6>
                </>
            );
        } else {
            return (
                <>
                    <img src={SolanaLogo} width={25} height={25} className={"object-contain ml-2"} alt={"Solana"} />
                    <h6 className={"font-weight-bold ml-2 mb-0 text-phantom"}>{job.currency} <NumberFormatter value={job.bounty} /> Blockchain tracked bounty</h6>
                    <SolanaWalletPopoverComponent />
                </>
            );
        }
    }

    render() {
        const {user ,shareJobModalOpen, history, job, toggleCommunityRedeemModal, redeem_token} = this.props;

        const salary = this.renderJobSalary();

        return (
            <Card className={"mb-4"}>
                <CardBody className="p-4 d-flex flex-column">
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <h4 className={"f-w-900 pointer"} onClick={() => history.push(`/jobs/preview/${job.referral_token.token}`)}>{job.title}</h4>
                        {this.isNewJob(job.created_at)
                            ? (<Badge color={"info"} className={"text-white d-none d-lg-inline-block p-2"}>NEW</Badge>)
                            : (<span className={"purple-text d-none d-lg-inline-block text-nowrap"}>{dayjs(job.created_at).fromNow()}</span>)}
                    </div>
                    <div className={"d-flex mb-2"}>
                        <LogoComponent logo={job.company.logo} width={40} height={40} className={"object-contain b-r-10"} />
                        <div className={"d-flex flex-column ml-3"}>
                            <span>{job.company.name}</span>
                            <span>{job.location && job.location}{salary ? " - " : ""}{salary}</span>
                        </div>
                    </div>
                    <div className={"d-flex mb-3 flex-wrap"}>
                        {job.topics.map((topic) =>
                            <Badge key={topic.id} color={"purple mb-1"}>{topic.name}</Badge>
                        )}
                    </div>
                    <div className={"d-flex align-items-center"}>
                        {(user || (!user && redeem_token)) && <Button color={"info"} size={"sm"} className={"px-3 py-1"} onClick={() => {
                            if(!user && redeem_token) {
                                toggleCommunityRedeemModal();
                                return;
                            }

                            shareJobModalOpen();
                        }}>
                            <i className="icofont icofont-share"/> SHARE
                        </Button>}
                        {this.renderBounty()}
                    </div>
                </CardBody>
            </Card>
        );
    }
}

const mapDispatchToProps = {
    toggleCommunityRedeemModal,
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    redeem_token: state.community.redeem_token,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobCard));
