import React from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import Chart from "react-google-charts";
import BlurredGraph from "./blurred_graph";

class EventsVSParticipantsDistributionReport extends React.Component {

    renderMap() {
        const {report} = this.props;

        const prepareData = (report) => {
            var dataSet = report.map((community) => {
                return [
                    '',
                    parseFloat(community.ppe),
                    parseFloat(community.epm),
                    community.n,
                    parseInt(community.t)
                ];
            });

            return [["ID", "Avg. Participants per event", "Avg. Events per month", "Name", "Total members"]].concat(dataSet);
        };

        const dataSet = prepareData(report);
        const options = {
            hAxis: {title: "Avg. Participants per event", logScale: true, viewWindowMode: "maximized"},
            vAxis: {title: "Avg. Events per month", logScale: true, viewWindowMode: "maximized"},
            legend: {
                position: "none"
            },
            tooltip: {
                trigger: 'selection'
            },
        };

        return (
            <Chart
                width={"100%"}
                height={"700px"}
                chartType="BubbleChart"
                loader={<div className={"text-center p-5"}>Loading Chart</div>}
                data={dataSet}
                options={options}
            />
        );
    }

    render() {
        const {report} = this.props;

        if (!report) {
            return null;
        }

        return (
            <Card>
                <CardHeader>
                    <h5>Events VS Participants Distribution</h5>
                </CardHeader>
                <CardBody className={'pl-0 pr-0 pt-0'}>
                    {report.public === false ? <BlurredGraph image={2}/> : this.renderMap()}
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    report: state.dashboard.insights.events_vs_participants_distribution.data,
});

export default connect(
    mapStateToProps,
    null
)(EventsVSParticipantsDistributionReport)
