import React from "react";
import {Button, Card, CardBody} from "reactstrap";
import LogoComponent from "../../common/helpers/logo_component";
import {editService, toggleAddServiceModal} from "../../../redux/community/actions";
import {connect} from "react-redux";
import {
    PRICE_TYPE_FIXED,
    PRICE_TYPE_PRIVATE,
    PRICE_TYPE_RANGE,
    RECURRENCE_TYPE_MONTHLY,
    RECURRENCE_TYPE_YEARLY
} from "../../../constants";
import NumberFormatter from "../../utils/number_formatter";
import VideoComponent from "../../common/helpers/video_component";

class ServiceFormCard extends React.Component {
    cutString(string, length) {
        return string.substr(0, length).concat(string.length > length ? "..." : "");
    }

    renderDescription() {
        const {service} = this.props;
        let length = service.image || service.video_link ? 200 : 260;

        return this.cutString(service.description, length);
    }

    renderRecurrence() {
        const {service} = this.props;

        switch (service.recurrence_type) {
            case RECURRENCE_TYPE_MONTHLY:
                return "monthly";
            case RECURRENCE_TYPE_YEARLY:
                return "yearly";
            default:
                return;
        }
    }

    renderCategory() {
        const {service, service_categories, loading_categories} = this.props;

        if(loading_categories) {
            return;
        }

        let category = service_categories.filter((category) => category.id === service.category.id)[0];

        if(category) {
            return category.name;
        }
    }

    calculatePricing() {
        const {service} = this.props;

        switch (service.price_type) {
            case PRICE_TYPE_FIXED:
                return (<span><NumberFormatter value={service.fixed_price} /> {service.currency} {Number(service.recurrent) ? " - paid " + this.renderRecurrence() : ""}</span>);
            case PRICE_TYPE_RANGE:
                return (<span><NumberFormatter value={service.min_price} /> - <NumberFormatter value={service.max_price} /> {service.currency}</span>);
            case PRICE_TYPE_PRIVATE:
                return (<span>Private. Inquiry for more info.</span>)
            default:
                return null;
        }
    }

    render() {
        const {service, index, toggleAddServiceModal, deleteService} = this.props;

        return (
            <Card>
                <CardBody className={"d-flex align-items-center p-3 border b-r-15 flex-column flex-lg-row"}>
                    {service.video_link && <div>
                        <VideoComponent title={`${service.name} - ${index}`} video={service.video_link} width={210} height={135} className={"object-contain b-r-10"} />
                    </div>}
                    {!service.video_link && service.image && <div>
                        <LogoComponent logo={service.image} width={210} height={135} className={"object-contain b-r-10"}/>
                    </div>}
                    <div className={"d-flex flex-column p-3"}>
                        <span className={"f-19 f-w-700 m-b-5"}>{service.name}</span>
                        <span className={"f-16 f-w-700"}>{this.renderCategory()}</span>
                        <span className={"f-14 m-b-10"}>{this.renderDescription()}</span>
                        <span className={"f-16"}><b>Pricing:</b> {this.calculatePricing()}</span>
                    </div>
                    <div className={"d-flex flex-lg-column ml-lg-auto"}>
                        <Button color={"green"} className={"m-5 d-flex align-items-center justify-content-center"} onClick={() => {
                            toggleAddServiceModal(index);
                        }}><i className="fa fa-edit m-5" />EDIT</Button>
                        <Button color={"outline-green"} className={"m-5 d-flex align-items-center justify-content-center"} onClick={() => {
                            if(window.confirm("Are you sure you want to delete this service?")) {
                                deleteService();
                            }
                        }}><i className="fa fa-trash m-5" />DELETE</Button>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

const mapDispatchToProps = {
    toggleAddServiceModal,
    editService,
};

const mapStateToProps = (state, ownProps) => ({
    service_categories: state.community.service_category.list,
    loading_categories: state.community.service_category.loading,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceFormCard);