import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import BlurredIcon from '../../../assets/images/blurred_icon.svg';

class FindCommunityOnAnonymous extends React.Component {
    render() {
        return (
            <Card>
                <CardHeader className={"pt-4 pb-4"}>
                    <h4 className={"m-0 grey-text"}><b>FIND THEM ON</b></h4>
                </CardHeader>
                <CardBody className={"d-flex pt-4 pb-4"}>
                    <img src={BlurredIcon} width={50}/>
                    <div className={"m-l-10"}></div>
                    <img src={BlurredIcon} width={50}/>
                    <div className={"m-l-10"}></div>
                    <img src={BlurredIcon} width={50}/>
                    <div className={"m-l-10"}></div>
                    <img src={BlurredIcon} width={50}/>
                </CardBody>
            </Card>
        );
    }
}


export default FindCommunityOnAnonymous;
