import React from "react";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader} from "reactstrap";
import {Chart} from "react-google-charts";

class LocationDistributionReport extends React.Component {
    buildListForChart() {
        const {report} = this.props;

        var chartData = [];

        report.forEach(function (location) {
            chartData.push([location.n, location.t, location.t]);
        });

        return chartData;
    }

    generateLabel() {
        const {selectedLocation, selectedCategory} = this.props;

        if (selectedLocation.id || selectedCategory.id) {
            var label = "";

            if (!selectedLocation['type'] || selectedLocation['type'] === "continent") {
                label += "Countries"
            } else {
                label += "Cities"
            }
            label += " with the most communities";

            if (selectedCategory.id) {
                label += " in the " + selectedCategory.name + " category";
            }

            return label;
        } else {
            return "Countries with the most communities";
        }
    }

    render() {
        const {report} = this.props;

        if (!report) {
            return null;
        }

        var chartData = [["Location", "Total communities", {role: 'annotation'}]].concat(this.buildListForChart());

        return (
            <Card>
                <CardHeader>
                    <h5>{this.generateLabel()}</h5>
                </CardHeader>
                <CardBody className="chart-block">
                    <div style={{"overflowY": "hidden", "overflowX": "scroll"}}>
                        <Chart
                            height={"300px"}
                            width={"100%"}
                            chartType="ColumnChart"
                            loader={<div>Loading Chart</div>}
                            data={chartData}
                            options={{
                                vAxis: {
                                    // logScale: true,
                                    textStyle: {fontSize: 12}
                                },
                                hAxis: {
                                    textStyle: {fontSize: 12}
                                },
                                width: chartData.length < 20 ? "100%" : chartData.length * 40,
                                backgroundColor: "transparent",
                                colors: ['#4391D1'],
                                legend: {position: 'none'},
                                chartArea: {
                                    left: 100,
                                    right: 0,
                                    top: 10,
                                },
                                annotations: {
                                    textStyle: {
                                        fontSize: 10,
                                        bold: true,
                                        italic: true,
                                    }
                                }
                            }}
                            columns={[0, 1,
                                {
                                    calc: "stringify",
                                    sourceColumn: 1,
                                    type: "string",
                                    role: "annotation"
                                }, 2
                            ]}
                        />
                    </div>
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    report: state.dashboard.insights.location_distribution.data,
    selectedLocation: state.dashboard.selected_location,
    selectedCategory: state.dashboard.selected_category
});

export default connect(
    mapStateToProps,
    null
)(LocationDistributionReport)
