import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import {Button, Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Container, Row} from 'reactstrap';
import {
    getJobApplicationFormDetails,
    saveJobApplicationForm,
    updateJobApplicationFormData
} from '../../redux/job/actions';
import {
    JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE,
    JOB_APPLICATION_FORM_FIELD_OPTIONAL,
    JOB_APPLICATION_FORM_FIELD_REQUIRED
} from "../../constants";
import CustomFieldModal from "./components/custom_fields/custom_field_modal";

class JobApplicationForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            edit_custom_field_index: null,
            open_new_custom_field_modal: false
        }
    }

    componentDidMount() {
        const {getJobApplicationFormDetails} = this.props;
        const id = this.props.routeParams.id;

        getJobApplicationFormDetails(id);
    }

    renderInvalidJobApplicationForm() {
        return (
            <div className={"d-flex align-items-center justify-content-center p-5"}>
                <h3>Invalid application form</h3>
            </div>
        )
    }

    toggleNewFieldModal() {
        const {open_new_custom_field_modal} = this.state;

        this.setState({
            edit_custom_field_index: null,
            open_new_custom_field_modal: open_new_custom_field_modal === false
        });
    }

    editExistingField(index) {
        this.setState({
            edit_custom_field_index: index,
            open_new_custom_field_modal: true
        });
    }

    persistApplicationForm(applicationForm) {
        const {updateJobApplicationFormData, saveJobApplicationForm} = this.props;
        
        updateJobApplicationFormData(applicationForm);
        saveJobApplicationForm(applicationForm.job.id, applicationForm);
    }

    removeCustomField(index) {
        if(window.confirm("Are you sure?")){
            const {application_form} = this.props;
            application_form.custom_fields.splice(index, 1);

            this.persistApplicationForm(application_form);
        }
    }

    renderLoading() {
        return (
            <Card className={"align-items-center justify-content-center p-4"}>
                <h3>Loading...</h3>
            </Card>
        )
    }

    generateFieldSettings(key) {
        return [
            <option key={key + "1"} value={JOB_APPLICATION_FORM_FIELD_NOT_VISIBLE}>Not visible</option>,
            <option key={key + "2"} value={JOB_APPLICATION_FORM_FIELD_OPTIONAL}>Visible but Optional</option>,
            <option key={key + "3"} value={JOB_APPLICATION_FORM_FIELD_REQUIRED}>Visible but Required</option>
        ]
    }

    onSettingsFormChange(value, field) {
        const {application_form} = this.props;
        application_form[field] = Number.parseInt(value);

        this.persistApplicationForm(application_form);
    }

    onNewCustomFieldAdded(newCustomField) {
        const {application_form} = this.props;
        const {edit_custom_field_index} = this.state;

        if(edit_custom_field_index === null){
            application_form.custom_fields.push(newCustomField);
        } else {
            application_form.custom_fields[edit_custom_field_index]  = newCustomField;
        }

        this.persistApplicationForm(application_form);
    }

    render() {
        const {
            loading,
            application_form
        } = this.props;
        const {open_new_custom_field_modal} = this.state;

        return (
            <Fragment>
                {!loading && application_form && (
                    <div>
                        <Breadcrumb title={"Job details"} hideBreadcrumbRight={true}/>
                        <Container fluid={true}>
                            <Card>
                                <CardHeader>
                                    <CardTitle><h3>Application Form</h3></CardTitle>
                                    <CardSubtitle><h5>{application_form.job.title}</h5></CardSubtitle>
                                </CardHeader>
                                <CardBody>
                                    <Row className={"mb-3"}>
                                        <Col xs="12" sm="3"><span>Ask Birthday</span></Col>
                                        <Col xs="12" sm="9">
                                            <select defaultValue={application_form.ask_birthday}
                                                    onChange={(e) => this.onSettingsFormChange(e.target.value, 'ask_birthday')}
                                                    className="form-control"
                                                    required
                                            >
                                                {this.generateFieldSettings("ask_birthday")}
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className={"mb-3"}>
                                        <Col xs="12" sm="3"><span>Ask Current Role</span></Col>
                                        <Col xs="12" sm="9">
                                            <select defaultValue={application_form.ask_current_role}
                                                    onChange={(e) => this.onSettingsFormChange(e.target.value, 'ask_current_role')}
                                                    className="form-control"
                                                    required
                                            >
                                                {this.generateFieldSettings("ask_current_role")}
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className={"mb-3"}>
                                        <Col xs="12" sm="3"><span>Ask Linkedin Profile</span></Col>
                                        <Col xs="12" sm="9">
                                            <select defaultValue={application_form.ask_linkedin_profile}
                                                    onChange={(e) => this.onSettingsFormChange(e.target.value, 'ask_linkedin_profile')}
                                                    className="form-control"
                                                    required
                                            >
                                                {this.generateFieldSettings("ask_linkedin_profile")}
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className={"mb-3"}>
                                        <Col xs="12" sm="3"><span>Ask Github Profile</span></Col>
                                        <Col xs="12" sm="9">
                                            <select defaultValue={application_form.ask_github_profile}
                                                    onChange={(e) => this.onSettingsFormChange(e.target.value, 'ask_github_profile')}
                                                    className="form-control"
                                                    required
                                            >
                                                {this.generateFieldSettings("ask_github_profile")}
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className={"mb-3"}>
                                        <Col xs="12" sm="3"><span>Ask Other url</span></Col>
                                        <Col xs="12" sm="9">
                                            <select defaultValue={application_form.ask_other_url}
                                                    onChange={(e) => this.onSettingsFormChange(e.target.value, 'ask_other_url')}
                                                    className="form-control"
                                                    required
                                            >
                                                {this.generateFieldSettings("ask_other_url")}
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className={"mb-3"}>
                                        <Col xs="12"
                                             sm="3"><span>Ask Current Location</span></Col>
                                        <Col xs="12" sm="9">
                                            <select defaultValue={application_form.ask_current_location}
                                                    onChange={(e) => this.onSettingsFormChange(e.target.value, 'ask_current_location')}
                                                    className="form-control"
                                                    required
                                            >
                                                {this.generateFieldSettings("ask_current_location")}
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className={"mb-3"}>
                                        <Col xs="12" sm="3"><span>Ask Gender</span></Col>
                                        <Col xs="12" sm="9">
                                            <select defaultValue={application_form.ask_gender}
                                                    onChange={(e) => this.onSettingsFormChange(e.target.value, 'ask_gender')}
                                                    className="form-control"
                                                    required
                                            >
                                                {this.generateFieldSettings("ask_gender")}
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className={"mb-3"}>
                                        <Col xs="12" sm="3"><span>Ask Phone Number</span></Col>
                                        <Col xs="12" sm="9">
                                            <select defaultValue={application_form.ask_phone_number}
                                                    onChange={(e) => this.onSettingsFormChange(e.target.value, 'ask_phone_number')}
                                                    className="form-control"
                                                    required
                                            >
                                                {this.generateFieldSettings("ask_phone_number")}
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className={"mb-3"}>
                                        <Col xs="12" sm="3"><span>Ask CV</span></Col>
                                        <Col xs="12" sm="9">
                                            <select defaultValue={application_form.ask_cv}
                                                    onChange={(e) => this.onSettingsFormChange(e.target.value, 'ask_cv')}
                                                    className="form-control"
                                                    required
                                            >
                                                {this.generateFieldSettings("ask_cv")}
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className={"mb-3"}>
                                        <Col xs="12" sm="3"><span>Ask Biography</span></Col>
                                        <Col xs="12" sm="9">
                                            <select defaultValue={application_form.ask_biography}
                                                    onChange={(e) => this.onSettingsFormChange(e.target.value, 'ask_biography')}
                                                    className="form-control"
                                                    required
                                            >
                                                {this.generateFieldSettings("ask_biography")}
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className={"mb-3"}>
                                        <Col xs="12" sm="3"><span>Ask Years Of Experience</span></Col>
                                        <Col xs="12" sm="9">
                                            <select defaultValue={application_form.ask_years_of_experience}
                                                    onChange={(e) => this.onSettingsFormChange(e.target.value, 'ask_years_of_experience')}
                                                    className="form-control"
                                                    required
                                            >
                                                {this.generateFieldSettings("ask_years_of_experience")}
                                            </select>
                                        </Col>
                                    </Row>

                                    <hr/>

                                    <Row>
                                        <Col>
                                            <h5>Custom Fields</h5>
                                        </Col>
                                    </Row>

                                    {application_form.custom_fields.map((customField, fieldIndex) => {
                                        return (
                                            <div className={"d-flex flex-row"} key={"custom_field_"+fieldIndex}>
                                                <h6>{fieldIndex+1}.</h6>
                                                <div className={"d-flex flex-column ml-2 align-items-start"}>
                                                    <span><b>Name:</b> {customField.title}</span>
                                                    <span><b>Description:</b> {customField.description}</span>
                                                    <span><b>Type:</b> {customField.type}</span>
                                                    <span><b>Required:</b> {customField.required ? 'YES' : "NO"}</span>
                                                    {customField.type === "choice" && (
                                                        <div className={"d-flex flex-column"}>
                                                            <span><b>Expanded:</b> {customField.expanded ? 'YES' : "NO"}</span>
                                                            <span><b>Multiple:</b> {customField.multiple ? 'YES' : "NO"}</span>
                                                            <div className={"d-flex flex-row"}>
                                                                <b>Fields:</b>
                                                                <ol className={"flex-column"}>
                                                                    {customField.choice_options.map((option, choiceIndex) => {
                                                                        return <li key={"custom_field_"+fieldIndex+"_option_"+choiceIndex}>{option.title}</li>;
                                                                    })}
                                                                </ol>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className={"d-flex"}>
                                                        <Button size={"xs"} className={"mt-1"} onClick={() => this.editExistingField(fieldIndex)}>Edit</Button>
                                                        <Button size={"xs"} className={"mt-1 ml-1"} color={"danger"} onClick={() => this.removeCustomField(fieldIndex)}>Delete</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </CardBody>
                            </Card>
                        </Container>

                        <Container className={"mb-4"}>
                            {open_new_custom_field_modal && <CustomFieldModal
                                closeModal={() => this.toggleNewFieldModal()}
                                onNewCustomFieldAdded={(newCustomField) => this.onNewCustomFieldAdded(newCustomField)}
                                customField={this.state.edit_custom_field_index !== null ? application_form.custom_fields[this.state.edit_custom_field_index] : null}
                            />}
                            <Row>
                                <Col xs={12} className={"text-center"}>
                                    <Button onClick={() => {
                                        this.toggleNewFieldModal();
                                    }}>
                                        Add new field
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )}
                {loading && this.renderLoading()}
                {!loading && !application_form && this.renderInvalidJobApplicationForm()}
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    getJobApplicationFormDetails,
    updateJobApplicationFormData,
    saveJobApplicationForm
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    customer: state.customer.data,
    application_form: state.job.application_form.data,
    loading: state.job.application_form.loading
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobApplicationForm)
