// Community
export const LOAD_COMMUNITIES = "LOAD_COMMUNITIES";
export const INCREMENT_COMMUNITIES_PAGE = "INCREMENT_COMMUNITIES_PAGE";
export const DECREMENT_COMMUNITIES_PAGE = "DECREMENT_COMMUNITIES_PAGE";
export const RESET_COMMUNITIES_PAGE = "RESET_COMMUNITIES_PAGE";
export const SET_FREE_SEARCH_FOR_COMMUNITY = "SET_FREE_SEARCH_FOR_COMMUNITY";
export const SELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY = "SELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY";
export const UNSELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY = "UNSELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY";
export const GET_COMMUNITY_DETAILS = "GET_COMMUNITY_DETAILS";
export const SEARCH_FOR_COMMUNITY = "SEARCH_FOR_COMMUNITY";
export const SEARCH_FOR_COMMUNITY_NETWORK = "SEARCH_FOR_COMMUNITY_NETWORK";
export const CLEAR_SEARCHED_COMMUNITY_LIST = "CLEAR_SEARCHED_COMMUNITY_LIST";
export const CLEAR_SELECTED_COMMUNITY = "CLEAR_SELECTED_COMMUNITY";
export const SET_SORTING_CONDITION = "SET_SORTING_CONDITION";
export const GET_COMMUNITY_TO_REDEEM = "GET_COMMUNITY_TO_REDEEM";
export const REDEEM_COMMUNITY = "REDEEM_COMMUNITY";
export const GET_COMMUNITY_EDIT_DETAILS = "GET_COMMUNITY_EDIT_DETAILS";
export const EDIT_COMMUNITY = "EDIT_COMMUNITY";
export const REMOVE_TOPIC_FROM_COMMUNITY = "REMOVE_TOPIC_FROM_COMMUNITY";
export const REMOVE_TOPIC_FROM_COMMUNITY_REGISTRATION = "REMOVE_TOPIC_FROM_COMMUNITY_REGISTRATION";
export const ADD_TOPIC_IN_COMMUNITY = "ADD_TOPIC_IN_COMMUNITY";
export const ADD_TOPIC_IN_COMMUNITY_REGISTRATION = "ADD_TOPIC_IN_COMMUNITY_REGISTRATION";
export const EDIT_COMMUNITY_LINKS = "EDIT_COMMUNITY_LINKS";
export const SELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY = "SELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY";
export const UNSELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY = "UNSELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY";
export const TOGGLE_NEW_COMMUNITIES_FILTER = "TOGGLE_NEW_COMMUNITIES_FILTER";
export const SET_COMMUNITY_REDEEM_TOKEN = "SET_COMMUNITY_REDEEM_TOKEN";
export const CLEAR_COMMUNITY_REDEEM_TOKEN = "CLEAR_COMMUNITY_REDEEM_TOKEN";
export const TOGGLE_REDEEMED_COMMUNITY_FILTER = "TOGGLE_REDEEMED_COMMUNITY_FILTER";
export const TOGGLE_COMMUNITIES_WITH_VERIFIED_ADMINS_FILTER = "TOGGLE_COMMUNITIES_WITH_VERIFIED_ADMINS_FILTER";
export const VERIFY_EXISTING_MEETUP_COMMUNITY = "VERIFY_EXISTING_MEETUP_COMMUNITY";
export const CREATE_COMMUNITY = "CREATE_COMMUNITY";
export const TOGGLE_ADD_SERVICE_MODAL = "TOGGLE_ADD_SERVICE_MODAL";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const EDIT_SERVICE = "EDIT_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const GET_SERVICE_CATEGORIES = "GET_SERVICE_CATEGORIES";
export const CLEAR_COMMUNITY_DETAILS = "CLEAR_COMMUNITY_DETAILS";
export const UPDATE_COMMUNITY_CATEGORY = "UPDATE_COMMUNITY_CATEGORY";
export const UPDATE_COMMUNITY_DATA = "UPDATE_COMMUNITY_DATA";
export const TOGGLE_CONTACT_COMMUNITY_MODAL = "TOGGLE_CONTACT_COMMUNITY_MODAL";
export const TOGGLE_SERVICE_DETAILS_MODAL = "TOGGLE_SERVICE_DETAILS_MODAL";
export const DISABLE_SLACK_ON_EDIT_COMMUNITY = "DISABLE_SLACK_ON_EDIT_COMMUNITY";
export const DISABLE_DISCORD_ON_EDIT_COMMUNITY = "DISABLE_DISCORD_ON_EDIT_COMMUNITY";
export const SET_EDIT_COMMUNITY_DATA = "SET_EDIT_COMMUNITY_DATA";
export const SAVE_SOLANA_WALLET_ON_COMMUNITY = "SAVE_SOLANA_WALLET_ON_COMMUNITY";

// Categories
export const LOAD_CATEGORIES = "LOAD_CATEGORIES";

// Topics
export const SEARCH_TOPICS_TO_FILTER_COMMUNITIES = "SEARCH_TOPICS_TO_FILTER_COMMUNITIES";
export const SEARCH_TOPICS_TO_ADD_IN_COMMUNITY = "SEARCH_TOPICS_TO_ADD_IN_COMMUNITY";
export const SELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY = "SELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY";
export const UNSELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY = "UNSELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY";
export const CLEAR_TOPICS_TO_FILTER_FOR_COMMUNITY = "CLEAR_TOPICS_TO_FILTER_FOR_COMMUNITY";
export const LOAD_LANDING_PAGE_TOPICS = "LOAD_LANDING_PAGE_TOPICS";
export const SEARCH_TOPICS_TO_EXCLUDE_FROM_COMMUNITY_SEARCH = "SEARCH_TOPICS_TO_EXCLUDE_FROM_COMMUNITY_SEARCH";
export const SELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH = "SELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH";
export const UNSELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH = "UNSELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH";

// User
export const LOAD_USER = "LOAD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const REGISTER_USER = "REGISTER_USER";
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const SAVE_NEW_PASSWORD = "SAVE_NEW_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SET_USER_AUTH_TOKEN = "SET_USER_AUTH_TOKEN";

// Customer
export const LOAD_CUSTOMER = "LOAD_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const LOAD_SUBSCRIPTIONS = "LOAD_SUBSCRIPTIONS";
export const UPDATE_CUSTOMER_COUNTRY = "UPDATE_CUSTOMER_COUNTRY";
export const CANCEL_SUBSCRIPTION_RENEW = "CANCEL_SUBSCRIPTION_RENEW";

// Lists
export const LOAD_COMMUNITY_LISTS = "LOAD_COMMUNITY_LISTS";
export const LOAD_LITE_COMMUNITY_LISTS = "LOAD_LITE_COMMUNITY_LISTS";
export const ADD_COMMUNITIES_TO_LISTS = "ADD_COMMUNITIES_TO_LISTS";
export const ADD_ALL_COMMUNITIES_TO_LISTS = "ADD_ALL_COMMUNITIES_TO_LISTS";
export const CREATE_COMMUNITY_LIST = "CREATE_COMMUNITY_LIST";
export const CREATE_COMMUNITY_LIST_AND_ADD_COMMUNITIES = "CREATE_COMMUNITY_LIST_AND_ADD_COMMUNITIES";
export const CREATE_COMMUNITY_LIST_AND_ADD_ALL_COMMUNITIES = "CREATE_COMMUNITY_LIST_AND_ADD_ALL_COMMUNITIES";
export const LOAD_COMMUNITIES_OF_LIST = "LOAD_COMMUNITIES_OF_LIST";
export const LOAD_LIST_DETAILS = "LOAD_LIST_DETAILS";
export const SET_SORTING_CONDITION_FOR_COMMUNITIES_OF_LIST = "SET_SORTING_CONDITION_FOR_COMMUNITIES_OF_LIST";
export const REMOVE_COMMUNITY_FROM_LIST = "REMOVE_COMMUNITY_FROM_LIST";
export const DELETE_COMMUNITY_LIST = "DELETE_COMMUNITY_LIST";
export const INCREMENT_COMMUNITIES_OF_LIST_PAGE = "INCREMENT_COMMUNITIES_OF_LIST_PAGE";
export const DECREMENT_COMMUNITIES_OF_LIST_PAGE = "DECREMENT_COMMUNITIES_OF_LIST_PAGE";
export const INCREMENT_LISTS_PAGE = "INCREMENT_LISTS_PAGE";
export const DECREMENT_LISTS_PAGE = "DECREMENT_LISTS_PAGE";
export const CLEAR_LIST_DETAILS = "CLEAR_LIST_DETAILS";
export const SEARCH_COMMUNITY_LIST = "SEARCH_COMMUNITY_LIST";
export const CHANGE_FILTER_FOR_LIST = "CHANGE_FILTER_FOR_LIST";
export const SET_MIN_MEMBERS_CONDITION_FOR_COMMUNITIES_LIST = "SET_MIN_MEMBERS_CONDITION_FOR_COMMUNITIES_LIST";

// Extras
export const LOAD_UTILS = "LOAD_UTILS";
export const UPLOAD_COMMUNITY_IMAGE_FILE = "UPLOAD_COMMUNITY_IMAGE_FILE";
export const UPLOAD_COMMUNITY_SERVICE_IMAGE_FILE = "UPLOAD_COMMUNITY_SERVICE_IMAGE_FILE";
export const UPLOAD_USER_PROFILE_IMAGE_FILE = "UPLOAD_USER_PROFILE_IMAGE_FILE";

// Locations
export const LOAD_LOCATIONS = "LOAD_LOCATIONS";

// Dashboard
export const SELECT_LOCATION_ON_DASHBOARD = "SELECT_LOCATION_ON_DASHBOARD";
export const CLEAR_GLOBAL_INSIGHTS = "CLEAR_GLOBAL_INSIGHTS";
export const SELECT_REPORT = "SELECT_REPORT";
export const CLEAR_SELECTED_REPORT = "CLEAR_SELECTED_REPORT";
export const SELECT_CATEGORY_ON_DASHBOARD = "SELECT_CATEGORY_ON_DASHBOARD";
export const LOAD_GLOBAL_INSIGHT_MAP_DISTRIBUTION = "LOAD_GLOBAL_INSIGHT_MAP_DISTRIBUTION";
export const LOAD_GLOBAL_INSIGHT_CATEGORY_DISTRIBUTION = "LOAD_GLOBAL_INSIGHT_CATEGORY_DISTRIBUTION";
export const LOAD_GLOBAL_INSIGHT_LOCATION_DISTRIBUTION = "LOAD_GLOBAL_INSIGHT_LOCATION_DISTRIBUTION";
export const LOAD_GLOBAL_INSIGHT_COMMUNITY_TOTALS = "LOAD_GLOBAL_INSIGHT_COMMUNITY_TOTALS";
export const LOAD_GLOBAL_INSIGHT_SOCIAL_MEDIA_DISTRIBUTION = "LOAD_GLOBAL_INSIGHT_SOCIAL_MEDIA_DISTRIBUTION";
export const LOAD_GLOBAL_INSIGHT_ENGAGEMENT_DISTRIBUTION = "LOAD_GLOBAL_INSIGHT_ENGAGEMENT_DISTRIBUTION";
export const LOAD_GLOBAL_INSIGHT_EVENTS_VS_PARTICIPANTS_DISTRIBUTION = "LOAD_GLOBAL_INSIGHT_EVENTS_VS_PARTICIPANTS_DISTRIBUTION";
export const LOAD_GLOBAL_INSIGHT_TOPIC_DISTRIBUTION = "LOAD_GLOBAL_INSIGHT_TOPIC_DISTRIBUTION";
export const LOAD_GLOBAL_INSIGHT_AVERAGE_PARAMETERS = "LOAD_GLOBAL_INSIGHT_AVERAGE_PARAMETERS";

// Reports
export const LOAD_REPORTS = "LOAD_REPORTS";
export const CREATE_REPORT = "CREATE_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";
export const LOAD_REPORT_DETAILS = "LOAD_REPORT_DETAILS";
export const LOAD_COMMUNITIES_OF_REPORT = "LOAD_COMMUNITIES_OF_REPORT";
export const INCREMENT_COMMUNITIES_OF_REPORT_PAGE = "INCREMENT_COMMUNITIES_OF_REPORT_PAGE";
export const DECREMENT_COMMUNITIES_OF_REPORT_PAGE = "DECREMENT_COMMUNITIES_OF_REPORT_PAGE";
export const INCREMENT_REPORTS_PAGE = "INCREMENT_REPORTS_PAGE";
export const DECREMENT_REPORTS_PAGE = "DECREMENT_REPORTS_PAGE";

// Networks
export const SELECT_COMMUNITY_NETWORK = "SELECT_COMMUNITY_NETWORK";
export const CLEAR_SELECTED_COMMUNITY_NETWORK = "CLEAR_SELECTED_COMMUNITY_NETWORK";
export const LOAD_COMMUNITIES_OF_NETWORK = "LOAD_COMMUNITIES_OF_NETWORK";
export const INCREMENT_COMMUNITIES_OF_NETWORK_PAGE = "INCREMENT_COMMUNITIES_OF_NETWORK_PAGE";
export const DECREMENT_COMMUNITIES_OF_NETWORK_PAGE = "DECREMENT_COMMUNITIES_OF_NETWORK_PAGE";

// Channels
export const ADD_CHANNEL_TO_LIST_OF_CHANNELS = "ADD_CHANNEL_TO_LIST_OF_CHANNELS";
export const CREATE_CHANNEL = "CREATE_CHANNEL";
export const CONFIRM_CHANNEL = "CONFIRM_CHANNEL";
export const DECLINE_CHANNEL = "DECLINE_CHANNEL";
export const CLEAR_SELECTED_CHANNEL = "CLEAR_SELECTED_CHANNEL";
export const LOAD_CHANNELS = "LOAD_CHANNELS";
export const LOAD_MESSAGES = "LOAD_MESSAGES";
export const INCREMENT_CHANNELS_PAGE = "INCREMENT_CHANNELS_PAGE";
export const INCREMENT_MESSAGES_PAGE = "INCREMENT_MESSAGES_PAGE";
export const SELECT_CURRENT_CHANNEL = "SELECT_CURRENT_CHANNEL";
export const SEARCH_CHANNELS = "SEARCH_CHANNELS";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const PUBNUB_LOAD_MESSAGE = "PUBNUB_LOAD_MESSAGE";
export const MARK_CHANNEL_READ = "MARK_CHANNEL_READ";
export const SET_WINDOW_FOCUS = "SET_WINDOW_FOCUS";

// Campaigns
export const LOAD_CAMPAIGNS = "LOAD_CAMPAIGNS";
export const INCREMENT_CAMPAIGNS_PAGE = "INCREMENT_CAMPAIGNS_PAGE";
export const DECREMENT_CAMPAIGNS_PAGE = "DECREMENT_CAMPAIGNS_PAGE";
export const CLEAR_SELECTED_CAMPAIGN = "CLEAR_SELECTED_CAMPAIGN";
export const LOAD_CAMPAIGN_DETAILS = "LOAD_CAMPAIGN_DETAILS";
export const LOAD_CAMPAIGN_COMMUNITIES = "LOAD_CAMPAIGN_COMMUNITIES";
export const LOAD_CAMPAIGN_EMAILS = "LOAD_CAMPAIGN_EMAILS";
export const ADD_EMAIL = "ADD_EMAIL";
export const SAVE_COMMUNITY_EMAIL = "SAVE_COMMUNITY_EMAIL";
export const SAVE_CONTACTED_MEMBER_EMAIL = "SAVE_CONTACTED_MEMBER_EMAIL";
export const SAVE_NOT_CONTACTED_MEMBER_EMAIL = "SAVE_NOT_CONTACTED_MEMBER_EMAIL";
export const UPDATE_CAMPAIGN_EMAIL_ORDER = "UPDATE_COMMUNITY_EMAILS_ORDER";
export const DELETE_EMAIL = "DELETE_EMAIL";
export const DELETE_LOCAL_EMAIL = "DELETE_LOCAL_EMAIL";
export const INCREMENT_CAMPAIGN_COMMUNITIES_PAGE = "INCREMENT_CAMPAIGN_COMMUNITIES_PAGE";
export const DECREMENT_CAMPAIGN_COMMUNITIES_PAGE = "DECREMENT_CAMPAIGN_COMMUNITIES_PAGE";
export const LOAD_COMMUNITY_MEMBERS = "LOAD_COMMUNITY_MEMBERS";
export const INCREMENT_MEMBERS_OF_COMMUNITY_PAGE = "INCREMENT_MEMBERS_OF_COMMUNITY_PAGE";
export const TOGGLE_LINKEDIN_PROFILE_VERIFICATION = "TOGGLE_LINKEDIN_PROFILE_VERIFICATION";
export const TOGGLE_COMMUNITY_CONTAINER = "TOGGLE_COMMUNITY_CONTAINER";
export const TOGGLE_COMMUNITY_MEMBER_CONTAINER = "TOGGLE_COMMUNITY_MEMBER_CONTAINER";
export const TOGGLE_COMMUNITY_MEMBER_HIDDEN_PROFILES = "TOGGLE_COMMUNITY_MEMBER_HIDDEN_PROFILES";
export const CONFIRM_CAMPAIGN_PROFILES = "CONFIRM_CAMPAIGN_PROFILES";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CANCEL_CAMPAIGN = "CANCEL_CAMPAIGN";
export const VERIFY_ALREADY_CONTACTED_COMMUNITIES = "VERIFY_ALREADY_CONTACTED_COMMUNITIES";
export const CLOSE_CONFIRM_CONTACTED_COMMUNITIES_MODAL = "CLOSE_CONFIRM_CONTACTED_COMMUNITIES_MODAL";
export const SET_CONTACT_MODE = "SET_CONTACT_MODE";
export const SEND_TO_MAIL_MARKETING_TOOL = "SEND_TO_MAIL_MARKETING_TOOL";
export const GET_MAIL_MARKETING_PLACEHOLDERS = "GET_MAIL_MARKETING_PLACEHOLDERS";

// Jobs
export const LOAD_JOBS = "LOAD_JOBS";
export const INCREMENT_JOB_LIST_PAGE = "INCREMENT_JOB_LIST_PAGE";
export const DECREMENT_JOB_LIST_PAGE = "DECREMENT_JOB_LIST_PAGE";
export const GET_JOB_DETAILS = "GET_JOB_DETAILS";
export const CHANGE_BOUNTY_PORTION = "CHANGE_BOUNTY_PORTION";
export const SET_SELECTED_COMMUNITY = "SET_SELECTED_COMMUNITY";
export const GET_REFERRER_DETAILS = "GET_REFERRER_DETAILS";
export const SET_JOB_BOARD_TOKEN = "SET_JOB_BOARD_TOKEN";
export const SET_JOB_TOKEN = "SET_JOB_TOKEN";
export const REFER_JOB = "REFER_JOB";
export const TOGGLE_REFER_JOB_MODAL = "TOGGLE_REFER_JOB_MODAL";
export const TOGGLE_SUCCESS_REFER_JOB_MODAL = "TOGGLE_SUCCESS_REFER_JOB_MODAL";
export const APPLY_TO_JOB = "APPLY_TO_JOB";
export const TOGGLE_APPLY_TO_JOB_MODAL = "TOGGLE_APPLY_TO_JOB_MODAL";
export const TOGGLE_SUCCESS_APPLY_TO_JOB_MODAL = "TOGGLE_SUCCESS_APPLY_TO_JOB_MODAL";
export const RESET_JOB_BOARD = "RESET_JOB_BOARD";
export const TOGGLE_REMOTE_JOB_FILTER = "TOGGLE_REMOTE_JOB_FILTER";
export const TOGGLE_MY_COMPANY_JOB_FILTER = "TOGGLE_MY_COMPANY_JOB_FILTER";
export const TOGGLE_COMMUNITY_REDEEM_MODAL = "TOGGLE_COMMUNITY_REDEEM_MODAL";
export const SUBSCRIBE_TO_JOB_BOUNTIES = "SUBSCRIBE_TO_JOB_BOUNTIES";
export const SUBSCRIBE_TO_JOB_BOUNTIES_VIA_CTA = "SUBSCRIBE_TO_JOB_BOUNTIES_VIA_CTA";
export const TOGGLE_SUBSCRIBE_TO_JOBS_MODAL = "TOGGLE_SUBSCRIBE_TO_JOBS_MODAL";
export const GET_JOB_APPLICATION_FORM_DETAILS = "GET_JOB_APPLICATION_FORM_DETAILS";
export const UPDATE_JOB_APPLICATION_FORM_DATA = "UPDATE_JOB_APPLICATION_FORM_DATA";
export const SAVE_JOB_APPLICATION_FORM = "SAVE_JOB_APPLICATION_FORM";

// Job Applicants
export const DECREMENT_JOB_APPLICANTS_LIST_PAGE = "DECREMENT_JOB_APPLICANTS_LIST_PAGE";
export const INCREMENT_JOB_APPLICANTS_LIST_PAGE = "INCREMENT_JOB_APPLICANTS_LIST_PAGE";
export const LOAD_JOB_APPLICANTS = "LOAD_JOB_APPLICANTS";
export const RESET_JOB_APPLICANTS_LIST = "RESET_JOB_APPLICANTS_LIST";
export const SELECT_JOB_TO_SEARCH_APPLICANTS = "SELECT_JOB_TO_SEARCH_APPLICANTS";
export const UNSELECT_JOB_TO_SEARCH_APPLICANTS = "UNSELECT_JOB_TO_SEARCH_APPLICANTS";
export const TOGGLE_APPLICATION_FORM_MODAL = "TOGGLE_APPLICATION_FORM_MODAL";
export const TOGGLE_APPLICATION_UPDATE_MODAL = "TOGGLE_APPLICATION_UPDATE_MODAL";
export const GET_JOB_APPLICATION_DETAILS = "GET_JOB_APPLICATION_DETAILS";
export const UPDATE_JOB_APPLICATION = "UPDATE_JOB_APPLICATION";
export const SEARCH_COMPANY_TO_SEARCH_APPLICANTS = "SEARCH_COMPANY_TO_SEARCH_APPLICANTS";
export const SELECT_COMPANY_TO_SEARCH_APPLICANTS = "SELECT_COMPANY_TO_SEARCH_APPLICANTS";
export const UNSELECT_COMPANY_TO_SEARCH_APPLICANTS = "UNSELECT_COMPANY_TO_SEARCH_APPLICANTS";
export const LOAD_FULL_JOBS_LIST_TO_SEARCH_APPLICANTS = "LOAD_FULL_JOBS_LIST_TO_SEARCH_APPLICANTS";
export const SET_SORTING_CONDITION_FOR_JOB_APPLICATIONS = "SET_SORTING_CONDITION_FOR_JOB_APPLICATIONS";

// Filters
export const UNSELECT_LOCATION_TO_FILTER = "UNSELECT_LOCATION_TO_FILTER";
export const SELECT_LOCATION_TO_FILTER = "SELECT_LOCATION_TO_FILTER";
export const SEARCH_LOCATION_TO_FILTER = "SEARCH_LOCATION_TO_FILTER";
export const RESET_ALL_LOCATION_FILTERS = "RESET_ALL_LOCATION_FILTERS";
export const SEARCH_TOPIC_TO_FILTER = "SEARCH_TOPIC_TO_FILTER";
export const SELECT_TOPIC_TO_FILTER = "SELECT_TOPIC_TO_FILTER";
export const UNSELECT_TOPIC_TO_FILTER = "UNSELECT_TOPIC_TO_FILTER";
export const RESET_ALL_TOPIC_FILTERS = "RESET_ALL_TOPIC_FILTERS";

// oauth
export const CONNECT_SLACK = "CONNECT_SLACK";
export const DISCONNECT_SLACK = "DISCONNECT_SLACK";
export const SET_SLACK_STATUS = "SET_SLACK_STATUS";
export const CONNECT_DISCORD = "CONNECT_DISCORD";
export const SET_DISCORD_STATUS = "SET_DISCORD_STATUS";
export const DISCONNECT_DISCORD = "DISCONNECT_DISCORD";

// Solana Wallet
export const SOLANA_WALLET_SET_WALLET_FOUND = "SOLANA_WALLET_SET_WALLET_FOUND";
export const SOLANA_WALLET_SET_WALLET_PUBLIC_KEY = "SOLANA_WALLET_SET_WALLET_PUBLIC_KEY";
export const SOLANA_WALLET_GET_TRANSACTIONS_LIST = "SOLANA_WALLET_GET_TRANSACTIONS_LIST";
export const SOLANA_WALLET_INCREMENT_TRANSACTIONS_LIST_PAGE = "SOLANA_WALLET_INCREMENT_TRANSACTIONS_LIST_PAGE";
export const SOLANA_WALLET_CLEAR_TRANSACTIONS_LIST = "SOLANA_WALLET_CLEAR_TRANSACTIONS_LIST";
export const SOLANA_GET_PRICE = "SOLANA_GET_PRICE";
export const SOLANA_CONFIRM_PAYMENT = "SOLANA_CONFIRM_PAYMENT";
