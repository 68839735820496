import React from 'react';
import {Container, Row, Col, Breadcrumb, BreadcrumbItem} from 'reactstrap'
import {Link} from 'react-router-dom'

const Breadcrumbs = (props) => {
    return (
        <Container fluid={true}>
            <div className="page-header">
                <Row>
                    <Col lg="6" className="main-header">
                        <h2>{props.title}<span></span></h2>
                        {props.subTitle && (<h6 className="mb-0">{props.subTitle}</h6>)}

                    </Col>
                    {props.hideBreadcrumbRight == undefined && (
                        <Col lg="6" className="breadcrumb-right">
                            <Breadcrumb>
                                <BreadcrumbItem><Link to="/"><i className="pe-7s-home"></i></Link></BreadcrumbItem>
                                {props.parent && (<BreadcrumbItem>{props.parent}</BreadcrumbItem>)}
                                <BreadcrumbItem active>{props.title}</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    )}

                    {props.actions && (
                        <Col lg="6" className="breadcrumb-right text-right">
                            {props.actions()}
                        </Col>
                    )}
                </Row>
                {props.underTitle && (
                    <Row>
                        <Col>
                            {props.underTitle()}
                        </Col>
                    </Row>
                )}
            </div>
        </Container>
    );
}

export default Breadcrumbs;
