import React from 'react';

class ShowMoreComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown_open: false,
        };
    }

    render() {
        const {closedText, openText, children} = this.props;
        const {dropdown_open} = this.state;

        return (
            <div>
                {dropdown_open && children}
                <div className={"purple-text pointer mt-1"}
                     onClick={() => this.setState({
                        dropdown_open: !dropdown_open
                })}>
                    <i className="fa fa-caret-down"
                       style={{
                           transition: "transform 300ms ease",
                           transform: `${dropdown_open ? "rotate(180deg)" : "rotate(0deg)"}`
                       }}/>
                    {" "}{dropdown_open ? openText : closedText}
                </div>
            </div>
        )
    }
}

export default ShowMoreComponent;