import React from "react";
import {ArrowDown, ArrowUp} from "react-feather";
import {connect} from "react-redux";
import {loadJobApplicants, resetJobApplicants, setSortingConditionForJobApplications} from "../../../redux/job_applicants/actions";

class OrderJobApplicationsList extends React.Component {
    render() {
        const {currentUser, currentField, setSortingConditionForJobApplications, sorting, loadJobApplicants, resetJobApplicants, loading} = this.props;

        if (!currentUser) {
            return null;
        }

        function isSelectedOrder(direction) {
            return sorting.field === currentField && sorting.direction === direction;
        }

        function isSelectedOrderAsc() {
            return isSelectedOrder("ASC");
        }

        function isSelectedOrderDesc() {
            return isSelectedOrder("DESC");
        }

        return (
            <div className={"d-flex m-l-5"}>
                <div className={"pointer d-flex align-items-end"}>
                    <ArrowUp color={isSelectedOrderAsc() ? "#1391BB" : "black"} width={15} onClick={() => {
                        if (!loading && !isSelectedOrderAsc()) {
                            setSortingConditionForJobApplications(currentField, "ASC");
                            resetJobApplicants();
                            loadJobApplicants();
                        }
                    }}/>
                </div>
                <div className={"pointer d-flex align-items-end"}>
                    <ArrowDown width={15} color={isSelectedOrderDesc() ? "#1391BB" : "black"} onClick={() => {
                        if (!loading && !isSelectedOrderDesc()) {
                            setSortingConditionForJobApplications(currentField, "DESC");
                            resetJobApplicants();
                            loadJobApplicants();
                        }
                    }}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {loadJobApplicants, resetJobApplicants, setSortingConditionForJobApplications};

const mapStateToProps = (state, ownProps) => ({
    loading: state.job_applicants.loading,
    sorting: state.job_applicants.sorting,
    currentUser: state.user.data,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderJobApplicationsList)
