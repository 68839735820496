import {
    JOB_APPLICATION_STATUS_APPLIED,
    JOB_APPLICATION_STATUS_HIRED,
    JOB_APPLICATION_STATUS_INVITED_FOR_INTERVIEW,
    JOB_APPLICATION_STATUS_REJECTED,
    JOB_APPLICATION_STATUS_REJECTED_BY_ADMIN,
    JOB_APPLICATION_STATUS_REVIEWED_BY_ADMIN,
    JOB_APPLICATION_STATUS_SENT_OFFER
} from "../../../constants";

const JobApplicationStatusViewer = (props) => {
    const status = props.status;
    const user = props.user;

    switch (status) {
        case JOB_APPLICATION_STATUS_APPLIED:
            return "Applied";
        case JOB_APPLICATION_STATUS_REJECTED_BY_ADMIN:
            return "Rejected By Admin";
        case JOB_APPLICATION_STATUS_REVIEWED_BY_ADMIN:
            return user && user.is_super_admin ? "Reviewed By Admin" : "Applied";
        case JOB_APPLICATION_STATUS_REJECTED:
            return "Rejected";
        case JOB_APPLICATION_STATUS_INVITED_FOR_INTERVIEW:
            return "Invited for Interview";
        case JOB_APPLICATION_STATUS_SENT_OFFER:
            return "Sent Offer";
        case JOB_APPLICATION_STATUS_HIRED:
            return "Hired";
        default:
            return "";
    }
}

export default JobApplicationStatusViewer;