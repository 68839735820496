import { LOAD_CUSTOMER, UPDATE_CUSTOMER, LOAD_SUBSCRIPTIONS, UPDATE_CUSTOMER_COUNTRY, CANCEL_SUBSCRIPTION_RENEW } from "../action_types";

export function loadCustomer() {
    return {
        type: LOAD_CUSTOMER,
        api: true
    }
}

export function updateCustomer(customer) {
    return {
        type: UPDATE_CUSTOMER,
        api: true,
        payload: customer,
        fetch_safe: true,
    }
}

export function loadSubscriptions() {
    return {
        type: LOAD_SUBSCRIPTIONS,
        api: true
    }
}

export function updateCountry(country) {
    return {
        type: UPDATE_CUSTOMER_COUNTRY,
        payload: country
    }
}

export function cancelSubscriptionRenew(id) {
    return {
        type: CANCEL_SUBSCRIPTION_RENEW,
        api: true,
        payload: {
            id: id
        }
    }
}
