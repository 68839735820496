import React from "react";

class InfoCustomFields extends React.Component {
    render() {
        const {customField} = this.props;

        return (
            <div className="m-b-10">
                <p>{customField.title}</p>
            </div>
        );
    }
}

export default InfoCustomFields;