import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Button
} from 'reactstrap';
import {
    getSolanaPrice,
    solanaConfirmPayment
} from "../../redux/solana/actions";
import SolanaLogo from "../../assets/images/solana-logo.png";
import ConnectSolanaWalletComponent from "../common/solana/ConnectSolanaWalletComponent";
import * as web3 from '@solana/web3.js';

class SolanaTransactionsPayPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            receiver_key: null,
            bounty: null,
            entity_id: null,
            entity_type: null,
            paying: false,
            payment_status: null
        };
    }

    componentDidMount() {
        const {price} = this.props;
        const {value, loading} = price;
        const {getSolanaPrice} = this.props;

        this.setState({
            receiver_key: this.props.routeParams.receiverKey,
            bounty: this.props.routeParams.bounty,
            entity_id: this.props.routeParams.entityId,
            entity_type: this.props.routeParams.entityType,
        });

        if (!loading && !value) {
            getSolanaPrice();
        }
    }

    async getProvider() {
        if ("solana" in window) {
            const provider = window.solana;
            if (provider.isPhantom) {
                console.log("Is Phantom installed?  ", provider.isPhantom);
                return provider;
            }
        } else {
            window.open("https://www.phantom.app/", "_blank");
        }
    };

    async payTransaction(priceInSol) {
        try {
            const {solanaConfirmPayment} = this.props;
            const {receiver_key, entity_id, entity_type} = this.state;

            const provider = await this.getProvider();

            // Establishing connection
            const connection = new web3.Connection(
                web3.clusterApiUrl('mainnet-beta'),
            );

            // I have hardcoded my secondary wallet address here. You can take this address either from user input or your DB or wherever
            const recieverWallet = new web3.PublicKey(receiver_key);

            const transaction = new web3.Transaction().add(
                web3.SystemProgram.transfer({
                    fromPubkey: provider.publicKey,
                    toPubkey: recieverWallet,
                    lamports: web3.LAMPORTS_PER_SOL * priceInSol //Investing 1 SOL. Remember 1 Lamport = 10^-9 SOL.
                }),
            );

            this.setState({
                "paying": true
            });

            // Setting the variables for the transaction
            transaction.feePayer = await provider.publicKey;
            let blockhashObj = await connection.getRecentBlockhash();
            transaction.recentBlockhash = await blockhashObj.blockhash;

            // Transaction constructor initialized successfully
            if (transaction) {
                console.log("Txn created successfully");
            }

            // Request creator to sign the transaction (allow the transaction)
            let signed = await provider.signTransaction(transaction);
            // The signature is generated
            let signature = await connection.sendRawTransaction(signed.serialize());
            // Confirm whether the transaction went through or not

            // show PAYING LOADING ICON
            await connection.confirmTransaction(signature);

            //Print the signature here
            // CONFIRM THE PAYMENT
            this.setState({
                "paying": false,
                "payment_status": true
            });

            // save transaction
            solanaConfirmPayment(entity_id, entity_type, signature);
        } catch (e) {
            this.setState({
                "paying": false,
                "payment_status": false
            });
        }
    }

    renderPayButton(priceInSol) {
        const {wallet_public_key} = this.props;
        const {paying, payment_status} = this.state;

        if (paying) {
            return (
                <Button color={"primary"} outline={true} size={"lg"} disabled={true}>
                    <img src={SolanaLogo} width={25}/> Paying <i className={"fa fa-spinner fa-spin"}/>
                </Button>
            );
        }

        if (payment_status === true) {
            return (
                <Row>
                    <Col className={"d-flex flex-column justify-content-center align-items-center"}>
                        <i className={"fa fa-5x fa-check-circle text-success"}/>
                        <h3>Transaction paid successfully</h3>
                    </Col>
                </Row>
            );
        } else if (payment_status === false) {
            return (
                <Row>
                    <Col className={"d-flex flex-column justify-content-center align-items-center"}>
                        <i className={"fa fa-5x fa-times-circle text-danger"}/>
                        <h3>Transaction not completed. Please try again</h3>
                    </Col>
                </Row>
            );
        }

        if (wallet_public_key) {
            return (
                <Row>
                    <Col className={"d-flex justify-content-center"}>
                        <Button color={"primary"} outline={true} size={"lg"} onClick={() => this.payTransaction(priceInSol)}>
                            <img src={SolanaLogo} width={25}/> Pay <u>{priceInSol} SOL</u>
                        </Button>
                    </Col>
                </Row>
            );
        }
    }

    buildResponse() {
        const {price, wallet_public_key} = this.props;
        const {receiver_key, bounty, paying, payment_status} = this.state;

        if (price.loading) {
            return (<h3>Loading...</h3>);
        }

        if (!price.value && !price.loading) {
            return (<h3>There was a problem. Please close the page and try again!</h3>);
        }

        if (price.value && !price.loading) {
            const priceInSol = Math.round((bounty / price.value)*1000) / 1000;

            return (
                <>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader><h5>Receiver Wallet</h5></CardHeader>
                                <CardBody>
                                    <a target="_blank"
                                       href={`https://explorer.solana.com/address/${receiver_key}?cluster=mainnet-beta`}>{receiver_key}</a>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardHeader><h5>Solana Price</h5></CardHeader>
                                <CardBody>1 SOL = {price.value} EURO</CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardHeader><h5>Bounty</h5></CardHeader>
                                <CardBody>
                                    {bounty} EURO / SOL {priceInSol}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={"mb-4"}>
                        <Col className={"d-flex justify-content-center"}>
                            <ConnectSolanaWalletComponent/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"d-flex justify-content-center"}>
                            {this.renderPayButton(priceInSol)}
                        </Col>
                    </Row>
                </>
            );
        }

        return null;
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Pay Transaction"/>
                <Container fluid={true}>
                    {this.buildResponse()}
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {getSolanaPrice, solanaConfirmPayment};

const mapStateToProps = (state, ownProps) => ({
    wallet_public_key: state.solana.wallet_public_key,
    price: state.solana.price
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SolanaTransactionsPayPage)
