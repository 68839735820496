import {
    CREATE_REPORT,
    DECREMENT_COMMUNITIES_OF_REPORT_PAGE,
    DELETE_REPORT,
    INCREMENT_COMMUNITIES_OF_REPORT_PAGE,
    LOAD_COMMUNITIES_OF_REPORT,
    LOAD_REPORT_DETAILS,
    LOAD_REPORTS,
    INCREMENT_REPORTS_PAGE,
    DECREMENT_REPORTS_PAGE
} from "../action_types";

export function loadReports() {
    return {
        type: LOAD_REPORTS,
        api: true,
        fetch_safe: true,
    }
}

export function createReport(report) {
    return {
        type: CREATE_REPORT,
        payload: report,
        api: true
    }
}

export function deleteReport(id) {
    return {
        type: DELETE_REPORT,
        payload: {id},
        api: true
    }
}

export function loadReportDetails(id) {
    return {
        type: LOAD_REPORT_DETAILS,
        payload: {id},
        api: true,
        fetch_safe: true,
    }
}

export function loadCommunitiesOfReport(id) {
    return {
        type: LOAD_COMMUNITIES_OF_REPORT,
        api: true,
        payload: {
            id: id
        },
        fetch_safe: true,
    }
}

export function incrementCommunitiesOfReportPage() {
    return {
        type: INCREMENT_COMMUNITIES_OF_REPORT_PAGE,
    }
}

export function decrementCommunitiesOfReportPage() {
    return {
        type: DECREMENT_COMMUNITIES_OF_REPORT_PAGE,
    }
}

export function incrementReportsPage() {
    return {
      type: INCREMENT_REPORTS_PAGE,
    }
}
  
export function decrementReportsPage() {
    return {
      type: DECREMENT_REPORTS_PAGE,
    }
}

