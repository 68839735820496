import React from "react";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {connect} from "react-redux";
import RegisterForm from "../../auth/register_form";
import LoginForm from "../../auth/login_form";

class CommunityRedeemAuthenticationModal extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            active_tab: "1",
        };
    }

    setActiveTab(id) {
        this.setState({
            active_tab: id
        })
    }

    render() {
        const {currentUser, isModalOpen, toggleModal} = this.props;
        const {active_tab} = this.state;

        return(
            <Modal isOpen={isModalOpen && !currentUser}>
                <ModalHeader toggle={()=> {toggleModal()}}>
                    <Nav className={"d-flex"} tabs>
                        <NavItem>
                            <NavLink
                                className={active_tab === "1" ? "active" : ""}
                                onClick={() => this.setActiveTab("1")}
                            >
                                Register
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={active_tab === "2" ? "active" : ""}
                                onClick={() => this.setActiveTab("2")}
                            >
                                Login
                            </NavLink>
                        </NavItem>
                    </Nav>
                </ModalHeader>
                <ModalBody>
                    <TabContent activeTab={active_tab}>
                        <TabPane className="fade show" tabId="1">
                            <RegisterForm isRedeeming={true} />
                        </TabPane>
                        <TabPane className="fade show" tabId="2">
                            <LoginForm isRedeeming={true} />
                        </TabPane>
                    </TabContent>
                </ModalBody>
            </Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.data,
});

export default connect(mapStateToProps, null)(CommunityRedeemAuthenticationModal)