import {LOAD_LOCATIONS} from "../action_types";

const initial_state = {
    list: []
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case LOAD_LOCATIONS.concat("_SUCCESS"):
            return {...state, list: action.payload.data};
        default:
            return {...state};
    }
}
