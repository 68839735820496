import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import {Container} from 'reactstrap';
import {
    clearSelectedCommunity,
    getCommunityDetails,
    getCommunityDetailsByAccessToken,
    toggleContactCommunityModal
} from '../../redux/community/actions';
import CommunityHeader from "./components/header";
import {useLocation} from "react-router";
import AddToListModal from "./components/add_to_community_list_modal";
import {createWelcomeMessage} from '../../redux/channel/actions';
import CommunityProfileComponent from "./components/community_profile_component";
import NetworkCommunityProfile from "./components/network_community_profile";
import ContactCommunityModal from "./components/contact_community_modal";

class CommunityDetailsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            add_community_to_list_modal_is_open: false,
            message: "",
            charLimit: 1000,
        };
    }

    componentDidMount() {
        // load the community profile
        if (this.props.routeParams.id) {
            this.props.getCommunityDetails(this.props.routeParams.id);
        }

        if (this.props.routeParams.token) {
            this.props.getCommunityDetailsByAccessToken(this.props.routeParams.token);
        }
    }

    componentWillUnmount() {
        this.props.clearSelectedCommunity();
    }

    openAddCommunityToListModal() {
        this.setState({
            add_community_to_list_modal_is_open: true
        })
    }

    renderLoading() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Loading...</h4>
            </div>
        );
    }

    renderInvalidCommunity() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Invalid community :(</h4>
            </div>
        );
    }

    renderCommunityProfile(community) {
        const {currentUser, toggleContactCommunityModal, contact_community_modal_is_open, is_screenshot} = this.props;

        return (
            <div>
                {this.state.add_community_to_list_modal_is_open && <AddToListModal
                    inActivityDetail={true}
                    idsToAddToList={[community.id]}
                    closeModal={() => {
                        this.setState({
                            add_community_to_list_modal_is_open: false
                        })}
                    }/>}
                {contact_community_modal_is_open && <ContactCommunityModal community={community}/>}

                <Container fluid={true} className={"community-details"}>
                    <CommunityHeader currentUser={currentUser} community={community}
                                     onAddButtonClick={() => {this.openAddCommunityToListModal();}}
                                     contactCommunity={() => {toggleContactCommunityModal();}}/>
                    <CommunityProfileComponent community={community} is_screenshot={is_screenshot} />
                </Container>
            </div>
        );
    }

    renderNetworkProfile(community) {
        return (
            <NetworkCommunityProfile communityId={this.props.routeParams.id} community={community}/>
        )
    }

    render() {
        const {selectedCommunity} = this.props;

        return(
            <div>
                {selectedCommunity.loading && this.renderLoading()}
                {!selectedCommunity.loading && selectedCommunity.data &&
                (<Fragment>
                    <BuildBreadcrumb title={selectedCommunity.data.is_network ? "Network" : "Community"}/>
                    {!selectedCommunity.data.is_network && this.renderCommunityProfile(selectedCommunity.data)}
                    {selectedCommunity.data.is_network && this.renderNetworkProfile(selectedCommunity.data)}
                </Fragment>)}
                {!selectedCommunity.loading && !selectedCommunity.data && this.renderInvalidCommunity()}
            </div>
        )
    }
}

const mapDispatchToProps = {
    createWelcomeMessage,
    getCommunityDetails,
    getCommunityDetailsByAccessToken,
    clearSelectedCommunity,
    toggleContactCommunityModal,
};

const mapStateToProps = (state, ownProps) => ({
    selectedCommunity: state.community.selected_community,
    currentUser: state.user.data,
    communityList: state.community.list,
    fullCommunityLists: state.community_list.full_list,
    customerData: state.customer.data,
    contact_community_modal_is_open: state.community.contact_community_modal_is_open,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityDetailsPage)


function BuildBreadcrumb(props) {
    const location = useLocation();

    if (location.pathname.indexOf("/preview-community/") > -1) {
        return (
            <div className={"d-flex pt-3 pl-3 pb-3 align-items-center justify-content-end"}>
                <span className={"mr-2"}>Powered by </span>
                <a href="https://thecommunityatlas.com" target="_blank" rel="noopener noreferrer">
                    <img height="40" src={require("../../assets/images/logo/logo.png")} alt={"logo"}/>
                </a>
            </div>
        );
    }

    return (<Breadcrumb title={`${props.title} Details`}/>);
}
