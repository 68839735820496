import {connect} from "react-redux";
import {uploadUserProfileImageFile} from '../../../redux/file/actions'
import React from "react";
import ImageUploader from "./image_uploader";

class UserProfileImageUploader extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.file !== this.props.file){
            this.props.onNewImage(this.props.file);
        }
    }

    render() {
        const {uploadUserProfileImageFile, isDisabled} = this.props;

        return (
            <ImageUploader uploadFile={(file) => uploadUserProfileImageFile(file)} isDisabled={isDisabled}>
                {this.props.children}
            </ImageUploader>
        );
    }
}

const mapDispatchToProps = {uploadUserProfileImageFile};

const mapStateToProps = (state, ownProps) => ({
    file: state.file.user_profile_image
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfileImageUploader)
