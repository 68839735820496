import React, {Fragment} from 'react';
import Loader from './component/common/loader/loader'
import Header from './component/common/header/header'
import Sidebar from './component/common/sidebar/sidebar'
import Footer from './component/common/footer/footer'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/app.scss';
import {loadLocations} from "./redux/locations/actions";
import {loadCategories} from "./redux/category/actions";
import {loadUser, logoutUser} from "./redux/user/actions";
import {loadCustomer, loadSubscriptions} from "./redux/customer/actions";
import {loadUtils} from "./redux/utils/actions";
import {connect} from "react-redux";
import ReactGA from 'react-ga';
import ConfigDB from '../src/config';
import {useLocation} from "react-router";
import PubnubInstance from "../src/component/utils/pubnub_instance";
import {pubnubLoadMessage, markChannelRead, setWindowFocus} from "./redux/channel/actions";
import {loadLandingPageTopics} from "./redux/dashboard/actions";
import LandingFooter from "./component/common/footer/landing_footer";
import CommunityRedeemBottomBanner from "./component/common/job_board/community_redeem_bottom_banner";
import {getCommunityToRedeem} from "./redux/community/actions";
import InitSolanaWalletComponent from "./component/common/solana/InitSolanaWalletComponent";

class App extends React.Component {

    pubnub = null;

    initPubNub() {
        const {currentUser, pubnubLoadMessage} = this.props;

        this.pubnub = PubnubInstance.instance(currentUser.pubnub_subscriber_channel);
        this.pubnub.addListener({
            message: function(msg) {
                pubnubLoadMessage(msg.message, currentUser);
            },
        });

        this.pubnub.subscribe({
            channels: [currentUser.pubnub_subscriber_channel]
        });
    }

    componentDidMount() {
        const {categoriesList, categoriesLoading, currentUser, userLoading, currentUserToken, loadCategories, loadUser, loadUtils, loadLocations, landingPageTopics, loadLandingPageTopics, redeem_token, getCommunityToRedeem} = this.props;

        loadUtils();
        loadLocations();

        if(!landingPageTopics) {
            loadLandingPageTopics();
        }

        if(redeem_token) {
            getCommunityToRedeem();
        }

        if (!currentUser && !userLoading && currentUserToken) {
            loadUser();
        }

        if (categoriesList.length === 0 && !categoriesLoading) {
            loadCategories();
        }
    }

    render() {
        const{currentUser, currentChannel, setWindowFocus, selectedChannel, markChannelRead} = this.props;
        if(currentUser && !this.pubnub){
           this.initPubNub();
        }

        window.onfocus = function() {
            setWindowFocus(true);
            if(selectedChannel && selectedChannel.total_unread_messages !== 0){
                setTimeout(markChannelRead(currentChannel.id), 1500);
            }
        };
        window.onblur = function() { setWindowFocus(false) }

        ReactGA.initialize("UA-199653725-1");
        ReactGA.pageview(window.location.pathname + window.location.search);
        document.documentElement.className = ConfigDB.data.color.color;

        return (
            <Fragment>
                <Loader/>
                <InitSolanaWalletComponent />

                <div className="page-wrapper">
                    <div className="page-body-wrapper">
                        <BuildHeader logoutUser={this.props.logoutUser} currentUser={this.props.currentUser}
                                     userLoading={this.props.userLoading}/>
                        <BuildSideBar currentUser={this.props.currentUser} redeemToken={this.props.redeem_token} jobBoardToken={this.props.job_board_token}/>

                        <BuildChildren children={this.props.children} />
                        <BuildFooter />
                    </div>
                </div>
                <ToastContainer/>
            </Fragment>
        )
    }
}

const mapDispatchToProps = {
    loadLocations,
    loadLandingPageTopics,
    loadCategories,
    loadUser,
    loadCustomer,
    loadSubscriptions,
    logoutUser,
    loadUtils,
    markChannelRead,
    setWindowFocus,
    pubnubLoadMessage,
    getCommunityToRedeem,
};

const mapStateToProps = (state, ownProps) => ({
    categoriesList: state.category.list,
    categoriesLoading: state.category.loading,
    currentUser: state.user.data,
    currentUserToken: state.user.token,
    currentCustomer: state.customer.data,
    currentChannel: state.channel.current_channel,
    customerLoading: state.customer.loading,
    selectedChannel: state.channel.list.channels[state.channel.current_channel.id],
    userLoading: state.user.loading,
    landingPageTopics: state.community.landing_page_topics.list,
    redeem_token: state.community.redeem_token,
    job_board_token: state.job.job_board_token
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)

function BuildSideBar(props) {
    const location = useLocation();

    if (location.pathname.indexOf("/preview-community/") > -1) {
        return null;
    }

    return (<Sidebar currentUser={props.currentUser} redeemToken={props.redeemToken} jobBoardToken={props.jobBoardToken}/>);
}

function BuildHeader(props) {
    const location = useLocation();

    if (location.pathname.indexOf("/preview-community/") > -1) {
        return null;
    }

    return (<Header userLoading={props.userLoading} currentUser={props.currentUser} onLogout={props.logoutUser}/>);
}

function BuildChildren(props) {
    const location = useLocation();
    var shouldHideSides = false;

    if (location.pathname.indexOf("/preview-community/") > -1) {
        shouldHideSides = true;
    }

    return (
        <div className="page-body" style={shouldHideSides ? {"marginTop": 0, "marginLeft": 0} : {}}>
            {location.pathname.indexOf("/redeem/") === -1 && <CommunityRedeemBottomBanner/>}
            {props.children}
        </div>
    );
}

function BuildFooter() {
    const location = useLocation();

    if(location.pathname === "/" || location.pathname.indexOf("/redeem") > -1) {
        return (
            <LandingFooter />
        )
    }

    return (
        <Footer/>
    )
}

