import {
    ADD_ALL_COMMUNITIES_TO_LISTS,
    ADD_COMMUNITIES_TO_LISTS,
    CLEAR_LIST_DETAILS,
    CREATE_COMMUNITY_LIST,
    CREATE_COMMUNITY_LIST_AND_ADD_COMMUNITIES,
    CREATE_COMMUNITY_LIST_AND_ADD_ALL_COMMUNITIES,
    DECREMENT_LISTS_PAGE,
    INCREMENT_COMMUNITIES_OF_LIST_PAGE,
    INCREMENT_LISTS_PAGE,
    LOAD_COMMUNITIES_OF_LIST,
    LOAD_COMMUNITY_LISTS,
    LOAD_LIST_DETAILS, LOAD_LITE_COMMUNITY_LISTS,
    REMOVE_COMMUNITY_FROM_LIST,
    SEARCH_COMMUNITY_LIST,
    SET_SORTING_CONDITION_FOR_COMMUNITIES_OF_LIST, DECREMENT_COMMUNITIES_OF_LIST_PAGE, DELETE_COMMUNITY_LIST
} from "../action_types";
import {toast} from "react-toastify";

const initial_state = {
    full_list: [],
    list: [],
    page: 0,
    total_pages: 0,
    total_results: 0,
    communities_of_list: {
        communities: [],
        list: null,
        page: 0,
        total_pages: 0,
        total_results: 0,
        sorting: {
            field: null,
            direction: null
        },
        loading: false
    },
    search: {
        searching: false,
        result: []
    },
    loading: true
};

export default (state = initial_state, action) => {
    var communities_of_list;

    switch (action.type) {
        case LOAD_COMMUNITY_LISTS:
            return {...state, loading: true};
        case LOAD_COMMUNITY_LISTS.concat("_SUCCESS"):
            return {
                ...state,
                loading: false,
                list: action.payload.data,
                total_pages: action.payload.total_pages,
                total_results: action.payload.total_results
            };
        case LOAD_LITE_COMMUNITY_LISTS.concat("_SUCCESS"):
            return {
                ...state,
                full_list: action.payload.data,
            };
        case LOAD_COMMUNITY_LISTS.concat("_FAILURE"):
            return {...state, loading: false};
        case LOAD_COMMUNITIES_OF_LIST:
            communities_of_list = Object.assign({}, state.communities_of_list);
            communities_of_list.loading = true;

            return {...state, communities_of_list: communities_of_list};
        case LOAD_COMMUNITIES_OF_LIST.concat("_SUCCESS"):
            communities_of_list = Object.assign({}, state.communities_of_list);
            communities_of_list.communities = action.payload.data;
            communities_of_list.loading = false;
            communities_of_list.total_pages = action.payload.total_pages;
            communities_of_list.total_results = action.payload.total_results;

            return {...state, communities_of_list: communities_of_list};
        case LOAD_COMMUNITIES_OF_LIST.concat("_FAILURE"):
            communities_of_list = Object.assign({}, state.communities_of_list);
            communities_of_list.communities = [];
            communities_of_list.loading = false;
            communities_of_list.total_pages = 0;
            communities_of_list.total_results = 0;

            return {...state, communities_of_list: communities_of_list};
        case LOAD_LIST_DETAILS:
            communities_of_list = Object.assign({}, state.communities_of_list);
            communities_of_list.list = null;

            return {...state, communities_of_list: communities_of_list};
        case LOAD_LIST_DETAILS.concat("_SUCCESS"):
            communities_of_list = Object.assign({}, state.communities_of_list);
            communities_of_list.list = action.payload.data;

            return {...state, communities_of_list: communities_of_list};
        case LOAD_LIST_DETAILS.concat("_FAILURE"):
            communities_of_list = Object.assign({}, state.communities_of_list);
            communities_of_list.list = null;

            return {...state, communities_of_list: communities_of_list};
        case DELETE_COMMUNITY_LIST.concat("_SUCCESS"):
            toast.success("List deleted successfully");
            return {...state};
        case DELETE_COMMUNITY_LIST.concat("_FAILURE"):
            toast.error(action.payload.error);
            return {...state};
        case CLEAR_LIST_DETAILS:
            return {...state, communities_of_list: initial_state.communities_of_list};
        case SET_SORTING_CONDITION_FOR_COMMUNITIES_OF_LIST:
            communities_of_list = Object.assign({}, state.communities_of_list);
            communities_of_list.sorting = action.payload;

            return {...state, communities_of_list: communities_of_list};
        case ADD_COMMUNITIES_TO_LISTS.concat("_SUCCESS"):
            toast.success("Community saved in list");
            return {...state};
        case ADD_ALL_COMMUNITIES_TO_LISTS:
            return {...state};
        case ADD_ALL_COMMUNITIES_TO_LISTS.concat("_SUCCESS"):
            toast.success("Communities saved to list");
            return {...state};
        case REMOVE_COMMUNITY_FROM_LIST.concat("_SUCCESS"):
            toast.success("Community removed from list");
            return {...state};
        case CREATE_COMMUNITY_LIST.concat("_SUCCESS"):
            toast.success("List created");
            return {...state};
        case CREATE_COMMUNITY_LIST_AND_ADD_COMMUNITIES.concat("_SUCCESS"):
            toast.success("List created");
            return {...state};
        case CREATE_COMMUNITY_LIST_AND_ADD_ALL_COMMUNITIES.concat("_SUCCESS"):
            toast.success("List created");
            return {...state};
        case INCREMENT_COMMUNITIES_OF_LIST_PAGE:
            if ((state.communities_of_list.page + 1) === state.communities_of_list.total_pages) {
                return {...state};
            }

            return {
                ...state,
                communities_of_list: {...state.communities_of_list, page: state.communities_of_list.page + 1}
            };
        case DECREMENT_COMMUNITIES_OF_LIST_PAGE:
            if (state.communities_of_list.page === 0) {
                return {...state};
            }

            return {
                ...state,
                communities_of_list: {...state.communities_of_list, page: state.communities_of_list.page - 1}
            };
        case SEARCH_COMMUNITY_LIST:
            return {...state, search: {searching: true, result: []}};
        case SEARCH_COMMUNITY_LIST.concat("_SUCCESS"):
            return {...state, search: {searching: false, result: action.payload.data}};
        case SEARCH_COMMUNITY_LIST.concat("_FAILURE"):
            return {...state, search: {searching: false, result: []}};
        case INCREMENT_LISTS_PAGE:
            if ((state.page + 1) === state.total_pages) {
                return {...state};
            }

            return {
                ...state,
                page: state.page + 1
            };
        case DECREMENT_LISTS_PAGE:
            if (state.page === 0) {
                return {...state};
            }

            return {
                ...state,
                page: state.page - 1
            };
        default:
            return {...state};
    }
}
