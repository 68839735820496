import {
    ADD_TOPIC_IN_COMMUNITY,
    ADD_TOPIC_IN_COMMUNITY_REGISTRATION,
    CHANGE_FILTER_FOR_LIST,
    CLEAR_COMMUNITY_DETAILS,
    CLEAR_COMMUNITY_REDEEM_TOKEN,
    CLEAR_SEARCHED_COMMUNITY_LIST,
    CLEAR_SELECTED_COMMUNITY,
    CLEAR_TOPICS_TO_FILTER_FOR_COMMUNITY,
    CREATE_COMMUNITY,
    CREATE_SERVICE,
    DECREMENT_COMMUNITIES_OF_NETWORK_PAGE,
    DECREMENT_COMMUNITIES_PAGE,
    DELETE_SERVICE,
    DISABLE_DISCORD_ON_EDIT_COMMUNITY,
    DISABLE_SLACK_ON_EDIT_COMMUNITY,
    EDIT_COMMUNITY_LINKS,
    EDIT_SERVICE,
    GET_COMMUNITY_DETAILS,
    GET_COMMUNITY_EDIT_DETAILS,
    GET_COMMUNITY_TO_REDEEM,
    GET_SERVICE_CATEGORIES,
    INCREMENT_COMMUNITIES_OF_NETWORK_PAGE,
    INCREMENT_COMMUNITIES_PAGE,
    LOAD_COMMUNITIES,
    LOAD_COMMUNITIES_OF_NETWORK,
    LOAD_LANDING_PAGE_TOPICS,
    REDEEM_COMMUNITY,
    REMOVE_TOPIC_FROM_COMMUNITY,
    REMOVE_TOPIC_FROM_COMMUNITY_REGISTRATION,
    RESET_COMMUNITIES_PAGE,
    SEARCH_FOR_COMMUNITY,
    SEARCH_FOR_COMMUNITY_NETWORK,
    SEARCH_TOPICS_TO_ADD_IN_COMMUNITY,
    SEARCH_TOPICS_TO_EXCLUDE_FROM_COMMUNITY_SEARCH,
    SEARCH_TOPICS_TO_FILTER_COMMUNITIES,
    SELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY,
    SELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY,
    SELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY,
    SELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH,
    SET_COMMUNITY_REDEEM_TOKEN,
    SET_FREE_SEARCH_FOR_COMMUNITY,
    SET_MIN_MEMBERS_CONDITION_FOR_COMMUNITIES_LIST,
    SET_SORTING_CONDITION,
    TOGGLE_ADD_SERVICE_MODAL,
    TOGGLE_COMMUNITIES_WITH_VERIFIED_ADMINS_FILTER,
    TOGGLE_COMMUNITY_REDEEM_MODAL,
    TOGGLE_CONTACT_COMMUNITY_MODAL,
    TOGGLE_NEW_COMMUNITIES_FILTER,
    TOGGLE_REDEEMED_COMMUNITY_FILTER,
    TOGGLE_SERVICE_DETAILS_MODAL,
    UNSELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY,
    UNSELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY,
    UNSELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY,
    UNSELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH,
    UPDATE_COMMUNITY_CATEGORY,
    VERIFY_EXISTING_MEETUP_COMMUNITY,
    SET_EDIT_COMMUNITY_DATA, UPDATE_COMMUNITY_DATA
} from "../action_types";
import {toast} from "react-toastify";
import cookie from "react-cookies";

function getTopicsCookie() {
    let cookieData = cookie.load("TOPICS");
    if (cookieData !== "null") {
        return cookieData;
    }
    return null;
}

function setTopicsCookie(token) {
    cookie.save("TOPICS", token);
}

function getRedeemTokenCookie() {
    let cookieData = cookie.load("REDEEM_TOKEN");
    if (cookieData !== "null") {
        return cookieData;
    }
    return null;
}

function setRedeemTokenCookie(token, deleting) {
    const COOKIE_EXPIRATION_DAYS = deleting ? 0 : 7;
    let date = new Date();
    date.setTime(date.getTime() + (COOKIE_EXPIRATION_DAYS * 24 * 60 * 60 * 1000));
    cookie.save("REDEEM_TOKEN", token, {path: '/', expires: date});
}

const initial_state = {
    page: 0,
    list: [],
    loading: false,
    sorting: {
        field: null,
        direction: null
    },
    total_pages: 0,
    total_results: 0,
    list_filters: {
        filter_for_networks: false,
        new_communities_filter: false,
        redeemed_community_filter: false,
        with_verified_admins: false
    },
    name_filter: {
        value: "",
    },
    location_filters: {
        searching: false,
        options: [],
        selected_locations: []
    },
    topic_filters: {
        searching: false,
        options: [],
        selected_topics: []
    },
    excluded_topic_filters: {
        searching: false,
        options: [],
        selected_topics: [],
    },
    community_network_filters: {
        searching: false,
        options: [],
        selected_community_networks: []
    },
    category_filters: {
        selected_categories: []
    },
    social_media_filters: {
        selected_socials: [
            {
                name: "Meetup",
                disabled: true,
                value: "meetup"
            }
        ]
    },
    selected_community: {
        loading: false,
        data: null,
        children: {
            list: [],
            page: 0,
            total_pages: 0,
            total_results: 0,
            loading: false
        }
    },
    edit_community: {
        loading: false,
        data: {
            name: "",
            description: "",
            email: "",
            phone_number: "",
            topics: [],
            meetup_url: "",
            category_id: "-1",
            enabled_new_job_emails: "1",
            facebook_page_profiles: [],
            facebook_group_profiles: [],
            twitter_profiles: [],
            instagram_profiles: [],
            bitly_profiles: [],
            discord_profiles: [],
            flickr_profiles: [],
            github_profiles: [],
            google_docs_profiles: [],
            google_plus_profiles: [],
            linkedin_profiles: [],
            medium_profiles: [],
            reddit_profiles: [],
            slack_profiles: [],
            telegram_profiles: [],
            youtube_profiles: [],
            services: [],
        },
        searched_topics: [],
        searching_topics: false,
        is_existing_meetup_community: false,
        user: {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
        },
        modal: {
            service: null,
            is_open: false,
        }
    },
    service_category: {
        list: [],
        loading: false,
    },
    community_service: {
        selected: null,
        modal_open: false,
    },
    search_communities: {
        list: [],
        searching: false
    },
    members_range: {
        min_members: 0,
    },
    redeem: {
        community: null,
        loading: false,
        done: false
    },
    landing_page_topics: {
        list: getTopicsCookie(),
        loading: false,
    },
    contact_community_modal_is_open: false,
    redeem_token: getRedeemTokenCookie(),
    community_redeem_modal_open: false,
};

export default (state = initial_state, action) => {
    var nameFilter;
    var listFilters;
    var categoryFilters;
    var topicFilters;
    var communityNetworkFilters;
    var selectedCommunity;
    var selectedEditCommunity;
    var searchCommunities;
    var socialMediaFilters;
    var redeem;
    var page;
    var landingPageTopics;
    var excludedTopicFilters;
    var membersRange;
    var serviceCategory;
    var communityService;

    switch (action.type) {
        case LOAD_COMMUNITIES:
            return {...state, loading: true};
        case LOAD_COMMUNITIES.concat("_SUCCESS"):
            return {
                ...state,
                loading: false,
                list: action.payload.data,
                total_pages: action.payload.total_pages,
                total_results: action.payload.total_results
            };
        case LOAD_COMMUNITIES.concat("_FAILURE"):
            return {...state, loading: false};
        case INCREMENT_COMMUNITIES_PAGE:
            if ((state.page + 1) === state.total_pages) {
                return {...state};
            }

            return {...state, page: state.page + 1};
        case DECREMENT_COMMUNITIES_PAGE:
            if (state.page === 0) {
                return {...state};
            }

            return {...state, page: state.page - 1};
        case RESET_COMMUNITIES_PAGE:
            return {...state, page: 0};
        case CHANGE_FILTER_FOR_LIST:
            listFilters = Object.assign({}, state.list_filters);
            listFilters.filter_for_networks = action.payload;
            return {...state, page: 0, list_filters: listFilters};
        case SET_FREE_SEARCH_FOR_COMMUNITY:
            nameFilter = Object.assign({}, state.name_filter);
            nameFilter.value = action.payload.value;
            return {...state, page: 0, name_filter: nameFilter};
        case SELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY:
            categoryFilters = Object.assign({}, state.category_filters);

            // check if category is currently selected
            var selectedCategory = action.payload.category;
            if (categoryFilters.selected_categories.filter((category) => {
                return category.id === selectedCategory.id;
            }).length > 0) {
                return state;
            }

            categoryFilters.selected_categories.push(selectedCategory);
            return {...state, page: 0, category_filters: categoryFilters};
        case UNSELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY:
            categoryFilters = Object.assign({}, state.category_filters);
            var unselectedCategory = action.payload.category;

            for (var i = 0; i < categoryFilters.selected_categories.length; i++) {
                var category = categoryFilters.selected_categories[i];
                if (category.id === unselectedCategory.id) {
                    categoryFilters.selected_categories.splice(i, 1);
                }
            }

            return {...state, page: 0, category_filters: categoryFilters};
        case SEARCH_TOPICS_TO_FILTER_COMMUNITIES:
            topicFilters = Object.assign({}, state.topic_filters);
            topicFilters.searching = true;
            return {...state, topic_filters: topicFilters};
        case SEARCH_TOPICS_TO_FILTER_COMMUNITIES.concat("_SUCCESS"):
            topicFilters = Object.assign({}, state.topic_filters);
            topicFilters.searching = false;
            topicFilters.options = action.payload.data;

            return {...state, topic_filters: topicFilters};
        case SEARCH_TOPICS_TO_FILTER_COMMUNITIES.concat("_FAILURE"):
            topicFilters = Object.assign({}, state.topic_filters);
            topicFilters.searching = false;
            return {...state, topic_filters: topicFilters};
        case SEARCH_TOPICS_TO_EXCLUDE_FROM_COMMUNITY_SEARCH:
            excludedTopicFilters = Object.assign({}, state.excluded_topic_filters);
            excludedTopicFilters.searching = true;
            return {...state, excluded_topic_filters: excludedTopicFilters};
        case SEARCH_TOPICS_TO_EXCLUDE_FROM_COMMUNITY_SEARCH.concat("_SUCCESS"):
            excludedTopicFilters = Object.assign({}, state.excluded_topic_filters);
            excludedTopicFilters.searching = false;
            excludedTopicFilters.options = action.payload.data;

            return {...state, excluded_topic_filters: excludedTopicFilters};
        case SEARCH_TOPICS_TO_EXCLUDE_FROM_COMMUNITY_SEARCH.concat("_FAILURE"):
            excludedTopicFilters = Object.assign({}, state.excluded_topic_filters);
            excludedTopicFilters.searching = false;

            return {...state, excluded_topic_filters: excludedTopicFilters};
        case LOAD_LANDING_PAGE_TOPICS: {
            landingPageTopics = Object.assign({}, state.landing_page_topics);
            landingPageTopics.loading = true;

            return {
                ...state,
                landing_page_topics: landingPageTopics,
            }
        }
        case LOAD_LANDING_PAGE_TOPICS.concat("_SUCCESS"): {
            landingPageTopics = Object.assign({}, state.landing_page_topics);
            landingPageTopics.loading = false;
            landingPageTopics.list = action.payload.data;
            setTopicsCookie(action.payload.data);

            return {
                ...state,
                landing_page_topics: landingPageTopics,
            }
        }
        case LOAD_LANDING_PAGE_TOPICS.concat("_FAILURE"): {
            landingPageTopics = Object.assign({}, state.landing_page_topics);
            landingPageTopics.loading = false;

            return {
                ...state,
                landing_page_topics: landingPageTopics,
            }
        }
        case SELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH:
            excludedTopicFilters = Object.assign({}, state.excluded_topic_filters);

            // check if topic is currently selected
            var excludeTopicSelected = action.payload.topic;
            if (excludedTopicFilters.selected_topics.filter((topic) => {
                return topic.id === excludeTopicSelected.id && topic.type === excludeTopicSelected.type;
            }).length > 0) {
                return state;
            }

            excludedTopicFilters.selected_topics.push(excludeTopicSelected);
            return {...state, page: 0, excluded_topic_filters: excludedTopicFilters};
        case UNSELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH:
            excludedTopicFilters = Object.assign({}, state.excluded_topic_filters);
            var unselectedTopicToExclude = action.payload.topic;

            for (var i = 0; i < excludedTopicFilters.selected_topics.length; i++) {
                var topic = excludedTopicFilters.selected_topics[i];
                if (topic.id === unselectedTopicToExclude.id && topic.type === unselectedTopicToExclude.type) {
                    excludedTopicFilters.selected_topics.splice(i, 1);
                }
            }

            return {...state, page: 0, excluded_topic_filters: excludedTopicFilters};
        case CLEAR_TOPICS_TO_FILTER_FOR_COMMUNITY:
            topicFilters = Object.assign({}, state.topic_filters);
            topicFilters = {
                searching: false,
                options: [],
                selected_topics: []
            };

            return {...state, page: 0, topic_filters: topicFilters};
        case SELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY:
            communityNetworkFilters = Object.assign({}, state.community_network_filters);

            // check if community is currently selected
            var selectedCommunityNetwork = action.payload.community;

            if (communityNetworkFilters.selected_community_networks.filter((community) => {
                return community.id === selectedCommunityNetwork.id;
            }).length > 0) {
                return state;
            }

            communityNetworkFilters.selected_community_networks.push(selectedCommunityNetwork);

            return {...state, page: 0, community_network_filters: communityNetworkFilters};
        case UNSELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY:
            communityNetworkFilters = Object.assign({}, state.community_network_filters);
            var unselectedCommunityNetwork = action.payload.community;

            for (var i = 0; i < communityNetworkFilters.selected_community_networks.length; i++) {
                var community = communityNetworkFilters.selected_community_networks[i];
                if (community.id === unselectedCommunityNetwork.id) {
                    communityNetworkFilters.selected_community_networks.splice(i, 1);
                }
            }

            return {...state, page: 0, community_network_filters: communityNetworkFilters};
        case SEARCH_FOR_COMMUNITY_NETWORK:
            communityNetworkFilters = Object.assign({}, state.community_network_filters);
            communityNetworkFilters.searching = true;
            return {...state, community_network_filters: communityNetworkFilters};
        case SEARCH_FOR_COMMUNITY_NETWORK.concat("_SUCCESS"):
            communityNetworkFilters = Object.assign({}, state.community_network_filters);
            communityNetworkFilters.searching = false;
            communityNetworkFilters.options = action.payload.data;

            return {...state, community_network_filters: communityNetworkFilters};
        case SEARCH_FOR_COMMUNITY_NETWORK.concat("_FAILURE"):
            communityNetworkFilters = Object.assign({}, state.community_network_filters);
            communityNetworkFilters.searching = false;

            return {...state, community_network_filters: communityNetworkFilters};
        case GET_COMMUNITY_DETAILS:
            selectedCommunity = Object.assign({}, state.selected_community);
            selectedCommunity.loading = true;

            return {...state, selected_community: selectedCommunity};
        case CLEAR_SELECTED_COMMUNITY:
            selectedCommunity = Object.assign({}, state.selected_community);
            selectedCommunity.loading = false;
            selectedCommunity.data = null;

            return {...state, selected_community: selectedCommunity};
        case GET_COMMUNITY_DETAILS.concat("_SUCCESS"):
            selectedCommunity = Object.assign({}, state.selected_community);
            selectedCommunity.loading = false;
            selectedCommunity.data = action.payload.data;

            return {...state, selected_community: selectedCommunity};
        case GET_COMMUNITY_DETAILS.concat("_FAILURE"):
            selectedCommunity = Object.assign({}, state.selected_community);
            selectedCommunity.loading = false;

            return {...state, selected_community: selectedCommunity};
        case LOAD_COMMUNITIES_OF_NETWORK:
            return {
                ...state,
                selected_community: {
                    ...state.selected_community,
                    children: {...state.selected_community.children, list: [], loading: true}
                }
            };
        case LOAD_COMMUNITIES_OF_NETWORK.concat("_SUCCESS"):
            return {
                ...state,
                selected_community: {
                    ...state.selected_community,
                    children: {
                        ...state.selected_community.children,
                        list: action.payload.data,
                        loading: false,
                        total_pages: action.payload.total_pages,
                        total_results: action.payload.total_results
                    }
                }
            };
        case LOAD_COMMUNITIES_OF_NETWORK.concat("_FAILURE"):
            return {
                ...state,
                selected_community: {
                    ...state.selected_community,
                    children: {
                        ...state.selected_community.children,
                        list: [],
                        loading: false,
                        total_pages: 0,
                        total_results: 0
                    }
                }
            };
        case SEARCH_FOR_COMMUNITY:
            searchCommunities = Object.assign({}, state.search_communities);
            searchCommunities.searching = true;

            return {...state, search_communities: searchCommunities};
        case SEARCH_FOR_COMMUNITY.concat("_SUCCESS"):
            searchCommunities = Object.assign({}, state.search_communities);
            searchCommunities.searching = false;
            searchCommunities.list = action.payload.data;

            return {...state, search_communities: searchCommunities};
        case SEARCH_FOR_COMMUNITY.concat("_FAILURE"):
            searchCommunities = Object.assign({}, state.search_communities);
            searchCommunities.searching = false;

            return {...state, search_communities: searchCommunities};
        case CLEAR_SEARCHED_COMMUNITY_LIST:
            searchCommunities = Object.assign({}, state.search_communities);
            searchCommunities.searching = false;
            searchCommunities.list = [];

            return {...state, search_communities: searchCommunities};
        case SET_SORTING_CONDITION:
            return {...state, sorting: action.payload};
        case GET_COMMUNITY_TO_REDEEM:
            redeem = Object.assign({}, state.redeem);
            redeem.loading = true;
            redeem.done = false;
            redeem.community = null;

            return {...state, redeem: redeem};
        case GET_COMMUNITY_TO_REDEEM.concat("_SUCCESS"):
            redeem = Object.assign({}, state.redeem);
            redeem.loading = false;
            redeem.community = action.payload.data;

            return {...state, redeem: redeem};
        case GET_COMMUNITY_TO_REDEEM.concat("_FAILURE"):
            redeem = Object.assign({}, state.redeem);
            redeem.loading = false;
            redeem.community = null;

            toast.error("Invalid community!");

            return {...state, redeem: redeem};
        case SET_COMMUNITY_REDEEM_TOKEN:
            setRedeemTokenCookie(action.payload);
            return {...state, redeem_token: action.payload};
        case CLEAR_COMMUNITY_REDEEM_TOKEN:
            setRedeemTokenCookie(null, true);
            return {...state, redeem_token: null};
        case REDEEM_COMMUNITY.concat("_SUCCESS"):
            redeem = Object.assign({}, state.redeem);
            redeem.done = true;

            toast.success("Congratulations :)");

            return {...state, redeem: redeem};
        case CREATE_COMMUNITY.concat("_SUCCESS"):
            toast.success("Community Created Successfully");

            return {...state};
        case GET_COMMUNITY_EDIT_DETAILS:
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.loading = true;

            return {...state, edit_community: selectedEditCommunity};
        case GET_COMMUNITY_EDIT_DETAILS.concat("_SUCCESS"):
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.loading = false;
            selectedEditCommunity.data = action.payload.data;

            return {...state, edit_community: selectedEditCommunity};
        case GET_COMMUNITY_EDIT_DETAILS.concat("_FAILURE"):
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.loading = false;
            selectedEditCommunity.data = null;

            return {...state, edit_community: selectedEditCommunity};
        case UPDATE_COMMUNITY_CATEGORY:
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.data.category_id = action.payload;

            return {...state, edit_community: selectedEditCommunity};
        case UPDATE_COMMUNITY_DATA:
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.data = action.payload;

            return {...state, edit_community: selectedEditCommunity};
        case EDIT_COMMUNITY_LINKS:
            return {
                ...state,
                edit_community: {
                    ...state.edit_community,
                    data: {...state.edit_community.data, facebook_page_profiles: action.payload.facebook_page_profiles}
                }
            };
        case ADD_TOPIC_IN_COMMUNITY:
        case ADD_TOPIC_IN_COMMUNITY_REGISTRATION:
            selectedEditCommunity = Object.assign({}, state.edit_community);

            if (selectedEditCommunity.data.topics.filter((topic) => {
                return topic.id == action.payload.topic.id;
            }).length === 0) {
                selectedEditCommunity.data.topics.push(action.payload.topic);
            }

            return {...state, edit_community: selectedEditCommunity};
        case REMOVE_TOPIC_FROM_COMMUNITY:
        case REMOVE_TOPIC_FROM_COMMUNITY_REGISTRATION:
            selectedEditCommunity = Object.assign({}, state.edit_community);

            selectedEditCommunity.data.topics.forEach((topic, index) => {
                if (topic.id == action.payload.topic_id) {
                    selectedEditCommunity.data.topics.splice(index, 1);
                }
            });

            return {...state, edit_community: selectedEditCommunity};
        case SEARCH_TOPICS_TO_ADD_IN_COMMUNITY:
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.searching_topics = true;
            selectedEditCommunity.selected_topics = [];

            return {...state, edit_community: selectedEditCommunity};
        case SEARCH_TOPICS_TO_ADD_IN_COMMUNITY.concat("_SUCCESS"):
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.searching_topics = false;
            selectedEditCommunity.searched_topics = action.payload.data;

            return {...state, edit_community: selectedEditCommunity};
        case SEARCH_TOPICS_TO_ADD_IN_COMMUNITY.concat("_FAILURE"):
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.searching_topics = false;
            selectedEditCommunity.searched_topics = [];

            return {...state, edit_community: selectedEditCommunity};
        case SELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY:
            socialMediaFilters = Object.assign({}, state.social_media_filters);

            // check if social media is currently selected
            var selectedSocialMedia = action.payload.social_media;
            socialMediaFilters.selected_socials.push(selectedSocialMedia);

            return {...state, page: 0, social_media_filters: socialMediaFilters};
        case UNSELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY:
            socialMediaFilters = Object.assign({}, state.social_media_filters);

            // check if social media is currently selected
            var unselectedSocialMedia = action.payload.social_media;
            socialMediaFilters.selected_socials.forEach((social, index) => {
                if (social.value == unselectedSocialMedia.value) {
                    socialMediaFilters.selected_socials.splice(index, 1);
                }
            });

            return {...state, page: 0, social_media_filters: socialMediaFilters};
        case TOGGLE_NEW_COMMUNITIES_FILTER:
            listFilters = Object.assign({}, state.list_filters);
            listFilters.new_communities_filter = action.payload.active;

            return {...state, page: 0, list_filters: listFilters};
        case SET_MIN_MEMBERS_CONDITION_FOR_COMMUNITIES_LIST:
            membersRange = Object.assign({}, state.members_range);
            membersRange.min_members = action.payload;

            return {...state, page: 0, members_range: membersRange};
        case TOGGLE_COMMUNITY_REDEEM_MODAL:
            return {...state, community_redeem_modal_open: !state.community_redeem_modal_open}
        case TOGGLE_REDEEMED_COMMUNITY_FILTER:
            listFilters = Object.assign({}, state.list_filters);
            listFilters.redeemed_community_filter = !state.list_filters.redeemed_community_filter;

            return {...state, list_filters: listFilters};
        case TOGGLE_COMMUNITIES_WITH_VERIFIED_ADMINS_FILTER:
            listFilters = Object.assign({}, state.list_filters);
            listFilters.with_verified_admins = !state.list_filters.with_verified_admins;

            return {...state, list_filters: listFilters};
        case VERIFY_EXISTING_MEETUP_COMMUNITY.concat("_SUCCESS"):
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.is_existing_meetup_community = action.payload.data.verified;

            return {...state, edit_community: selectedEditCommunity};
        case TOGGLE_ADD_SERVICE_MODAL:
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.modal.is_open = !state.edit_community.modal.is_open;
            selectedEditCommunity.modal.service = action.payload !== undefined ? selectedEditCommunity.data.services[action.payload] : null;

            return {...state, edit_community: selectedEditCommunity};
        case CREATE_SERVICE:
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.data.services.push(action.payload);

            return {...state, edit_community: selectedEditCommunity};
        case EDIT_SERVICE:
            selectedEditCommunity = Object.assign({}, state.edit_community);

            selectedEditCommunity.data.services.forEach((service, index) => {
                if (service.id === action.payload.id) {
                    selectedEditCommunity.data.services[index] = action.payload;
                }
            })

            return {...state, edit_community: selectedEditCommunity};
        case DELETE_SERVICE:
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.data.services.splice(action.payload, 1);

            return {...state, edit_community: selectedEditCommunity};
        case GET_SERVICE_CATEGORIES:
            serviceCategory = Object.assign({}, state.service_category);
            serviceCategory.loading = true;

            return {...state, service_category: serviceCategory};
        case GET_SERVICE_CATEGORIES.concat("_SUCCESS"):
            serviceCategory = Object.assign({}, state.service_category);
            serviceCategory.loading = false;
            serviceCategory.list = action.payload.data;

            return {...state, service_category: serviceCategory};
        case GET_SERVICE_CATEGORIES.concat("_FAILURE"):
            serviceCategory = Object.assign({}, state.service_category);
            serviceCategory.loading = false;

            return {...state, service_category: serviceCategory};
        case CLEAR_COMMUNITY_DETAILS:
            selectedEditCommunity = Object.assign({}, state.edit_community);
            selectedEditCommunity.data = initial_state.edit_community.data;

            return {...state, edit_community: selectedEditCommunity};
        case TOGGLE_CONTACT_COMMUNITY_MODAL:
            let contactCommunityModal = !state.contact_community_modal_is_open;

            return {...state, contact_community_modal_is_open: contactCommunityModal};
        case TOGGLE_SERVICE_DETAILS_MODAL:
            communityService = Object.assign({}, state.community_service);
            communityService.selected = action.payload ? action.payload : null;
            communityService.modal_open = !state.community_service.modal_open;

            return {...state, community_service: communityService};
        case INCREMENT_COMMUNITIES_OF_NETWORK_PAGE:
            page = state.selected_community.children.page;
            var totalPages = state.selected_community.children.total_pages;

            if ((page + 1) === totalPages) {
                return {...state};
            }

            return {
                ...state,
                selected_community: {
                    ...state.selected_community,
                    children: {
                        ...state.selected_community.children,
                        page: page + 1
                    }
                }
            };
        case DECREMENT_COMMUNITIES_OF_NETWORK_PAGE:
            page = state.selected_community.children.page;
            if (page === 0) {
                return {...state};
            }

            return {
                ...state,
                selected_community: {
                    ...state.selected_community,
                    children: {
                        ...state.selected_community.children,
                        page: page - 1
                    }
                }
            };
        case DISABLE_SLACK_ON_EDIT_COMMUNITY:
            return {
                ...state,
                edit_community: {...state.edit_community, data: {...state.edit_community.data, connected_slack: false}}
            };
        case DISABLE_DISCORD_ON_EDIT_COMMUNITY:
            return {
                ...state,
                edit_community: {
                    ...state.edit_community,
                    data: {...state.edit_community.data, connected_discord: false}
                }
            };
        case SET_EDIT_COMMUNITY_DATA:
            return {
                ...state,
                edit_community: {
                    ...state.edit_community,
                    data: action.payload
                }
            };
        default:
            return {...state};
    }
}
