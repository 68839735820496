import {
        LOAD_CATEGORIES,
    } from "../action_types";

const initial_state = {
    list: [],
    loading: false
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case LOAD_CATEGORIES:
            return { ...state, loading: true};
        case LOAD_CATEGORIES.concat("_SUCCESS"):
            return { ...state, loading: false, list: action.payload.data };
        case LOAD_CATEGORIES.concat("_FAILURE"):
            return { ...state, loading: false };
        default: return { ...state };
    }
}
