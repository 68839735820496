import {
    ADD_ALL_COMMUNITIES_TO_LISTS,
    ADD_COMMUNITIES_TO_LISTS,
    ADD_TOPIC_IN_COMMUNITY,
    APPLY_TO_JOB,
    CANCEL_CAMPAIGN,
    CANCEL_SUBSCRIPTION_RENEW,
    CHANGE_BOUNTY_PORTION,
    CHANGE_PASSWORD,
    CONFIRM_CAMPAIGN_PROFILES,
    CONFIRM_CHANNEL,
    CONNECT_DISCORD,
    CONNECT_SLACK,
    CREATE_CAMPAIGN,
    CREATE_CHANNEL,
    CREATE_COMMUNITY,
    CREATE_COMMUNITY_LIST,
    CREATE_COMMUNITY_LIST_AND_ADD_ALL_COMMUNITIES,
    CREATE_COMMUNITY_LIST_AND_ADD_COMMUNITIES,
    CREATE_REPORT,
    DECLINE_CHANNEL,
    DELETE_COMMUNITY_LIST,
    DELETE_EMAIL,
    DELETE_REPORT,
    DISCONNECT_DISCORD,
    DISCONNECT_SLACK,
    EDIT_COMMUNITY,
    GET_COMMUNITY_DETAILS,
    GET_COMMUNITY_EDIT_DETAILS,
    GET_COMMUNITY_TO_REDEEM,
    GET_JOB_APPLICATION_DETAILS,
    GET_JOB_APPLICATION_FORM_DETAILS,
    GET_JOB_DETAILS,
    GET_MAIL_MARKETING_PLACEHOLDERS,
    GET_REFERRER_DETAILS,
    GET_SERVICE_CATEGORIES,
    LOAD_CAMPAIGN_COMMUNITIES,
    LOAD_CAMPAIGN_DETAILS,
    LOAD_CAMPAIGN_EMAILS,
    LOAD_CAMPAIGNS,
    LOAD_CATEGORIES,
    LOAD_CHANNELS,
    LOAD_COMMUNITIES,
    LOAD_COMMUNITIES_OF_LIST,
    LOAD_COMMUNITIES_OF_NETWORK,
    LOAD_COMMUNITIES_OF_REPORT,
    LOAD_COMMUNITY_LISTS,
    LOAD_COMMUNITY_MEMBERS,
    LOAD_CUSTOMER,
    LOAD_FULL_JOBS_LIST_TO_SEARCH_APPLICANTS,
    LOAD_GLOBAL_INSIGHT_AVERAGE_PARAMETERS,
    LOAD_GLOBAL_INSIGHT_CATEGORY_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_COMMUNITY_TOTALS,
    LOAD_GLOBAL_INSIGHT_ENGAGEMENT_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_EVENTS_VS_PARTICIPANTS_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_LOCATION_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_MAP_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_SOCIAL_MEDIA_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_TOPIC_DISTRIBUTION,
    LOAD_JOB_APPLICANTS,
    LOAD_JOBS,
    LOAD_LANDING_PAGE_TOPICS,
    LOAD_LIST_DETAILS,
    LOAD_LITE_COMMUNITY_LISTS,
    LOAD_LOCATIONS,
    LOAD_MESSAGES,
    LOAD_REPORT_DETAILS,
    LOAD_REPORTS,
    LOAD_SUBSCRIPTIONS,
    LOAD_USER,
    LOAD_UTILS,
    LOGIN_USER,
    MARK_CHANNEL_READ,
    RECOVER_PASSWORD,
    REDEEM_COMMUNITY,
    REFER_JOB,
    REGISTER_USER,
    REMOVE_COMMUNITY_FROM_LIST,
    REMOVE_TOPIC_FROM_COMMUNITY,
    SAVE_COMMUNITY_EMAIL,
    SAVE_CONTACTED_MEMBER_EMAIL,
    SAVE_JOB_APPLICATION_FORM,
    SAVE_NEW_PASSWORD,
    SAVE_NOT_CONTACTED_MEMBER_EMAIL, SAVE_SOLANA_WALLET_ON_COMMUNITY,
    SEARCH_CHANNELS,
    SEARCH_COMMUNITY_LIST,
    SEARCH_COMPANY_TO_SEARCH_APPLICANTS,
    SEARCH_FOR_COMMUNITY,
    SEARCH_FOR_COMMUNITY_NETWORK,
    SEARCH_LOCATION_TO_FILTER,
    SEARCH_TOPIC_TO_FILTER,
    SEARCH_TOPICS_TO_ADD_IN_COMMUNITY,
    SEARCH_TOPICS_TO_EXCLUDE_FROM_COMMUNITY_SEARCH,
    SEARCH_TOPICS_TO_FILTER_COMMUNITIES,
    SEND_MESSAGE,
    SEND_TO_MAIL_MARKETING_TOOL, SOLANA_WALLET_GET_TRANSACTIONS_LIST,
    SUBSCRIBE_TO_JOB_BOUNTIES,
    SUBSCRIBE_TO_JOB_BOUNTIES_VIA_CTA,
    TOGGLE_LINKEDIN_PROFILE_VERIFICATION,
    UPDATE_CAMPAIGN_EMAIL_ORDER,
    UPDATE_CUSTOMER,
    UPDATE_JOB_APPLICATION,
    UPDATE_USER,
    UPLOAD_COMMUNITY_IMAGE_FILE,
    UPLOAD_COMMUNITY_SERVICE_IMAGE_FILE,
    UPLOAD_USER_PROFILE_IMAGE_FILE,
    VERIFY_ALREADY_CONTACTED_COMMUNITIES,
    VERIFY_EXISTING_MEETUP_COMMUNITY,
    SOLANA_GET_PRICE, SOLANA_CONFIRM_PAYMENT,
} from "./action_types";

function buildLocationsQueryString(state) {
    return JSON.stringify(state.filters.location_filters.selected_locations.map((location) => {
        return {
            id: location.id,
            code: location.code,
            t: location.type,
            c: location.country,
            lat: location.lat,
            lng: location.lng
        }
    }))
}

function buildCategoriesQueryString(state) {
    return JSON.stringify(state.community.category_filters.selected_categories.map((category) => {
        return category.id;
    }))
}

function buildCommunityNetworkIds(state) {
    return JSON.stringify(state.community.community_network_filters.selected_community_networks.map((communityNetwork) => {
        return communityNetwork.id;
    }))
}

function buildTopicsQueryString(state) {
    return JSON.stringify(state.filters.topic_filters.selected_topics.map((category) => {
        return category.id;
    }))
}

function buildExcludedTopicsQueryString(state) {
    return JSON.stringify(state.community.excluded_topic_filters.selected_topics.map((category) => {
        return category.id;
    }))
}

function buildSocialMediasQueryString(state) {
    return JSON.stringify(state.community.social_media_filters.selected_socials.filter(function (social) {
        return !social.disabled;
    }).map((social) => {
        return social.value;
    }))
}

function buildGlobalInsightsQueryStrings(state) {
    return `continent_id=${state.dashboard.selected_continent}&country_id=${state.dashboard.selected_country}&category_id=${state.dashboard.selected_category.id}&report_id=${state.dashboard.selected_report}&community_network_id=${state.dashboard.selected_community_network}`;
}

function buildListFilterQueryString(state) {
    return `is_network=${state.community.list_filters.filter_for_networks}&locations=${buildLocationsQueryString(state)}&categories=${buildCategoriesQueryString(state)}&topics=${buildTopicsQueryString(state)}&excluded_topics=${buildExcludedTopicsQueryString(state)}&social_medias=${buildSocialMediasQueryString(state)}&parent_ids=${buildCommunityNetworkIds(state)}&sorting=${JSON.stringify(state.community.sorting)}&new_communities=${state.community.list_filters.new_communities_filter}&redeemed_communities=${state.community.list_filters.redeemed_community_filter}&with_verified_admins=${state.community.list_filters.with_verified_admins}&min_total_members=${state.community.members_range.min_members}&q=${state.community.name_filter.value}`;
}

function buildJobBoardToken(state) {
    const {job, community} = state;
    if (job.job_board_token) {
        return state.job.job_board_token
    } else if (job.selected_community) {
        return job.selected_community.job_referral_token.token
    } else if (community.redeem.community) {
        return community.redeem.community.job_referral_token.token;
    }
}

const endpoints = {
    [LOAD_COMMUNITIES]: {
        endpoint: state => `/community/?page=${state.community.page}&${buildListFilterQueryString(state)}`,
        method: 'GET'
    },
    [LOAD_CATEGORIES]: {
        endpoint: state => `/category/`,
        method: 'GET'
    },
    [SEARCH_LOCATION_TO_FILTER]: {
        endpoint: (state, action) => `/location/?q=${action.payload.value}&type=${action.payload.type}`,
        method: 'GET'
    },
    [SEARCH_TOPICS_TO_FILTER_COMMUNITIES]: {
        endpoint: (state, action) => `/topic/?q=${action.payload.value}`,
        method: 'GET'
    },
    [SEARCH_TOPIC_TO_FILTER]: {
        endpoint: (state, action) => `/topic/?q=${action.payload.value}`,
        method: 'GET'
    },
    [SEARCH_TOPICS_TO_EXCLUDE_FROM_COMMUNITY_SEARCH]: {
        endpoint: (state, action) => `/topic/?q=${action.payload.value}`,
        method: 'GET'
    },
    [LOAD_LANDING_PAGE_TOPICS]: {
        endpoint: (state, action) => `/topic/exposed`,
        method: 'GET',
    },
    [LOAD_USER]: {
        endpoint: (state, action) => `/user/me`,
        method: 'GET'
    },
    [GET_COMMUNITY_DETAILS]: {
        endpoint: (state, action) => {
            if (action.payload.id) {
                return `/community/${action.payload.id}`;
            } else if (action.payload.token) {
                return `/community/preview/${action.payload.token}`;
            }
        },
        method: 'GET'
    },
    [GET_COMMUNITY_EDIT_DETAILS]: {
        endpoint: (state, action) => `/community/edit/${action.payload.id}`,
        method: 'GET'
    },
    [LOAD_COMMUNITIES_OF_NETWORK]: {
        endpoint: (state, action) => `/community/?page=${state.community.selected_community.children.page}&parent_ids=[${state.community.selected_community.data.id}]`,
        method: 'GET'
    },
    [SEARCH_FOR_COMMUNITY]: {
        endpoint: (state, action) => `/community/search?name=` + action.payload.name,
        method: 'GET'
    },
    [SEARCH_FOR_COMMUNITY_NETWORK]: {
        endpoint: (state, action) => `/community/search?is_network=1&name=` + action.payload.value,
        method: 'GET'
    },
    [UPDATE_USER]: {
        endpoint: (state, action) => '/user/me',
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [CHANGE_PASSWORD]: {
        endpoint: (state, action) => '/auth/change-password',
        method: 'POST',
        payload: (state, action) => action.payload,
    },
    [LOAD_CUSTOMER]: {
        endpoint: (state, action) => '/customer/',
        method: 'GET',
        payload: (state, action) => action.payload
    },
    [UPDATE_CUSTOMER]: {
        endpoint: (state, action) => '/customer/',
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [LOAD_SUBSCRIPTIONS]: {
        endpoint: (state, action) => '/customer/subscriptions',
        method: 'GET',
        payload: (state, action) => action.payload
    },
    [LOAD_UTILS]: {
        endpoint: (state, action) => '/utils',
        method: 'GET',
        payload: (state, action) => action.payload
    },
    [CANCEL_SUBSCRIPTION_RENEW]: {
        endpoint: (state, action) => `/customer/subscriptions/${action.payload.id}/cancel`,
        method: 'POST'
    },
    [LOAD_COMMUNITY_LISTS]: {
        endpoint: (state, action) => `/list/?page=${state.community_list.page}`,
        method: 'GET'
    },
    [LOAD_LITE_COMMUNITY_LISTS]: {
        endpoint: (state, action) => '/list/all',
        method: 'GET'
    },
    [ADD_COMMUNITIES_TO_LISTS]: {
        endpoint: (state, action) => '/list/community',
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [ADD_ALL_COMMUNITIES_TO_LISTS]: {
        endpoint: (state, action) => `/list/community/full-search?${buildListFilterQueryString(state)}`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [CREATE_COMMUNITY_LIST]: {
        endpoint: (state, action) => '/list/',
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [CREATE_COMMUNITY_LIST_AND_ADD_COMMUNITIES]: {
        endpoint: (state, action) => '/list/',
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [CREATE_COMMUNITY_LIST_AND_ADD_ALL_COMMUNITIES]: {
        endpoint: (state, action) => '/list/',
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [LOAD_LIST_DETAILS]: {
        endpoint: (state, action) => `/list/${action.payload.id}`,
        method: 'GET'
    },
    [LOAD_COMMUNITIES_OF_LIST]: {
        endpoint: (state, action) => `/community/?list_id=${action.payload.id}&sorting=${JSON.stringify(state.community_list.communities_of_list.sorting)}&page=${state.community_list.communities_of_list.page}`,
        method: 'GET'
    },
    [REMOVE_COMMUNITY_FROM_LIST]: {
        endpoint: (state, action) => `/list/community?community_id=${action.payload.community_id}&list_id=${action.payload.list_id}`,
        method: 'DELETE'
    },
    [DELETE_COMMUNITY_LIST]: {
        endpoint: (state, action) => `/list/${action.payload.id}`,
        method: 'DELETE'
    },
    [LOGIN_USER]: {
        endpoint: (state, action) => '/auth/login',
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [GET_COMMUNITY_TO_REDEEM]: {
        endpoint: (state, action) => `/community/${state.community.redeem_token}`,
        method: 'GET'
    },
    [REGISTER_USER]: {
        endpoint: (state, action) => '/auth/register',
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [REDEEM_COMMUNITY]: {
        endpoint: (state, action) => '/community/redeem',
        method: 'POST',
        payload: (state, action) => {
            return {
                token: state.community.redeem_token
            }
        }
    },
    [EDIT_COMMUNITY]: {
        endpoint: (state, action) => '/community/edit/' + action.payload.community.id,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [CREATE_COMMUNITY]: {
        endpoint: (state, action) => `/community/create`,
        method: 'POST',
        payload: (state, action) => {
            return {
                community: action.payload.community,
                user: action.payload.user
            }
        },
    },
    [REMOVE_TOPIC_FROM_COMMUNITY]: {
        endpoint: (state, action) => '/community/topic',
        method: 'DELETE',
        payload: (state, action) => action.payload
    },
    [ADD_TOPIC_IN_COMMUNITY]: {
        endpoint: (state, action) => '/community/topic',
        method: 'POST',
        payload: (state, action) => {
            return {
                community_id: action.payload.community_id,
                topic_id: action.payload.topic.id
            };
        }
    },
    [UPLOAD_COMMUNITY_IMAGE_FILE]: {
        endpoint: (state, action) => '/image/',
        method: 'POST',
        file: true,
        payload: (state, action) => (function () {
            var formData = new FormData();
            formData.append("file", action.payload.file);

            return formData;
        })()
    },
    [UPLOAD_COMMUNITY_SERVICE_IMAGE_FILE]: {
        endpoint: (state, action) => '/image/',
        method: 'POST',
        file: true,
        payload: (state, action) => (function () {
            var formData = new FormData();
            formData.append("file", action.payload.file);

            return formData;
        })()
    },
    [UPLOAD_USER_PROFILE_IMAGE_FILE]: {
        endpoint: (state, action) => '/image/',
        method: 'POST',
        file: true,
        payload: (state, action) => (function () {
            var formData = new FormData();
            formData.append("file", action.payload.file);

            return formData;
        })()
    },
    [SEARCH_TOPICS_TO_ADD_IN_COMMUNITY]: {
        endpoint: (state, action) => `/topic/?q=${action.payload.value}`,
        method: 'GET'
    },
    [RECOVER_PASSWORD]: {
        endpoint: (state, action) => `/auth/request-recover-password`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [SAVE_NEW_PASSWORD]: {
        endpoint: (state, action) => `/auth/save-new-password`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [LOAD_LOCATIONS]: {
        endpoint: (state, action) => `/location/list`,
        method: 'GET'
    },
    [LOAD_GLOBAL_INSIGHT_MAP_DISTRIBUTION]: {
        endpoint: (state, action) => `/global-insights/map-distribution?${buildGlobalInsightsQueryStrings(state)}`,
        method: 'GET'
    },
    [LOAD_GLOBAL_INSIGHT_CATEGORY_DISTRIBUTION]: {
        endpoint: (state, action) => `/global-insights/category-distribution?${buildGlobalInsightsQueryStrings(state)}`,
        method: 'GET'
    },
    [LOAD_GLOBAL_INSIGHT_LOCATION_DISTRIBUTION]: {
        endpoint: (state, action) => `/global-insights/location-distribution?${buildGlobalInsightsQueryStrings(state)}`,
        method: 'GET'
    },
    [LOAD_GLOBAL_INSIGHT_COMMUNITY_TOTALS]: {
        endpoint: (state, action) => `/global-insights/community-totals?${buildGlobalInsightsQueryStrings(state)}`,
        method: 'GET'
    },
    [LOAD_GLOBAL_INSIGHT_SOCIAL_MEDIA_DISTRIBUTION]: {
        endpoint: (state, action) => `/global-insights/social-media-distribution?${buildGlobalInsightsQueryStrings(state)}`,
        method: 'GET'
    },
    [LOAD_GLOBAL_INSIGHT_ENGAGEMENT_DISTRIBUTION]: {
        endpoint: (state, action) => `/global-insights/engagement-distribution?${buildGlobalInsightsQueryStrings(state)}`,
        method: 'GET'
    },
    [LOAD_GLOBAL_INSIGHT_EVENTS_VS_PARTICIPANTS_DISTRIBUTION]: {
        endpoint: (state, action) => `/global-insights/events-participants-distribution?${buildGlobalInsightsQueryStrings(state)}`,
        method: 'GET'
    },
    [LOAD_GLOBAL_INSIGHT_TOPIC_DISTRIBUTION]: {
        endpoint: (state, action) => `/global-insights/topic-distribution?${buildGlobalInsightsQueryStrings(state)}`,
        method: 'GET'
    },
    [LOAD_GLOBAL_INSIGHT_AVERAGE_PARAMETERS]: {
        endpoint: (state, action) => `/global-insights/average-parameters?${buildGlobalInsightsQueryStrings(state)}`,
        method: 'GET'
    },
    [LOAD_REPORTS]: {
        endpoint: (state, action) => `/report/?page=${state.report.page}`,
        method: 'GET'
    },
    [CREATE_REPORT]: {
        endpoint: (state, action) => '/report/',
        payload: (state, action) => {
            return {
                name: action.payload.name,
                community_list_id: action.payload.selected_list_id,
            };
        },
        method: 'POST'
    },
    [DELETE_REPORT]: {
        endpoint: (state, action) => `/report/${action.payload.id}`,
        method: 'DELETE'
    },
    [LOAD_REPORT_DETAILS]: {
        endpoint: (state, action) => `/report/${action.payload.id}`,
        method: 'GET'
    },
    [LOAD_COMMUNITIES_OF_REPORT]: {
        endpoint: (state, action) => `/community/?page=${state.report.selected_report.communities.page}&report_id=${action.payload.id}`,
        method: 'GET'
    },
    [SEARCH_COMMUNITY_LIST]: {
        endpoint: (state, action) => `/list/?q=${action.payload}`,
        method: 'GET'
    },
    [LOAD_CAMPAIGNS]: {
        endpoint: (state, action) => `/campaign/?page=${state.campaign.page}`,
        method: 'GET'
    },
    [LOAD_CAMPAIGN_DETAILS]: {
        endpoint: (state, action) => `/campaign/${action.payload.id}`,
        method: 'GET'
    },
    [LOAD_CAMPAIGN_COMMUNITIES]: {
        endpoint: (state, action) => `/campaign/${state.campaign.selected.campaign.id}/communities?page=${state.campaign.selected.communities.page}`,
        method: 'GET'
    },
    [LOAD_CAMPAIGN_EMAILS]: {
        endpoint: (state, action) => `/campaign-email/${action.payload.id}/list`,
        method: 'GET',
    },
    [SAVE_COMMUNITY_EMAIL]: {
        endpoint: (state, action) => `/campaign-email/${state.campaign.selected.campaign.id}/community`,
        method: 'POST',
        payload: (state, action) => {
            return {
                email: action.payload.email,
            }
        }
    },
    [SAVE_CONTACTED_MEMBER_EMAIL]: {
        endpoint: (state, action) => `/campaign-email/${state.campaign.selected.campaign.id}/contacted-member`,
        method: 'POST',
        payload: (state, action) => {
            return {
                email: action.payload.email,
            }
        }
    },
    [SAVE_NOT_CONTACTED_MEMBER_EMAIL]: {
        endpoint: (state, action) => `/campaign-email/${state.campaign.selected.campaign.id}/not-contacted-member`,
        method: 'POST',
        payload: (state, action) => {
            return {
                email: action.payload.email,
            }
        }
    },
    [UPDATE_CAMPAIGN_EMAIL_ORDER]: {
        endpoint: (state, action) => `/campaign-email/${state.campaign.selected.campaign.id}/order`,
        method: 'POST',
        payload: (state, action) => {
            return {
                emails: action.payload.emails,
            }
        }
    },
    [DELETE_EMAIL]: {
        endpoint: (state, action) => `/campaign-email/${state.campaign.selected.campaign.id}/${action.payload.id}`,
        method: 'DELETE',
    },
    [LOAD_COMMUNITY_MEMBERS]: {
        endpoint: (state, action) => `/campaign/${state.campaign.selected.campaign.id}/communities/${action.payload.community_id}/members?page=${action.payload.page}`,
        method: 'GET'
    },
    [TOGGLE_LINKEDIN_PROFILE_VERIFICATION]: {
        endpoint: (state, action) => `/campaign/${state.campaign.selected.campaign.id}/communities/${action.payload.community_id}/profiles/${action.payload.profile_id}/toggle-verification`,
        method: 'POST'
    },
    [VERIFY_ALREADY_CONTACTED_COMMUNITIES]: {
        endpoint: (state, action) => `/campaign/verify-already-contacted-communities?list_id=${action.payload.list_id}`,
        method: 'GET',
    },
    [CREATE_CAMPAIGN]: {
        endpoint: (state, action) => '/campaign/',
        method: 'POST',
        payload: (state, action) => {
            return {
                list_id: state.campaign.new_campaign.list_id,
                message: state.campaign.new_campaign.message,
                contact_mode: state.campaign.new_campaign.contact_mode,
            }
        },
    },
    [CANCEL_CAMPAIGN]: {
        endpoint: (state, action) => `/campaign/cancel/${action.payload.campaign_id}`,
        method: 'POST',
    },
    [CREATE_CHANNEL]: {
        endpoint: (state, action) => '/channel/',
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [LOAD_CHANNELS]: {
        endpoint: (state, action) => `/channel/?page=${state.channel.list.page}&limit=${state.channel.list.limit}`,
        method: 'GET',
    },
    [SEARCH_CHANNELS]: {
        endpoint: (state, action) => `/channel/?q=${state.channel.search_channels.param}&page=${state.channel.list.page}`,
        method: 'GET',
    },
    [LOAD_MESSAGES]: {
        endpoint: (state, action) => {
            var currentChannelMessagesPage = state.channel.list.channels[state.channel.current_channel.id].messages_page;
            return `/message/?page=0&limit=${state.channel.message_limit}&page=${currentChannelMessagesPage}&channel_id=${state.channel.current_channel.id}`
        },
        method: 'GET',
    },
    [SEND_MESSAGE]: {
        endpoint: (state, action) => '/message/',
        method: 'POST',
        file: true,
        payload: (state, action) => (function () {
            var formData = new FormData();
            formData.append("attachment", action.payload.attachment);
            formData.append("channel_id", action.payload.channel_id);
            formData.append("content", action.payload.content);

            return formData;
        })()
    },
    [CONFIRM_CHANNEL]: {
        endpoint: (state, action) => `/channel/confirm/${action.payload}`,
        method: 'POST'
    },
    [DECLINE_CHANNEL]: {
        endpoint: (state, action) => `/channel/reject/${action.payload}`,
        method: 'POST'
    },
    [MARK_CHANNEL_READ]: {
        endpoint: (state, action) => `/channel/read/${action.payload}`,
        method: 'POST'
    },
    [CONFIRM_CAMPAIGN_PROFILES]: {
        endpoint: (state, action) => `/campaign/${state.campaign.selected.campaign.id}/confirm`,
        method: 'POST'
    },
    [SEND_TO_MAIL_MARKETING_TOOL]: {
        endpoint: (state, action) => `/campaign/send-to-mail-marketing-tool/${state.campaign.selected.campaign.id}`,
        method: 'POST'
    },
    [GET_MAIL_MARKETING_PLACEHOLDERS]: {
        endpoint: (state, action) => `/campaign-email/${action.payload}/mmt-contact-fields`,
        method: 'GET',
    },
    [LOAD_JOBS]: {
        endpoint: (state, action) => `/job/list/${buildJobBoardToken(state)}?page=${state.job.page}&locations=${buildLocationsQueryString(state)}&topics=${buildTopicsQueryString(state)}&is_remote=${state.job.filters.is_remote}&my_jobs=${state.job.filters.my_jobs}&rd=${(!!state.community.redeem_token)}`,
        method: 'GET',
    },
    [GET_JOB_DETAILS]: {
        endpoint: (state, action) => `/job/details/${action.payload}`,
        method: 'GET',
    },
    [CHANGE_BOUNTY_PORTION]: {
        endpoint: (state, action) => `/job-referral-token/bounty-portion/${action.payload.token}`,
        method: 'POST',
        payload: (state, action) => {
            return {
                bounty_portion: action.payload.bounty_portion,
            }
        },
    },
    [GET_REFERRER_DETAILS]: {
        endpoint: (state, action) => `/job-referral-token/details/${action.payload}`,
        method: 'GET',
    },
    [REFER_JOB]: {
        endpoint: (state, action) => `/job/refer/${state.job.job_token}`,
        method: 'POST',
        payload: (state, action) => action.payload,
    },
    [APPLY_TO_JOB]: {
        endpoint: (state, action) => `/job/apply/${state.job.job_token}`,
        method: 'POST',
        payload: (state, action) => (function () {

            var formData = new FormData();

            if (action.payload.custom_fields) {
                for (const [key, value] of Object.entries(action.payload.custom_fields)) {
                    formData.append("custom_field_" + key, value);
                }
                delete action.payload.custom_fields;
            }

            for (const [key, value] of Object.entries(action.payload)) {
                formData.append(key, value);
            }

            return formData;
        })()
    },
    [VERIFY_EXISTING_MEETUP_COMMUNITY]: {
        endpoint: (state, action) => `/community/verify-meetup?link=${action.payload}`,
        method: 'GET',
    },
    [GET_SERVICE_CATEGORIES]: {
        endpoint: (state, action) => "/community-service-category/",
        method: 'GET',
    },
    [SUBSCRIBE_TO_JOB_BOUNTIES]: {
        endpoint: (state, action) => `/job/subscribe/${state.job.job_token}`,
        method: 'POST',
        payload: (state, action) => action.payload.subscriber,
    },
    [SUBSCRIBE_TO_JOB_BOUNTIES_VIA_CTA]: {
        endpoint: (state, action) => `/job/subscribe/${buildJobBoardToken(state)}`,
        method: 'POST',
        payload: (state, action) => action.payload.subscriber,
    },
    [CONNECT_SLACK]: {
        endpoint: (state, action) => `/oauth/connect-slack`,
        method: 'POST',
        payload: (state, action) => action.payload,
    },
    [CONNECT_DISCORD]: {
        endpoint: (state, action) => `/oauth/connect-discord`,
        method: 'POST',
        payload: (state, action) => action.payload,
    },
    [DISCONNECT_SLACK]: {
        endpoint: (state, action) => `/oauth/disconnect-slack`,
        method: 'POST',
        payload: (state, action) => action.payload,
    },
    [DISCONNECT_DISCORD]: {
        endpoint: (state, action) => `/oauth/disconnect-discord`,
        method: 'POST',
        payload: (state, action) => action.payload,
    },
    [GET_JOB_APPLICATION_FORM_DETAILS]: {
        endpoint: (state, action) => `/job-application-form/${action.payload}`,
        method: 'GET'
    },
    [SAVE_JOB_APPLICATION_FORM]: {
        endpoint: (state, action) => `/job-application-form/${action.payload.job_id}`,
        method: 'POST',
        payload: (state, action) => action.payload.application_form
    },
    [LOAD_JOB_APPLICANTS]: {
        endpoint: (state, action) => `/job-applicants/?page=${state.job_applicants.page}&job_ids=${JSON.stringify(state.job_applicants.filters.job.selected_jobs.map((job) => job.id))}&company_ids=${JSON.stringify(state.job_applicants.filters.company.selected_companies.map((company) => company.id))}&sorting=${JSON.stringify(state.job_applicants.sorting)}`,
        method: 'GET',
    },
    [LOAD_FULL_JOBS_LIST_TO_SEARCH_APPLICANTS]: {
        endpoint: (state, action) => `/job/lite-list?company_ids=${JSON.stringify(state.job_applicants.filters.company.selected_companies.map((company) => company.id))}`,
        method: 'GET',
    },
    [SEARCH_COMPANY_TO_SEARCH_APPLICANTS]: {
        endpoint: (state, action) => `/customer/search?q=${action.payload.q}`,
        method: 'GET',
    },
    [GET_JOB_APPLICATION_DETAILS]: {
        endpoint: (state, action) => `/job-applicants/applicant/${action.payload}/details`,
        method: 'GET',
    },
    [UPDATE_JOB_APPLICATION]: {
        endpoint: (state, action) => `/job-applicants/applicant/${action.payload.applicant_id}`,
        method: 'POST',
        payload: (state, action) => action.payload.data
    },
    [SAVE_SOLANA_WALLET_ON_COMMUNITY]: {
        endpoint: (state, action) => `/community/${action.payload.id}/solana-wallet`,
        method: 'POST',
        payload: (state, action) => {
            return {
                "key": action.payload.key
            }
        }
    },
    [SOLANA_WALLET_GET_TRANSACTIONS_LIST]: {
        endpoint: (state, action) => `/solana/transactions?page=${state.solana.transactions.page}&wallet=${action.payload.wallet ?? ''}`,
        method: 'GET',
    },
    [SOLANA_GET_PRICE]: {
        endpoint: (state, action) => "https://api.coingecko.com/api/v3/coins/solana",
        method: 'GET',
    },
    [SOLANA_CONFIRM_PAYMENT]: {
        endpoint: (state, action) => `/solana/confirm-payment`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
};

export default endpoints;
