import { createStore, applyMiddleware, compose } from 'redux';

// middlewares
import thunkMiddleware from 'redux-thunk'
import apiMiddleware from '../middlewares/api'

// Import custom components
import reducers from '../redux/index';

const devToolsEnhancer = process.env.NODE_ENV !== "production" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
const store = createStore(reducers, devToolsEnhancer(
    applyMiddleware(apiMiddleware),
    applyMiddleware(thunkMiddleware),
));

export default store;
