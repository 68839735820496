// starter kit
import DashboardPage from '../component/dashboard/dashboard'
import CommunityListPage from "../component/community/list";
import CommunityDetailsPage from "../component/community/details";
import ProfilePage from "../component/profile/profile";
import ListPage from "../component/list";
import ListDetailsPage from "../component/list/details";
import LoginPage from "../component/auth/login";
import RedeemPage from "../component/community/redeem";
import EditCommunityPage from "../component/community/edit";
import RecoverPasswordPage from "../component/auth/recover_password";
import RequestRecoverPasswordPage from "../component/auth/request_recover_password";
import ReportsListPage from "../component/report";
import ReportDetailsPage from "../component/report/details";
import Channel from "../component/channel/index";
import CampaignsListPage from "../component/campaign";
import CampaignValidatePage from "../component/campaign/validate";
import LandingPage from "../component/landing";
import CampaignEmailsPage from "../component/campaign/emails";
import OAuthSlack from "../component/oauth/oauth_slack";
import OAuthDiscord from "../component/oauth/oauth_discord";
import OAuthMeetup from "../component/oauth/oauth_meetup";
import JobBoardList from "../component/job_board/list";
import JobApplicantsPage from "../component/job_board/applicants";
import JobDetails from "../component/job_board/details";
import JobApplicationForm from "../component/job_board/job_application_form";
import SolanaTransactionsPage from "../component/solana/list";
import SolanaTransactionsPayPage from "../component/solana/pay";

export const routes = [
    { path:"/", Component: LandingPage },
    { path:"/dashboard", Component: DashboardPage },
    { path:"/directory", Component: CommunityListPage },
    { path:"/community/:id", Component: CommunityDetailsPage },
    { path:"/community/:id/screenshot", Component: CommunityDetailsPage, params: {is_screenshot: true} },
    { path:"/preview-community/:token", Component: CommunityDetailsPage },
    { path:"/profile", Component: ProfilePage },
    { path:"/lists", Component: ListPage },
    { path:"/lists/:id", Component: ListDetailsPage },
    { path:"/login", Component: LoginPage },
    { path:"/redeem-jobs/:community_redeem_token?", Component: JobBoardList },
    { path:"/redeem/:token", Component: RedeemPage },
    { path:"/community/edit/:id", Component: EditCommunityPage },
    { path:"/create-community", Component: EditCommunityPage },
    { path:"/request-recover-password", Component: RequestRecoverPasswordPage },
    { path:"/recover-password/:token", Component: RecoverPasswordPage },
    { path:"/reports", Component: ReportsListPage },
    { path:"/reports/:id", Component: ReportDetailsPage },
    { path: "/campaigns", Component: CampaignsListPage },
    { path: "/campaigns/:id/validate", Component: CampaignValidatePage },
    { path: "/campaigns/:id/emails", Component: CampaignEmailsPage },
    { path:"/inbox", Component: Channel},
    { path:"/oauth/verify-slack", Component: OAuthSlack},
    { path:"/oauth/verify-discord", Component: OAuthDiscord},
    { path:"/oauth/verify-meetup", Component: OAuthMeetup},
    { path:"/jobs/", Component: JobBoardList},
    { path:"/jobs/applicants/list", Component: JobApplicantsPage},
    { path:"/jobs/application-form/:id", Component: JobApplicationForm},
    { path:"/jobs/:token", Component: JobBoardList},
    { path:"/jobs/preview/:token", Component: JobDetails},
    { path:"/solana/transactions", Component: SolanaTransactionsPage},
    { path:"/solana/transactions/pay/:entityId/:entityType/:receiverKey/:bounty", Component: SolanaTransactionsPayPage},
];
