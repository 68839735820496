import React, {Fragment} from 'react';
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {loginUser} from "../../redux/user/actions";
import LoginForm from "./login_form";

class LoginPage extends React.Component {
    render() {
        const {currentUser} = this.props;

        if(currentUser){
            return (<Redirect to={"/"} />);
        }

        return (
            <Fragment>
                <Container fluid={true}>
                    <Row className={"justify-content-center pt-5"}>
                        <Col xl="6" lg="6" md="8" sm="12" xs="12">
                            <Card>
                                <CardHeader className={'text-center'}>
                                    <h5 className="mb-0">WELCOME</h5>
                                    <p className="mb-0">Please login</p>
                                </CardHeader>

                                <CardBody className="p-4">
                                    <LoginForm />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {loginUser};

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.data
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
