import React from "react";
import {Input, Label} from "reactstrap";
import {connect} from "react-redux";
import {
    loadCommunities,
    setFreeSearchForCommunity,
} from '../../../redux/community/actions';

class CommunityNameFilter extends React.Component {

    inputTimeout;

    handleCommunityNameSearch() {
        const {loadCommunities} = this.props;
        if(this.inputTimeout) {
            clearTimeout(this.inputTimeout);
        }

        this.inputTimeout = setTimeout(() => {
            loadCommunities();
        }, 500);
    }

    render() {
        const {setFreeSearchForCommunity, communityNameFilter} = this.props;

        return (
            <div>
                <Label className="d-block">
                    Text search:
                </Label>

                <div className="faq-form m-b-10">
                    <Input type={"text"}
                           value={communityNameFilter}
                           placeholder={"Search here..."}
                           onChange={(e) => {
                               setFreeSearchForCommunity(e.target.value);
                               if (communityNameFilter.length === 0) {
                                   loadCommunities();
                               } else if (communityNameFilter.length > 3) {
                                   this.handleCommunityNameSearch(e.target.value)
                               }
                           }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    communityNameFilter: state.community.name_filter.value
});

const mapDispatchToProps = {
    setFreeSearchForCommunity,
    loadCommunities
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityNameFilter)