import {
    CHANGE_PASSWORD,
    LOAD_USER,
    LOGIN_USER,
    LOGOUT_USER,
    RECOVER_PASSWORD,
    REGISTER_USER,
    SAVE_NEW_PASSWORD,
    SET_USER_AUTH_TOKEN,
    UPDATE_USER,
} from "../action_types";
import cookie from 'react-cookies'
import {toast} from "react-toastify";

function getTokenCookie() {
    let cookieData = cookie.load("AUTH_TOKEN");
    if(cookieData !== "null") {
        return cookieData;
    }
    return null;
}

function setTokenCookie(token) {
    cookie.save("AUTH_TOKEN", token, {
        "path": "/"
    });
}

const initial_state = {
    token: getTokenCookie(),
    data: null,
    loading: false
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case LOAD_USER:
            return {...state, loading: true};
        case LOAD_USER.concat("_SUCCESS"):
            return {...state, loading: false, data: action.payload.data};
        case LOAD_USER.concat("_FAILURE"):
            return {...state, loading: false};
        case UPDATE_USER.concat("_SUCCESS"):
            toast.success("Profile is updated!");
            setTokenCookie(action.payload.data.token);
            return {...state, token: action.payload.data.token};
        case LOGIN_USER:
            return {...state, token: null, loading: true};
        case LOGIN_USER.concat("_SUCCESS"):
            toast.success("Welcome!");
            setTokenCookie(action.payload.data.token);
            return {...state, token: action.payload.data.token, loading: false};
        case LOGIN_USER.concat("_FAILURE"):
            toast.error("Please check your credentials!");
            return {...state, token: null, loading: false};
        case LOGOUT_USER:
            setTokenCookie(null);
            return {...state, token: null, data: null};
        case REGISTER_USER:
            return {...state, token: null, registering: true};
        case REGISTER_USER.concat("_SUCCESS"):
            toast.success("Welcome :)");
            setTokenCookie(action.payload.data.token);
            return {...state, token: action.payload.data.token, registering: false};
        case REGISTER_USER.concat("_FAILURE"):
            toast.error("Please check your data!");
            return {...state, token: null, registering: false};
        case RECOVER_PASSWORD:
            toast.success("We will send you an email shortly!");
            return {...state};
        case SAVE_NEW_PASSWORD:
            toast.success("Password changed. Please proceed to login!");
            return {...state};
        case CHANGE_PASSWORD.concat("_SUCCESS"):
            toast.success("Password changed successfully!");
            return {...state};
        case CHANGE_PASSWORD.concat("_FAILURE"):
            toast.error("Please check your data!");
            return {...state};
        case SET_USER_AUTH_TOKEN:
            setTokenCookie(action.payload);
            return {...state, token: action.payload};
        default:
            return {...state};
    }
}
