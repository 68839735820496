import React from 'react';
import {Button, Card} from 'reactstrap'

class InfoCard extends React.Component {

    Icon(children) {
        const {hasButton} = this.props;

        return React.cloneElement(children, {
            style: {
                width: "80px",
                height: "80px"
            },
            className: hasButton ? "d-none d-lg-block" : "d-block"
        })
    }

    render() {
        const {children, color, description, cardClick, buttonClick, hasButton, buttonLabel, className, isLink, link} = this.props;

        if(isLink) {
            return (
                <a href={link} target={"_blank"} rel={"noopener noreferrer"} className={"w-100 pointer d-flex"}>
                    <Card className={`${className ? className : ""} card-${color} justify-content-center b-r-15 text-white text-left w-100`}>
                        <div className={"d-flex align-items-center justify-content-center p-4 flex-column flex-lg-row"}>
                            {this.Icon(children[0])}
                            <div className={"d-flex flex-column ml-3 mb-3 mb-lg-0 flex-grow-1"}>
                                {children[1]}
                                <span>{description}</span>
                            </div>
                            {hasButton &&
                            (<div>
                                <Button color={"white"} onClick={buttonClick} className={`${color}-text bg-white ml-3 flex-grow-1`}>{buttonLabel}</Button>
                            </div>)}
                        </div>
                    </Card>
                </a>
            );
        }

        return (
            <Card className={`${className ? className : ""} card-${color} justify-content-center b-r-15 text-white text-left`} onClick={cardClick}>
                <div className={"d-flex align-items-center justify-content-center p-4 flex-column flex-lg-row"}>
                    {this.Icon(children[0])}
                    <div className={"d-flex flex-column ml-3 mb-3 mb-lg-0 flex-grow-1"}>
                        {children[1]}
                        <span>{description}</span>
                    </div>
                    {hasButton &&
                    (<div>
                        <Button color={"white"} onClick={buttonClick} className={`${color}-text bg-white ml-3 flex-grow-1`}>{buttonLabel}</Button>
                    </div>)}
                </div>
            </Card>
        );
    }
}

export default InfoCard;
