import React from "react";
import {connect} from "react-redux";
import {Card, CardBody, Col, Row} from "reactstrap";
import {ReactComponent as Communities} from "../../../assets/images/communities.svg";
import {ReactComponent as Members} from "../../../assets/images/user.svg";
import {ReactComponent as Events} from "../../../assets/images/calendar.svg";
import {ReactComponent as Participants} from "../../../assets/images/participant_card.svg";
import NumberFormatter from "../../utils/number_formatter";

class CommunityTotalsReport extends React.Component {
    renderBlockData(Icon, value, label, gradient) {
        const{isNetwork} = this.props;

        return(
            <Col xl={`${isNetwork ? "" : "3"}`} sm="6 box-col-6 xl-50">
                <Card className={`${gradient} o-hidden`}>
                    <CardBody className="b-r-4">
                        <div className="media static-top-widget">
                            <div className="align-self-center text-center">
                                {!isNetwork && Icon}
                            </div>
                            <div className={`media-body ${isNetwork ? "pl-0 text-center" : ""}`}>
                                <span className="m-0 text-white">{label}</span>
                                <h4 className="mb-0 counter text-white"><NumberFormatter value={value}/>
                                </h4>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    render() {
        const {report, community} = this.props;

        if (!report) {
            return null;
        }

        return (
            <div className={'general-widget'}>
                <Row>
                    {community && community.is_network && this.renderBlockData(null, community.nr_of_countries, "Countries", "gradient-blue")}
                    {this.renderBlockData(<Communities/>, report.total_communities, "Communities", "gradient-primary")}
                    {this.renderBlockData(<Members/>, report.total_members, "Members", "gradient-secondary")}
                    {this.renderBlockData(<Events/>, report.total_events, "Events", "gradient-warning")}
                    {this.renderBlockData(<Participants/>, report.total_participants, "Participants", "gradient-info")}
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    report: state.dashboard.insights.community_totals.data,
    community: state.community.selected_community.data,
});

export default connect(
    mapStateToProps,
    null
)(CommunityTotalsReport)
