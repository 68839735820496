import React from "react";
import CustomFieldHeader from "./custom_field_header";

class DateCustomFields extends React.Component {
    render() {
        const {customField, onValueChange} = this.props;

        return (
            <div className="m-b-10">
                <CustomFieldHeader customField={customField} />
                <input className="refer-modal-input form-control"
                       type={"date"}
                       onChange={(e) => onValueChange(e.target.value)}
                       placeholder={customField.title}
                       // required={customField.required}
                />
            </div>
        );
    }
}

export default DateCustomFields;