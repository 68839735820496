import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import InfiniteScroll from "react-infinite-scroller";
import {
    incrementJobApplicantsListPage,
    loadFullJobsListToSearchApplicants,
    loadJobApplicants,
    resetJobApplicants,
    toggleApplicationFormModal,
    toggleApplicationUpdateModal
} from "../../redux/job_applicants/actions";
import NumberFormatter from "../utils/number_formatter";
import SelectedApplicationModal from "../common/job_board/selected_application_modal";
import UpdateApplicationModal from "../common/job_board/update_application_modal";
import CompaniesFilterForApplicants from "./filters/companies_for_applicants";
import JobApplicationStatusViewer from "../common/job_board/job_application_status_viewer";
import JobsFilterForApplicants from "./filters/jobs_for_applicants";
import OrderJobApplicationsList from "./components/order_communities_list";
import {JOB_APPLY_LINK_TYPE_EXTERNAL, JOB_APPLY_LINK_TYPE_INTERNAL} from "../../constants";
import {toast} from "react-toastify";

class JobApplicantsPage extends React.Component {
    componentDidMount() {
        const {loadFullJobsListToSearchApplicants} = this.props;
        const {user} = this.props;

        this.loadInitialJobApplicants();

        if (!user || (user && !user.is_super_admin)) {
            loadFullJobsListToSearchApplicants();
        }
    }

    renderError() {
        return (
            <div className={"p-5 d-flex justify-content-center align-items-center mb-4"}>
                <span className={"f-18"}>There was an error loading jobs.</span>
            </div>
        )
    }

    renderLoading() {
        return (
            <div className={"p-5 d-flex justify-content-center align-items-center mb-4"}>
                <span className={"f-18"}>Loading...</span>
            </div>
        )
    }

    renderNoResult() {
        return (
            <div className="p-5 d-flex justify-content-center align-items-center mb-4"><span className="f-18">There are no Job Applicants.</span>
            </div>)
    }

    renderJobApplicantsList(applicants) {
        const {toggleApplicationFormModal, toggleApplicationUpdateModal} = this.props;
        const {user} = this.props;

        return (
            <div className={"table-responsive"}>
                <table className={"table table-hover"}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Linkedin</th>
                        <th>Job</th>
                        {user && user.is_super_admin && (<th>Company</th>)}
                        <th>
                            <div className={"d-flex"}>
                                <div>System Score</div>
                                <OrderJobApplicationsList currentField={"system_score"}/>
                            </div>
                        </th>
                        <th>Status</th>
                        <th>
                            <div className={"d-flex"}>
                                <div>Date</div>
                                <OrderJobApplicationsList currentField={"date"}/>
                            </div>
                        </th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {applicants.map((applicant) => {
                        return (
                            <tr key={"applicant-key-" + applicant.id}>
                                <td>{applicant.person.full_name}</td>
                                <td>{applicant.person.linkedin_profile_url &&
                                <a href={applicant.person.linkedin_profile_url} target="_blank"
                                   rel="noopener noreferrer">Link</a>}</td>
                                <td>{applicant.job.title}</td>
                                {user && user.is_super_admin && (<td>{applicant.job.company.name}</td>)}
                                <td>{applicant.admin_score}</td>
                                <td><JobApplicationStatusViewer status={applicant.status} user={user}/></td>
                                <td>{applicant.created_at}</td>
                                <td className={"text-right"}>
                                    <div className="btn-group">
                                        <Button color={"primary"} size={"xs"}
                                                onClick={() => toggleApplicationUpdateModal(applicant)}>Update
                                            status</Button>
                                        <Button color={applicant.job.apply_link_type === JOB_APPLY_LINK_TYPE_INTERNAL ? "secondary" : "light"} size={"xs"} onClick={() => {
                                            if(applicant.job.apply_link_type === JOB_APPLY_LINK_TYPE_INTERNAL){
                                                toggleApplicationFormModal(applicant)
                                            } else {
                                                toast.success("Candidate was sent to external link.");
                                            }
                                        }}>View application form</Button>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    loadInitialJobApplicants() {
        const {loadJobApplicants, resetJobApplicants} = this.props;

        resetJobApplicants();
        loadJobApplicants()
    }

    loadApplicantsTimeout;

    loadMoreJobApplicants() {
        const {incrementJobApplicantsListPage, loadJobApplicants} = this.props;
        const {applicants} = this.props;
        const {page, total_pages} = applicants;

        if (this.loadApplicantsTimeout) {
            clearTimeout(this.loadApplicantsTimeout);
        }

        this.loadApplicantsTimeout = setTimeout(() => {
            if (page < total_pages) {
                incrementJobApplicantsListPage();
                loadJobApplicants();
            }
        }, 200);
    }

    render() {
        const {
            toggleApplicationFormModal,
            toggleApplicationUpdateModal,
            loadFullJobsListToSearchApplicants
        } = this.props;
        const {user, applicants} = this.props;
        const {
            page,
            total_pages,
            total_results,
            loading,
            list,
            error,
            selected_application_form,
            update_application_modal
        } = applicants;

        return (
            <Fragment>
                <Breadcrumb title="Job Applicants" subTitle="Filter by your interests"/>
                <Container fluid={true}>
                    <Row>
                        <Col xl="3">
                            <Card>
                                <CardHeader className={'p-4'}>
                                    <div className={"d-flex justify-content-between align-items-center"}>
                                        <h5 className="mb-0">Filter by:</h5>
                                    </div>
                                </CardHeader>


                                <CardBody className="p-4">
                                    {user && user.is_super_admin && (
                                        <CompaniesFilterForApplicants
                                            onCompanySelected={() => {
                                                loadFullJobsListToSearchApplicants()
                                                this.loadInitialJobApplicants()
                                            }} onCompanyUnselected={() => {
                                            loadFullJobsListToSearchApplicants()
                                            this.loadInitialJobApplicants()
                                        }}/>
                                    )}

                                    <JobsFilterForApplicants onJobChanged={() => this.loadInitialJobApplicants()}/>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="9">
                            {selected_application_form.opened &&
                            <SelectedApplicationModal toggleModal={toggleApplicationFormModal}/>}
                            {update_application_modal.opened &&
                            <UpdateApplicationModal toggleModal={toggleApplicationUpdateModal}/>}
                            <Card>
                                <CardBody>
                                    {((!loading && error) || (!user && list.length === 0 && error)) && this.renderError()}
                                    {!loading && !error && list.length === 0 && this.renderNoResult()}
                                    <InfiniteScroll
                                        pageStart={page}
                                        loadMore={() => {
                                            this.loadMoreJobApplicants();
                                        }}
                                        hasMore={page < total_pages && !loading}
                                    >
                                        {list.length > 0 && this.renderJobApplicantsList(list)}
                                    </InfiniteScroll>
                                    {loading && this.renderLoading()}
                                    {total_results > 0 && <div className={"my-5 pl-3 purple-text"}>
                                        <span>Total results: <b><NumberFormatter value={total_results}/></b></span>
                                    </div>}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    loadJobApplicants,
    incrementJobApplicantsListPage,
    resetJobApplicants,
    toggleApplicationUpdateModal,
    loadFullJobsListToSearchApplicants,
    toggleApplicationFormModal
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    jobs: state.jobs,
    applicants: state.job_applicants
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobApplicantsPage)
