import React from 'react';
import {Container, Row, Col} from 'reactstrap'
import Logo from "../../../assets/images/logo/logo.png";

const LandingFooter = (props) => {
    return (
        <footer className="bg-blue footer text-white">
            <Container fluid={true}>
                <Row>
                    <Col className={"footer-copyright d-flex justify-content-between flex-column flex-md-row align-items-center"}>
                        <div className={"d-flex align-items-center flex-column flex-md-row"}>
                            <div className={"mt-1"}>
                                <a href="https://www.iubenda.com/terms-and-conditions/24574118" target="_blank"
                                   rel="noopener noreferrer" className={"p-3 text-white"}>TERMS & CONDITIONS</a>
                                <a href="mailto:support@thecommunityatlas.com" className={"p-3 text-white"}>SUPPORT</a>
                            </div>
                            <div className={"d-flex align-items-center"}>
                                <a href="https://www.facebook.com/thecommunityatlas" target="_blank"
                                   rel="noopener noreferrer"><i className="fa fa-2x fa-facebook-f p-3 text-white"/></a>
                                <a href="https://twitter.com/thecommatlas" target="_blank" rel="noopener noreferrer"><i
                                    className="fa fa-2x fa-twitter p-3 text-white"/></a>
                                <a href="https://www.linkedin.com/company/69233583" target="_blank"
                                   rel="noopener noreferrer"><i className="fa fa-2x fa-linkedin p-3 text-white"/></a>
                            </div>
                        </div>
                        <div>
                            <a href="https://thecommunityatlas.com/" target="_blank" rel="noopener noreferrer"><img
                                src={Logo} width={"200"} style={{filter: "brightness(0) invert(1)"}}
                                alt="TCA-LOGO"/></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default LandingFooter;
