import axios from 'axios'
import ConfigDB from '../config'
import endpoints from '../redux/api_endpoints'
import {
    ADD_ALL_COMMUNITIES_TO_LISTS,
    ADD_COMMUNITIES_TO_LISTS,
    APPLY_TO_JOB,
    CANCEL_CAMPAIGN,
    CANCEL_SUBSCRIPTION_RENEW,
    CONFIRM_CAMPAIGN_PROFILES,
    CREATE_CAMPAIGN,
    CREATE_CHANNEL,
    CREATE_COMMUNITY,
    CREATE_COMMUNITY_LIST,
    CREATE_COMMUNITY_LIST_AND_ADD_ALL_COMMUNITIES,
    CREATE_COMMUNITY_LIST_AND_ADD_COMMUNITIES,
    CREATE_REPORT,
    DELETE_COMMUNITY_LIST,
    DELETE_REPORT,
    DISCONNECT_DISCORD,
    DISCONNECT_SLACK,
    GET_COMMUNITY_TO_REDEEM, GET_REFERRER_DETAILS,
    LOAD_CAMPAIGN_DETAILS,
    LOAD_USER,
    LOGIN_USER,
    REDEEM_COMMUNITY,
    REFER_JOB,
    REGISTER_USER,
    REMOVE_COMMUNITY_FROM_LIST,
    SAVE_COMMUNITY_EMAIL,
    SAVE_CONTACTED_MEMBER_EMAIL,
    SAVE_NOT_CONTACTED_MEMBER_EMAIL, SAVE_SOLANA_WALLET_ON_COMMUNITY,
    SEND_TO_MAIL_MARKETING_TOOL,
    UPDATE_USER,
    VERIFY_ALREADY_CONTACTED_COMMUNITIES
} from "../redux/action_types";
import {loadUser, logoutUser, setUserAuthToken} from "../redux/user/actions";
import {loadCustomer, loadSubscriptions} from "../redux/customer/actions";
import {
    addAllCommunitiesToLists,
    addCommunitiesToLists,
    loadCommunitiesOfList,
    loadCommunityLists,
    loadLiteCommunityLists
} from "../redux/community_list/actions";
import {
    clearCommunityRedeemToken,
    disableDiscordOnEditCommunity,
    disableSlackOnEditCommunity,
    loadCommunities,
    redeemCommunity
} from "../redux/community/actions";
import {loadReports} from "../redux/report/actions";
import {loadChannels} from '../redux/channel/actions';
import {
    clearSelectedCampaign,
    createCampaign,
    loadCampaignCommunities,
    loadCampaignDetails,
    loadCampaigns
} from "../redux/campaign/actions";
import {
    loadJobs,
    resetJobBoard,
    setSelectedCommunity,
    toggleSuccessApplyToJobModal,
    toggleSuccessReferJobModal
} from "../redux/job/actions";
import {toast} from "react-toastify";
import {loadJobApplicants} from "../redux/job_applicants/actions";

const logSuccess = (res, type) => {
    if (process.env.NODE_ENV === "production") {
        return;
    }

    console.log(
        '%cAPI Response', 'background: green; color: black', type, '\n',
        // 'URL:', res.config.url, '\n',
        'Response:', res, '\n',
        'Data:', res.data,
    )
};

let timeout;
const publishMessageWithTimeout = (toastMessage) => {
    if (timeout) {
        clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
        toastMessage();
    }, 1000)

}

const publishErrorMessage = (response) => {
    publishMessageWithTimeout(() => toast.error(response.error));
};

const publishSuccessMessage = (response) => {
    return;
    if (response.message) {
        toast.success(response.message);
    }
};

const logFailure = (err, type) => {
    if (process.env.NODE_ENV === "production") {
        return;
    }

    if (err.response !== undefined) {
        console.error(
            '%cAPI Error', 'background: red; color: white', type, '\n',
            'Error:', err, '\n',
            // 'URL:', err.config.url, '\n',
            'Response:', err.response,
            'Data:', err.response.data,
        )
    } else {
        console.error(
            '%cAPI Error', 'background: red; color: white', type, '\n',
            'Error:', err, '\n',
            // 'URL:', err.config.url, '\n',
        )
    }
};

const handleAlternativeActions = (dispatch, type, payload, state, previousPayload) => {
    if (type === UPDATE_USER || type === REDEEM_COMMUNITY || type === SAVE_SOLANA_WALLET_ON_COMMUNITY) {
        dispatch(loadUser())
    } else if (type === CANCEL_SUBSCRIPTION_RENEW) {
        dispatch(loadSubscriptions())
    } else if (type === LOAD_USER && payload.data != null) {
        dispatch(loadCustomer());
        dispatch(loadSubscriptions());
        dispatch(loadChannels());

        if (payload.data.is_super_admin || payload.data.is_community) {
            dispatch(setSelectedCommunity(payload.data.communities[0]));
            dispatch(resetJobBoard());
            dispatch(loadJobs());
        }

        if (payload.data.is_super_admin || payload.data.is_company) {
            dispatch(loadCommunityLists());
            dispatch(loadLiteCommunityLists());
            dispatch(loadReports());
            dispatch(loadCampaigns());
        }
    } else if ((type === ADD_COMMUNITIES_TO_LISTS && payload.data === "OK") || (type === ADD_ALL_COMMUNITIES_TO_LISTS && payload.data === "OK") || type === CREATE_COMMUNITY_LIST) {
        dispatch(loadCommunityLists());
        dispatch(loadLiteCommunityLists());
    } else if (type === CREATE_COMMUNITY_LIST_AND_ADD_COMMUNITIES && previousPayload.community_ids) {
        dispatch(addCommunitiesToLists(previousPayload.community_ids, [payload.data.id]));
    } else if (type === CREATE_COMMUNITY_LIST_AND_ADD_ALL_COMMUNITIES) {
        dispatch(addAllCommunitiesToLists([payload.data.id]));
    } else if (type === REMOVE_COMMUNITY_FROM_LIST) {
        dispatch(loadCommunitiesOfList(payload.data.list_id));
        // dispatch(loadListDetails(payload.data.list_id));
        dispatch(loadCommunityLists());
        dispatch(loadLiteCommunityLists());
    } else if (type === DELETE_COMMUNITY_LIST) {
        dispatch(loadCommunityLists());
    } else if (type === DELETE_REPORT || type === CREATE_REPORT) {
        dispatch(loadReports());
    } else if (type === LOGIN_USER || type === REGISTER_USER) {
        dispatch(loadUser());
        if (previousPayload.is_redeeming) {
            dispatch(redeemCommunity(state.community.redeem_token));
            dispatch(clearCommunityRedeemToken());
        }
        dispatch(loadCommunities());
    } else if (type === CREATE_CHANNEL) {
        dispatch(loadChannels());
    } else if (type === LOAD_CAMPAIGN_DETAILS) {
        dispatch(loadCampaignCommunities());
    } else if (type === CONFIRM_CAMPAIGN_PROFILES) {
        dispatch(clearSelectedCampaign());
        dispatch(loadCampaignDetails(payload.data.id));
    } else if (type === VERIFY_ALREADY_CONTACTED_COMMUNITIES) {
        if (payload.data.length === 0) {
            dispatch(createCampaign());
        }
    } else if (type === CREATE_CAMPAIGN || type === CANCEL_CAMPAIGN) {
        dispatch(loadCampaigns());
        dispatch(loadCustomer());
    } else if (type === REFER_JOB) {
        dispatch(toggleSuccessReferJobModal(payload.data));
    } else if (type === APPLY_TO_JOB) {
        dispatch(toggleSuccessApplyToJobModal());
    } else if (type === GET_COMMUNITY_TO_REDEEM && !state.user.token) {
        dispatch(resetJobBoard());
        dispatch(loadJobs());
    } else if (type === CREATE_COMMUNITY) {
        if (!state.user.token) {
            dispatch(setUserAuthToken(payload.data.token));
        }

        dispatch(loadUser());
    } else if (type === DISCONNECT_SLACK) {
        dispatch(disableSlackOnEditCommunity());
    } else if (type === DISCONNECT_DISCORD) {
        dispatch(disableDiscordOnEditCommunity());
    } else if (type === GET_REFERRER_DETAILS) {
        dispatch(setSelectedCommunity(payload.data.community));
    }
};

const handleErrorActions = (dispatch, type) => {
    if (type === LOAD_USER) {
        dispatch(logoutUser());
    } else if (type === SAVE_COMMUNITY_EMAIL || type === SAVE_CONTACTED_MEMBER_EMAIL || type === SAVE_NOT_CONTACTED_MEMBER_EMAIL) {
        toast.error("Error saving email");
    } else if (type === SEND_TO_MAIL_MARKETING_TOOL) {
        toast.error("Failed to send to mail marketing tool");
    }
};

let abortControllerReference = {};

const handleApiCall = (store, action) => {
    if (abortControllerReference[action.type] && action.fetch_safe) {
        abortControllerReference[action.type].abort();
    }

    var controller = new AbortController();

    const state = store.getState();
    const {dispatch} = store;
    const instance = axios.create();

    instance.interceptors.response.use(
        response => response,
        (error) => {
            return Promise.reject(error)
        },
    );

    instance.request({
        baseURL: ConfigDB.data.api.host,
        headers: {
            'Content-Type': action.file ? 'multipart/form-data' : 'application/json',
            ...(state.user.token ? {'X-AUTH-TOKEN': state.user.token} : {})
        },
        signal: controller.signal,
        method: endpoints[action.type].method,
        url: endpoints[action.type].endpoint(state, action),
        data: (
            endpoints[action.type].payload === undefined
                ? undefined
                : endpoints[action.type].payload(state, action)
        ),
    })
        .then((res) => {
            dispatch({
                type: action.type.concat('_SUCCESS'),
                payload: res.data,
                origin_action_payload: action.payload,
            });
            logSuccess(res, action.type);
            publishSuccessMessage(res.data);
            handleAlternativeActions(dispatch, action.type, res.data, state, action.payload)
        })
        .catch((err) => {
            if (err && err.message === "canceled") {
                return;
            }
            dispatch({
                type: action.type.concat('_FAILURE'),
                origin_action_payload: action.payload,
                payload: err.response ? err.response.data : null
            });
            logFailure(err, action.type);
            handleErrorActions(dispatch, action.type);
            if (err.response && err.response.status === 429) {
                publishErrorMessage(err.response.data);
            }
            if (err.response !== undefined) {
                // publishErrorMessage(err.response.data);

                // handleErrorMessage(
                //     dispatch,
                //     err.response.data,
                // )
            }
        });

    if (action.fetch_safe) {
        abortControllerReference[action.type] = controller;
    }
};

export default (store) => (next) => (action) => {
    if (action.api === true) handleApiCall(store, action);
    next(action)
}
