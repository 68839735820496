import {
    CLEAR_SELECTED_CAMPAIGN, CONFIRM_CAMPAIGN_PROFILES, CREATE_CAMPAIGN,
    DECREMENT_CAMPAIGN_COMMUNITIES_PAGE,
    DECREMENT_CAMPAIGNS_PAGE, CANCEL_CAMPAIGN,
    INCREMENT_CAMPAIGN_COMMUNITIES_PAGE,
    INCREMENT_CAMPAIGNS_PAGE,
    INCREMENT_MEMBERS_OF_COMMUNITY_PAGE,
    LOAD_CAMPAIGN_COMMUNITIES,
    LOAD_CAMPAIGN_DETAILS,
    LOAD_CAMPAIGN_EMAILS,
    LOAD_CAMPAIGNS,
    LOAD_COMMUNITY_MEMBERS,
    ADD_EMAIL,
    DELETE_EMAIL,
    DELETE_LOCAL_EMAIL,
    SAVE_COMMUNITY_EMAIL,
    SAVE_CONTACTED_MEMBER_EMAIL,
    SAVE_NOT_CONTACTED_MEMBER_EMAIL,
    TOGGLE_COMMUNITY_CONTAINER,
    TOGGLE_COMMUNITY_MEMBER_CONTAINER,
    TOGGLE_COMMUNITY_MEMBER_HIDDEN_PROFILES,
    TOGGLE_LINKEDIN_PROFILE_VERIFICATION,
    VERIFY_ALREADY_CONTACTED_COMMUNITIES,
    CLOSE_CONFIRM_CONTACTED_COMMUNITIES_MODAL,
    SET_CONTACT_MODE,
    UPDATE_CAMPAIGN_EMAIL_ORDER,
    GET_MAIL_MARKETING_PLACEHOLDERS,
    SEND_TO_MAIL_MARKETING_TOOL,
} from "../action_types";
import {toast} from "react-toastify";
import {
    CAMPAIGN_CONTACT_MODE_ALL,
    CAMPAIGN_EMAIL_TYPE_COMMUNITY,
    CAMPAIGN_EMAIL_TYPE_CONTACTED_MEMBER,
    CAMPAIGN_EMAIL_TYPE_NOT_CONTACTED_MEMBER
} from "../../constants";

const initial_state = {
    page: 1,
    list: [],
    loading: true,
    total_pages: 0,
    total_results: 0,
    selected: {
        campaign: null,
        loading: false,
        communities: {
            list: [],
            loading: false,
            page: 1,
            total_pages: 0,
            total_results: 0,
        },
        emails: {
            community: [],
            contacted_member: [],
            not_contacted_member: [],
            loading: false,
            initialized: false,
            placeholders: [],
            loading_placeholders: true,
        }
    },
    new_campaign: {
        list_id: null,
        message: null,
        contact_mode: CAMPAIGN_CONTACT_MODE_ALL,
        open_modal_to_verify_already_contacted_communities: false,
        contacted_communities: {
            list: [],
        },
    }
};

export default (state = initial_state, action) => {
    var selectedCampaign = null;
    var communities = null;
    var emails = null;

    switch (action.type) {
        case LOAD_CAMPAIGNS:
            return {...state, loading: true};
        case LOAD_CAMPAIGNS.concat("_SUCCESS"):
            return {
                ...state,
                loading: false,
                list: action.payload.data,
                total_pages: action.payload.total_pages,
                total_results: action.payload.total_results
            };
        case LOAD_CAMPAIGNS.concat("_FAILURE"):
            return {...state, loading: false};
        case VERIFY_ALREADY_CONTACTED_COMMUNITIES:
            return {...state,
                new_campaign: {
                    ...state.new_campaign,
                    message: action.payload.message,
                    list_id: action.payload.list_id,
                }
            };
        case VERIFY_ALREADY_CONTACTED_COMMUNITIES.concat("_SUCCESS"):
            let contactedCommunities = Object.assign({}, state.new_campaign.contacted_communities.list);
            contactedCommunities = action.payload.data;

            return {
                ...state,
                new_campaign: {
                    ...state.new_campaign,
                    open_modal_to_verify_already_contacted_communities: contactedCommunities.length > 0,
                    contacted_communities: {
                        ...state.new_campaign.contacted_communities,
                        list: contactedCommunities,
                    }
                }
            }
        case VERIFY_ALREADY_CONTACTED_COMMUNITIES.concat("_FAILURE"):
            return {
                ...state,
                new_campaign: {
                    ...state.new_campaign,
                    list_id: null,
                    message: null,
                    contact_mode: CAMPAIGN_CONTACT_MODE_ALL,
                    open_modal_to_verify_already_contacted_communities: false,
                    contacted_communities: {
                        ...state.new_campaign.contacted_communities,
                        list: [],
                    }
                }
            };
        case SET_CONTACT_MODE:
            let contactMode = action.payload.contact_mode;

            return {
                ...state,
                new_campaign: {
                    ...state.new_campaign,
                    contact_mode: contactMode
                }
            }
        case CLOSE_CONFIRM_CONTACTED_COMMUNITIES_MODAL:
            return {
                ...state,
                new_campaign: {
                    ...state.new_campaign,
                    open_modal_to_verify_already_contacted_communities: false,
                }
            }
        case CREATE_CAMPAIGN.concat("_SUCCESS"):
            toast.success("Campaign created successfully.");
            return {
                ...state,
                new_campaign: {
                    ...state.new_campaign,
                    list_id: null,
                    message: null,
                    contact_mode: CAMPAIGN_CONTACT_MODE_ALL,
                    open_modal_to_verify_already_contacted_communities: false,
                    contacted_communities: {
                        ...state.new_campaign.contacted_communities,
                        list: [],
                    }
                }
            }
        case CREATE_CAMPAIGN.concat("_FAILURE"):
            toast.error("There was an issue creating the Campaign.");
            return {
                ...state,
                new_campaign: {
                    ...state.new_campaign,
                    list_id: null,
                    message: null,
                    contact_mode: CAMPAIGN_CONTACT_MODE_ALL,
                    open_modal_to_verify_already_contacted_communities: false,
                    contacted_communities: {
                        ...state.new_campaign.contacted_communities,
                        list: [],
                    }
                }
            }
        case CANCEL_CAMPAIGN.concat("_SUCCESS"):
            toast.success("Campaign cancelled successfully.");
            return {...state};
        case CANCEL_CAMPAIGN.concat("_FAILURE"):
            toast.error("There was an issue cancelling the Campaign.");
            return {...state};
        case INCREMENT_CAMPAIGNS_PAGE:
            var page = state.page;
            var totalPages = state.total_pages;

            if ((page) === totalPages) {
                return {...state};
            }

            return {
                ...state,
                page: page + 1
            };
        case DECREMENT_CAMPAIGNS_PAGE:
            var page = state.page;
            if (page === 0) {
                return {...state};
            }

            return {
                ...state,
                page: page - 1
            };
        case CLEAR_SELECTED_CAMPAIGN:
            return {...state, selected: initial_state.selected};
        case LOAD_CAMPAIGN_DETAILS:
            selectedCampaign = Object.assign({}, state.selected);
            selectedCampaign.loading = true;

            return {...state, selected: selectedCampaign};
        case LOAD_CAMPAIGN_DETAILS.concat("_SUCCESS"):
            selectedCampaign = Object.assign({}, state.selected);
            selectedCampaign.loading = false;
            selectedCampaign.campaign = action.payload.data;

            return {...state, selected: selectedCampaign};
        case LOAD_CAMPAIGN_DETAILS.concat("_FAILURE"):
            selectedCampaign = Object.assign({}, state.selected);
            selectedCampaign.loading = false;

            return {...state, selected: selectedCampaign};
        case INCREMENT_CAMPAIGN_COMMUNITIES_PAGE:
            communities = Object.assign({}, state.selected.communities);
            if (communities.page < communities.total_pages) {
                communities.page++;
            }

            return {...state, selected: {...state.selected, communities: communities}};
        case DECREMENT_CAMPAIGN_COMMUNITIES_PAGE:
            communities = Object.assign({}, state.selected.communities);
            if (communities.page > 0) {
                communities.page--;
            }

            return {...state, selected: {...state.selected, communities: communities}};
        case LOAD_CAMPAIGN_COMMUNITIES:
            communities = Object.assign({}, state.selected.communities);
            communities.loading = true;

            return {...state, selected: {...state.selected, communities: communities}};
        case LOAD_CAMPAIGN_COMMUNITIES.concat("_SUCCESS"):
            communities = Object.assign({}, state.selected.communities);
            communities.loading = false;
            communities.list = communities.list.concat(action.payload.data);
            communities.total_pages = action.payload.total_pages;
            communities.total_results = action.payload.total_results;

            return {...state, selected: {...state.selected, communities: communities}};
        case LOAD_CAMPAIGN_COMMUNITIES.concat("_FAILURE"):
            communities = Object.assign({}, state.selected.communities);
            communities.loading = false;

            return {...state, selected: {...state.selected, communities: communities}};
        case LOAD_CAMPAIGN_EMAILS:
            emails = Object.assign({}, state.selected.emails);
            emails.loading = true;

            return {...state, selected: {...state.selected, emails: emails}};
        case LOAD_CAMPAIGN_EMAILS.concat("_SUCCESS"):
            emails = Object.assign({}, state.selected.emails);
            emails.community = action.payload.data.community;
            emails.contacted_member = action.payload.data.contacted_member;
            emails.not_contacted_member = action.payload.data.not_contacted_member;
            emails.loading = false;
            emails.initialized = true;

            return {...state, selected: {...state.selected, emails: emails}};
        case LOAD_CAMPAIGN_EMAILS.concat("_FAILURE"):
            emails = Object.assign({}, state.selected.emails);
            emails.loading = false;

            return {...state, selected: {...state.selected, emails: emails}};
        case ADD_EMAIL:
            emails = Object.assign({}, state.selected.emails);
            emails[action.payload.type].push(action.payload.email);

            return {...state, selected: {...state.selected, emails: emails}};
        case SAVE_COMMUNITY_EMAIL.concat("_SUCCESS"):
            emails = Object.assign({}, state.selected.emails);
            if(action.payload.data.temp_id) {
                emails[CAMPAIGN_EMAIL_TYPE_COMMUNITY].forEach((email, index) => {
                    if(email.temp_id === action.payload.data.temp_id) {
                        emails[CAMPAIGN_EMAIL_TYPE_COMMUNITY][index] = action.payload.data;
                    }
                })
            }

            return {...state, selected: {...state.selected, emails: emails}};
        case SAVE_CONTACTED_MEMBER_EMAIL.concat("_SUCCESS"):
            emails = Object.assign({}, state.selected.emails);
            if(action.payload.data.temp_id) {
                emails[CAMPAIGN_EMAIL_TYPE_CONTACTED_MEMBER].forEach((email, index) => {
                    if(email.temp_id === action.payload.data.temp_id) {
                        emails[CAMPAIGN_EMAIL_TYPE_CONTACTED_MEMBER][index] = action.payload.data;
                    }
                })
            }

            return {...state, selected: {...state.selected, emails: emails}};
        case SAVE_NOT_CONTACTED_MEMBER_EMAIL.concat("_SUCCESS"):
            emails = Object.assign({}, state.selected.emails);
            if(action.payload.data.temp_id) {
                emails[CAMPAIGN_EMAIL_TYPE_NOT_CONTACTED_MEMBER].forEach((email, index) => {
                    if(email.temp_id === action.payload.data.temp_id) {
                        emails[CAMPAIGN_EMAIL_TYPE_NOT_CONTACTED_MEMBER][index] = action.payload.data;
                    }
                })
            }

            return {...state, selected: {...state.selected, emails: emails}};
        case UPDATE_CAMPAIGN_EMAIL_ORDER.concat("_SUCCESS"):
            emails = Object.assign({}, state.selected.emails);
            emails.community = action.payload.data.community;
            emails.contacted_member = action.payload.data.contacted_member;
            emails.not_contacted_member = action.payload.data.not_contacted_member;

            return {...state, selected: {...state.selected, emails: emails}};
        case DELETE_LOCAL_EMAIL:
            emails = Object.assign({}, state.selected.emails);
            emails[action.payload.type].forEach((email, index) => {
                if(email.temp_id === action.payload.temp_id) {
                    emails[action.payload.type].splice(index, 1);
                }
            })

            return {...state, selected: {...state.selected, emails: emails}};
        case DELETE_EMAIL.concat("_SUCCESS"):
            emails = Object.assign({}, state.selected.emails);
            emails[action.origin_action_payload.type].forEach((email, index) => {
                if(email.id == action.origin_action_payload.id) {
                    emails[action.origin_action_payload.type].splice(index, 1);
                }
            })
            toast.success("Email deleted successfully");

            return {...state, selected: {...state.selected, emails: emails}};
        case LOAD_COMMUNITY_MEMBERS:
            communities = [].concat(state.selected.communities.list);
            communities.map((community) => {
                if (community.id == action.payload.community_id) {
                    community.members.loading = true;
                }

                return community;
            });

            return {
                ...state, selected: {
                    ...state.selected, communities: {
                        ...state.selected.communities,
                        list: communities
                    }
                }
            };
        case TOGGLE_COMMUNITY_CONTAINER:
            communities = [].concat(state.selected.communities.list);
            communities.map((community) => {
                if (community.id == action.payload.id) {
                    community.container_is_open = community.container_is_open === false;
                }

                return community;
            });

            return {
                ...state, selected: {
                    ...state.selected, communities: {
                        ...state.selected.communities,
                        list: communities
                    }
                }
            };
        case TOGGLE_COMMUNITY_MEMBER_CONTAINER:
            communities = [].concat(state.selected.communities.list);
            communities.map((community) => {
                    if (community.id == action.payload.community_id) {
                        community.members.list.map((member) => {
                            if (member.member.id == action.payload.member_id) {
                                member.member.container_is_open = member.member.container_is_open === false;

                                return member;
                            }
                        });
                    }

                    return community;
                }
            );

            return {
                ...state, selected: {
                    ...state.selected, communities: {
                        ...state.selected.communities,
                        list: communities
                    }
                }
            };
        case TOGGLE_COMMUNITY_MEMBER_HIDDEN_PROFILES:
            communities = [].concat(state.selected.communities.list);
            communities.map((community) => {
                    if (community.id == action.payload.community_id) {
                        community.members.list.map((member) => {
                            if (member.member.id == action.payload.member_id) {
                                if (member.member.show_hidden_profiles === undefined) {
                                    member.member.show_hidden_profiles = false;
                                }

                                member.member.show_hidden_profiles = member.member.show_hidden_profiles === false;

                                return member;
                            }
                        });
                    }

                    return community;
                }
            );

            return {
                ...state, selected: {
                    ...state.selected, communities: {
                        ...state.selected.communities,
                        list: communities
                    }
                }
            };
        case LOAD_COMMUNITY_MEMBERS.concat("_SUCCESS"):
            communities = [].concat(state.selected.communities.list);
            communities.map((community) => {
                if (community.id == action.origin_action_payload.community_id) {
                    community.members.loading = false;
                    community.members.initialized = true;
                    community.members.list = community.members.list.concat(action.payload.data);
                    community.members.total_results = action.payload.total_results;
                    community.members.total_pages = action.payload.total_pages;
                }

                return community;
            });

            return {
                ...state, selected: {
                    ...state.selected, communities: {
                        ...state.selected.communities,
                        list: communities
                    }
                }
            };
        case LOAD_COMMUNITY_MEMBERS.concat("_FAILURE"):
            communities = [].concat(state.selected.communities.list);
            communities.map((community) => {
                if (community.id == action.origin_action_payload.community_id) {
                    community.members.loading = false;
                }

                return community;
            });

            return {
                ...state, selected: {
                    ...state.selected, communities: {
                        ...state.selected.communities,
                        list: communities
                    }
                }
            };
        case INCREMENT_MEMBERS_OF_COMMUNITY_PAGE:
            communities = [].concat(state.selected.communities.list);
            communities.map((community) => {
                if (community.id == action.payload.community_id) {
                    community.members.page++;
                }

                return community;
            });

            return {
                ...state, selected: {
                    ...state.selected, communities: {
                        ...state.selected.communities,
                        list: communities
                    }
                }
            };
        case TOGGLE_LINKEDIN_PROFILE_VERIFICATION:
            communities = [].concat(state.selected.communities.list);
            communities.map((community) => {
                if (community.id == action.payload.community_id) {
                    community.members.list.map((member) => {
                        if (member.id == action.payload.member_id) {
                            member.profiles.map((profile) => {
                                if (profile.id == action.payload.profile_id) {
                                    profile.verified = profile.verified === false;
                                }

                                return profile;
                            });

                            return member;
                        }
                    });
                }

                return community;
            });

            return {
                ...state, selected: {
                    ...state.selected, communities: {
                        ...state.selected.communities,
                        list: communities
                    }
                }
            };
        case CONFIRM_CAMPAIGN_PROFILES.concat("_SUCCESS"):
            selectedCampaign = Object.assign({}, state.selected);
            selectedCampaign.loading = true;

            return {...state, selected: selectedCampaign};
        case GET_MAIL_MARKETING_PLACEHOLDERS:
            emails = Object.assign({}, state.selected.emails);
            emails.loading_placeholders = true;

            return {...state, selected: {...state.selected, emails: emails}};
        case GET_MAIL_MARKETING_PLACEHOLDERS.concat("_SUCCESS"):
            emails = Object.assign({}, state.selected.emails);
            emails.placeholders = action.payload.data;
            emails.loading_placeholders = false;

            return {...state, selected: {...state.selected, emails: emails}};
        case GET_MAIL_MARKETING_PLACEHOLDERS.concat("_FAILURE"):
            emails = Object.assign({}, state.selected.emails);
            emails.loading_placeholders = false;

            return {...state, selected: {...state.selected, emails: emails}};
        case SEND_TO_MAIL_MARKETING_TOOL.concat("_SUCCESS"):
            toast.success("Send to mail marketing tool successfully");
            return state;
        default:
            return state;
    }
}
