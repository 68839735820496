import {
    SOLANA_WALLET_SET_WALLET_PUBLIC_KEY,
    SOLANA_WALLET_SET_WALLET_FOUND,
    SOLANA_WALLET_GET_TRANSACTIONS_LIST,
    SOLANA_WALLET_INCREMENT_TRANSACTIONS_LIST_PAGE,
    SOLANA_WALLET_CLEAR_TRANSACTIONS_LIST,
    SOLANA_GET_PRICE,
    SOLANA_CONFIRM_PAYMENT
} from "../action_types";

export function setWalletFound(value) {
    return {
        type: SOLANA_WALLET_SET_WALLET_FOUND,
        payload: value
    }
}

export function setWalletPublicKey(publicKey) {
    return {
        type: SOLANA_WALLET_SET_WALLET_PUBLIC_KEY,
        payload: publicKey
    }
}

export function incrementSolanaTransactionsList() {
    return {
        type: SOLANA_WALLET_INCREMENT_TRANSACTIONS_LIST_PAGE,
    }
}

export function clearSolanaTransactionsList() {
    return {
        type: SOLANA_WALLET_CLEAR_TRANSACTIONS_LIST,
    }
}

export function getSolanaTransactionsList(wallet) {
    return {
        type: SOLANA_WALLET_GET_TRANSACTIONS_LIST,
        api: true,
        payload: {
            wallet
        }
    }
}

export function getSolanaPrice() {
    return {
        type: SOLANA_GET_PRICE,
        api: true
    }
}

export function solanaConfirmPayment(entityId, entityType, transactionId) {
    return {
        type: SOLANA_CONFIRM_PAYMENT,
        api: true,
        payload: {
            entity_id: entityId,
            entity_type: entityType,
            transaction_id: transactionId
        }
    }
}
