import React, {Fragment, useRef} from "react";
import {Badge, Label} from "reactstrap";
import {connect} from "react-redux";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {
    resetAllTopicFilters,
    searchTopic,
    selectTopicToFilter,
    unselectTopicToFilter
} from "../../../redux/filters/actions";
import {withRouter} from "react-router";

class TopicFilter extends React.Component {
    componentDidMount() {
        let keepFilters = this.props.location.search;
        if(keepFilters.indexOf('kf=1') > -1) {
            return;
        }
        this.props.resetAllTopicFilters();
    }

    buildListOfSelectedTopics() {
        const {topicFilters, unselectTopicToFilter, load} = this.props;
        const {selected_topics} = topicFilters;

        return selected_topics.map((topic) => {
            return (
                <Badge className={"pointer"} color={"info"} key={topic.id + topic.type} onClick={() => {
                    unselectTopicToFilter(topic);
                    load();
                }}>{topic.name}{topic.country ? " Area - " + topic.country : ''} x</Badge>
            );
        })
    }

    render() {
        const {load, searchTopic, selectTopicToFilter, topicFilters} = this.props;
        const {searching, options} = topicFilters;

        return (
            <div>
                <Label className="d-block">
                    Tag:
                </Label>

                <div className="faq-form m-b-10">
                    <TypeaheadComponent searching={searching}
                                        searchTopic={searchTopic}
                                        options={options}
                                        selectTopicToSearch={(selectedTopic) => {
                                            selectTopicToFilter(selectedTopic);
                                        }}
                                        load={load}/>
                </div>

                <div className="m-b-10">
                    {this.buildListOfSelectedTopics()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    topicFilters: state.filters.topic_filters
});

const mapDispatchToProps = {
    selectTopicToFilter,
    unselectTopicToFilter,
    searchTopic,
    resetAllTopicFilters,
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TopicFilter));

const TypeaheadComponent = (props) => {
    const ref = useRef();

    return (
        <Fragment>
            <AsyncTypeahead
                id="search-for-topic"
                isLoading={props.searching}
                labelKey="name"
                minLength={3}
                onSearch={props.searchTopic}
                options={props.options}
                placeholder="Type here..."
                renderMenuItemChildren={(option, props) => {
                    return (
                        <Fragment>
                            <span>{option.name}</span>
                        </Fragment>
                    )
                }}
                onChange={(selected) => {
                    if (selected.length > 0) {
                        props.selectTopicToSearch(selected[0]);
                        props.load();
                    }

                    ref.current.clear();
                }}
                ref={ref}
            />
        </Fragment>
    );
};
