import {
    APPLY_TO_JOB,
    CHANGE_BOUNTY_PORTION,
    DECREMENT_JOB_LIST_PAGE,
    GET_JOB_DETAILS,
    GET_REFERRER_DETAILS,
    INCREMENT_JOB_LIST_PAGE,
    LOAD_JOBS,
    RESET_JOB_BOARD,
    SET_JOB_BOARD_TOKEN,
    SET_JOB_TOKEN,
    SET_SELECTED_COMMUNITY,
    SUBSCRIBE_TO_JOB_BOUNTIES,
    SUBSCRIBE_TO_JOB_BOUNTIES_VIA_CTA,
    TOGGLE_APPLY_TO_JOB_MODAL,
    TOGGLE_REFER_JOB_MODAL,
    TOGGLE_REMOTE_JOB_FILTER,
    TOGGLE_MY_COMPANY_JOB_FILTER,
    TOGGLE_SUBSCRIBE_TO_JOBS_MODAL,
    TOGGLE_SUCCESS_APPLY_TO_JOB_MODAL,
    TOGGLE_SUCCESS_REFER_JOB_MODAL,
    GET_JOB_APPLICATION_FORM_DETAILS,
    UPDATE_JOB_APPLICATION_FORM_DATA,
    SAVE_JOB_APPLICATION_FORM,
} from "../action_types";
import {toast} from "react-toastify";

const initial_state = {
    list: [],
    loading: false,
    page: 1,
    total_pages: 0,
    total_results: 0,
    error: false,
    selected_job: {
        loading: false,
        data: null,
        refer_modal_open: false,
        success_refer_modal: {
            subscribed: false,
            subscriber: null,
            open: false
        },
        apply_to_job_modal_open: false,
        success_apply_to_job_modal_open: false,
    },
    selected_community: null,
    referrer_details: {
        loading: false,
        data: null,
    },
    job_board_token: null,
    job_token: null,
    location_filters: {
        searching: false,
        options: [],
        selected_locations: []
    },
    filters: {
        is_remote: false,
        my_jobs: false
    },
    subscribe: {
        modal_open: false
    },
    application_form: {
        data: null,
        loading: false
    }
};

export default (state = initial_state, action) => {
    let page;
    let selectedJob;
    let successReferModal;
    let selectedCommunity;
    let referrerDetails;
    let list;
    let filters;

    switch (action.type) {
        case LOAD_JOBS:
            return {...state, loading: true};
        case LOAD_JOBS.concat("_SUCCESS"):
            list = [...state.list];

            return {
                ...state,
                loading: false,
                total_pages: action.payload.total_pages,
                total_results: action.payload.total_results,
                list: list.concat(action.payload.data),
            };
        case LOAD_JOBS.concat("_FAILURE"):
            return {
                ...state,
                error: true,
                loading: false
            }
        case GET_JOB_DETAILS:
            selectedJob = Object.assign({}, state.selected_job);
            selectedJob.loading = true;
            return {...state, selected_job: selectedJob};
        case GET_JOB_DETAILS.concat("_SUCCESS"):
            selectedJob = Object.assign({}, state.selected_job);
            selectedJob.loading = false;
            selectedJob.data = action.payload.data;
            return {...state, selected_job: selectedJob};
        case GET_JOB_DETAILS.concat("_FAILURE"):
            selectedJob = Object.assign({}, state.selected_job);
            selectedJob.loading = false;
            return {...state, selected_job: selectedJob};
        case SET_SELECTED_COMMUNITY:
            return {...state, selected_community: action.payload}
        case CHANGE_BOUNTY_PORTION.concat("_SUCCESS"):
            selectedCommunity = Object.assign({}, state.selected_community);
            selectedCommunity.job_referral_token.referral_bounty_portion = action.payload.data.referral_bounty_portion;
            return {...state, selected_community: selectedCommunity}
        case GET_REFERRER_DETAILS:
            referrerDetails = Object.assign({}, state.referrer_details);
            referrerDetails.loading = true;
            return {...state, referrer_details: referrerDetails}
        case GET_REFERRER_DETAILS.concat("_SUCCESS"):
            referrerDetails = Object.assign({}, state.referrer_details);
            referrerDetails.loading = false;
            referrerDetails.data = action.payload.data;

            let jobBoardToken = state.job_board_token;
            if(action.payload.data.community){
                jobBoardToken = action.payload.data.community.job_referral_token;
            }

            return {...state, referrer_details: referrerDetails, job_board_token: jobBoardToken}
        case GET_REFERRER_DETAILS.concat("_FAILURE"):
            referrerDetails = Object.assign({}, state.referrer_details);
            referrerDetails.loading = false;
            return {...state, referrer_details: referrerDetails}
        case SET_JOB_BOARD_TOKEN:
            return {...state, job_board_token: action.payload};
        case SET_JOB_TOKEN:
            return {...state, job_token: action.payload};
        case TOGGLE_REFER_JOB_MODAL:
            selectedJob = Object.assign({}, state.selected_job);
            selectedJob.refer_modal_open = selectedJob.refer_modal_open === false;

            return {...state, selected_job: selectedJob}
        case TOGGLE_SUCCESS_REFER_JOB_MODAL:
            selectedJob = Object.assign({}, state.selected_job);
            selectedJob.refer_modal_open = false;

            successReferModal = Object.assign({}, selectedJob.success_refer_modal);
            successReferModal.subscribed = action.payload.subscribed;
            successReferModal.subscriber = action.payload.subscriber;
            successReferModal.open = selectedJob.success_refer_modal.open === false;

            return {...state, selected_job: {...selectedJob, success_refer_modal: successReferModal}}
        case TOGGLE_APPLY_TO_JOB_MODAL:
            selectedJob = Object.assign({}, state.selected_job);
            selectedJob.apply_to_job_modal_open = selectedJob.apply_to_job_modal_open === false;

            return {...state, selected_job: selectedJob}
        case TOGGLE_SUCCESS_APPLY_TO_JOB_MODAL:
            selectedJob = Object.assign({}, state.selected_job);
            selectedJob.apply_to_job_modal_open = false;
            selectedJob.success_apply_to_job_modal_open = selectedJob.success_apply_to_job_modal_open === false;

            return {...state, selected_job: selectedJob}
        case RESET_JOB_BOARD:
            return {...state, page: 1, list: []};
        case TOGGLE_REMOTE_JOB_FILTER:
            filters = Object.assign({}, state.filters);
            filters.is_remote = filters.is_remote === false;
            return {...state, filters: filters};
        case TOGGLE_MY_COMPANY_JOB_FILTER:
            filters = Object.assign({}, state.filters);
            filters.my_jobs = filters.my_jobs === false;
            return {...state, filters: filters};
        case INCREMENT_JOB_LIST_PAGE:
            page = state.page;
            if (page === state.total_pages) {
                return {...state};
            }

            page++;

            return {...state, page: page};
        case DECREMENT_JOB_LIST_PAGE:
            page = state.page;
            if (page === 1) {
                return {...state};
            }

            page--;

            return {...state, page: page};
        case SUBSCRIBE_TO_JOB_BOUNTIES.concat('_SUCCESS'):
            if (action.origin_action_payload.origin === "referral") {
                selectedJob = Object.assign({}, state.selected_job);

                successReferModal = Object.assign({}, selectedJob.success_refer_modal);
                successReferModal.subscribed = true;
                successReferModal.open = false;

                toast.success("You have been subscribed to our job board.");

                return {...state, selected_job: {...selectedJob, success_refer_modal: successReferModal}}
            }

            return state;
        case SUBSCRIBE_TO_JOB_BOUNTIES.concat('_FAILURE'):
            toast.error("There was an error. Please check your data and try again.");

            return state;
        case SUBSCRIBE_TO_JOB_BOUNTIES_VIA_CTA.concat('_SUCCESS'):
            toast.success("You have been subscribed to our job board.");
            return {...state, subscribe: {...state.subscribe, modal_open: false}}
        case TOGGLE_SUBSCRIBE_TO_JOBS_MODAL:
            return {...state, subscribe: {...state.subscribe, modal_open: !state.subscribe.modal_open}}
        case GET_JOB_APPLICATION_FORM_DETAILS:
            return {...state, application_form: {data: null, loading: true}}
        case UPDATE_JOB_APPLICATION_FORM_DATA:
            var applicationForm = Object.assign({}, state.application_form.data);
            applicationForm = action.payload;

            return {...state, application_form: {loading: false, data: applicationForm}}
        case GET_JOB_APPLICATION_FORM_DETAILS.concat('_SUCCESS'):
            return {...state, application_form: {data: action.payload.data, loading: false}}
        case GET_JOB_APPLICATION_FORM_DETAILS.concat('_FAILURE'):
            return {...state, application_form: {data: null, loading: false}}
        case SAVE_JOB_APPLICATION_FORM.concat('_SUCCESS'):
            return {...state, application_form: {data: action.payload.data, loading: false}}
        case SAVE_JOB_APPLICATION_FORM.concat('_FAILURE'):
            toast.error("There was an issue while saving the application form.")
            return state;
        case APPLY_TO_JOB.concat("_SUCCESS"):
            var applyLink = action.payload.data.apply_link;
            if(applyLink){
                setTimeout(function (){
                    window.location.href = applyLink;
                }, 500);
            }

            return state;
        default:
            return {...state};
    }
}
