import React from "react";
import {Label} from "reactstrap";
import CustomFieldHeader from "./custom_field_header";

class TextAreaCustomFields extends React.Component {
    render() {
        const {customField, onValueChange} = this.props;

        return (
            <div className="m-b-10">
                <CustomFieldHeader customField={customField} />
                <textarea
                    className="refer-modal-input form-control"
                    onChange={(e) => onValueChange(e.target.value)}
                    placeholder={customField.title}
                    // required={customField.required}
                ></textarea>
            </div>
        );
    }
}

export default TextAreaCustomFields;