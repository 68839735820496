import {
    RESET_ALL_LOCATION_FILTERS,
    RESET_ALL_TOPIC_FILTERS,
    SEARCH_LOCATION_TO_FILTER,
    SEARCH_TOPIC_TO_FILTER,
    SELECT_LOCATION_TO_FILTER,
    SELECT_TOPIC_TO_FILTER,
    UNSELECT_LOCATION_TO_FILTER,
    UNSELECT_TOPIC_TO_FILTER
} from "../action_types";

export function unselectLocationToFilter(location) {
    return {
        type: UNSELECT_LOCATION_TO_FILTER,
        payload: {
            location: location
        }
    }
}

export function selectLocationToFilter(location) {
    return {
        type: SELECT_LOCATION_TO_FILTER,
        payload: {
            location: location
        }
    }
}

export function searchLocationToFilter(value, type) {
    return {
        type: SEARCH_LOCATION_TO_FILTER,
        api: true,
        payload: {
            value: value,
            type: type
        }
    }
}

export function resetAllLocationFilters() {
    return {
        type: RESET_ALL_LOCATION_FILTERS
    }
}

export function searchTopic(value) {
    return {
        type: SEARCH_TOPIC_TO_FILTER,
        api: true,
        payload: {
            value: value
        }
    }
}

export function selectTopicToFilter(topic) {
    return {
        type: SELECT_TOPIC_TO_FILTER,
        payload: {
            topic: topic
        }
    }
}

export function unselectTopicToFilter(topic) {
    return {
        type: UNSELECT_TOPIC_TO_FILTER,
        payload: {
            topic: topic
        }
    }
}

export function resetAllTopicFilters() {
    return {
        type: RESET_ALL_TOPIC_FILTERS
    }
}