import React from 'react';
import {Modal, ModalBody, ModalHeader,} from 'reactstrap';
import referralComplete from "../../../assets/images/referral_complete.svg";
import DisplayResultButton from "./display_result_button";
import {toggleSuccessReferJobModal} from "../../../redux/job/actions";
import {connect} from "react-redux";
import CopyButton from "./copy_button";
import SubscribeJobBountiesCard from "./subscribe_job_bounties";

class ReferralCompleteModal extends React.Component {

    render() {
        const {toggleSuccessReferJobModal, success_refer_modal} = this.props;

        return (
            <Modal isOpen={true} size={"sm"} centered={true}
                   contentClassName={"b-r-20 bg-dark-gray"}>
                <ModalHeader className={"justify-content-center py-5 p-relative custom-close-btn"}
                             toggle={toggleSuccessReferJobModal}>
                    <div className={"d-flex flex-column align-items-center"}>
                        <img src={referralComplete} alt={"referral-complete-icon"} width={275} height={175}
                             className={"object-contain"}/>
                        <div className={"d-flex flex-column p-3 align-items-center"}>
                            <h4 className={"font-weight-bold"}>You completed the referral!</h4>
                            <small>We sent an email to inform your friend of the referral.</small>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className={"d-flex flex-column text-center px-5 py-3"}>
                    <div className={"d-flex flex-column px-lg-5"}>
                        <SubscribeJobBountiesCard subscriber={success_refer_modal.subscriber} subscribed={success_refer_modal.subscribed} />
                        {/*<span>We also created a unique link for you and your friend, to directly share the offer with him!</span>*/}
                    </div>
                    {/*<div className={"d-flex flex-column mt-4"}>*/}
                    {/*    <CopyButton*/}
                    {/*        color={"teal"}*/}
                    {/*        displayText={`https://thecommunityatlas.com/en/job-board/`}*/}
                    {/*        displayColor={"green"}*/}
                    {/*        buttonIcon={"copy"}*/}
                    {/*        buttonLabel={"COPY"}*/}
                    {/*        handleClick={() => {*/}
                    {/*            if(navigator.clipboard !== undefined) {*/}
                    {/*                navigator.clipboard.writeText('https://thecommunityatlas.com/en/job-board/');*/}
                    {/*            }*/}
                    {/*        }} />*/}
                    {/*</div>*/}
                </ModalBody>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    toggleSuccessReferJobModal,
};

const mapStateToProps = (state, ownProps) => ({
    success_refer_modal: state.job.selected_job.success_refer_modal,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralCompleteModal)
