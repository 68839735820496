import React from "react";
import {Button, Card} from "reactstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {clearCommunityRedeemToken, redeemCommunity} from "../../../redux/community/actions";
import CommunityRedeemAuthenticationModal from "../../community/components/community_redeem_authentication_tabs";

class CommunityRedeemBottomBanner extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
        };
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        })
    }

    handleCommunityRedeem() {
        const {currentUser, redeemCommunity, clearCommunityRedeemToken} = this.props;
        if(!currentUser) {
            this.toggleModal();
            return;
        }
        redeemCommunity();
        clearCommunityRedeemToken();
    }

    render() {
        const {redeem_community, redeem_token, community_redeem_modal_open} = this.props;
        const {isModalOpen} = this.state;

        if(!redeem_community || !redeem_token) {
            return null;
        }

        return (
            <div>
                <CommunityRedeemAuthenticationModal isModalOpen={isModalOpen} toggleModal={() => this.toggleModal()} />
                <div className={`fixed-bottom-shadow d-flex justify-content-center align-item-center ${isModalOpen || community_redeem_modal_open ? "hide-banner" : ""}`}>
                    <Card className={`d-flex flex-column p-5 text-center justify-content-center ${redeem_community.name.length > 80 ? "inherit-height" : ""}`}>
                        <div>
                            <span className={"f-17"}>Redeem the <span
                                className={"orange-text"}>{redeem_community.name}</span> community profile</span>
                        </div>
                        <div>
                            <span
                                className={"f-17"}>to <b>share</b> the <b>Job Offers</b> and <b>collect</b> the <b>Bounties</b>.</span>
                        </div>
                        <Button className={"f-15 f-w-400 my-3"} color={"info"} onClick={() => {
                            this.handleCommunityRedeem();
                        }}>REDEEM COMMUNITY PROFILE</Button>
                        <u><Link to={"/redeem/" + redeem_token} className={"green-text"}>Learn more</Link></u>
                    </Card>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    redeemCommunity,
    clearCommunityRedeemToken,
};

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.data,
    redeem_token: state.community.redeem_token,
    redeem_community: state.community.redeem.community,
    community_redeem_modal_open: state.community.community_redeem_modal_open,
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunityRedeemBottomBanner)