import React, {Fragment} from 'react';
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from "reactstrap";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
    decrementCommunitiesOfListPage,
    incrementCommunitiesOfListPage,
    loadCommunitiesOfList,
    loadListDetails,
    removeCommunityFromList,
    clearListDetails
} from "../../redux/community_list/actions";
import CommunityCategories from "../common/helpers/community_categories";
import OrderCommunitiesOfList from "./order_communities_of_list";
import {MinusCircle} from "react-feather";
import NumberFormatter from "../utils/number_formatter";
import ScaleBuilder from "../community/utilities/scale_builder";
import {Redirect} from "react-router";

class ListDetailsPage extends React.Component {
    componentDidMount() {
        this.props.clearListDetails();
        this.props.loadCommunitiesOfList(this.props.routeParams.id);
        this.props.loadListDetails(this.props.routeParams.id);
    }

    renderLoading() {
        return (
            <tr>
                <td colSpan={8}>
                    <div className="text-center p-5">
                        <h4 className="m-0">Loading...</h4>
                    </div>
                </td>
            </tr>
        );
    }

    renderNoCommunities() {
        return (
            <tr>
                <td colSpan={7} className="text-center">
                    <p className="m-0">No communities found</p>
                </td>
            </tr>
        );
    }

    renderInvalidList() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">List not found</h4>
            </div>
        );
    }

    renderCommunitiesList() {
        const {communities, removeCommunityFromList} = this.props;
        const tableRows = [];

        if (communities.length) {
            communities.forEach((community) => {
                tableRows.push(<tr key={community.community_id}>
                    <td>
                        <Link to={"/community/" + community.community_id} className={"green-cta"}>
                            <u><b>{community.community_name}</b></u>
                        </Link>
                    </td>
                    <td>
                        <CommunityCategories community={community}/>
                    </td>
                    {community.country && community.city
                        ? (<td>{community.country + ", " + community.city}</td>)
                        : (<td/>)}
                    <td><NumberFormatter value={community.total_members}/></td>
                    <td>
                        <ScaleBuilder value={community.overall_ranking} community={community}/>
                    </td>
                    <td>
                        <ScaleBuilder value={community.online_ranking} community={community}/>
                    </td>
                    <td>
                        <ScaleBuilder value={community.offline_ranking} community={community}/>
                    </td>
                    <td>
                        <div className={"purple-text text-center pointer"} style={{"width": "60px"}} onClick={() => {
                            if (window.confirm("Are you sure?")) {
                                removeCommunityFromList(community.community_id, this.props.routeParams.id);
                            }
                        }}>
                            <MinusCircle width={50} color={"#7B28CD"}/>
                            <span>Remove from list</span>
                        </div>
                    </td>
                </tr>);
            });

            return tableRows;
        }
    }

    renderList() {
        const {communities_of_list, loadCommunitiesOfList, decrementCommunitiesOfListPage, incrementCommunitiesOfListPage, communities, loading} = this.props;

        return (
            <Fragment>
                <Breadcrumb title={this.props.list ? this.props.list.name : ""}
                            hideBreadcrumbRight={true} actions={() => {
                    return (
                        <Link to={"/lists"} className={"btn btn-green text-white"}>
                            Go to lists
                        </Link>
                    );
                }}/>

                <Container fluid={true}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table hover>
                                            <thead>
                                            <tr>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Name</div>
                                                        <OrderCommunitiesOfList currentField={"name"}
                                                                                listId={this.props.routeParams.id}/>
                                                    </div>
                                                </th>
                                                <th scope="col" className={'border-top-0'}>Tags</th>
                                                <th scope="col" className={'border-top-0'}>Location</th>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Members</div>
                                                        <OrderCommunitiesOfList currentField={"total_members"}
                                                                                listId={this.props.routeParams.id}/>
                                                    </div>
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Global engagement</div>
                                                        <OrderCommunitiesOfList currentField={"overall_ranking"}
                                                                                listId={this.props.routeParams.id}/>
                                                    </div>
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Online engagement</div>
                                                        <OrderCommunitiesOfList currentField={"online_ranking"}
                                                                                listId={this.props.routeParams.id}/>
                                                    </div>
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Offline engagement</div>
                                                        <OrderCommunitiesOfList currentField={"offline_ranking"}
                                                                                listId={this.props.routeParams.id}/>
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {loading && this.renderLoading()}
                                            {this.renderCommunitiesList()}
                                            {!loading && communities.length === 0 && this.renderNoCommunities()}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                                <CardFooter className={"d-flex"}>
                                    {communities_of_list.total_pages <= 1 ? null : (
                                        <Pagination
                                            className="pagination-primary"
                                        >
                                            <PaginationItem disabled={communities_of_list.page === 0}>
                                                <PaginationLink onClick={() => {
                                                    decrementCommunitiesOfListPage();
                                                    loadCommunitiesOfList(this.props.routeParams.id);
                                                }}>Previous</PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink
                                                    href="#javascript">{communities_of_list.page + 1}/{communities_of_list.total_pages}</PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                disabled={communities_of_list.page + 1 === communities_of_list.total_pages}>
                                                <PaginationLink onClick={() => {
                                                    incrementCommunitiesOfListPage();
                                                    loadCommunitiesOfList(this.props.routeParams.id);
                                                }}>Next</PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    )}

                                    <div className={"pt-2 pl-3 purple-text"}>
                                    <span>Total results: <b><NumberFormatter
                                        value={communities_of_list.total_results}/></b></span>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }

    render() {
        const {user, communities, loading} = this.props;

        if (user && user.is_community) {
            return (<Redirect to={"/"}/>);
        }

        if (!loading && !communities) {
            return this.renderInvalidList();
        }

        return this.renderList(communities);
    }
}

const mapDispatchToProps = {
    loadCommunitiesOfList,
    loadListDetails,
    removeCommunityFromList,
    decrementCommunitiesOfListPage,
    incrementCommunitiesOfListPage,
    clearListDetails
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    communities_of_list: state.community_list.communities_of_list,
    communities: state.community_list.communities_of_list.communities,
    loading: state.community_list.communities_of_list.loading,
    list: state.community_list.communities_of_list.list
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListDetailsPage)

