import Push from 'push.js';

function managePermission(){
    function onGranted(){
        return Push.Permission.GRANTED;
    }

    function onDenied(){
        return Push.Permission.DENIED;
    }

    if(Push.Permission.DENIED){
        Push.Permission.request(onGranted, onDenied);
    }
}

function create(title, description, onClick){
    managePermission();
    Push.create(title, {
        body: description,
        // icon: '/icon.png',
        timeout: 4000,
        onClick: function () {
            if(onClick !== undefined){
                onClick();
            }
            window.focus();
            this.close();
        }
    });
}

export default{
    create: create
};