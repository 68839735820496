import React from "react";
import ConfigDB from "../../../config";
import {toast} from "react-toastify";

const FileUpload = (props) => {
    const {onFileChoose} = props;
    const inputRef = React.useRef(null);

    const clearInput = () => {
        inputRef.current.value = null;
    }

    return (
        <input className={`form-control ${props.className}`}
               type={"file"}
               ref={inputRef}
               onChange={(e) => {
                   var file = e.target.files.length ? e.target.files[0] : null;

                   if (file && file.size / 1048576 <= ConfigDB.data.upload_file_size) {
                       onFileChoose(file);
                   } else {
                       clearInput();
                       toast.warn(`File must be max ${ConfigDB.data.upload_file_size}MB`);
                   }
               }}
        />
    );
}

export default FileUpload;