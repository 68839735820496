import React from "react";
import {Link} from "react-router-dom";
import ChannelListItem from "./channel_list_item";

class ChannelHeader extends React.Component{

    render(){
    const{channel, currentUser} = this.props;

    if(channel){
    return(
        <div className="chat-header clearfix">
            <ChannelListItem id={channel.id} isInHeader={true}/>
            <ul className="list-inline float-left float-sm-right chat-menu-icons mr-3">
            {!currentUser.is_community && (<Link target="_blank" rel="noopener noreferrer" to={"/community/" + channel.community.id} className="btn btn-green text-white" >
                SEE PROFILE
            </Link>)}
            </ul>
        </div>
    )}
    return null;
    }
}

export default ChannelHeader;
