import React from 'react';
import {
    Button, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import wantedIcon from "../../../assets/images/wanted_icon.svg";
import NumberFormatter from "../../utils/number_formatter";
import InputRange from "react-input-range";
import {connect} from "react-redux";
import {referJob, toggleReferJobModal} from "../../../redux/job/actions";
import {EMAIL_REGEX, LINKEDIN_REGEX, PAYMENT_TYPE_CRYPTO, PRIVACY_POLICY_LINK, TOS_LINK} from "../../../constants";
import ConnectSolanaWalletComponent from "../solana/ConnectSolanaWalletComponent";
import {toast} from "react-toastify";

class ReferJobModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            acceptedTermsOfUse: false,
            toggleAcceptedTermsOfUse: () => {
                this.setState({
                    acceptedTermsOfUse: !this.state.acceptedTermsOfUse
                })
            },
            setReferrerBountyPortion: (value) => {
                let referJob = this.state.refer_job;
                referJob.referral_bounty_portion = value;
                this.setState({
                    refer_job: referJob
                })
            },
            refer_job: {
                referral_bounty_portion: 50,
                referral: {
                    full_name: "",
                    email: "",
                    linkedin_profile: "",
                },
                referrer: {
                    full_name: "",
                    email: "",
                },
            },
            hasErrors: false,
        };
    }

    onFormChange(value, type, field) {
        const {refer_job} = this.state;
        refer_job[type][field] = value;
    }

    isNameValid(value){
        return value.split(" ").length >= 2;
    }

    isEmailValid(value){
        var regex = new RegExp(EMAIL_REGEX);
        return regex.test(value);
    }

    isLinkedinValid(value){
        var regex = new RegExp(LINKEDIN_REGEX);
        return regex.test(value);
    }

    isValidForm() {
        const {user, job, solana} = this.props;
        const {refer_job, acceptedTermsOfUse} = this.state;

        let hasErrors = false;

        if(!acceptedTermsOfUse){
            hasErrors = true;
        }

        if(!user) {
            if(!this.isNameValid(refer_job.referrer.full_name)){
                hasErrors = true;
            }

            if(!this.isEmailValid(refer_job.referrer.email)){
                hasErrors = true;
            }
        }

        if(!hasErrors) {
            if(!this.isNameValid(refer_job.referral.full_name)){
                hasErrors = true;
            }

            if(!this.isEmailValid(refer_job.referral.email)){
                hasErrors = true;
            }

            if(!this.isLinkedinValid(refer_job.referral.linkedin_profile)){
                hasErrors = true;
            }
        }

        if(job.payment_type === PAYMENT_TYPE_CRYPTO && !solana.wallet_public_key){
            toast.error("Please connect a Solana Wallet!");
            hasErrors = true;
        }

        this.setState({
            hasErrors: hasErrors,
        });

        return !hasErrors;
    }

    handleRefer() {
        const {solana} = this.props;
        const {referJob, toggleReferJobModal} = this.props;
        const {refer_job} = this.state;

        if(!this.isValidForm()) {
            return;
        }

        refer_job.wallet_public_key = solana.wallet_public_key;

        referJob(refer_job);
        toggleReferJobModal();
    }

    calculateBountyPortion(value) {
        return 100 - value;
    }

    render() {
        const {user, job, toggleReferJobModal} = this.props;
        const {acceptedTermsOfUse, toggleAcceptedTermsOfUse, setReferrerBountyPortion, refer_job, hasErrors} = this.state;

        return (
            <Modal isOpen={true} size={"sm"} centered={true}
                   contentClassName={"b-r-20 bg-dark-gray"}>
                <ModalHeader className={"justify-content-center py-5 p-relative custom-close-btn"}
                             toggle={toggleReferJobModal}>
                    <div className={"d-flex align-items-center"}>
                        <img src={wantedIcon} alt={"wanted-icon"} width={97} height={97} className={"object-contain"}/>
                        <div className={"d-flex flex-column px-3"}>
                            <span className={"font-weight-bold f-40"}>BOUNTY</span>
                            <span className={"font-weight-extra-bold mb-0 f-50"}>{job.currency} <NumberFormatter value={job.bounty}/></span>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className={"p-3"}>
                        <h5 className={"font-weight-bold text-center f-18"}>How would you like to split the bounty?</h5>
                        <div className={"d-flex justify-content-between"}>
                            <span className={"font-weight-bold f-18"}>{job.currency} <NumberFormatter value={Math.round(job.bounty * (this.calculateBountyPortion(refer_job.referral_bounty_portion)/100))} /></span>
                            <span className={"font-weight-bold f-18"}>{job.currency} <NumberFormatter value={Math.round(job.bounty * (100 - this.calculateBountyPortion(refer_job.referral_bounty_portion))/100)} /></span>
                        </div>
                        <InputRange
                            onChange={(e) => {
                                setReferrerBountyPortion(e);
                            }}
                            step={2}
                            formatLabel={value => null}
                            minValue={20}
                            maxValue={100}
                            value={refer_job.referral_bounty_portion}/>
                        <div className={"d-flex justify-content-between mb-4"}>
                            <span className={"font-weight-bold f-18"}>to you</span>
                            <span className={"font-weight-bold f-18"}>to your friend</span>
                        </div>

                        {job.payment_type === PAYMENT_TYPE_CRYPTO && (
                            <div className="p-3">
                                <ConnectSolanaWalletComponent />
                            </div>
                        )}

                        <form className={`needs-validation ${hasErrors ? "was-validated" : ""}`}>
                            <div className={"mb-5"}>
                                <span className={"font-weight-bold f-19 mb-3"}>Your friend</span>
                                    <div className="m-b-10">
                                        <input className="refer-modal-input form-control" type={"text"}
                                               onChange={(e) => this.onFormChange(e.target.value, 'referral', 'full_name')}
                                               required
                                               pattern=".*\s.*"
                                               placeholder={"Name"}/>
                                    </div>
                                    <div className="m-b-10">
                                        <input className="refer-modal-input form-control" type={"email"}
                                               pattern={EMAIL_REGEX}
                                               onChange={(e) => this.onFormChange(e.target.value, 'referral', 'email')}
                                               required
                                               placeholder={"Email"}/>
                                    </div>
                                    <div className="m-b-10">
                                        <input className="refer-modal-input form-control" type={"text"}
                                               pattern={LINKEDIN_REGEX}
                                               onChange={(e) => this.onFormChange(e.target.value, 'referral', 'linkedin_profile')}
                                               required
                                               placeholder={"Linkedin Profile"}/>
                                    </div>
                            </div>
                            {!user && (<div className={"mb-3"}>
                                <span className={"font-weight-bold f-19 mb-3"}>You</span>
                                <div className="m-b-10">
                                    <input className="refer-modal-input form-control" type={"text"}
                                           onChange={(e) => this.onFormChange(e.target.value, 'referrer', 'full_name')}
                                           required
                                           pattern=".*\s.*"
                                           placeholder={"Name"}/>
                                </div>
                                <div className="m-b-10">
                                    <input className="refer-modal-input form-control" type={"email"}
                                           pattern={EMAIL_REGEX}
                                           onChange={(e) => this.onFormChange(e.target.value, 'referrer', 'email')}
                                           required
                                           placeholder={"Email"}/>
                                </div>
                            </div>)}
                            <div className="faq-form m-b-10 d-flex">
                                <div id="terms-of-use" className={"size-20 checkbox-container " + (acceptedTermsOfUse ? "active full" : "")}
                                     onClick={() => {
                                         toggleAcceptedTermsOfUse();
                                     }}>
                                    <i className={"fa fa-check"}/>
                                </div>
                                <Label htmlFor="terms-of-use" className={`ml-2 ${hasErrors && !acceptedTermsOfUse ? "text-danger" : ""}`} >
                                    You agree to the&nbsp;
                                    <u><a href={TOS_LINK} target={"_blank"} rel={"noopener noreferrer"} className={"text-info"}>terms of use</a></u> and&nbsp;
                                    <u><a href={PRIVACY_POLICY_LINK} target={"_blank"} rel={"noopener noreferrer"} className={"text-info"}>privacy policy</a></u>
                                </Label>
                            </div>
                        </form>
                    </div>
                </ModalBody>
                <ModalFooter className={"justify-content-center p-3"}>
                    <Button color={"red"} className={"flex-grow-1"} size={"lg"} onClick={() => this.handleRefer()}>REFER</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapDispatchToProps = {
    referJob,
    toggleReferJobModal,
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    solana: state.solana
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferJobModal)
