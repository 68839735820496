import React from "react";
import CustomFieldHeader from "./custom_field_header";
import Select from "react-select";

class ChoiceCustomFields extends React.Component {
    render() {
        const {customField, onValueChange} = this.props;

        const renderSimpleSelect = () => {
            return <select
                className="refer-modal-input form-control"
                onChange={(e) => onValueChange(e.target.value)}
            >
                <option key={customField.id + "-empty-option"}>Choose option</option>
                {customField.choice_options.map(function (option, index) {
                    return <option value={option.id}
                                   key={customField.id + "-" + option.title + "-" + index}>{option.title}</option>
                })}
            </select>;
        };

        const renderMultiSelect = () => {
            return <Select
                closeMenuOnSelect={false}
                isMulti
                blurInputOnSelect={true}
                onChange={(selected) => {
                    onValueChange(selected.map((option) => option.value))
                }}
                options={customField.choice_options.map(function (option, index) {
                    return {
                        value: option.id,
                        label: option.title
                    };
                })}
            />
        };

        return (
            <div className="m-b-10">
                <CustomFieldHeader customField={customField}/>

                {!customField.multiple && renderSimpleSelect()}
                {customField.multiple && renderMultiSelect()}
            </div>
        );
    }
}

export default ChoiceCustomFields;