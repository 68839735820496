import React from "react";
import {Button, Card} from "reactstrap";
import MainCategory from "../../common/helpers/main_category";
import {MapPin} from "react-feather";
import Statystics from "../../../assets/images/statystics.svg";
import ScaleBarBuilder from "../utilities/scale_bar_builder";
import ScaleBuilder from "../utilities/scale_builder";

function CommunityHeader(props) {
    const {community, currentUser, onAddButtonClick, contactCommunity} = props;
    const logoUrl = community.logo ? community.logo.url : null;

    return (
        <div className={"d-flex flex-2 flex-column flex-md-row"}>
            <Card className={"logo"} style={{
                backgroundColor: logoUrl ? "#ffffff" : "#1E2AA5",
                backgroundImage: "url(" + logoUrl + ")",
                backgroundPosition: "center",
                backgroundSize: "cover"
            }}/>
            <div className={"pl-3 flex-grow-1"}>
                <MainCategory community={community}/>
                <h3 className={"m-t-10"}>{community.name.toUpperCase()}</h3>
                {community.is_network !== true && (<div className={"d-flex"}>
                    <MapPin color={"red"}/>
                    <span className={"pl-2"}><b>{community.country}, {community.city}</b></span>
                </div>)}
                <div className="d-flex my-2">
                    {(currentUser && !currentUser.is_community && community.is_network === false) && (
                    <div>
                        <Button onClick={()=> onAddButtonClick()} color="green" >SAVE IN A LIST</Button>
                        <Button onClick={()=> contactCommunity()} className={"m-2"} color="green">CONTACT</Button>
                    </div>)}
                </div>
            </div>
            <div className={"d-flex flex-column pl-4 flex-grow-1"}>
                <div className={"d-flex flex-row"}>
                    <div className={"d-flex flex-row align-items-start"}>
                        <img src={Statystics} width={70}/>
                        <div className={"d-flex flex-column pl-3"}>
                            <div>
                                <b>Engagement level:</b>
                                <ScaleBarBuilder value={community.overall_worldwide_main_category_ranking}/>
                            </div>
                            <div className="m-t-10">
                                <b>Worldwide engagement ranking</b>
                                <h6 className={"d-flex flex-row align-items-start"}><ScaleBuilder
                                    value={community.overall_worldwide_main_category_ranking}/>
                                    <span>&nbsp; on &nbsp;</span> <b><MainCategory community={community}
                                                                                   justText={true}/></b></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommunityHeader;
