import React from "react";
import {ArrowUp, ArrowDown} from "react-feather";
import {connect} from "react-redux";
import {setSortingConditionForCommunitiesOfList, loadCommunitiesOfList} from "../../redux/community_list/actions";

class OrderCommunitiesOfList extends React.Component {
    render() {
        const {currentUser, currentField, setSortingConditionForCommunitiesOfList, sorting, loadCommunitiesOfList, loading, listId} = this.props;

        if(!currentUser){
            return null;
        }

        function isSelectedOrder(direction) {
            return sorting.field === currentField && sorting.direction === direction;
        }

        function isSelectedOrderAsc() {
            return isSelectedOrder("ASC");
        }

        function isSelectedOrderDesc() {
            return isSelectedOrder("DESC");
        }

        return (
            <div className={"d-flex m-l-5"}>
                <div className={"pointer d-flex align-items-end"}>
                    <ArrowUp color={isSelectedOrderAsc() ? "#1391BB" : "black"} width={15} onClick={() => {
                        if(!loading && !isSelectedOrderAsc()){
                            setSortingConditionForCommunitiesOfList(currentField, "ASC");
                            loadCommunitiesOfList(listId);
                        }
                    }}/>
                </div>
                <div className={"pointer d-flex align-items-end"}>
                    <ArrowDown width={15} color={isSelectedOrderDesc() ? "#1391BB" : "black"} onClick={() => {
                        if(!loading && !isSelectedOrderDesc()){
                            setSortingConditionForCommunitiesOfList(currentField, "DESC");
                            loadCommunitiesOfList(listId);
                        }
                    }}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {setSortingConditionForCommunitiesOfList, loadCommunitiesOfList};

const mapStateToProps = (state, ownProps) => ({
    loading: state.community_list.communities_of_list.loading,
    sorting: state.community_list.communities_of_list.sorting,
    currentUser: state.user.data,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderCommunitiesOfList)
