import React from 'react';
import {Badge} from 'reactstrap'
import {connect} from "react-redux";

class CommunityCategories extends React.Component{
    getMainCategoryName() {
        const { categoryList, community } = this.props;
        let categoryName = null;

        categoryList.forEach((category) => {
            if(category.id === community.category_id){
                categoryName = category.name;
            }
        });

        return categoryName;
    }

    render() {
        const {community} = this.props;
        const communityTopics = community.topics;
        const topicElements = [];


        if(communityTopics.length > 0){
            communityTopics.slice(0, 10).forEach((topic) => {
                topicElements.push(<Badge color={'purple'} key={community.community_id+"-"+topic.id}>#{topic.name}</Badge>)
            });

            if(communityTopics.length > 10) {
                topicElements.push(<Badge color={'grey'} key={community.community_id+"-"+communityTopics.length - 10+"-more"}> and {communityTopics.length - 10} more</Badge>)
            }
        }

        return (
            <div>
                <Badge color={'primary'}>#{this.getMainCategoryName()}</Badge> <br/>
                {topicElements}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    categoryList: state.category.list,
    categoriesLoading: state.category.loading,
});

export default connect(
    mapStateToProps,
    null
)(CommunityCategories)
