import React from 'react';
import {
    Container, Modal, ModalBody,
} from 'reactstrap';
import NumberFormatter from "../../utils/number_formatter";
import {connect} from "react-redux";
import LogoComponent from "../helpers/logo_component";
import CopyButton from "./copy_button";

class ShareJobModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    calculatePortion(value) {
        return 100 - value;
    }

    render() {
        const {selected_community, toggleShareJobModal, job, token} = this.props;
        const link = `${window.location.origin}/jobs/preview/${token}`;

        return (
            <Modal isOpen={true} size={"lg"} toggle={toggleShareJobModal} centered={true} contentClassName={"b-r-20"}>
                <ModalBody>
                    <Container className={"text-center p-3"}>
                        <div className={"d-flex flex-column"}>
                            <div className={"d-flex mb-3 flex-column flex-lg-row align-items-center"}>
                                <LogoComponent logo={job.company.logo} width={120} height={60} className={"object-contain  mr-4 b-r-10"} />
                                <div className={"text-left"}>
                                    <h3 className={"f-w-900"}>{job.title}</h3>
                                    <div>
                                        <span>When anyone successfully applies or is referred through this link, the community will get a share of the reward. </span>
                                        <u><a className={"green-text"} href="https://absorbing-periwinkle-352.notion.site/Job-bounties-c4861516955e4fc3a07f4e69e2d13d3c">How does it work?</a></u>
                                    </div>
                                    {selected_community &&
                                    (<div>
                                        <span>For this position the community bounty is <span className={"purple-text"}>{job.currency} <NumberFormatter
                                            value={Math.round(job.bounty * (this.calculatePortion(selected_community.job_referral_token.referral_bounty_portion) / 100))}/></span></span>
                                    </div>)}
                                </div>
                            </div>
                            <div>
                                <CopyButton
                                    color={"teal"}
                                    displayText={link.substr(0, 50) + "..."}
                                    displayColor={"green"}
                                    buttonIcon={"copy"}
                                    buttonLabel={"COPY"}
                                    handleClick={() => {
                                        if(navigator.clipboard !== undefined) {
                                            navigator.clipboard.writeText(link);
                                        }
                                }} />
                            </div>
                        </div>
                    </Container>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    selected_community: state.job.selected_community,
});

export default connect(mapStateToProps, null)(ShareJobModal);
