import React, {Fragment, useRef} from "react";
import {MapPin} from "react-feather";
import {Badge, Label} from "reactstrap";
import {connect} from "react-redux";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {
    resetAllLocationFilters,
    searchLocationToFilter,
    selectLocationToFilter,
    unselectLocationToFilter
} from "../../../redux/filters/actions";

class LocationFilter extends React.Component {
    componentDidMount() {
        this.props.resetAllLocationFilters();
    }

    buildListOfSelectedLocations() {
        const {locationFilters, unselectLocationToFilter, onLocationUnselected} = this.props;
        const {selected_locations} = locationFilters;

        return selected_locations.map((location) => {
            return (
                <Badge className={"pointer"} color={"info"} key={location.id + location.type} onClick={() => {
                    unselectLocationToFilter(location);

                    if(onLocationUnselected){
                        onLocationUnselected();
                    }
                }}>{location.name}{location.country ? " Area - " + location.country : ''} x</Badge>
            );
        })
    }

    render() {
        const {
            searchLocationToFilter,
            selectLocationToFilter,
            locationFilters,
            type,
            onLocationSelected,
            hideLabel,
            isGlobal
        } = this.props;
        const {searching, options} = locationFilters;

        return (
            <div>
                {!hideLabel && <Label className="d-block">Location:</Label>}

                <div className="faq-form m-b-10">
                    <TypeaheadComponent
                        searching={searching}
                        searchLocationToFilter={(value) => {
                            searchLocationToFilter(value, type ?? "all");
                        }}
                        options={options}
                        onLocationSelected={(selectedLocation) => {
                            if (isGlobal) {
                                selectLocationToFilter(selectedLocation);
                            }

                            onLocationSelected(selectedLocation);
                        }}
                    />

                    <MapPin className="search-icon"/>
                </div>

                <div className="m-b-10">
                    {this.buildListOfSelectedLocations()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    locationFilters: state.filters.location_filters
});

const mapDispatchToProps = {
    searchLocationToFilter,
    selectLocationToFilter,
    unselectLocationToFilter,
    resetAllLocationFilters,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationFilter)

const TypeaheadComponent = (props) => {
    const ref = useRef();

    return (
        <Fragment>
            <AsyncTypeahead
                id="search-for-location"
                isLoading={props.searching}
                labelKey="name"
                minLength={4}
                onSearch={props.searchLocationToFilter}
                options={props.options}
                placeholder="Type here..."
                renderMenuItemChildren={(option, props) => {
                    return (
                        <Fragment>
                            <span>{option.name}{option.country ? " Area - " + option.country : ''}</span>
                        </Fragment>
                    )
                }}
                onChange={(selected) => {
                    if (selected.length > 0) {
                        props.onLocationSelected(selected[0]);
                    }

                    ref.current.clear();
                }}
                ref={ref}
            />
        </Fragment>
    );
};
