import React, {Fragment, useRef} from "react";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

const TypeaheadComponent = (props) => {
    const ref = useRef();

    return (
        <Fragment>
            <AsyncTypeahead
                id={props.id}
                isLoading={props.searching}
                labelKey={props.labelKey}
                disabled={props.disabled}
                minLength={3}
                onSearch={props.onSearch}
                options={props.options}
                placeholder="Type here..."
                renderMenuItemChildren={(option, props) => {
                    console.log("option[props.labelKey]", option, props.labelKey, option[props.labelKey]);
                    return (
                        <Fragment>
                            <span>{option[props.labelKey]}</span>
                        </Fragment>
                    )
                }}
                onChange={(selected) => {
                    if (selected.length > 0) {
                        props.onSelect(selected[0]);
                    }

                    ref.current.clear();
                }}
                ref={ref}
            />
        </Fragment>
    );
};

export default TypeaheadComponent;