import React from "react";

function ScaleBarBuilder(props) {
    function getLevelValue(rankingPercentage) {
        return Math.round(100 - rankingPercentage);
    }

    const value = getLevelValue(props.value);

    function getColorForValue(value) {
        if (value >= 0 && value <= 40) {
            return "#FF637B";
        } else if (value > 40 && value <= 75) {
            return "#FDBF00";
        } else if (value > 75 && value <= 100) {
            return "#00C8C8";
        }

        return null;
    }

    return (
        <div className="scale-bar-wrapper">
            <div className="bars-container" style={{"backgroundColor": getColorForValue(value) + "60"}}>
                <div className="value-container"
                     style={{"backgroundColor": getColorForValue(value), "width": value + "%"}}></div>
            </div>
            <div className="value">
                <span className="highlighted" style={{"color": getColorForValue(value)}}>{value}</span>
                <span>/</span>
                <span>100</span>
            </div>
        </div>
    );
}

export default ScaleBarBuilder;
