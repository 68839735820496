import {
    ADD_TOPIC_IN_COMMUNITY,
    ADD_TOPIC_IN_COMMUNITY_REGISTRATION,
    CHANGE_FILTER_FOR_LIST,
    CLEAR_COMMUNITY_DETAILS,
    CLEAR_COMMUNITY_REDEEM_TOKEN,
    CLEAR_SEARCHED_COMMUNITY_LIST,
    CLEAR_SELECTED_COMMUNITY,
    CLEAR_TOPICS_TO_FILTER_FOR_COMMUNITY,
    CREATE_COMMUNITY,
    CREATE_SERVICE,
    DECREMENT_COMMUNITIES_OF_NETWORK_PAGE,
    DECREMENT_COMMUNITIES_PAGE,
    DELETE_SERVICE,
    EDIT_COMMUNITY,
    EDIT_COMMUNITY_LINKS,
    EDIT_SERVICE,
    GET_COMMUNITY_DETAILS,
    GET_COMMUNITY_EDIT_DETAILS,
    GET_COMMUNITY_TO_REDEEM,
    GET_SERVICE_CATEGORIES,
    INCREMENT_COMMUNITIES_OF_NETWORK_PAGE,
    INCREMENT_COMMUNITIES_PAGE,
    LOAD_COMMUNITIES,
    LOAD_COMMUNITIES_OF_NETWORK,
    REDEEM_COMMUNITY,
    REMOVE_TOPIC_FROM_COMMUNITY,
    REMOVE_TOPIC_FROM_COMMUNITY_REGISTRATION,
    RESET_COMMUNITIES_PAGE,
    SEARCH_FOR_COMMUNITY,
    SEARCH_FOR_COMMUNITY_NETWORK,
    SEARCH_TOPICS_TO_ADD_IN_COMMUNITY,
    SEARCH_TOPICS_TO_EXCLUDE_FROM_COMMUNITY_SEARCH,
    SEARCH_TOPICS_TO_FILTER_COMMUNITIES,
    SELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY,
    SELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY,
    SELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY,
    SELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH,
    SET_COMMUNITY_REDEEM_TOKEN,
    SET_FREE_SEARCH_FOR_COMMUNITY,
    SET_MIN_MEMBERS_CONDITION_FOR_COMMUNITIES_LIST,
    SET_SORTING_CONDITION,
    TOGGLE_ADD_SERVICE_MODAL,
    TOGGLE_COMMUNITIES_WITH_VERIFIED_ADMINS_FILTER,
    TOGGLE_COMMUNITY_REDEEM_MODAL,
    TOGGLE_CONTACT_COMMUNITY_MODAL,
    TOGGLE_NEW_COMMUNITIES_FILTER,
    TOGGLE_REDEEMED_COMMUNITY_FILTER,
    TOGGLE_SERVICE_DETAILS_MODAL,
    UNSELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY,
    UNSELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY,
    UNSELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY,
    UNSELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH,
    UPDATE_COMMUNITY_CATEGORY,
    UPDATE_COMMUNITY_DATA,
    VERIFY_EXISTING_MEETUP_COMMUNITY,
    DISABLE_SLACK_ON_EDIT_COMMUNITY,
    DISABLE_DISCORD_ON_EDIT_COMMUNITY,
    SET_EDIT_COMMUNITY_DATA,
    SAVE_SOLANA_WALLET_ON_COMMUNITY
} from "../action_types";

export function loadCommunities() {
    return {
        type: LOAD_COMMUNITIES,
        api: true,
        fetch_safe: true,
    }
}

export function incrementCommunitiesPage() {
    return {
        type: INCREMENT_COMMUNITIES_PAGE,
    }
}

export function decrementCommunitiesPage() {
    return {
        type: DECREMENT_COMMUNITIES_PAGE,
    }
}

export function resetCommunitiesPage() {
    return {
        type: RESET_COMMUNITIES_PAGE,
    }
}

export function changeFilterForList(toggle) {
    return {
        type: CHANGE_FILTER_FOR_LIST,
        payload: toggle,
    }
}

export function setFreeSearchForCommunity(name) {
    return {
        type: SET_FREE_SEARCH_FOR_COMMUNITY,
        payload: {
            value: name
        }
    }
}

export function selectCategoryToSearchForCommunity(category) {
    return {
        type: SELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY,
        payload: {
            category: category
        }
    }
}

export function unselectCategoryToSearchForCommunity(category) {
    return {
        type: UNSELECT_CATEGORY_TO_FILTER_FOR_COMMUNITY,
        payload: {
            category: category
        }
    }
}

export function searchTopicForCommunity(value) {
    return {
        type: SEARCH_TOPICS_TO_FILTER_COMMUNITIES,
        api: true,
        payload: {
            value: value
        }
    }
}

export function clearTopicToSearchForCommunity() {
    return {
        type: CLEAR_TOPICS_TO_FILTER_FOR_COMMUNITY,
    }
}

export function searchTopicsToExcludeFromCommunitySearch(value) {
    return {
        type: SEARCH_TOPICS_TO_EXCLUDE_FROM_COMMUNITY_SEARCH,
        api: true,
        payload: {
            value: value
        }
    }
}

export function selectTopicToExcludeFromCommunitySearch(topic) {
    return {
        type: SELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH,
        payload: {
            topic: topic
        }
    }
}

export function unselectTopicToExcludeFromCommunitySearch(topic) {
    return {
        type: UNSELECT_TOPIC_TO_EXCLUDE_FROM_COMMUNITY_SEARCH,
        payload: {
            topic: topic
        }
    }
}

export function selectCommunityNetworkToFilterForCommunity(community) {
    return {
        type: SELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY,
        payload: {
            community
        }
    }
}

export function unselectCommunityNetworkToFilterForCommunity(community) {
    return {
        type: UNSELECT_COMMUNITY_NETWORK_TO_FILTER_FOR_COMMUNITY,
        payload: {
            community
        }
    }
}

export function selectSocialToSearchForCommunity(socialMedia) {
    return {
        type: SELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY,
        payload: {
            social_media: socialMedia
        }
    }
}

export function unselectSocialToSearchForCommunity(socialMedia) {
    return {
        type: UNSELECT_SOCIAL_MEDIA_TO_FILTER_FOR_COMMUNITY,
        payload: {
            social_media: socialMedia
        }
    }
}

export function toggleNewCommunitiesFilter(state) {
    return {
        type: TOGGLE_NEW_COMMUNITIES_FILTER,
        payload: {
            active: state
        }
    }
}

export function getCommunityDetails(communityId) {
    return {
        type: GET_COMMUNITY_DETAILS,
        api: true,
        payload: {
            id: communityId
        }
    }
}

export function getCommunityDetailsByAccessToken(communityToken) {
    return {
        type: GET_COMMUNITY_DETAILS,
        api: true,
        payload: {
            token: communityToken
        }
    }
}

export function getCommunityEditDetails(communityId) {
    return {
        type: GET_COMMUNITY_EDIT_DETAILS,
        api: true,
        payload: {
            id: communityId
        }
    }
}

export function loadCommunitiesOfNetwork(id) {
    return {
        type: LOAD_COMMUNITIES_OF_NETWORK,
        api: true,
        payload: {
            id: id
        },
        fetch_safe: true,
    }
}

export function incrementCommunitiesOfNetworkPage() {
    return {
        type: INCREMENT_COMMUNITIES_OF_NETWORK_PAGE,
    }
}

export function decrementCommunitiesOfNetworkPage() {
    return {
        type: DECREMENT_COMMUNITIES_OF_NETWORK_PAGE,
    }
}

export function searchForCommunity(name) {
    return {
        type: SEARCH_FOR_COMMUNITY,
        api: true,
        payload: {
            name: name
        }
    }
}

export function clearSearchedCommunityList() {
    return {
        type: CLEAR_SEARCHED_COMMUNITY_LIST,
    }
}

export function clearSelectedCommunity() {
    return {
        type: CLEAR_SELECTED_COMMUNITY,
    }
}

export function setSortingCondition(field, direction) {
    return {
        type: SET_SORTING_CONDITION,
        payload: {
            field,
            direction
        }
    }
}

export function getCommunityToRedeem() {
    return {
        type: GET_COMMUNITY_TO_REDEEM,
        api: true,
    }
}

export function redeemCommunity() {
    return {
        type: REDEEM_COMMUNITY,
        api: true,
    }
}

export function editCommunity(community) {
    return {
        type: EDIT_COMMUNITY,
        api: true,
        payload: {
            community: community
        }
    }
}

export function editCommunityLinks(community) {
    return {
        type: EDIT_COMMUNITY_LINKS,
        payload: community
    }
}

export function removeTopicFromCommunity(topicId, communityId) {
    return {
        type: REMOVE_TOPIC_FROM_COMMUNITY,
        api: true,
        payload: {
            topic_id: topicId,
            community_id: communityId
        }
    }
}

export function removeTopicFromCommunityRegistration(topicId) {
    return {
        type: REMOVE_TOPIC_FROM_COMMUNITY_REGISTRATION,
        payload: {
            topic_id: topicId,
        }
    }
}

export function addTopicInCommunity(communityId, topic) {
    return {
        type: ADD_TOPIC_IN_COMMUNITY,
        api: true,
        payload: {
            topic: topic,
            community_id: communityId
        }
    }
}

export function addTopicInCommunityRegistration(topic) {
    return {
        type: ADD_TOPIC_IN_COMMUNITY_REGISTRATION,
        payload: {
            topic: topic,
        }
    }
}

export function searchTopicToAddInCommunity(value) {
    return {
        type: SEARCH_TOPICS_TO_ADD_IN_COMMUNITY,
        api: true,
        payload: {
            value: value
        }
    }
}

export function searchForCommunityNetwork(value) {
    return {
        type: SEARCH_FOR_COMMUNITY_NETWORK,
        api: true,
        payload: {
            value
        }
    }
}

export function setCommunityRedeemToken(token) {
    return {
        type: SET_COMMUNITY_REDEEM_TOKEN,
        payload: token,
    }
}

export function clearCommunityRedeemToken() {
    return {
        type: CLEAR_COMMUNITY_REDEEM_TOKEN,
    }
}

export function setMinMembersConditionForCommunities(value) {
    return {
        type: SET_MIN_MEMBERS_CONDITION_FOR_COMMUNITIES_LIST,
        payload: value
    }
}

export function toggleCommunityRedeemModal() {
    return {
        type: TOGGLE_COMMUNITY_REDEEM_MODAL
    }
}

export function toggleRedeemedCommunityFilter() {
    return {
        type: TOGGLE_REDEEMED_COMMUNITY_FILTER
    }
}

export function toggleWithVerifiedAdminsFilter() {
    return {
        type: TOGGLE_COMMUNITIES_WITH_VERIFIED_ADMINS_FILTER
    }
}

export function verifyExistingMeetupCommunity(meetupUrl) {
    return {
        type: VERIFY_EXISTING_MEETUP_COMMUNITY,
        api: true,
        payload: meetupUrl,
    }
}

export function createCommunity(community, user) {
    return {
        type: CREATE_COMMUNITY,
        api: true,
        payload: {
            community: community,
            user: user,
        },
    }
}

export function updateCommunityCategory(categoryId) {
    return {
        type: UPDATE_COMMUNITY_CATEGORY,
        payload: categoryId
    }
}

export function updateCommunityData(community) {
    return {
        type: UPDATE_COMMUNITY_DATA,
        payload: community
    }
}

export function toggleAddServiceModal(index) {
    return {
        type: TOGGLE_ADD_SERVICE_MODAL,
        payload: index,
    }
}

export function createService(service) {
    return {
        type: CREATE_SERVICE,
        payload: service,
    }
}

export function editService(service) {
    return {
        type: EDIT_SERVICE,
        payload: service
    }
}

export function deleteService(index) {
    return {
        type: DELETE_SERVICE,
        payload: index,
    }
}

export function getServiceCategories() {
    return {
        type: GET_SERVICE_CATEGORIES,
        api: true,
    }
}

export function clearCommunityDetails() {
    return {
        type: CLEAR_COMMUNITY_DETAILS,
    }
}

export function toggleContactCommunityModal() {
    return {
        type: TOGGLE_CONTACT_COMMUNITY_MODAL,
    }
}

export function toggleServiceDetailsModal(service) {
    return {
        type: TOGGLE_SERVICE_DETAILS_MODAL,
        payload: service,
    }
}

export function disableSlackOnEditCommunity() {
    return {
        type: DISABLE_SLACK_ON_EDIT_COMMUNITY
    }
}

export function disableDiscordOnEditCommunity() {
    return {
        type: DISABLE_DISCORD_ON_EDIT_COMMUNITY
    }
}

export function setEditCommunityData(community) {
    return {
        type: SET_EDIT_COMMUNITY_DATA,
        payload: community
    }
}

export function saveSolanaWalletOnCommunity(id, publicKey) {
    return {
        type: SAVE_SOLANA_WALLET_ON_COMMUNITY,
        payload: {
            id,
            key: publicKey
        },
        api: true
    }
}