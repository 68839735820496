import React from 'react';
import {connect} from "react-redux";
import {Button, Container, Modal, ModalBody, Row} from "reactstrap";
import InputRange from "react-input-range";
import NumberFormatter from "../../utils/number_formatter";
import {changeBountyPortions} from "../../../redux/job/actions";

class BountySplitModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            bountySplitValue: 0,
            setBountySplitValue: (value) => {
                this.setState({
                    bountySplitValue: value
                })
            },
        };
    }

    componentDidMount() {
        const {job_referral} = this.props;
        this.state.setBountySplitValue(this.calculateBountyPortion(job_referral.referral_bounty_portion));
    }

    calculateBountyPortion(value) {
        return 100 - value;
    }

    handleSaveAndExit() {
        const {job_referral, changeBountyPortions, toggleModal} = this.props;
        const {bountySplitValue} = this.state;

        changeBountyPortions(job_referral.token, this.calculateBountyPortion(bountySplitValue));
        toggleModal();
    }

    render() {
        const {toggleModal} = this.props;
        const {bountySplitValue, setBountySplitValue} = this.state;

        return (
            <Modal isOpen={true} toggle={() => toggleModal()} centered={true} contentClassName={"b-r-20"}>
                <ModalBody>
                    <Container className={"d-flex flex-column p-3 text-left text-lg-center"}>
                        <Row className={"flex-column mb-4"}>
                            <h3 className={"f-w-900"}>Edit your revenue sharing settings</h3>
                            <span>Set community revenue share to:</span>
                        </Row>
                        <Row className={"justify-content-between"}>
                            <span>To community</span>
                            <span>To referrer</span>
                        </Row>
                        <Row className={"align-items-start"}>
                            <span className={"px-3 purple-text font-weight-bold"}
                                  style={{width: "60px"}}>{bountySplitValue}%</span>
                            <div className={"flex-grow-1"}>
                                <InputRange
                                    onChange={(e) => {
                                        setBountySplitValue(e);
                                    }}
                                    step={2}
                                    formatLabel={value => null}
                                    minValue={0}
                                    maxValue={80}
                                    value={bountySplitValue}/>
                            </div>
                            <span className={"px-3 grey-text font-weight-bold"}
                                  style={{width: "60px"}}>{100 - bountySplitValue}%</span>
                        </Row>
                        <Row className={"justify-content-center mb-2"}>
                            <span className={"grey-text"}>(we recommend a market standard of 10% to 20%)</span>
                        </Row>
                        <Row className={"flex-column align-items-center mb-2"}>
                            <span>EXAMPLE: If a <strong>$ <NumberFormatter value={5000}/></strong> bounty reward is won, the community will get <strong
                                className={"purple-text"}>$ <NumberFormatter
                                value={Math.round(5000 * (bountySplitValue / 100))}/></strong></span>
                            <span>Any referral generated before saving will use previous revenue shares setting.</span>
                        </Row>
                        <div className={"align-self-center"}>
                            <Button color={"teal"}
                                    size={"lg"}
                                    className={"rounded rounded-pill font-weight-normal"}
                                    onClick={() => {
                                        this.handleSaveAndExit();
                                    }}>SAVE AND UPDATE</Button>
                        </div>
                    </Container>
                </ModalBody>
            </Modal>
        )
    }
}

const mapDispatchToProps = {
    changeBountyPortions
};

const mapStateToProps = (state, ownProps) => ({
    job_referral: state.job.selected_community.job_referral_token,
});

export default connect(mapStateToProps, mapDispatchToProps)(BountySplitModal);
