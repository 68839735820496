import {
    CREATE_REPORT,
    DECREMENT_COMMUNITIES_OF_REPORT_PAGE,
    DECREMENT_REPORTS_PAGE,
    DELETE_REPORT,
    INCREMENT_COMMUNITIES_OF_REPORT_PAGE,
    INCREMENT_REPORTS_PAGE,
    LOAD_COMMUNITIES_OF_REPORT,
    LOAD_REPORT_DETAILS,
    LOAD_REPORTS
} from "../action_types";
import {toast} from "react-toastify";

const initial_state = {
    page: 0,
    list: [],
    loading: true,
    total_pages: 0,
    total_results: 0,
    selected_report: {
        loading: false,
        data: null,
        communities: {
            list: [],
            page: 0,
            total_pages: 0,
            total_results: 0,
            loading: false
        },
    }
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case LOAD_REPORTS:
            return {...state, loading: true};
        case LOAD_REPORTS.concat("_SUCCESS"):
            return {
                ...state,
                loading: false,
                list: action.payload.data,
                total_pages: action.payload.total_pages,
                total_results: action.payload.total_results
            };
        case LOAD_REPORTS.concat("_FAILURE"):
            return {...state, loading: false};
        case CREATE_REPORT.concat("_SUCCESS"):
            toast.success("Report created successfully.");
            return state;
        case CREATE_REPORT.concat("_FAILURE"):
            toast.error("There was an issue creating the report.");
            return state;
        case DELETE_REPORT.concat("_SUCCESS"):
            toast.success("Report deleted successfully.");
            return state;
        case DELETE_REPORT.concat("_FAILURE"):
            toast.error("There was an issue deleting the report.");
            return state;
        case LOAD_REPORT_DETAILS:
            return {...state, selected_report: {...state.selected_report, loading: true, data: null}};
        case LOAD_REPORT_DETAILS.concat("_SUCCESS"):
            return {...state, selected_report: {...state.selected_report, loading: false, data: action.payload.data}};
        case LOAD_REPORT_DETAILS.concat("_FAILURE"):
            toast.error("There was an issue loading the report!");
            return {...state, selected_report: {...state.selected_report, loading: false, data: null}};
        case LOAD_COMMUNITIES_OF_REPORT:
            return {
                ...state,
                selected_report: {
                    ...state.selected_report,
                    communities: {...state.selected_report.communities, list: [], loading: true}
                }
            };
        case INCREMENT_REPORTS_PAGE:
            var page = state.page;
            var totalPages = state.total_pages;

            if ((page + 1) === totalPages) {
                return {...state};
            }

            return {
                ...state,
                page: page + 1
            };
        case DECREMENT_REPORTS_PAGE:
            var page = state.page;
            if (page === 0) {
                return {...state};
            }

            return {
                ...state,
                page: page - 1
            };
        case LOAD_COMMUNITIES_OF_REPORT.concat("_SUCCESS"):
            return {
                ...state,
                selected_report: {
                    ...state.selected_report,
                    communities: {
                        ...state.selected_report.communities,
                        list: action.payload.data,
                        loading: false,
                        total_pages: action.payload.total_pages,
                        total_results: action.payload.total_results
                    }
                }
            };
        case LOAD_COMMUNITIES_OF_REPORT.concat("_FAILURE"):
            toast.error("There was an issue loading the communities of the report!");
            return {
                ...state,
                selected_report: {
                    ...state.selected_report,
                    communities: {
                        ...state.selected_report.communities,
                        list: [],
                        loading: false,
                        total_pages: 0,
                        total_results: 0
                    }
                }
            };
        case INCREMENT_COMMUNITIES_OF_REPORT_PAGE:
            var page = state.selected_report.communities.page;
            var totalPages = state.selected_report.communities.total_pages;

            if ((page + 1) === totalPages) {
                return {...state};
            }

            return {
                ...state,
                selected_report: {
                    ...state.selected_report,
                    communities: {
                        ...state.selected_report.communities,
                        page: page + 1
                    }
                }
            };
        case DECREMENT_COMMUNITIES_OF_REPORT_PAGE:
            var page = state.selected_report.communities.page;
            if (page === 0) {
                return {...state};
            }

            return {
                ...state,
                selected_report: {
                    ...state.selected_report,
                    communities: {
                        ...state.selected_report.communities,
                        page: page - 1
                    }
                }
            };
        default:
            return state;
    }
}
