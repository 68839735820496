import React from "react";
import {Card, CardBody, CardHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import Facebook from '../../../assets/images/social_profiles/facebook.svg';
import Twitter from '../../../assets/images/social_profiles/twitter.svg';
import Meetup from '../../../assets/images/social_profiles/meetup.svg';
import Instagram from '../../../assets/images/social_profiles/instagram.svg';
import Github from '../../../assets/images/social_profiles/github.svg';
import Reddit from '../../../assets/images/social_profiles/reddit.svg';
import Youtube from '../../../assets/images/social_profiles/youtube.svg';
import Linkedin from '../../../assets/images/social_profiles/linkedin.svg';
import GooglePlus from '../../../assets/images/social_profiles/google-plus.svg';
import GoogleDrive from '../../../assets/images/social_profiles/google-drive.svg';
import Telegram from '../../../assets/images/social_profiles/telegram.svg';
import Slack from '../../../assets/images/social_profiles/slack.svg';
import Bitly from '../../../assets/images/social_profiles/bitly.svg';
import Medium from '../../../assets/images/social_profiles/medium.svg';
import Discord from '../../../assets/images/social_profiles/discord.svg';
import Flickr from '../../../assets/images/social_profiles/flickr.svg';

class FindCommunityOn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            social_profiles: [
                {
                    property: "facebook_page_profiles",
                    image: Facebook,
                    dropdown_opened: false
                },
                {
                    property: "facebook_group_profiles",
                    image: Facebook,
                    dropdown_opened: false
                },
                {
                    property: "twitter_profiles",
                    image: Twitter,
                    dropdown_opened: false
                },
                {
                    property: "instagram_profiles",
                    image: Instagram,
                    dropdown_opened: false
                },
                {
                    property: "flickr_profiles",
                    image: Flickr,
                    dropdown_opened: false
                },
                {
                    property: "github_profiles",
                    image: Github,
                    dropdown_opened: false
                },
                {
                    property: "reddit_profiles",
                    image: Reddit,
                    dropdown_opened: false
                },
                {
                    property: "youtube_profiles",
                    image: Youtube,
                    dropdown_opened: false
                },
                {
                    property: "google_plus_profiles",
                    image: GooglePlus,
                    dropdown_opened: false
                },
                {
                    property: "linkedin_profiles",
                    image: Linkedin,
                    dropdown_opened: false
                },
                {
                    property: "telegram_profiles",
                    image: Telegram,
                    dropdown_opened: false
                },
                {
                    property: "google_docs_profiles",
                    image: GoogleDrive,
                    dropdown_opened: false
                },
                {
                    property: "slack_profiles",
                    image: Slack,
                    dropdown_opened: false
                },
                {
                    property: "bitly_profiles",
                    image: Bitly,
                    dropdown_opened: false
                },
                {
                    property: "medium_profiles",
                    image: Medium,
                    dropdown_opened: false
                },
                {
                    property: "discord_profiles",
                    image: Discord,
                    dropdown_opened: false
                }
            ],
            toggleDropdown: (profile, index) => {
                profile.dropdown_opened = (profile.dropdown_opened === false);
                const other_socials = [].concat(this.state.social_profiles);
                other_socials[index] = profile;

                this.setState({
                    social_profiles: other_socials
                })
            },
        }
    }

    render() {
        const {community} = this.props;
        const {social_profiles} = this.state;
        const {toggleDropdown} = this.state;

        function renderSocialProfile(socialProfile, index) {
            const profiles = community[socialProfile.property];

            if (profiles.length === 1) {
                return (
                    <div key={index} className={"m-r-10"}>
                        <a href={profiles[0].url} target="_blank" rel="noopener noreferrer">
                            <img src={socialProfile.image} width={40}/>
                        </a>
                    </div>
                );
            }

            return (
                <div className={"m-r-10"} key={index}>
                    <Dropdown isOpen={socialProfile.dropdown_opened} toggle={() => {
                        toggleDropdown(socialProfile, index);
                    }}>
                        <DropdownToggle tag={"div"}>
                            <img src={socialProfile.image} width={40}/>
                        </DropdownToggle>
                        <DropdownMenu>
                            {profiles.map((profile) => {
                                return (<DropdownItem key={profile.id}><a href={profile.url}
                                                                          key={profile.id}
                                                                          target="_blank"
                                                                          rel="noopener noreferrer">{profile.url}</a></DropdownItem>);
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            );
        }

        function renderSocialProfiles() {
            const view = [];

            for (var i = 0; i < social_profiles.length; i++) {
                var socialProfile = social_profiles[i];

                if (community[socialProfile.property].length > 0) {
                    view.push(renderSocialProfile(socialProfile, i));
                }
            }

            return view;
        }

        function renderMeetupProfile() {
            return (
                <a href={community.link} target="_blank" rel="noopener noreferrer">
                    <img src={Meetup} width={40}/>
                </a>
            );
        }

        return (
            <Card>
                <CardHeader className={"pt-4 pb-4"}>
                    <h4 className={"m-0 grey-text"}><b>FIND THEM ON</b></h4>
                </CardHeader>
                <CardBody className={"d-flex pt-4 pb-4"}>
                    {renderSocialProfiles()}
                    {renderMeetupProfile()}
                </CardBody>
            </Card>
        );
    }
}


export default FindCommunityOn;
