import React from 'react';
import {connect} from "react-redux";
import {Badge} from 'reactstrap';

class SidebarInboxBadge extends React.Component{
    render(){
        const {channelsList} = this.props;
        var totalUnreadMessages = 0;
        for (const [key, channel] of Object.entries(channelsList)){
            totalUnreadMessages += channel.total_unread_messages;
        }

        if(totalUnreadMessages > 0){
            return(
                <Badge className="custom-channel-badge" color="primary">{totalUnreadMessages}</Badge>
            )
        }
        return (
            <div></div>
        )
    }
}

const mapDispatchToProps = {
};

const mapStateToProps = (state, ownProps) => ({
    channelsList: state.channel.list.channels
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidebarInboxBadge);