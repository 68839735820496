import React, {Fragment} from 'react';
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table
} from "reactstrap";
import {connect} from "react-redux";
import {
    createCommunityList,
    decrementListsPage,
    deleteCommunityList,
    incrementListsPage,
    loadCommunityLists
} from "../../redux/community_list/actions";
import ListImage from "../../assets/images/list_black.png";
import {Link} from "react-router-dom";
import CreateCampaignModal from "../common/campaign/create_campaign_modal";
import {Redirect} from "react-router";
import NumberFormatter from "../utils/number_formatter";
import ConfirmContactedCommunitiesModal from "../common/campaign/confirm_contacted_communities_modal";
import {closeConfirmContactedCommunitiesModal} from "../../redux/campaign/actions";

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.closeCreateCampaignModal = this.closeCreateCampaignModal.bind(this);

        this.state = {
            createCommunityListModalIsOpen: false,
            showCreateCommunityListModalToggle: () => {
                this.setState({
                    createCommunityListModalIsOpen: this.state.createCommunityListModalIsOpen === false
                });
            },
            opened_dropdowns: [],
            create_campaign_modal: {
                open: false,
                list: null,
            },
            toggleCreateCampaignModal: (list) => {
                this.setState({
                    create_campaign_modal: {
                        list: list,
                        open: !this.state.create_campaign_modal.open,
                    },
                })
            }
        };
    }

    closeCreateCampaignModal() {
        this.setState({
            create_campaign_modal: {
                list: null,
                open: !this.state.create_campaign_modal.open,
            },
        })
    }

    toggleDropdown(list) {
        const {opened_dropdowns} = this.state;

        var position = opened_dropdowns.indexOf(list.id);

        if(position > -1){
            opened_dropdowns.splice(position, 1);
        } else {
            opened_dropdowns.push(list.id);
        }

        this.setState({
            opened_dropdowns: opened_dropdowns
        })
    }

    renderLoading() {
        return (
            <tr>
                <td className="text-center p-5" colSpan={5}>
                    <h4 className="m-0">Loading...</h4>
                </td>
            </tr>
        );
    }

    renderNoResults() {
        return (
            <tr>
                <td className="text-center p-5" colSpan={5}>
                    <img src={ListImage} width={50}/>
                    <br/>
                    <br/>
                    <span>No list has been created yet</span>
                    <br/>
                    <u><span className={"pointer purple-text"} onClick={this.state.showCreateCommunityListModalToggle}>Build your first list</span></u>
                </td>
            </tr>
        );
    }

    renderTable() {
        const {user, community_list, deleteCommunityList} = this.props;
        const {opened_dropdowns, toggleCreateCampaignModal} = this.state;
        const tableRows = [];

        if (community_list.list.length) {
            community_list.list.forEach((list) => {
                tableRows.push(<tr key={list.id}>
                    <td>
                        <Link to={"/lists/" + list.id} className={"green-cta"}>
                            <u><b>{list.name}</b></u>
                        </Link>
                    </td>
                    <td><NumberFormatter value={list.total_communities}/></td>
                    <td><NumberFormatter value={list.total_members}/></td>
                    <td><NumberFormatter value={list.total_social_media_audience}/></td>
                    <td>{list.total_avg_monthly_events}</td>
                    <td>
                        <Dropdown isOpen={opened_dropdowns.indexOf(list.id) > -1} toggle={() => {
                            this.toggleDropdown(list);
                        }}>
                            <DropdownToggle tag={"div"} className={"pointer"}>
                                . . .
                            </DropdownToggle>
                            <DropdownMenu className={"report-actions-dropdown"}>
                                {!user.is_community && list.total_communities > 0 && (<DropdownItem
                                    tag={"span"}
                                    className={"green-cta"}
                                    onClick={() => {
                                        toggleCreateCampaignModal(list);
                                    }}
                                >Create Campaign</DropdownItem>)}
                                <DropdownItem
                                    tag={"span"}
                                    className={"action-item"}
                                    onClick={() => {
                                        if (window.confirm("Are you sure?")) {
                                            deleteCommunityList(list.id);
                                        }
                                    }}
                                >Delete</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </td>
                </tr>);
            });

            return tableRows;
        }

        return null;
    }

    renderCreateCommunityListModal() {
        const {createCommunityList} = this.props;
        const {createCommunityListModalIsOpen, showCreateCommunityListModalToggle} = this.state;
        var listName = null;

        return (
            <Modal isOpen={createCommunityListModalIsOpen} toggle={showCreateCommunityListModalToggle}>
                <ModalHeader toggle={showCreateCommunityListModalToggle}>Create Community List</ModalHeader>
                <ModalBody>
                    <input type="text" className="form-control" placeholder={"Add name here"} onChange={(e) => {
                        listName = e.target.value
                    }}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={showCreateCommunityListModalToggle}>
                        Close
                    </Button>
                    <Button color="green" onClick={() => {
                        if (listName != null && listName.length > 0) {
                            createCommunityList(listName);
                            showCreateCommunityListModalToggle();
                        }
                    }}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }


    render() {
        const {community_list, user, isLoadingUser, loadCommunityLists, open_modal_to_verify_already_contacted_communities, closeConfirmContactedCommunitiesModal, incrementListsPage, decrementListsPage} = this.props;
        const {createCommunityListModalIsOpen, showCreateCommunityListModalToggle, create_campaign_modal} = this.state;

        if ((!isLoadingUser && !user) || (user && user.is_community)) {
            return (<Redirect to={"/"}/>);
        }

        return (
            <Fragment>

                {this.state.create_campaign_modal.open && <CreateCampaignModal list={create_campaign_modal.list} closeModal={this.closeCreateCampaignModal} />}
                {open_modal_to_verify_already_contacted_communities && <ConfirmContactedCommunitiesModal closeModal={() => {closeConfirmContactedCommunitiesModal()}}/>}
                {createCommunityListModalIsOpen && this.renderCreateCommunityListModal()}

                <Breadcrumb title="Your lists" hideBreadcrumbRight={true} actions={() => {
                    return (
                        <Button color={"green"} onClick={() => {
                            showCreateCommunityListModalToggle();
                        }}>
                            Create a new list
                        </Button>
                    );
                }}/>

                <Container fluid={true}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table hover>
                                            <thead>
                                            <tr>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Name</div>
                                                        {/*<OrderCommunitiesList currentField={"name"}/>*/}
                                                    </div>
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Total communities</div>
                                                    </div>
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Cumulative members</div>
                                                    </div>
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Cumulative social media audience</div>
                                                    </div>
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Cumulative Avg. monthly events</div>
                                                    </div>
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {community_list.loading ? this.renderLoading() : null}
                                            {community_list.list.length > 0 && this.renderTable()}
                                            {!community_list.loading && community_list.list.length === 0 ? this.renderNoResults() : null}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                                <CardFooter className={"d-flex"}>
                                    {community_list.total_pages <= 1 ? null : (
                                        <Pagination
                                            className="pagination-primary"
                                        >
                                            <PaginationItem
                                                disabled={community_list.page === 0}>
                                                <PaginationLink onClick={() => {
                                                    decrementListsPage();
                                                    loadCommunityLists();
                                                }}>Previous</PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink
                                                    href="#javascript">{community_list.page + 1}/{community_list.total_pages}</PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                disabled={community_list.page + 1 === community_list.total_pages}>
                                                <PaginationLink onClick={() => {
                                                    incrementListsPage();
                                                    loadCommunityLists();
                                                }}>Next</PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    )}

                                    <div className={"pt-2 pl-3 purple-text"}>
                                    <span>Total results: <b><NumberFormatter
                                        value={community_list.total_results}/></b></span>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    createCommunityList,
    deleteCommunityList,
    incrementListsPage,
    decrementListsPage,
    loadCommunityLists,
    closeConfirmContactedCommunitiesModal
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    isLoadingUser: state.user.loading,
    community_list: state.community_list,
    open_modal_to_verify_already_contacted_communities: state.campaign.new_campaign.open_modal_to_verify_already_contacted_communities,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListPage)

