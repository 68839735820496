import React from 'react';
import {connect} from "react-redux";
import {Button} from "reactstrap";
import PhantomWalletLogo from "../../../assets/images/phantom-icon-purple.png";
import PhantomWalletGhostLogo from "../../../assets/images/phantom-ghost-white.png";
import {setWalletFound, setWalletPublicKey} from "../../../redux/solana/actions";
import {saveSolanaWalletOnCommunity} from "../../../redux/community/actions";

class ConnectSolanaWalletComponent extends React.Component {
    async connectWallet() {
        const {solana} = window;
        const {setWalletPublicKey, saveSolanaWalletOnCommunity, selected_community, forCommunity} = this.props;

        const response = await solana.connect();
        const key = response.publicKey.toString();

        setWalletPublicKey(key);

        if (forCommunity && selected_community) {
            saveSolanaWalletOnCommunity(selected_community.id, key);
        }
    }

    render() {
        const {wallet, selected_community, forCommunity} = this.props;
        const {wallet_found, wallet_public_key} = wallet;

        const styles = {
            badge: {"borderRadius": "30px"},
            image: {"height": "40px"},
            title: {
                "fontWeight": 700,
                "fontSize": "20px",
                "marginBottom": "10px"
            },
            subTitle: {
                "whiteSpace": "initial",
                "fontSize": "15px"
            },
            link: {
                "color": "#4abee2"
            }
        };

        if (!wallet_found) {
            return (<span
                className={`badge badge-phantom d-flex align-items-center justify-content-start py-3 px-4`}
                style={styles.badge}>
                <img src={PhantomWalletGhostLogo} style={styles.image}/>

                <div className={"d-flex flex-column justify-content-center align-items-start pl-3"}>
                    <h5 className={"text-white"} style={styles.title}>WALLET NOT DETECTED</h5>
                    <span className={"text-white text-left"}
                          style={styles.subTitle}>Please use Chrome browser, install <a
                        className={"green-text"}
                        href="https://phantom.app/download" target="_blank">Phantom Wallet extension</a> and refresh the page!</span>
                </div>
            </span>);
        }

        if (wallet_found && (!wallet_public_key || (forCommunity && !selected_community.solana_wallet))) {
            return (
                <div className={"d-flex justify-content-center"}>
                    <span
                        className={`badge badge-phantom d-flex align-items-center justify-content-start py-3 px-4`}
                        style={{...styles.badge, width: "fit-content"}}>
                    <img src={PhantomWalletGhostLogo} style={styles.image}/>
                    <Button
                        color={"phantom"}
                        className={"d-flex justify-content-center align-items-center"}
                        onClick={() => {
                            this.connectWallet()
                        }}
                    >CONNECT PHANTOM WALLET</Button>
                </span>
                </div>
            );
        }

        return (
            <div className={"d-flex justify-content-center"}>
                <span
                    className={`d-flex align-items-center justify-content-start py-3 px-4`}
                    style={styles.badge}>
                    <img src={PhantomWalletLogo} style={styles.image}/>
                    <span style={styles.title} className={"pl-3 m-0 text-phantom"}>PHANTOM WALLET CONNECTED</span>
                </span>
            </div>
        );
    }
}

const mapDispatchToProps = {setWalletFound, setWalletPublicKey, saveSolanaWalletOnCommunity};

const mapStateToProps = (state, ownProps) => ({
    wallet: state.solana,
    selected_community: state.job?.selected_community,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectSolanaWalletComponent)