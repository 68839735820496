import React from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Button, Col, Row} from 'reactstrap';
import {toast} from 'react-toastify';
import {loginUser} from "../../redux/user/actions";

class LoginForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        }
    }

    onEmailChange(value) {
        this.setState({
            email: value
        })
    }

    onPasswordChange(value) {
        this.setState({
            password: value
        })
    }

    onLogin() {
        const {email, password} = this.state;
        const {loginUser, isRedeeming} = this.props;

        if (!email.trim() || !password.trim()) {
            toast.warn("Please add email and password!");
            return;
        }

        loginUser(email, password, isRedeeming);
    }

    render() {
        const {loading} = this.props;

        return (
            <div>
                <Row>
                    <Col>
                        <input type="email" className="form-control" placeholder={"Email"}
                               onChange={(e) => this.onEmailChange(e.target.value)}
                               onKeyDown = {(e) => {
                                   if(e.key === "Enter"){
                                       this.onLogin();
                                   }
                               }}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <input type="password" className="form-control" placeholder={"Password"}
                               onChange={(e) => this.onPasswordChange(e.target.value)}
                               onKeyDown = {(e) => {
                                   if(e.key === "Enter"){
                                       this.onLogin();
                                   }
                               }}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <Link to={"/request-recover-password"}>Forgot password?</Link>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col className={"text-center"}>
                        <Button color={"green"} onClick={() => {
                            if (loading) {
                                return;
                            }

                            this.onLogin();
                        }}>
                            {loading ? "PROCESSING..." : "LOGIN"}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = {loginUser};

const mapStateToProps = (state, ownProps) => ({
    loading: state.user.loading
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm);
