import React from "react";
import {ArrowDown, ArrowUp} from "react-feather";
import {connect} from "react-redux";
import {loadCommunities, setSortingCondition} from "../../../redux/community/actions";

class OrderCommunitiesList extends React.Component {
    render() {
        const {currentUser, currentField, setSortingCondition, communitySorting, loadCommunities, communityLoading} = this.props;

        if (!currentUser) {
            return null;
        }

        function isSelectedOrder(direction) {
            return communitySorting.field === currentField && communitySorting.direction === direction;
        }

        function isSelectedOrderAsc() {
            return isSelectedOrder("ASC");
        }

        function isSelectedOrderDesc() {
            return isSelectedOrder("DESC");
        }

        return (
            <div className={"d-flex m-l-5"}>
                <div className={"pointer d-flex align-items-end"}>
                    <ArrowUp color={isSelectedOrderAsc() ? "#1391BB" : "black"} width={15} onClick={() => {
                        if (!communityLoading && !isSelectedOrderAsc()) {
                            setSortingCondition(currentField, "ASC");
                            loadCommunities();
                        }
                    }}/>
                </div>
                <div className={"pointer d-flex align-items-end"}>
                    <ArrowDown width={15} color={isSelectedOrderDesc() ? "#1391BB" : "black"} onClick={() => {
                        if (!communityLoading && !isSelectedOrderDesc()) {
                            setSortingCondition(currentField, "DESC");
                            loadCommunities();
                        }
                    }}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {setSortingCondition, loadCommunities};

const mapStateToProps = (state, ownProps) => ({
    communityLoading: state.community.loading,
    communitySorting: state.community.sorting,
    currentUser: state.user.data,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderCommunitiesList)
