import {
    SOLANA_WALLET_SET_WALLET_PUBLIC_KEY,
    SOLANA_WALLET_SET_WALLET_FOUND,
    SOLANA_WALLET_GET_TRANSACTIONS_LIST,
    SOLANA_WALLET_INCREMENT_TRANSACTIONS_LIST_PAGE,
    SOLANA_WALLET_CLEAR_TRANSACTIONS_LIST, SOLANA_GET_PRICE
} from "../action_types";

const initial_state = {
    wallet_found: null,
    wallet_public_key: null,
    transactions: {
        list: [],
        page: 0,
        no_more_results: false,
        loading: false,
    },
    price: {
        loading: false,
        value: null
    }
};

export default (state = initial_state, action) => {
    let transactions;
    let price;

    switch (action.type) {
        case SOLANA_WALLET_SET_WALLET_FOUND:
            return {...state, wallet_found: action.payload};
        case SOLANA_WALLET_SET_WALLET_PUBLIC_KEY:
            return {...state, wallet_public_key: action.payload};
        case SOLANA_WALLET_GET_TRANSACTIONS_LIST:
            transactions = Object.assign({}, state.transactions);

            return {...state, transactions: {...transactions, loading: true}};
        case SOLANA_WALLET_CLEAR_TRANSACTIONS_LIST:
            transactions = Object.assign({}, state.transactions);

            return {...state, transactions: {...transactions, page: 1, list: []}};
        case SOLANA_WALLET_INCREMENT_TRANSACTIONS_LIST_PAGE:
            transactions = Object.assign({}, state.transactions);

            return {...state, transactions: {...transactions, page: transactions.page + 1}};
        case SOLANA_WALLET_GET_TRANSACTIONS_LIST.concat("_SUCCESS"):
            transactions = Object.assign({}, state.transactions);
            var list = transactions.list.concat(action.payload.data);

            return {...state, transactions: {...transactions, loading: false, list: list, no_more_results: action.payload.data.length === 0}};
        case SOLANA_GET_PRICE:
            price = Object.assign({}, state.price);
            price.loading = true;

            return {...state, price: price};
        case SOLANA_GET_PRICE.concat("_SUCCESS"):
            price = Object.assign({}, state.price);
            price.loading = false;
            price.value = action.payload.market_data.current_price.eur;

            return {...state, price: price};
        default:
            return {...state};
    }
}
