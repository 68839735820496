import React from "react";
import PlayButton from "../../../assets/images/play_button.svg";
import {Modal, ModalBody} from "reactstrap";
import {YOUTUBE_VIDEO_LINK_REGEX} from "../../../constants";

class VideoComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_video_modal_open: false,
            toggleModal: () => {
                this.setState({
                    is_video_modal_open: !this.state.is_video_modal_open,
                })
            }
        }
    }

    render() {
        const {video, title, width, height, className} = this.props;
        const {is_video_modal_open, toggleModal} = this.state;

        if(!video && !video.match(YOUTUBE_VIDEO_LINK_REGEX)) {
            return null;
        }

        let videoUrl = new URL(video);
        let video_id = videoUrl.searchParams.get("v");

        const thumbnail_url = `https://i.ytimg.com/vi/${video_id}/sddefault.jpg`;

        return (
            <>
                <Modal size={"lg"} centered isOpen={is_video_modal_open} toggle={() => {
                    toggleModal()
                }}>
                    <ModalBody>
                        <iframe className={className}
                                src={`https://www.youtube.com/embed/${video_id}?modestbranding=1`}
                                title={title}
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{
                                    width: "100%",
                                    minHeight: "340px"
                                }}/>
                    </ModalBody>
                </Modal>
                <div className={className}
                     onClick={() => {
                         toggleModal()
                     }}
                     style={{
                        cursor: "pointer",
                        width: `${width}px`,
                        height: `${height}px`,
                        backgroundColor: thumbnail_url ? "#ffffff" : "#1E2AA5",
                        backgroundImage: "url(" + PlayButton + "), url( "+ thumbnail_url +" )",
                        backgroundPosition: "center",
                        backgroundSize: "30%, cover",
                        backgroundRepeat: "no-repeat",
                    }}/>
            </>
        )
    }
}

export default VideoComponent;