import React from "react";
import {Button, Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import LogoComponent from "../../common/helpers/logo_component";
import {
    PRICE_TYPE_FIXED,
    PRICE_TYPE_PRIVATE,
    PRICE_TYPE_RANGE,
    RECURRENCE_TYPE_MONTHLY,
    RECURRENCE_TYPE_YEARLY
} from "../../../constants";
import VideoComponent from "../../common/helpers/video_component";
import NumberFormatter from "../../utils/number_formatter";
import {connect} from "react-redux";
import {toggleServiceDetailsModal} from "../../../redux/community/actions";

class ServiceCard extends React.Component {
    cutString(string, length) {
        return string.substr(0, length).concat(string.length > length ? "..." : "");
    }

    renderDescription() {
        const {service} = this.props;
        let length = service.image || service.video_link ? 300 : 500;

        return this.cutString(service.description, length);
    }

    renderRecurrence(service) {
        switch (service.recurrence_type) {
            case RECURRENCE_TYPE_MONTHLY:
                return "paid monthly";
            case RECURRENCE_TYPE_YEARLY:
                return "paid yearly";
            default:
                return;
        }
    }

    renderPricing() {
        const {user, service} = this.props;

        switch (service.price_type) {
            case PRICE_TYPE_FIXED:
                return (
                    <div className={"d-flex flex-column text-center"}>
                        <span className={"f-30 f-w-900 dark-blue-text"}><NumberFormatter value={service.fixed_price}/> {service.currency}</span>
                        <span className={"f-15 grey-text"}>{Number(service.recurrent) ? this.renderRecurrence(service) : ""}</span>
                    </div>
                );
            case PRICE_TYPE_RANGE:
                return (
                    <div className={"d-flex flex-column text-center m-b-5"}>
                        <span className={"f-30 f-w-900 dark-blue-text"}><NumberFormatter value={service.min_price}/> - <NumberFormatter value={service.max_price}/> {service.currency}</span>
                    </div>
                );
            case PRICE_TYPE_PRIVATE:
                return (
                    <div className={"m-b-15"}>
                        <span className={"f-22 f-w-600 mb-3"}><i>{user && user.is_community ? "Pricing is private" : "Contact for pricing"}</i></span>
                    </div>
                );
            default:
                return null;
        }
    }

    render() {
        const {service, toggleServiceDetailsModal} = this.props;

        return (
            <Card className={"min-height-750"}>
                <CardHeader className={"text-center p-3 min-height-100 d-flex align-items-center justify-content-center border-bottom-0"}>
                    <h3 className={"f-26 my-0 f-w-900"}>{service.name}</h3>
                </CardHeader>
                <CardBody className={"p-0 d-flex flex-column"}>
                    {service.video_link && <div>
                        <VideoComponent title={`${service.name} - ${service.id}`} video={service.video_link} width={360} height={200} className={"object-contain m-b-20 w-100"} />
                    </div>}
                    {!service.video_link && service.image && <div>
                        <LogoComponent logo={service.image} width={360} height={200} className={"object-contain m-b-20 w-100"}/>
                    </div>}
                    <div className={"d-flex flex-column px-3 pb-4"}>
                        <p className={"f-14 f-w-600 whitespace-pre-line"}>
                            {this.renderDescription()}
                        </p>
                    </div>
                    <div className={"mt-auto text-center pt-0 px-4 pb-4"}>
                        {this.renderPricing()}
                    </div>
                </CardBody>
                <CardFooter className={"p-0 d-flex flex-column"}>
                    <Button className={"rounded-top-0 rounded-bottom-25 p-t-10 p-b-10"} color={"green"} onClick={() => {
                        toggleServiceDetailsModal(service);
                    }}>SEE MORE</Button>
                </CardFooter>
            </Card>
        )
    }
}

const mapDispatchToProps = {
    toggleServiceDetailsModal,
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceCard);