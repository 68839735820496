import React, {Fragment} from 'react';
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import {connect} from "react-redux";
import ListImage from "../../assets/images/list_black.png";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";
import NumberFormatter from "../utils/number_formatter";
import {toast} from "react-toastify";
import {
    createReport,
    deleteReport,
    incrementCommunitiesOfReportPage,
    decrementCommunitiesOfReportPage,
    loadReports,
    incrementReportsPage,
    decrementReportsPage
} from "../../redux/report/actions";

class ReportsListPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            new_report: {
                name: "",
                selected_list_id: 0
            },
            createReportModalIsOpen: false,
            showCreateReportModalToggle: () => {
                this.setState({
                    createReportModalIsOpen: this.state.createReportModalIsOpen === false
                });
            },
            opened_dropdowns: []
        };
    }

    toggleDropdown(report) {
        const {opened_dropdowns} = this.state;

        var position = opened_dropdowns.indexOf(report.id);

        if (position > -1) {
            opened_dropdowns.splice(position, 1);
        } else {
            opened_dropdowns.push(report.id);
        }

        this.setState({
            opened_dropdowns: opened_dropdowns
        })
    }

    renderLoading() {
        return (
            <tr>
                <td className="text-center p-5" colSpan={5}>
                    <h4 className="m-0">Loading...</h4>
                </td>
            </tr>
        );
    }

    renderNoResults() {
        const {community_lists} = this.props;

        return (
            <tr>
                <td className="text-center p-5" colSpan={5}>
                    <img src={ListImage} width={50}/>
                    <br/>
                    <br/>
                    <span>No reports have been created yet</span>
                    <br/>
                    <u><span className={"pointer purple-text"} onClick={() => {
                        if (community_lists.length === 0) {
                            toast.warn("You need to create Community Lists first!")
                            return;
                        }

                        this.state.showCreateReportModalToggle();
                    }}>Build your first report</span></u>
                </td>
            </tr>
        );
    }

    renderTable() {
        const {reports, deleteReport} = this.props;
        const {opened_dropdowns} = this.state;
        const tableRows = [];

        if (reports.length) {
            reports.forEach((report) => {
                tableRows.push(<tr key={report.id}>
                    <td>
                        {report.status == 3 && (
                            <Link to={"/reports/" + report.id} className={"green-cta"}>
                                <u><b>{report.name}</b></u>
                            </Link>
                        )}

                        {report.status != 3 && (
                            <span>{report.name}</span>
                        )}
                    </td>
                    <td><NumberFormatter value={report.total_communities}/></td>
                    <td>
                        <Link to={"/lists/" + report.source_community_list_id} className={"green-cta"}>
                            <u><b>{report.source_community_list_name}</b></u>
                        </Link>
                    </td>
                    <td>
                        {/*{report.status == 1 && ("Waiting")}*/}
                        {report.status == 1 && ("Analysis in progress")}
                        {report.status == 2 && ("Analysis in progress")}
                        {report.status == 3 && ("Ready")}
                        {report.status == 4 && ("Failed")}
                    </td>
                    <td>
                        <Dropdown isOpen={opened_dropdowns.indexOf(report.id) > -1} toggle={() => {
                            this.toggleDropdown(report);
                        }}>
                            <DropdownToggle tag={"div"} className={"pointer"}>
                                . . .
                            </DropdownToggle>
                            <DropdownMenu className={"report-actions-dropdown"}>
                                <DropdownItem
                                    tag={"span"}
                                    className={"action-item"}
                                    onClick={() => {
                                        if (window.confirm("Are you sure?")) {
                                            deleteReport(report.id);
                                        }
                                    }}
                                >Delete</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </td>
                </tr>);
            });

            return tableRows;
        }

        return null;
    }

    renderCreateReportModal() {
        const {createReport, community_lists} = this.props;
        const {createReportModalIsOpen, showCreateReportModalToggle, new_report} = this.state;

        return (
            <Modal isOpen={createReportModalIsOpen} toggle={showCreateReportModalToggle}>
                <ModalHeader toggle={showCreateReportModalToggle}>New Report</ModalHeader>
                <ModalBody>
                    <input type="text" className="form-control" placeholder={"Name of the report"}
                           value={new_report.name} onChange={(e) => {
                        this.setState({
                            new_report: {
                                ...(new_report),
                                name: e.target.value
                            }
                        });
                    }}/>
                    <br/>
                    <select className={"form-control"}
                            value={new_report.selected_list_id}
                            onChange={(e) => {

                                let suggestedName = null;

                                if (new_report.name.length === 0) {
                                    suggestedName = community_lists.filter(function (list) {
                                        return list.id == e.target.value;
                                    })[0].name;
                                }

                                if (suggestedName) {
                                    this.setState({
                                        new_report: {
                                            name: suggestedName,
                                            selected_list_id: Number.parseInt(e.target.value)
                                        }
                                    });
                                } else {
                                    this.setState({
                                        new_report: {
                                            ...new_report,
                                            selected_list_id: Number.parseInt(e.target.value)
                                        }
                                    });
                                }
                            }}
                    >
                        <option value={0} key={0}>Choose a list</option>

                        {community_lists.filter((list) => list.total_communities > 0).map((list) => {
                            return (<option key={list.id} value={list.id}>{list.name}</option>);
                        })}
                    </select>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={showCreateReportModalToggle}>
                        Close
                    </Button>
                    <Button color="green" onClick={() => {
                        if (new_report.selected_list_id > 0 && new_report.name.length > 0) {
                            createReport(new_report);
                            showCreateReportModalToggle();
                        }
                    }}>
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        const {
            loading,
            reports,
            user,
            isLoadingUser,
            community_lists,
            report_list,
            loadReports,
            incrementReportsPage,
            decrementReportsPage
        } = this.props;
        const {createReportModalIsOpen, showCreateReportModalToggle} = this.state;

        if ((!isLoadingUser && !user) || (user && user.is_community)) {
            return (<Redirect to={"/"}/>);
        }

        return (
            <Fragment>

                {createReportModalIsOpen && this.renderCreateReportModal()}

                <Breadcrumb title="Your reports" hideBreadcrumbRight={true} actions={() => {
                    return (
                        <Button color={"green"} onClick={() => {
                            if (community_lists.length === 0) {
                                toast.warn("You need to create Community Lists first!")
                                return;
                            }

                            showCreateReportModalToggle();
                        }}>
                            Create a new report
                        </Button>
                    );
                }}/>

                <Container fluid={true}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table hover>
                                            <thead>
                                            <tr>
                                                <th scope="col" className={'border-top-0'}>
                                                    <div className={"d-flex"}>
                                                        <div>Name</div>
                                                        {/*<OrderCommunitiesReport currentField={"name"}/>*/}
                                                    </div>
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    Communities
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    Source
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    Status
                                                </th>
                                                <th scope="col" className={'border-top-0'}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {reports.length > 0 && this.renderTable()}
                                            {loading ? this.renderLoading() : null}
                                            {!loading && reports.length === 0 ? this.renderNoResults() : null}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                                <CardFooter className="d-flex">
                                    {report_list.total_pages <= 1 ? null : (
                                        <Pagination className="pagination-primary">
                                            <PaginationItem>
                                                <PaginationLink onClick={() => {
                                                    decrementReportsPage();
                                                    loadReports();
                                                }}>Previous</PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#javascript">
                                                    {report_list.page + 1}/{report_list.total_pages}</PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink onClick={() => {
                                                    incrementReportsPage();
                                                    loadReports();
                                                }}>Next</PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    )}
                                    <div className={"pt-2 pl-3 purple-text"}>
                                        <span>
                                        Total results:{" "}
                                            <b>
                                        <NumberFormatter
                                            value={report_list.total_results}
                                        ></NumberFormatter>
                                        </b>
                                        </span>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    createReport,
    deleteReport,
    incrementCommunitiesOfReportPage,
    decrementCommunitiesOfReportPage,
    loadReports,
    incrementReportsPage,
    decrementReportsPage,
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    isLoadingUser: state.user.loading,
    reports: state.report.list,
    report_list: state.report,
    loading: state.report.loading,
    community_lists: state.community_list.list
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportsListPage)

