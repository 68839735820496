import React, {Fragment} from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    TabContent,
    Table,
    TabPane
} from "reactstrap";
import {connect} from "react-redux";
import {
    clearGlobalInsights,
    loadGlobalCategoriesDistribution,
    loadGlobalCommunityTotals,
    loadGlobalEngagementDistribution,
    loadGlobalEventsVSParticipantsDistribution,
    loadGlobalLocationDistribution,
    loadGlobalMapDistribution,
    loadGlobalSocialMediaDistribution,
    loadGlobalTopicDistribution,
    selectNetwork,
    loadGlobalAverageParameters,
} from "../../../redux/dashboard/actions";
import MapDistributionReport from "../../dashboard/components/map_distribution";
import CommunityTotalsReport from "../../dashboard/components/community_totals";
import SocialMediaDistributionReport from "../../dashboard/components/social_media_distribution";
import EngagementDistributionReport from "../../dashboard/components/engagement_distribution";
import EventsVSParticipantsDistributionReport from "../../dashboard/components/events_vs_participants_distribution";
import TopicDistributionReport from "../../dashboard/components/topic_distribution";
import CategoryDistributionReport from "../../dashboard/components/category_distribution";
import LocationDistributionReport from "../../dashboard/components/location_distribution";
import NumberFormatter from "../../utils/number_formatter";
import {Link} from "react-router-dom";
import CommunityCategories from "../../common/helpers/community_categories";
import CommunityOfflineEngagement from "../../dashboard/components/offline_engagement";
import ScaleBuilder from "../../community/utilities/scale_builder";
import {Redirect} from "react-router";
import CommunityHeader from "./header";
import CommunityProfileComponent from "./community_profile_component";
import SocialProfilesTable from "./social_profiles_table";
import {
    decrementCommunitiesOfNetworkPage,
    incrementCommunitiesOfNetworkPage,
    loadCommunitiesOfNetwork
} from "../../../redux/community/actions";

class NetworkCommunityProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active_tab: "1"
        };
    }

    componentDidMount() {
        const {community, selectNetwork, loadCommunitiesOfNetwork, loadGlobalMapDistribution, loadGlobalCategoriesDistribution, loadGlobalLocationDistribution, loadGlobalCommunityTotals, loadGlobalSocialMediaDistribution, loadGlobalEngagementDistribution, loadGlobalEventsVSParticipantsDistribution, loadGlobalTopicDistribution, clearGlobalInsights, loadGlobalAverageParameters} = this.props;

        loadCommunitiesOfNetwork(community.id);
        clearGlobalInsights();
        selectNetwork(community.id);

        // load dashboard networks
        loadGlobalMapDistribution();
        loadGlobalLocationDistribution();
        loadGlobalCommunityTotals();
        loadGlobalSocialMediaDistribution();
        loadGlobalEngagementDistribution();
        loadGlobalEventsVSParticipantsDistribution();
        loadGlobalTopicDistribution();
        loadGlobalCategoriesDistribution();
        loadGlobalAverageParameters();
    }

    componentWillUnmount() {
        const {clearGlobalInsights} = this.props;
        clearGlobalInsights();
    }

    setActiveTab(id) {
        this.setState({
            active_tab: id
        })
    }

    renderLoading() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Loading...</h4>
            </div>
        );
    }

    renderTable() {
        return (
            <div className="table-responsive">
                <Table hover>
                    <thead>
                    <tr>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Name</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Tags</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Location</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Members</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Global engagement</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Online engagement</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Offline engagement</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderCommunitiesList()}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderCommunitiesList() {
        const {selectedCommunity} = this.props;
        const {children} = selectedCommunity;

        const tableRows = [];

        if (children.list.length) {
            children.list.forEach((community) => {
                tableRows.push(
                    <tr key={community.community_id}>
                        <td>
                            <Link to={"/community/" + community.community_id} className={"green-cta"} target="_blank" rel="noopener noreferrer">
                                <u><b>{community.community_name}</b></u>
                            </Link>
                        </td>
                        <td><CommunityCategories community={community}/></td>
                        {community.country && community.city
                            ? (<td>{community.country + ", " + community.city}</td>)
                            : (<td/>)}
                        <td><span><NumberFormatter value={community.total_members}/></span></td>
                        <td>
                            <ScaleBuilder value={community.overall_ranking} community={community}/>
                        </td>
                        <td>
                            <ScaleBuilder value={community.online_ranking} community={community}/>
                        </td>
                        <td>
                            <ScaleBuilder value={community.offline_ranking} community={community}/>
                        </td>
                    </tr>
                );
            });

            return tableRows;
        }

        return null;
    }

    render() {
        const {user, loadCommunitiesOfNetwork, incrementCommunitiesOfNetworkPage, decrementCommunitiesOfNetworkPage, community, selectedCommunity} = this.props;
        const {children} = selectedCommunity;
        const {active_tab} = this.state;

        if (user && user.is_community) {
            return (<Redirect to={"/"}/>);
        }

        return (
            <Fragment>
                <Container fluid={true} className={"community-details"}>
                    <CommunityHeader currentUser={user} community={community} />
                    <Nav className="nav-primary report-nav d-flex align-items-center" tabs>
                        <NavItem>
                            <NavLink
                                href="#javascript"
                                className={active_tab === "1" ? "active" : ""}
                                onClick={() => this.setActiveTab("1")}
                            >
                                <i className="icofont icofont-chart-bar-graph" />Dashboard
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#javascript"
                                className={active_tab === "2" ? "active" : ""}
                                onClick={() => this.setActiveTab("2")}
                            >
                                <i className="icofont icofont-listine-dots" />Source Communities
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={active_tab}>
                        <TabPane className="fade show" tabId="1">
                            <Container fluid={true} style={{paddingTop: "10px"}}>
                                <Row>
                                    <Col>
                                        <MapDistributionReport/>
                                    </Col>
                                </Row>

                                <Row className={'mt-5'}>
                                    <Col>
                                        <CommunityTotalsReport isNetwork={community.is_network}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <CommunityProfileComponent community={community} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <LocationDistributionReport/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} sm={6}>
                                        <CategoryDistributionReport/>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <SocialMediaDistributionReport/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <CommunityOfflineEngagement />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <EngagementDistributionReport/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <EventsVSParticipantsDistributionReport/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <TopicDistributionReport/>
                                    </Col>
                                </Row>
                                <SocialProfilesTable community={community} currentUser={user} />
                            </Container>
                        </TabPane>
                        <TabPane tabId="2">
                            <Card>
                                <CardBody>
                                    {this.renderTable()}
                                    {children.loading ? this.renderLoading() : ""}
                                </CardBody>
                                <CardFooter className={"d-flex"}>
                                    {children.total_pages <= 1 ? null : (
                                        <Pagination
                                            className="pagination-primary"
                                        >
                                            <PaginationItem disabled={children.page === 0}>
                                                <PaginationLink onClick={() => {
                                                    decrementCommunitiesOfNetworkPage();
                                                    loadCommunitiesOfNetwork(community.id);
                                                }}>Previous</PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink
                                                    href="#javascript">{children.page + 1}/{children.total_pages}</PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                disabled={children.page + 1 === children.total_pages}>
                                                <PaginationLink onClick={() => {
                                                    incrementCommunitiesOfNetworkPage();
                                                    loadCommunitiesOfNetwork(community.id);
                                                }}>Next</PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    )}

                                    <div className={"pt-2 pl-3 purple-text"}>
                                        <span>Total results: <b><NumberFormatter
                                            value={children.total_results}/></b></span>
                                    </div>
                                </CardFooter>
                            </Card>
                        </TabPane>
                    </TabContent>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    selectNetwork,
    loadCommunitiesOfNetwork,
    incrementCommunitiesOfNetworkPage,
    decrementCommunitiesOfNetworkPage,
    loadGlobalMapDistribution,
    loadGlobalCategoriesDistribution,
    loadGlobalLocationDistribution,
    loadGlobalCommunityTotals,
    loadGlobalSocialMediaDistribution,
    loadGlobalEngagementDistribution,
    loadGlobalEventsVSParticipantsDistribution,
    loadGlobalTopicDistribution,
    loadGlobalAverageParameters,
    clearGlobalInsights,
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    community: state.community.selected_community.data,
    selectedCommunity: state.community.selected_community,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NetworkCommunityProfile)