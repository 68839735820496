import {combineReducers} from 'redux'

import community from './community/reducer'
import category from './category/reducer'
import user from './user/reducer'
import customer from './customer/reducer'
import utils from './utils/reducer'
import community_list from './community_list/reducer'
import file from './file/reducer'
import locations from './locations/reducer'
import dashboard from './dashboard/reducer'
import report from './report/reducer'
import channel from './channel/reducer'
import campaign from './campaign/reducer'
import job from "./job/reducer"
import job_applicants from "./job_applicants/reducer"
import filters from "./filters/reducer"
import oauth from "./oauth/reducer"
import solana from "./solana/reducer"

const reducers = combineReducers({
    community,
    category,
    user,
    customer,
    utils,
    community_list,
    file,
    locations,
    dashboard,
    report,
    campaign,
    channel,
    job,
    job_applicants,
    filters,
    oauth,
    solana
});

export default reducers;
