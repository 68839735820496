import React from "react";
import {connect} from "react-redux";
import {selectLocationOnDashboard} from "../../../redux/dashboard/actions";
import DashboardFilter from "./base_dasboard_filter";

class LocationFilter extends React.Component {
    render() {
        const {locations, selectedLocation, selectLocationOnDashboard} = this.props;

        return (
            <DashboardFilter
                options={[{id: null, name: "GLOBAL"}].concat(locations)}
                selectedOption={selectedLocation}
                onSelection={(location) => {
                    selectLocationOnDashboard(location);

                    this.props.onValueChanged();
                }}
            />
        );
    }
}

const mapDispatchToProps = {
    selectLocationOnDashboard
};

const mapStateToProps = (state, ownProps) => ({
    locations: state.locations.list,
    selectedLocation: state.dashboard.selected_location,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationFilter)
