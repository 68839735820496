import React from "react";
import {Label} from "reactstrap";
import {connect} from "react-redux";
import {selectJobToSearchApplicants, unselectJobToSearchApplicants} from "../../../redux/job_applicants/actions";
import {withRouter} from "react-router";

class JobsFilterForApplicants extends React.Component {
    render() {
        const {selectJobToSearchApplicants, unselectJobToSearchApplicants, onJobChanged} = this.props;
        const {filters, user} = this.props;
        const {job, company} = filters;

        return (
            <div>
                <Label className="d-block">
                    Job:
                </Label>
                <select className={"form-control"}
                        disabled={!user || (user.is_super_admin && company.selected_companies.length === 0)}
                        onChange={(e) => {
                            let selectedIndex = parseInt(e.target.value);

                            if (selectedIndex >= 0) {
                                selectJobToSearchApplicants(job.options[selectedIndex]);
                            } else {
                                unselectJobToSearchApplicants(0);
                            }

                            onJobChanged();
                        }}
                >
                    <option value="-1">Click to select</option>
                    {job.options.map((job, index) => {
                        return <option key={"job-to-filter-" + job.id}
                                       value={index}>{job.title}</option>;
                    })}
                </select>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    filters: state.job_applicants.filters
});

const mapDispatchToProps = {
    selectJobToSearchApplicants,
    unselectJobToSearchApplicants
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(JobsFilterForApplicants));