import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {saveNewPassword} from "../../redux/user/actions";

class RecoverPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            repeat_password: '',
            token: this.props.routeParams.token
        };
    }

    render() {
        const {saveNewPassword} = this.props;

        return (
            <Fragment>
                <Container fluid={true}>
                    <Row className={"justify-content-center pt-5"}>
                        <Col xl="4" lg="4" md="8" sm="12" xs="12">
                            <Card>
                                <CardHeader className={'text-center'}>
                                    <h5 className="mb-0">SET NEW PASSWORD</h5>
                                </CardHeader>

                                <CardBody className="p-4">
                                    <Row>
                                        <Col>
                                            <input type="email" className="form-control" placeholder={"Password"}
                                                   value={this.state.password}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           password: e.target.value
                                                       });
                                                   }}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col>
                                            <input type="email" className="form-control" placeholder={"Repeat Password"}
                                                   value={this.state.repeat_password}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           repeat_password: e.target.value
                                                       });
                                                   }}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col className={"text-center"}>
                                            <Button color={"green"} onClick={() => {
                                                if(this.state.password.length >= 8 && this.state.repeat_password.length >= 8 && this.state.password === this.state.repeat_password){
                                                    this.setState({
                                                        password: '',
                                                        repeat_password: '',
                                                    });

                                                    // send request
                                                    saveNewPassword(this.state.password, this.state.repeat_password, this.state.token);
                                                }
                                            }}>
                                                SAVE
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {saveNewPassword};

export default connect(
    null,
    mapDispatchToProps
)(RecoverPasswordPage);
