import React from "react";
import {Col, Row} from "reactstrap";
import CommunityFacebookGroups from "./facebook_groups";
import CommunityFacebookPages from "./facebook_pages";
import CommunityTwitterProfiles from "./twitter_profiles";

function SocialProfilesTable(props) {
    const {community, currentUser} = props;

    return (
        <div>
            {community.facebook_group_profiles.length > 0 && (
                <Row>
                    <Col>
                        <CommunityFacebookGroups community={community} currentUser={currentUser}/>
                    </Col>
                </Row>
            )}
            {community.facebook_page_profiles.length > 0 && (
                <Row>
                    <Col>
                        <CommunityFacebookPages community={community} currentUser={currentUser}/>
                    </Col>
                </Row>
            )}
            {community.twitter_profiles.length > 0 && (
                <Row>
                    <Col>
                        <CommunityTwitterProfiles community={community} currentUser={currentUser}/>
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default SocialProfilesTable;