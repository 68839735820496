import React from "react";
import {Button, ButtonGroup} from "reactstrap";
import {connect} from "react-redux";
import {
    changeFilterForList,
    loadCommunities,
    setFreeSearchForCommunity
} from '../../../redux/community/actions';

class CommunityTypeFilter extends React.Component {

    handleToggleListFilter(isNetwork) {
        const {loadCommunities, changeFilterForList, setFreeSearchForCommunity} = this.props;

        changeFilterForList(isNetwork);
        setFreeSearchForCommunity('');
        loadCommunities();
    }

    calculateColor(toggleValue) {
        return (toggleValue ? "primary" : "light");
    }

    render() {
        const {listFilters} = this.props;
        const {filter_for_networks} = listFilters;

        return (
            <div>
                <div className="faq-form m-b-0 m-t-15">
                    <ButtonGroup className={"w-100"}>
                        <Button size={'sm'} className={'w-50 px-3'} color={this.calculateColor(!filter_for_networks)} onClick={() => {this.handleToggleListFilter(false);}}>
                            Communities
                        </Button>
                        <Button size={'sm'} className={'w-50 px-3'} style={{lineHeight: "1.2"}} color={this.calculateColor(filter_for_networks)} onClick={() => {this.handleToggleListFilter(true);}}>
                            Networks of communities
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    listFilters: state.community.list_filters
});

const mapDispatchToProps = {
    changeFilterForList,
    loadCommunities,
    setFreeSearchForCommunity
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityTypeFilter)
