import React, {Fragment, useEffect, useLayoutEffect, useState} from 'react';
import {MENUITEMS} from './menu'
import SidebarInboxBadge from "../../common/channel/sidebar_inbox_badge";
import ConfigDB from '../../../config'
import {Link} from 'react-router-dom'
import useWindowSize from "../hooks/use_window_size";

const Sidebar = (props) => {
    const [margin, setMargin] = useState(0);
    const [mainmenu, setMainMenu] = useState(MENUITEMS);
    const sidebar_type = ConfigDB.data.settings.sidebar.wrapper;
    const layout_type = useState(ConfigDB.data.settings.layout_type);
    const [hideRightArrow, setHideRightArrow] = useState(true);
    const [hideLeftArrow, setHideLeftArrow] = useState(true);
    const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
    const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
    const [width, height] = useWindowSize(sidebar_type);

    useEffect(() => {

        const currentUrl = window.location.pathname;

        mainmenu.filter(items => {
            if (items.generated_path(props.currentUser, props.redeemToken, props.jobBoardToken) === currentUrl)
                setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
                if (subItems.generated_path(props.currentUser, props.redeemToken, props.jobBoardToken) === currentUrl)
                    setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                    if (subSubItems.generated_path(props.currentUser, props.redeemToken, props.jobBoardToken) === currentUrl) {
                        setNavActive(subSubItems)
                        return true
                    } else {
                        return false
                    }
                })
                return subItems
            })

            return items
        })
    }, []);

    const setNavActive = (item) => {
        // MENUITEMS.filter(menuItem => {
        //     if (menuItem !== item)
        //         menuItem.active = false
        //     if (menuItem.children && menuItem.children.includes(item))
        //         menuItem.active = true
        //     if (menuItem.children) {
        //         menuItem.children.filter(submenuItems => {
        //             if (submenuItems.children && submenuItems.children.includes(item)) {
        //                 menuItem.active = true
        //                 submenuItems.active = true
        //                 return true
        //             } else {
        //                 return false
        //             }
        //         })
        //     }
        //     return menuItem
        // });

        MENUITEMS.forEach(menuItem => {
            menuItem.active = false;
        });

        item.active = !item.active;
        setMainMenu({mainmenu: MENUITEMS})
    }

    const toggletNavActive = (item) => {
        if (!item.active) {
            MENUITEMS.forEach(a => {
                if (MENUITEMS.includes(item))
                    a.active = false
                if (!a.children) return false
                a.children.forEach(b => {
                    if (a.children.includes(item)) {
                        b.active = false
                    }
                    if (!b.children) return false
                    b.children.forEach(c => {
                        if (b.children.includes(item)) {
                            c.active = false
                        }
                    })
                })
            });
        }
        item.active = !item.active
        setMainMenu({mainmenu: MENUITEMS})

        if (!ConfigDB.data.settings.sidebar.disable_submenu) {
            document.querySelector(".iconsidebar-menu").classList.remove('iconbar-mainmenu-close')
        }
    }

    const scrollToRight = () => {
        const elmnt = document.getElementById("myDIV");
        const menuWidth = elmnt.offsetWidth;
        const temp = menuWidth + margin
        // Checking condition for remaing margin
        if (temp < menuWidth) {
            setMargin(-temp);
            setHideRightArrow(true);
        } else {
            setMargin(margin => margin += -width);
            setHideLeftArrow(false);
        }

    }

    const scrollToLeft = () => {
        // If Margin is reach between screen resolution
        if (margin >= -width) {
            setMargin(0)
            setHideLeftArrow(true);
        } else {
            setMargin(margin => margin += width);
            setHideRightArrow(false);
        }
    }

    const scrollToLeftRTL = () => {
        if (margin <= -width) {
            setMargin(margin => margin += -width);
            setHideLeftArrowRTL(true);
        } else {
            setMargin(margin => margin += -width);
            setHideRightArrowRTL(false);
        }
    }

    const scrollToRightRTL = () => {
        const temp = width + margin
        // Checking condition for remaing margin
        if (temp === 0) {
            setMargin(temp);
            setHideRightArrowRTL(true);
        } else {
            setMargin(margin => margin += width);
            setHideRightArrowRTL(false);
            setHideLeftArrowRTL(false);
        }
    }

    const isMobileScreen = () => {
        return (window.innerWidth > 100 && window.innerWidth < 991);
    }

    return (
        <div className={`iconsidebar-menu ${isMobileScreen() ? "iconbar-second-close" : "iconbar-mainmenu-close"} d-block`}>
            <div className="sidebar custom-scrollbar">
                <ul className="iconMenu-bar custom-scrollbar">
                    <li className={`left-arrow ${layout_type === 'rtl' ? hideLeftArrowRTL ? 'd-none' : 'hideLeftArrowRTL' : hideLeftArrow ? 'd-none' : 'hideLeftArrow'}`}
                        onClick={(ConfigDB.data.settings.sidebar.type === 'horizontal_sidebar' && layout_type === 'rtl') ? scrollToLeftRTL : scrollToLeft}>
                        <i className="fa fa-angle-left"></i>
                    </li>
                    {
                        MENUITEMS.map((menuItem, i) => {
                                if (menuItem.authenticated && !props.currentUser)
                                    return null;

                                if (menuItem.authenticated && props.currentUser && (menuItem.verify_security && !menuItem.verify_security(props.currentUser)))
                                    return null;

                                if (menuItem.verify_security && !menuItem.verify_security(props.currentUser, props.redeemToken, props.jobBoardToken))
                                    return null;

                                return (
                                    <li className={`${menuItem.active ? 'open' : ''}`} key={i}>
                                        {menuItem.tag}
                                        {menuItem.external && (
                                            <a className="bar-icons"
                                               href={menuItem.generated_path(props.currentUser, props.redeemToken, props.jobBoardToken)}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               onClick={() => toggletNavActive(menuItem)}
                                            >
                                                {menuItem.active ? menuItem.enabled_icon : menuItem.icon}
                                                <span>{menuItem.title}</span>
                                            </a>
                                        ) || (
                                            <Link className="bar-icons" to={menuItem.generated_path(props.currentUser, props.redeemToken, props.jobBoardToken)}
                                                  onClick={() => toggletNavActive(menuItem)}>
                                                {menuItem.active ? menuItem.enabled_icon : menuItem.icon}
                                                <span>{menuItem.title}</span></Link>
                                        )}

                                        {menuItem.title === "INBOX" && !menuItem.active ? <SidebarInboxBadge /> : null}

                                        {/* If we add sidebar children we can activate this code */}
                                        {/* {menuItem.children ?
                                            <ul className="iconbar-mainmenu custom-scrollbar">
                                                {menuItem.children.map((childrenItem, index) =>
                                                    <Fragment key={index}>

                                                        {(childrenItem.type === 'sub') ?
                                                            <li className="iconbar-header">{childrenItem.title}</li>
                                                            : ''}

                                                        {(childrenItem.type === 'sub-header') ?
                                                            <li className="iconbar-header sub-header">{childrenItem.title}</li>
                                                            : ''}

                                                        {(childrenItem.type === 'link') ?
                                                            <li className={childrenItem.active ? 'active' : ''}>
                                                                <Link className={childrenItem.active ? 'active' : ''}
                                                                      to={childrenItem.path}
                                                                      onClick={() => toggletNavActive(childrenItem)}>{childrenItem.title}</Link>
                                                            </li>
                                                            : ""}
                                                    </Fragment>
                                                )}
                                            </ul>
                                            : ''} */}
                                    </li>
                                );
                            }
                        )}
                    <li className={`right-arrow ${layout_type === 'rtl' ? hideRightArrowRTL ? 'd-none' : 'hideRightArrowRTL' : hideRightArrow ? 'd-none' : 'hideRightArrow'}`}
                        onClick={(sidebar_type === 'horizontal_sidebar' && layout_type === 'rtl') ? scrollToRightRTL : scrollToRight}>
                        <i className="fa fa-angle-right"></i>
                    </li>
                </ul>
            </div>
        </div>
    );
}


export default Sidebar
