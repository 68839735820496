import React, {Fragment} from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    TabContent,
    Table,
    TabPane
} from "reactstrap";
import {connect} from "react-redux";
import {
    decrementCommunitiesOfReportPage,
    incrementCommunitiesOfReportPage,
    loadCommunitiesOfReport,
    loadReportDetails
} from "../../redux/report/actions";
import {
    clearGlobalInsights,
    loadGlobalCategoriesDistribution,
    loadGlobalCommunityTotals,
    loadGlobalEngagementDistribution,
    loadGlobalEventsVSParticipantsDistribution,
    loadGlobalLocationDistribution,
    loadGlobalMapDistribution,
    loadGlobalSocialMediaDistribution,
    loadGlobalTopicDistribution,
    selectReport,
    loadGlobalAverageParameters
} from "../../redux/dashboard/actions";
import MapDistributionReport from "../dashboard/components/map_distribution";
import CommunityTotalsReport from "../dashboard/components/community_totals";
import OnlineEngagementReport from "../dashboard/components/online_engagement";
import SocialMediaDistributionReport from "../dashboard/components/social_media_distribution";
import EngagementDistributionReport from "../dashboard/components/engagement_distribution";
import EventsVSParticipantsDistributionReport from "../dashboard/components/events_vs_participants_distribution";
import TopicDistributionReport from "../dashboard/components/topic_distribution";
import CategoryDistributionReport from "../dashboard/components/category_distribution";
import LocationDistributionReport from "../dashboard/components/location_distribution";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import NumberFormatter from "../utils/number_formatter";
import {Link} from "react-router-dom";
import CommunityCategories from "../common/helpers/community_categories";
import CommunityOfflineEngagement from "../dashboard/components/offline_engagement";
import AverageCommunityReport from "../dashboard/components/average_community";
import ScaleBuilder from "../community/utilities/scale_builder";
import {Redirect} from "react-router";

class ReportDetailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active_tab: "1"
        };
    }

    componentDidMount() {
        const {loadReportDetails, loadCommunitiesOfReport, loadGlobalMapDistribution, loadGlobalCategoriesDistribution, loadGlobalLocationDistribution, loadGlobalCommunityTotals, loadGlobalSocialMediaDistribution, loadGlobalEngagementDistribution, loadGlobalEventsVSParticipantsDistribution, loadGlobalTopicDistribution, clearGlobalInsights, selectReport, loadGlobalAverageParameters} = this.props;

        loadReportDetails(this.props.routeParams.id);
        loadCommunitiesOfReport(this.props.routeParams.id);

        clearGlobalInsights();
        selectReport(this.props.routeParams.id);

        // load dashboard reports
        loadGlobalMapDistribution();
        loadGlobalLocationDistribution();
        loadGlobalCommunityTotals();
        loadGlobalSocialMediaDistribution();
        loadGlobalEngagementDistribution();
        loadGlobalEventsVSParticipantsDistribution();
        loadGlobalTopicDistribution();
        loadGlobalCategoriesDistribution();
        loadGlobalAverageParameters();
    }

    componentWillUnmount() {
        const {clearGlobalInsights} = this.props;
        clearGlobalInsights();
    }

    setActiveTab(id) {
        this.setState({
            active_tab: id
        })
    }

    renderLoading() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Loading...</h4>
            </div>
        );
    }

    renderTable() {
        return (
            <div className="table-responsive">
                <Table hover>
                    <thead>
                    <tr>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Name</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Tags</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Location</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Members</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Global engagement</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Online engagement</th>
                        <th scope="col" className={'border-top-0 border-bottom-0'}>Offline engagement</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderCommunitiesList()}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderCommunitiesList() {
        const {report} = this.props;
        const {communities} = report;

        const tableRows = [];

        if (communities.list.length) {
            communities.list.forEach((community) => {
                tableRows.push(
                    <tr key={community.community_id}>
                        <td>
                            <Link to={"/community/" + community.community_id} className={"green-cta"} target="_blank" rel="noopener noreferrer">
                                <u><b>{community.community_name}</b></u>
                            </Link>
                        </td>
                        <td><CommunityCategories community={community}/></td>
                        {community.country && community.city
                            ? (<td>{community.country + ", " + community.city}</td>)
                            : (<td/>)}
                        <td><span><NumberFormatter value={community.total_members}/></span></td>
                        <td>
                            <ScaleBuilder value={community.overall_ranking} community={community}/>
                        </td>
                        <td>
                            <ScaleBuilder value={community.online_ranking} community={community}/>
                        </td>
                        <td>
                            <ScaleBuilder value={community.offline_ranking} community={community}/>
                        </td>
                    </tr>
                );
            });

            return tableRows;
        }

        return null;
    }

    render() {
        const {user, report, loadCommunitiesOfReport, incrementCommunitiesOfReportPage, decrementCommunitiesOfReportPage} = this.props;
        const {communities} = report;
        const {active_tab} = this.state;

        if (user && user.is_community) {
            return (<Redirect to={"/"}/>);
        }

        if (report.loading) {
            return (
                <div className="text-center p-5">
                    <h4 className="m-0">Loading...</h4>
                </div>
            );
        }

        if (!report.loading && !report.data) {
            return (
                <div className="text-center p-5">
                    <h4 className="m-0">No report found!</h4>
                </div>
            );
        }

        return (
            <Fragment>
                {!report.loading && report.data && (
                    <Breadcrumb title={report.data.name} subTitle={"Last updated: " + report.data.updated_at}/>)}

                <Nav className="nav-primary report-nav d-flex align-items-center" tabs>
                    <NavItem>
                        <NavLink
                            href="#javascript"
                            className={active_tab === "1" ? "active" : ""}
                            onClick={() => this.setActiveTab("1")}
                        >
                            <i className="icofont icofont-chart-bar-graph"></i>Dashboard
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            href="#javascript"
                            className={active_tab === "2" ? "active" : ""}
                            onClick={() => this.setActiveTab("2")}
                        >
                            <i className="icofont icofont-listine-dots"></i>Source Communities
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={active_tab}>
                    <TabPane className="fade show" tabId="1">
                        <Container fluid={true} style={{paddingTop: "10px"}}>
                            <Row>
                                <Col>
                                    <MapDistributionReport/>
                                </Col>
                            </Row>

                            <Row className={'mt-5'}>
                                <Col>
                                    <CommunityTotalsReport/>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} sm={5} md={4} lg={4} xl={4}>
                                    <CategoryDistributionReport/>
                                </Col>
                                <Col xs={12} sm={7} md={8} lg={8} xl={8}>
                                    <LocationDistributionReport/>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} sm={6}>
                                    <AverageCommunityReport />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <SocialMediaDistributionReport/>
                                </Col>
                                <Col>
                                    <OnlineEngagementReport/>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <CommunityOfflineEngagement />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <EngagementDistributionReport/>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <EventsVSParticipantsDistributionReport/>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <TopicDistributionReport/>
                                </Col>
                            </Row>
                        </Container>
                    </TabPane>
                    <TabPane tabId="2">
                        <Card>
                            <CardBody>
                                {this.renderTable()}
                                {communities.loading ? this.renderLoading() : ""}
                            </CardBody>
                            <CardFooter className={"d-flex"}>
                                {communities.total_pages <= 1 ? null : (
                                    <Pagination
                                        className="pagination-primary"
                                    >
                                        <PaginationItem disabled={communities.page === 0}>
                                            <PaginationLink onClick={() => {
                                                decrementCommunitiesOfReportPage();
                                                loadCommunitiesOfReport(this.props.routeParams.id);
                                            }}>Previous</PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink
                                                href="#javascript">{communities.page + 1}/{communities.total_pages}</PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem
                                            disabled={communities.page + 1 === communities.total_pages}>
                                            <PaginationLink onClick={() => {
                                                incrementCommunitiesOfReportPage();
                                                loadCommunitiesOfReport(this.props.routeParams.id);
                                            }}>Next</PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                )}

                                <div className={"pt-2 pl-3 purple-text"}>
                                    <span>Total results: <b><NumberFormatter
                                        value={communities.total_results}/></b></span>
                                </div>
                            </CardFooter>
                        </Card>
                    </TabPane>
                </TabContent>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    loadReportDetails,
    loadGlobalMapDistribution,
    loadGlobalCategoriesDistribution,
    loadGlobalLocationDistribution,
    loadGlobalCommunityTotals,
    loadGlobalSocialMediaDistribution,
    loadGlobalEngagementDistribution,
    loadGlobalEventsVSParticipantsDistribution,
    loadGlobalTopicDistribution,
    loadGlobalAverageParameters,
    clearGlobalInsights,
    selectReport,
    loadCommunitiesOfReport,
    incrementCommunitiesOfReportPage,
    decrementCommunitiesOfReportPage
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user.data,
    report: state.report.selected_report
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportDetailsPage)

