import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import {Table} from "react-bootstrap";
import Blur from "../../common/helpers/blur";
import NumberFormatter from "../../utils/number_formatter";

function CommunityTwitterProfiles(props) {
    const {community} = props;

    function UseBlur(localProps) {
        const {community} = props;
        return (<Blur radius={community.public === "true" ? "0" : "5px"}>{localProps.children}</Blur>);
    }

    return (
        <Card>
            <CardHeader className={"pt-4 pb-4"}>
                <h4 className={"m-b-0 m-r-5 grey-text"}><b>TWITTER PROFILES</b></h4>
            </CardHeader>

            <CardBody className={"p-0"}>
                <div className="table-responsive border-0">
                    <Table>
                        <thead>
                        <tr>
                            <th className={"pl-5"}>URL</th>
                            <th>Number of members</th>
                            <th>Number of tweets</th>
                        </tr>
                        </thead>
                        <tbody>
                        {community.twitter_profiles.map(profile => (
                            <tr key={profile.id}>
                                <UseBlur>
                                    <td className={"pl-5"}>{profile.url}</td>
                                </UseBlur>
                                <UseBlur>
                                    <td><NumberFormatter value={profile.followers}/></td>
                                </UseBlur>
                                <UseBlur>
                                    <td><NumberFormatter value={profile.total_statuses}/></td>
                                </UseBlur>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>
    );
}

export default CommunityTwitterProfiles;
