import React from 'react'
import WORLDWIDE_BLACK from "../../../assets/images/worldwide_black.png"
import WORLDWIDE_WHITE from "../../../assets/images/worldwide_white.png"
import LOUPE_BLACK from "../../../assets/images/loupe_black.png"
import LOUPE_WHITE from "../../../assets/images/loupe_white.png"
import INFO_WHITE from "../../../assets/images/information_white.png"
import INFO_BLACK from "../../../assets/images/information_black.png"
import USER_WHITE from "../../../assets/images/user_white.png"
import USER_BLACK from "../../../assets/images/user_black.png"
import LIST_BLACK from "../../../assets/images/list_black.png"
import LIST_WHITE from "../../../assets/images/list_white.png"
import CAMPAIGN_BLACK from "../../../assets/images/campaign.svg"
import CAMPAIGN_WHITE from "../../../assets/images/campaign_white.svg"
import REPORTS_BLACK from "../../../assets/images/reports.svg"
import REPORTS_WHITE from "../../../assets/images/reports_white.svg"
import CHANNEL_BLACK from "../../../assets/images/channel.svg"
import CHANNEL_WHITE from "../../../assets/images/channel_white.svg"
import BOUNTIES_WHITE from "../../../assets/images/bounties_white.svg"
import BOUNTIES_BLACK from "../../../assets/images/bounties_black.svg"
import BRIEFCASE_WHITE from "../../../assets/images/briefcase_white.png"
import BRIEFCASE_BLACK from "../../../assets/images/briefcase_black.png"

export const MENUITEMS = [
    {
        title: 'DASHBOARD',
        icon: <img src={WORLDWIDE_BLACK} width={50} height={50}/>,
        enabled_icon: <img src={WORLDWIDE_WHITE} width={50} height={50}/>,
        generated_path: () => `${process.env.PUBLIC_URL}/dashboard`,
        type: 'sub',
        active: false,
        bookmark: false,
        children: []
    },
    {
        title: 'DIRECTORY',
        icon: <img src={LOUPE_BLACK} width={50} height={50}/>,
        enabled_icon: <img src={LOUPE_WHITE} width={50} height={50}/>,
        generated_path: () => `${process.env.PUBLIC_URL}/directory`,
        type: 'sub',
        active: false,
        bookmark: false,
        children: []
    },
    {
        title: 'LISTS',
        icon: <img src={LIST_BLACK} width={50} height={50}/>,
        enabled_icon: <img src={LIST_WHITE} width={50} height={50}/>,
        generated_path: () => `${process.env.PUBLIC_URL}/lists`,
        type: 'sub',
        active: false,
        bookmark: false,
        authenticated: true,
        verify_security: function (user) {
            return !user.is_community;
        },
        children: []
    },
    {
        title: 'REPORTS',
        icon: <img src={REPORTS_BLACK} width={50} height={50}/>,
        enabled_icon: <img src={REPORTS_WHITE} width={50} height={50}/>,
        generated_path: () => `${process.env.PUBLIC_URL}/reports`,
        type: 'sub',
        active: false,
        bookmark: false,
        authenticated: true,
        verify_security: function (user) {
            return !user.is_community;
        },
        children: []
    },
    {
        title: 'CAMPAIGNS',
        icon: <img src={CAMPAIGN_BLACK} width={50} height={50}/>,
        enabled_icon: <img src={CAMPAIGN_WHITE} width={50} height={50}/>,
        generated_path: () => `${process.env.PUBLIC_URL}/campaigns`,
        type: 'sub',
        active: false,
        bookmark: false,
        authenticated: true,
        verify_security: function (user) {
            return user.is_super_admin || user.is_company;
        },
        children: []
    },
    {
        title: 'INBOX',
        icon: <img src={CHANNEL_BLACK} width={50} height={50} />,
        enabled_icon: <img src={CHANNEL_WHITE} width={50} height={50} />,
        generated_path: () => `${process.env.PUBLIC_URL}/inbox`,
        type: 'sub',
        active: false,
        bookmark: false,
        authenticated: true,
        children: []
    },
    {
        title: 'BOUNTIES',
        icon: <img src={BRIEFCASE_BLACK} width={50} height={50}/>,
        enabled_icon: <img src={BRIEFCASE_WHITE} width={50} height={50}/>,
        generated_path: (user, redeemToken, jobBoardToken) => {
            if(user && user.is_company){
                return `${process.env.PUBLIC_URL}/jobs/job-applicants`;
            } else if(redeemToken) {
                return `${process.env.PUBLIC_URL}/redeem-jobs`;
            } else if(jobBoardToken) {
                return `${process.env.PUBLIC_URL}/jobs/${jobBoardToken}`;
            } else if (user && (user.is_community || user.is_super_admin)){
                return `${process.env.PUBLIC_URL}/jobs`;
            }

            return "";
        },
        type: 'sub',
        active: false,
        bookmark: false,
        authenticated: false,
        children: [],
        verify_security: function (user, redeemToken, jobBoardToken) {
            return (user && (user.is_community || user.is_super_admin)) || redeemToken || jobBoardToken;
        },
    },
    {
        title: 'CANDIDATES',
        icon: <img src={BOUNTIES_BLACK} width={50} height={50}/>,
        enabled_icon: <img src={BOUNTIES_WHITE} width={50} height={50}/>,
        generated_path: () => `${process.env.PUBLIC_URL}/jobs/applicants/list`,
        type: 'sub',
        active: false,
        bookmark: false,
        authenticated: true,
        children: [],
        verify_security: function (user) {
            return user && !user.is_community;
        },
    },
    {
        title: 'REFERRALS',
        icon: <img src={BOUNTIES_BLACK} width={50} height={50}/>,
        enabled_icon: <img src={BOUNTIES_WHITE} width={50} height={50}/>,
        generated_path: () => `${process.env.PUBLIC_URL}/solana/transactions`,
        type: 'sub',
        active: false,
        bookmark: false,
        authenticated: false,
        children: [],
    },
    {
        title: 'PROFILE',
        icon: <img src={USER_BLACK} width={50} height={50}/>,
        enabled_icon: <img src={USER_WHITE} width={50} height={50}/>,
        generated_path: () => `${process.env.PUBLIC_URL}/profile`,
        type: 'sub',
        active: false,
        bookmark: false,
        authenticated: true,
        children: []
    },
    {
        title: 'ABOUT',
        icon: <img src={INFO_BLACK} width={50} height={50}/>,
        enabled_icon: <img src={INFO_WHITE} width={50} height={50}/>,
        generated_path: () => `${process.env.PUBLIC_URL}/`,
        type: 'sub',
        external: false,
        active: true,
        bookmark: false,
        children: []
    }
];
