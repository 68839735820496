import {LOAD_UTILS} from "../action_types";

const initial_state = {
    data: {
        countries: []
    },
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case LOAD_UTILS.concat("_SUCCESS"):
            return {...state, data: action.payload.data};
        default:
            return {...state};
    }
}
