import {
    CLEAR_SELECTED_CHANNEL,
    CREATE_CHANNEL,
    CONFIRM_CHANNEL,
    LOAD_MESSAGES,
    LOAD_CHANNELS,
    MARK_CHANNEL_READ,
    SELECT_CURRENT_CHANNEL,
    INCREMENT_CHANNELS_PAGE,
    SEARCH_CHANNELS,
    SET_WINDOW_FOCUS,
    INCREMENT_MESSAGES_PAGE,
    SEND_MESSAGE,
    PUBNUB_LOAD_MESSAGE,
    ADD_CHANNEL_TO_LIST_OF_CHANNELS,
    DECLINE_CHANNEL,
} from "../action_types";

export function createWelcomeMessage(communityId, welcomeMessage) {
    return {
        type: CREATE_CHANNEL,
        api: true,
        payload: {
            community_id: communityId,
            welcome_message: welcomeMessage
        }
    }
}

export function clearSelectedChannel(){
    return {
        type: CLEAR_SELECTED_CHANNEL
    }
}

export function loadChannels() {
    return {
        type: LOAD_CHANNELS,
        api: true
    }
}

export function incrementChannelsPage(){
    return {
        type: INCREMENT_CHANNELS_PAGE
    }
}

export function loadMessages(channelId){
    return {
        type: LOAD_MESSAGES,
        api: true,
        payload: channelId
    }
}

export function incrementMessagesPage(){
    return {
        type: INCREMENT_MESSAGES_PAGE
    }
}

export function sendMessage(channelId, content, file){
    return {
        type: SEND_MESSAGE,
        api: true,
        payload: {
            channel_id: channelId,
            content: content,
            attachment: file
        }
    }
}

export function pubnubLoadMessage(message, currentUser){
    return {
        type: PUBNUB_LOAD_MESSAGE,
        payload: {message: message, current_user: currentUser}
    }
}

export function selectCurrentChannel(channelId){
    return {
        type: SELECT_CURRENT_CHANNEL,
        payload: channelId
    }
}

export function searchChannels(q){
    return {
        type: SEARCH_CHANNELS,
        api: true,
        payload: q
    }
}

export function addChannelToListOfChannels(channel){
    let newChannel = {[channel.id]: channel}
    return {
        type: ADD_CHANNEL_TO_LIST_OF_CHANNELS,
        payload: newChannel
    }
}

export function confirmChannel(id){
    return {
        type: CONFIRM_CHANNEL,
        api: true,
        payload: id
    }
}

export function declineChannel(id){
    return {
        type: DECLINE_CHANNEL,
        api: true,
        payload: id
    }
}

export function markChannelRead(id){
    return {
        type: MARK_CHANNEL_READ,
        api: true,
        payload: id
    }
}

export function setWindowFocus(isFocused){
    return {
        type: SET_WINDOW_FOCUS,
        payload: isFocused
    }
}