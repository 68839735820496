import React from 'react';
import {connect} from 'react-redux'
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap';
import {
    clearSelectedCommunity,
} from '../../../redux/community/actions';
import CommunityGlobalRankings from "../components/global_ranking";
import CommunityDescription from "../components/description";
import CommunityOfflineEngagement from "../components/offline_engagement";
import FindCommunityOn from "../components/find_community_on";
import FindCommunityOnAnonymous from "../components/find_community_on_anonymous";
import TotalAudience from "../components/total_audience";
import {createWelcomeMessage} from '../../../redux/channel/actions';
import SocialProfilesTable from "./social_profiles_table";
import ServiceCard from "./service_card";
import ServiceDetailsModal from "./service_details_modal";

class CommunityProfileComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active_tab: "1",
            setActiveTab: (tab) => {
                this.setState({
                    active_tab: tab,
                })
            }
        };
    }

    componentDidMount() {
        const {community} = this.props;
        const {setActiveTab} = this.state;

        setActiveTab(community.is_network || community.services.length === 0 ? "2" : "1");
    }

    renderTabs() {
        const {active_tab, setActiveTab} = this.state;

        return (
            <Col xs={12}>
                <Nav className="nav-primary report-nav d-flex align-items-center" tabs>
                    <NavItem>
                        <NavLink
                            className={`pointer ${active_tab === "1" ? "active" : ""}`}
                            onClick={() => setActiveTab("1")}
                        >
                            <i className="icofont icofont-star" />Services
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={`pointer ${active_tab === "2" ? "active" : ""}`}
                            onClick={() => setActiveTab("2")}
                        >
                            <i className="icofont icofont-bars" />Engagement metrics
                        </NavLink>
                    </NavItem>
                </Nav>
            </Col>
        )
    }

    renderCommunityProfile(community) {
        const {currentUser, is_screenshot} = this.props;
        const {active_tab} = this.state;
        
        console.log("is_screenshot", is_screenshot);

        return (
            <div>
                <Row>
                    <Col xs={12} sm={12} md={6}>
                        <CommunityDescription community={community}/>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        {community.public === "true" && <FindCommunityOn community={community}/>}
                        {community.public !== "true" && <FindCommunityOnAnonymous community={community}/>}
                    </Col>
                    {community.is_network === false && community.services.length > 0 && this.renderTabs()}
                    <Col xs={12}>
                        <TabContent activeTab={active_tab} className={"w-100"}>
                            <TabPane className="fade show px-lg-5" tabId="1">
                                <Row>
                                    <ServiceDetailsModal/>
                                    {community.services.map((service) =>
                                        <Col xs={12} md={6} xl={4} key={"service-"+service.id}>
                                            <ServiceCard service={service} />
                                        </Col>
                                    )}
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                    <Col xs={12}>
                        <TabContent activeTab={active_tab} className={"w-100"}>
                            <TabPane className="fade show" tabId="2">
                                <Row>
                                    <Col xs={12} md={6}>
                                        <TotalAudience community={community}/>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <CommunityGlobalRankings community={community} currentUser={currentUser}/>
                                    </Col>
                                    {community.is_network === false && (<Col xs={12}>
                                        <CommunityOfflineEngagement community={community} currentUser={currentUser}/>
                                    </Col>)}
                                    <Col xs={12}>
                                        {!is_screenshot && community.is_network === false && (<SocialProfilesTable community={community} currentUser={currentUser}/>)}
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        const {community} = this.props;
        return this.renderCommunityProfile(community);
    }
}

const mapDispatchToProps = {
    createWelcomeMessage,
    clearSelectedCommunity,
};

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.data,
    customerData: state.customer.data,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityProfileComponent)
