export class ConfigDB {
    static data = {
    	api: {
    		host: process.env.REACT_APP_API_HOST
		},
        slack: {
            client_id: process.env.REACT_APP_SLACK_CLIENT_ID,
        },
        discord: {
            client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
        },
        meetup: {
    	    oauth_url: `https://secure.meetup.com/oauth2/authorize?client_id=${process.env.REACT_APP_MEETUP_CLIENT_ID}&response_type=token`,
            client_id: process.env.REACT_APP_MEETUP_CLIENT_ID,
        },
        telegram: {
    	    bot_username: process.env.REACT_APP_TELEGRAM_BOT_USERNAME,
        },
        settings: {
            layout_type: 'ltr',
            sidebar: {
                type: 'default',
                disable_submenu: true
            },
        },
        color: {
            layout_version: 'light',
            color: 'color-1',
            primary_color: '#7e37d8',
            secondary_color: '#fe80b2',
            grey_color: "#aaaaaa",
            mix_layout: 'color-only',
        },
        router_animation: null,
        upload_file_size: parseInt(process.env.REACT_APP_MAX_FILE_SIZE),
        google_maps_api_key: "AIzaSyBxPzWCaCZW2kyWpnrxC671_-z-JbC9n00"
    }
}

export default ConfigDB;
