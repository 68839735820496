import React from "react";
import {Label} from "reactstrap";

class CustomFieldHeader extends React.Component {
    render() {
        const {customField, comment} = this.props;

        return (
            <div className={"d-flex flex-column"}>
                <Label className={"m-0"}>{customField.title} {comment && <small>{comment}</small>} {customField.required &&<span className={"text-danger"}>*</span>}</Label>
                <small className={"mb-1"}>{customField.description}</small>
            </div>
        );
    }
}

export default CustomFieldHeader;