import React from "react";
import CustomFieldHeader from "./custom_field_header";
import ConfigDB from "../../../../../config";
import FileUpload from "../../../helpers/file_upload";

class FileCustomFields extends React.Component {
    render() {
        const {customField, onValueChange} = this.props;

        return (
            <div className="m-b-10">
                <CustomFieldHeader customField={customField}
                                   comment={"PDF files, max " + ConfigDB.data.upload_file_size + "MB"}/>
                <FileUpload className="refer-modal-input" onFileChoose={(file) => onValueChange(file, 'cv')}/>
            </div>
        );
    }
}

export default FileCustomFields;