import React, {Fragment, useRef} from 'react';
import {connect} from 'react-redux'
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import {
    addTopicInCommunity,
    addTopicInCommunityRegistration,
    clearCommunityDetails,
    createCommunity,
    deleteService,
    editCommunity,
    getCommunityEditDetails,
    getServiceCategories,
    removeTopicFromCommunity,
    removeTopicFromCommunityRegistration,
    searchTopicToAddInCommunity,
    setEditCommunityData,
    toggleAddServiceModal,
    updateCommunityCategory,
    updateCommunityData,
    verifyExistingMeetupCommunity
} from '../../redux/community/actions'
import {Badge, Button, Card, CardBody, CardHeader, Col, Container, Label, Row} from 'reactstrap';
import ImagePreview from "../common/helpers/image_preview";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import CommunityLinksManagements from "./components/links_management";
import {isArray} from "leaflet/src/core/Util";
import {EMAIL_REGEX, MEETUP_GROUP_PROFILE_REGEX, PRIVACY_POLICY_LINK, TOS_LINK} from "../../constants";
import ServiceFormCard from "./components/service_form_card";
import AddServiceModal from "./components/add_service_modal";
import {withRouter} from "react-router";
import OAuthIntegrationBanner from "./components/oauth_integration_banner";
import CommunityImageUploader from "../common/helpers/community_image_uploader";
import LocationFilter from "../community/community_filters/location_filter";

class EditCommunityPage extends React.Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();

        this.updateTimeout = null;
        this.state = {
            new_profile_image: null,
            is_creating: false,
            setIsCreating: (state) => {
                this.setState({
                    is_creating: state,
                })
            },
            has_errors: false,
            setHasErrors: (value) => {
                this.setState({
                    has_errors: value
                })
            },
            acceptedTermsOfUse: false,
            toggleAcceptedTermsOfUse: () => {
                this.setState({
                    acceptedTermsOfUse: !this.state.acceptedTermsOfUse
                })
            },
            form_labels: {
                edit: {
                    name: "Name",
                    description: "Description",
                    email: "Email",
                    phone: "Phone",
                    category: "Category",
                },
                create: {
                    name: "Community Name *",
                    description: "Community Description *",
                    email: "Community Email",
                    phone: "Community Phone",
                    category: "Category *"
                }
            }
        }
    }

    componentDidMount() {
        const {getServiceCategories, getCommunityEditDetails, clearCommunityDetails} = this.props;

        getServiceCategories();
        clearCommunityDetails();

        // load the community profile
        if (this.props.routeParams.id) {
            getCommunityEditDetails(this.props.routeParams.id);
        } else {
            this.state.setIsCreating(true);
        }

        if (this.ref.current && window.location.hash.includes("#integration")) {
            this.ref.current.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
    }

    componentWillUnmount() {
        this.props.clearCommunityDetails();
    }

    renderLoading() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Loading...</h4>
            </div>
        );
    }

    renderInvalidCommunity() {
        return (
            <div className="text-center p-5">
                <h4 className="m-0">Invalid community :(</h4>
            </div>
        );
    }

    updateTheCommunity(community) {
        if (this.state.is_creating) {
            return;
        }

        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
        }

        this.updateTimeout = setTimeout(() => {
            this.props.editCommunity(community);
        }, 400);
    }

    onCommunityPropertyChange(property, value, required) {
        const {community, setEditCommunityData} = this.props;

        community[property] = value;

        setEditCommunityData(community);

        let hasErrors = this.hasEmptyRequiredFields();

        if ((!required || (required && value.trim().length)) && hasErrors === false) {
            this.updateTheCommunity(community);
        }
    }

    onUserPropertyChange(property, value) {
        const {registering_user} = this.props;

        registering_user[property] = value;
        this.hasEmptyUserFields();
    }

    handleCategoryChange(categoryId) {
        const {community, updateCommunityCategory} = this.props;
        updateCommunityCategory(categoryId);
        this.updateTheCommunity(community);
    }

    handleCityChange(city) {
        const {community, updateCommunityData} = this.props;

        community.city = city;
        community.city_id = city.id;

        updateCommunityData(community);
        this.updateTheCommunity(community);
    }

    hasEmptyRequiredFields() {
        const {community} = this.props;
        const optional_fields = ["email", "logo", "phone_number", "meetup_url"];

        return Object.entries(community).some(([field, value]) => {
            if (typeof value === "string") {
                if (optional_fields.includes(field)) {
                    return false;
                }

                return value.trim().length === 0;
            }

            return false;
        });
    }

    hasEmptyUserFields() {
        const {registering_user} = this.props;

        return Object.entries(registering_user).some(([field, value]) => {
            return value.trim().length === 0;
        });
    }


    hasAtLeastOneSocialProfile() {
        const {community} = this.props;

        return Object.entries(community).some(([field, value]) => {
            if (community.meetup_url.trim() !== "" && community.meetup_url.match(MEETUP_GROUP_PROFILE_REGEX)) {
                return true;
            }

            if (isArray(value)) {
                if (field !== "topics") {
                    return value.length > 0;
                }
            }

            return false;
        })
    }

    submitCommunityRegistrationForm() {
        const {user, community, registering_user, verifyExistingMeetupCommunity, createCommunity, history} = this.props;
        const {setHasErrors, acceptedTermsOfUse} = this.state;

        if (community.meetup_url.trim() !== "") {
            verifyExistingMeetupCommunity(community.meetup_url.trim());
        }

        if (!user.token && (this.hasEmptyUserFields() || acceptedTermsOfUse === false)) {
            setHasErrors(true);
            return;
        }

        if (community.category_id === "-1" || this.hasAtLeastOneSocialProfile() === false || this.hasEmptyRequiredFields()) {
            setHasErrors(true);
            return;
        }

        setHasErrors(false);
        createCommunity(community, registering_user);
        history.push("/profile");
    }

    renderBody() {
        const {
            user,
            community,
            registering_user,
            categories,
            addTopicInCommunity,
            addTopicInCommunityRegistration,
            removeTopicFromCommunity,
            removeTopicFromCommunityRegistration,
            searchTopicToAddInCommunity,
            searchingTopics,
            searchedTopics,
            toggleAddServiceModal,
            deleteService
        } = this.props;
        const {is_creating, form_labels, has_errors, acceptedTermsOfUse, toggleAcceptedTermsOfUse} = this.state;
        const labels = form_labels[is_creating ? "create" : "edit"];

        return (
            <Container fluid={!is_creating} className={is_creating ? "narrow-container" : ""}>
                <form className={`needs-validation ${has_errors ? "was-validated" : ""}`}>
                    <Row>
                        <Col xl={is_creating ? "12" : "6"} lg={is_creating ? "12" : "6"} md={is_creating ? "12" : "6"}
                             sm="12" xs="12">
                            <Card>
                                <CardHeader>
                                    <h5 className="mb-0">COMMUNITY DATA:</h5>
                                </CardHeader>

                                <CardBody className="p-4">
                                    <Row className={"align-items-center"}>
                                        <Col xs="12" className={"m-b-10"}><span>{labels.name}</span></Col>
                                        <Col xs="12">
                                            <input type="text" className="form-control" required
                                                   defaultValue={community.name}
                                                   onChange={(e) => this.onCommunityPropertyChange('name', e.target.value, true)}/>
                                            <div className={"invalid-feedback"}>
                                                <span>Community must have a name</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row className={"align-items-center"}>
                                        <Col xs={12} className={"m-b-10 d-flex flex-column"}>
                                            <span>{labels.description}</span>
                                            {is_creating &&
                                            <span className={"grey-text"}>Describe the purpose of the community and how you wish to collaborate with companies.</span>}
                                        </Col>
                                        <Col xs={12}>
                                            <textarea className={"form-control"} required
                                                      defaultValue={community.description}
                                                      rows={7}
                                                      onChange={(e) => this.onCommunityPropertyChange('description', e.target.value, true)}/>
                                            <div className={"invalid-feedback"}>
                                                <span>Community must have a description</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row className={"align-items-center"}>
                                        <Col xs="12" className={"d-flex flex-column m-b-10"}>
                                            <span>{labels.email}</span>
                                            {is_creating && <span className={"grey-text"}>The address to notify community inquiries.</span>}
                                        </Col>
                                        <Col xs="12">
                                            <input type={"email"}
                                                   className={`form-control ${community.email && community.email.length === 0 ? "no-validate" : ""}`}
                                                   defaultValue={community.email}
                                                   pattern={EMAIL_REGEX}
                                                   onChange={(e) => this.onCommunityPropertyChange('email', e.target.value)}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row className={"align-items-center"}>
                                        <Col xs="12" className={"d-flex flex-column m-b-10"}>
                                            <span>{labels.phone}</span>
                                            {is_creating && <span className={"grey-text"}>The phone to notify community inquiries.</span>}
                                        </Col>
                                        <Col xs="12">
                                            <input type="number"
                                                   className={`form-control ${is_creating && community.phone_number.length === 0 ? "no-validate" : ""}`}
                                                   defaultValue={community.phone_number}
                                                   onChange={(e) => this.onCommunityPropertyChange('phone_number', e.target.value)}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    {is_creating && <Row>
                                        <Col xs={"12"} className={"m-b-10"}><span>Community Profile Picture</span></Col>
                                        <Col xs={"12"}>
                                            <CommunityImageUploader onNewImage={(image) => {
                                                this.setState({
                                                    new_profile_image: image
                                                });

                                                this.onCommunityPropertyChange('logo', image);
                                            }}>
                                                <ImagePreview
                                                    logo={this.state.new_profile_image ? this.state.new_profile_image : community.logo}/>
                                            </CommunityImageUploader>
                                        </Col>
                                    </Row>}
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={is_creating ? "12" : "6"} lg={is_creating ? "12" : "6"} md={is_creating ? "12" : "6"}
                             sm="12" xs="12">
                            {!is_creating && <Row>
                                <Col>
                                    <Card>
                                        <CardHeader>
                                            <h5 className="mb-0">COMMUNITY PROFILE PICTURE:</h5>
                                        </CardHeader>

                                        <CardBody className="p-4 d-flex justify-content-center">
                                            <CommunityImageUploader onNewImage={(image) => {
                                                this.setState({
                                                    new_profile_image: image
                                                });

                                                this.onCommunityPropertyChange('logo', image);
                                            }}>
                                                <ImagePreview
                                                    logo={this.state.new_profile_image ? this.state.new_profile_image : community.logo}/>
                                            </CommunityImageUploader>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>}
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody className="p-4">
                                            <Row className={"align-items-center"}>
                                                <Col xs="12" className={"m-b-10"}><span>{labels.category}</span></Col>
                                                <Col xs="12">
                                                    <select value={community.category_id}
                                                            required
                                                            onChange={(e) => this.handleCategoryChange(e.target.value)}
                                                            className="form-control">
                                                        {is_creating && community.category_id === "-1" &&
                                                        <option value={""} key={"default-select-option"}>Select a
                                                            Category</option>}
                                                        {categories.map((category) => {
                                                            return (<option value={category.id}
                                                                            key={"category-" + category.id}>{category.name}</option>);
                                                        })}
                                                    </select>
                                                    <div className={"invalid-feedback"}>
                                                        <span>Community must have a category</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row className={"align-items-center"}>
                                                <Col xs="12" className={"m-b-10"}><span>Location</span></Col>
                                                {community.city && (
                                                    <Col xs="12" className={"m-b-10"}>
                                                        <Badge color={'primary'}>{community.city.name}</Badge>
                                                    </Col>
                                                )}
                                                <Col xs="12">
                                                    <LocationFilter type={"cities"} hideLabel={true}
                                                                    onLocationSelected={(selected) => this.handleCityChange(selected)}/>
                                                    <div className={"invalid-feedback"}>
                                                        <span>Community must have a city</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row className={"align-items-center"}>
                                                <Col xs="12" className={"m-b-10"}><span>Topics</span></Col>
                                                <Col xs="12">
                                                    {community.topics.map((topic) => (
                                                        <Badge color={'primary'} className={"pointer"} key={topic.id}
                                                               onClick={() => {
                                                                   if (is_creating) {
                                                                       removeTopicFromCommunityRegistration(topic.id);
                                                                       return;
                                                                   }

                                                                   removeTopicFromCommunity(topic.id, community.id)
                                                               }}>#{topic.name} x</Badge>))}
                                                </Col>
                                                <Col xs={12} className={"m-t-10"}>
                                                    <TypeaheadComponent searching={searchingTopics}
                                                                        searchTopicForCommunity={searchTopicToAddInCommunity}
                                                                        options={searchedTopics}
                                                                        onTopicSelect={(selectedTopic) => {
                                                                            if (is_creating) {
                                                                                addTopicInCommunityRegistration(selectedTopic);
                                                                                return;
                                                                            }
                                                                            addTopicInCommunity(community.id, selectedTopic);
                                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CommunityLinksManagements isCreating={is_creating}
                                                       hasValidLink={this.hasAtLeastOneSocialProfile()}
                                                       checkForValidLink={() => this.hasAtLeastOneSocialProfile()}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h5 className="mb-0 f-w-800 m-b-15">NOTIFICATIONS</h5>
                                    <div className="media">
                                        <div className="text-left">
                                            <label className="switch">
                                                <input type="checkbox"
                                                       checked={Number(community.enabled_new_job_emails)}
                                                       onChange={(e) => {
                                                           this.onCommunityPropertyChange('enabled_new_job_emails', e.target.checked)
                                                       }}/><span className="switch-state"/>
                                            </label>
                                        </div>
                                        <label className="col-form-label m-l-10">Send me a newsletter with New Job
                                            Announcements matching the profile of the community</label>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div ref={this.ref}>
                        {is_creating === false && <Row>
                            <Col>
                                <OAuthIntegrationBanner community={community}/>
                            </Col>
                        </Row>}
                    </div>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col xs={"12"} md={"12"} lg={"9"} className={"m-b-10"}>
                                            <h5 className="mb-0">SERVICES</h5>
                                            <span>Manage the services that your Community can provide to Companies.</span>
                                        </Col>
                                        <Col xs={"12"} md={"12"} lg={"3"}
                                             className={"d-flex justify-content-center m-b-10"}>
                                            <Button color={"green"} onClick={() => toggleAddServiceModal()}>ADD NEW
                                                SERVICE</Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                {community.services.length > 0 && <CardBody>
                                    {community.services.map((service, index) =>
                                        <ServiceFormCard key={"service-" + index} service={service} index={index}
                                                         deleteService={() => {
                                                             deleteService(index);
                                                             this.updateTheCommunity(community);
                                                         }
                                                         }/>)}
                                    <div className={"d-flex justify-content-center"}>
                                        <Button color={"green"} onClick={() => toggleAddServiceModal()}>ADD NEW
                                            SERVICE</Button>
                                    </div>
                                </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                    {is_creating && !user.data && <>
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <h5 className="mb-0">YOUR DATA:</h5>
                                        <span>We will use this data to contact you and give you access to the community profile.</span>
                                    </CardHeader>

                                    <CardBody className="p-4">
                                        <Row className={"align-items-center"}>
                                            <Col xs="12" className={"m-b-10"}><span>First Name *</span></Col>
                                            <Col xs="12">
                                                <input type="text" className="form-control" required
                                                       defaultValue={registering_user.firstname}
                                                       onChange={(e) => this.onUserPropertyChange('firstname', e.target.value)}/>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row className={"align-items-center"}>
                                            <Col xs="12" className={"m-b-10"}><span>Last Name *</span></Col>
                                            <Col xs="12">
                                                <input type="text" className="form-control" required
                                                       defaultValue={registering_user.lastname}
                                                       onChange={(e) => this.onUserPropertyChange('lastname', e.target.value)}/>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row className={"align-items-center"}>
                                            <Col xs="12" className={"m-b-10"}><span>Email *</span></Col>
                                            <Col xs="12">
                                                <input type={"email"} className="form-control"
                                                       defaultValue={registering_user.email}
                                                       pattern={EMAIL_REGEX}
                                                       required
                                                       onChange={(e) => this.onUserPropertyChange('email', e.target.value)}/>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row className={"align-items-center"}>
                                            <Col xs="12" className={"m-b-10"}><span>Password *</span></Col>
                                            <Col xs="12">
                                                <input type={"password"} className="form-control"
                                                       defaultValue={registering_user.password}
                                                       required
                                                       onChange={(e) => this.onUserPropertyChange('password', e.target.value)}/>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col className={"d-flex"}>
                                                <div id="terms-of-use"
                                                     className={"size-20 checkbox-container " + (acceptedTermsOfUse ? "active full" : "")}
                                                     onClick={() => {
                                                         toggleAcceptedTermsOfUse();
                                                     }}>
                                                    <i className={"fa fa-check"}/>
                                                </div>
                                                <Label htmlFor="terms-of-use"
                                                       className={`ml-2 ${has_errors && !acceptedTermsOfUse ? "text-danger" : ""}`}>
                                                    You agree to the&nbsp;
                                                    <u><a href={TOS_LINK} target={"_blank"} rel={"noopener noreferrer"}
                                                          className={"text-info"}>terms of use</a></u> and&nbsp;
                                                    <u><a href={PRIVACY_POLICY_LINK} target={"_blank"}
                                                          rel={"noopener noreferrer"} className={"text-info"}>privacy
                                                        policy</a></u>
                                                </Label>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>}
                    {is_creating && (
                        <div className={"d-flex mb-5"}>
                            <Button color={"primary"} className={"mx-auto"}
                                    onClick={() => this.submitCommunityRegistrationForm()}>Submit Registration</Button>
                        </div>
                    )}
                </form>
            </Container>
        );
    }

    render() {
        const {community, loading, add_service_modal_open} = this.props;
        const {is_creating} = this.state;

        return (
            <Fragment>
                <Breadcrumb title={is_creating ? "Register Community" : "Community Data Management"}/>
                {add_service_modal_open && <AddServiceModal updateCommunity={() => {
                    this.updateTheCommunity(community)
                }}/>}
                {community && this.renderBody()}
                {!community && loading && this.renderLoading()}
                {!community && !loading && this.renderInvalidCommunity()}
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    getCommunityEditDetails,
    editCommunity,
    removeTopicFromCommunity,
    addTopicInCommunity,
    searchTopicToAddInCommunity,
    addTopicInCommunityRegistration,
    removeTopicFromCommunityRegistration,
    verifyExistingMeetupCommunity,
    createCommunity,
    toggleAddServiceModal,
    getServiceCategories,
    clearCommunityDetails,
    deleteService,
    updateCommunityCategory,
    updateCommunityData,
    setEditCommunityData
};

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    community: state.community.edit_community.data,
    registering_user: state.community.edit_community.user,
    searchedTopics: state.community.edit_community.searched_topics,
    searchingTopics: state.community.edit_community.searching_topics,
    categories: state.category.list,
    loading: state.community.edit_community.loading,
    add_service_modal_open: state.community.edit_community.modal.is_open,
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCommunityPage))


const TypeaheadComponent = (props) => {
    const ref = useRef();

    return (
        <Fragment>
            <AsyncTypeahead
                inputProps={{
                    className: 'no-validate',
                }}
                id="search-for-topic"
                isLoading={props.searching}
                labelKey="name"
                minLength={2}
                onSearch={props.searchTopicForCommunity}
                options={props.options}
                placeholder="Add new tag"
                renderMenuItemChildren={(option, props) => {
                    return (
                        <Fragment>
                            <span>{option.name}</span>
                        </Fragment>
                    )
                }}
                onChange={(selected) => {
                    if (selected.length > 0) {
                        props.onTopicSelect(selected[0]);
                    }

                    ref.current.clear();
                }}
                ref={ref}
            />
        </Fragment>
    );
};
