import React from "react";
import Blur from "../../common/helpers/blur";

function ScaleBuilder(props) {
    function getColorForValue(value) {
        if (value >= 0 && value <= 40) {
            return "#FF637B";
        } else if (value > 40 && value <= 75) {
            return "#FDBF00";
        } else if (value > 75 && value <= 100) {
            return "#00C8C8";
        }

        return null;
    }

    function getLevelValue(rankingPercentage) {
        return Math.round(100 - rankingPercentage);
    }

    const {community} = props;
    const value = getLevelValue(props.value);

    if (!community || (community && community.public === "true")) {
        return (
            props.value
                ? (<div>
                    <span className="highlighted" style={{"color": getColorForValue(value)}}>{value}</span>
                    <span>/</span>
                    <span>100</span>
                </div>)
                : "N/A"
        );
    }

    return (
        <Blur radius={"5px"}>{value}/100</Blur>
    );
}

export default ScaleBuilder;
