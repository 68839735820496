import {
    CLEAR_GLOBAL_INSIGHTS,
    CLEAR_SELECTED_COMMUNITY_NETWORK,
    CLEAR_SELECTED_REPORT,
    LOAD_GLOBAL_INSIGHT_AVERAGE_PARAMETERS,
    LOAD_GLOBAL_INSIGHT_CATEGORY_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_COMMUNITY_TOTALS,
    LOAD_GLOBAL_INSIGHT_ENGAGEMENT_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_EVENTS_VS_PARTICIPANTS_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_LOCATION_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_MAP_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_SOCIAL_MEDIA_DISTRIBUTION,
    LOAD_GLOBAL_INSIGHT_TOPIC_DISTRIBUTION,
    SELECT_CATEGORY_ON_DASHBOARD,
    SELECT_LOCATION_ON_DASHBOARD,
    SELECT_COMMUNITY_NETWORK,
    SELECT_REPORT
} from "../action_types";

const initial_state = {
    selected_location: {
        id: null,
        name: "GLOBAL"
    },
    selected_continent: null,
    selected_country: null,
    selected_category: {
        id: null,
        name: "All Categories"
    },
    selected_report: null,
    selected_community_network: null,
    insights: {
        average_parameters: {
            loading: false,
            data: null
        },
        map_distribution: {
            loading: false,
            data: null
        },
        category_distribution: {
            loading: false,
            data: null
        },
        location_distribution: {
            loading: false,
            data: null
        },
        community_totals: {
            loading: false,
            data: null
        },
        social_media_distribution: {
            loading: false,
            data: null
        },
        engagement_distribution: {
            loading: false,
            data: null
        },
        events_vs_participants_distribution: {
            loading: false,
            data: null
        },
        topic_distribution: {
            loading: false,
            data: null
        }
    }
};

export default (state = initial_state, action) => {
    var data = null;

    switch (action.type) {
        case SELECT_LOCATION_ON_DASHBOARD:
            return {
                ...state,
                selected_location: action.payload,
                selected_continent: (action.payload.type === "continent" ? action.payload.id : null),
                selected_country: (action.payload.type === "country" ? action.payload.id : null)
            };
        case SELECT_REPORT:
            return {
                ...initial_state,
                selected_report: action.payload.id
            };
        case CLEAR_SELECTED_REPORT:
            return {
                ...state,
                selected_report: null
            };
        case SELECT_COMMUNITY_NETWORK:
            return {
                ...initial_state,
                selected_community_network: action.payload.id
            }
        case CLEAR_SELECTED_COMMUNITY_NETWORK:
            return {
                ...state,
                selected_community_network: null
            }
        case CLEAR_GLOBAL_INSIGHTS:
            return Object.assign({}, initial_state);
        case SELECT_CATEGORY_ON_DASHBOARD:
            return {...state, selected_category: action.payload};
        case LOAD_GLOBAL_INSIGHT_MAP_DISTRIBUTION:
            return {...state, insights: {...state.insights, map_distribution: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_MAP_DISTRIBUTION.concat("_SUCCESS"):
            return {
                ...state,
                insights: {...state.insights, map_distribution: {loading: false, data: JSON.parse(action.payload.data)}}
            };
        case LOAD_GLOBAL_INSIGHT_MAP_DISTRIBUTION.concat("_FAILURE"):
            return {...state, insights: {...state.insights, map_distribution: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_CATEGORY_DISTRIBUTION:
            return {...state, insights: {...state.insights, category_distribution: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_CATEGORY_DISTRIBUTION.concat("_SUCCESS"):
            return {
                ...state,
                insights: {
                    ...state.insights,
                    category_distribution: {loading: false, data: JSON.parse(action.payload.data)}
                }
            };
        case LOAD_GLOBAL_INSIGHT_CATEGORY_DISTRIBUTION.concat("_FAILURE"):
            return {...state, insights: {...state.insights, category_distribution: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_LOCATION_DISTRIBUTION:
            return {...state, insights: {...state.insights, location_distribution: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_LOCATION_DISTRIBUTION.concat("_SUCCESS"):
            return {
                ...state,
                insights: {
                    ...state.insights,
                    location_distribution: {loading: false, data: JSON.parse(action.payload.data)}
                }
            };
        case LOAD_GLOBAL_INSIGHT_LOCATION_DISTRIBUTION.concat("_FAILURE"):
            return {...state, insights: {...state.insights, location_distribution: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_COMMUNITY_TOTALS:
            return {...state, insights: {...state.insights, community_totals: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_COMMUNITY_TOTALS.concat("_SUCCESS"):
            return {
                ...state,
                insights: {
                    ...state.insights,
                    community_totals: {loading: false, data: action.payload.data}
                }
            };
        case LOAD_GLOBAL_INSIGHT_COMMUNITY_TOTALS.concat("_FAILURE"):
            return {...state, insights: {...state.insights, community_totals: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_SOCIAL_MEDIA_DISTRIBUTION:
            return {...state, insights: {...state.insights, social_media_distribution: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_SOCIAL_MEDIA_DISTRIBUTION.concat("_SUCCESS"):
            data = action.payload.data;

            if (data.public !== false) {
                data.data = JSON.parse(data.data);
                data.audience_per_social_media_type = JSON.parse(data.audience_per_social_media_type);
            }

            return {
                ...state,
                insights: {
                    ...state.insights,
                    social_media_distribution: {loading: false, data: data}
                }
            };
        case LOAD_GLOBAL_INSIGHT_SOCIAL_MEDIA_DISTRIBUTION.concat("_FAILURE"):
            return {...state, insights: {...state.insights, social_media_distribution: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_ENGAGEMENT_DISTRIBUTION:
            return {...state, insights: {...state.insights, engagement_distribution: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_ENGAGEMENT_DISTRIBUTION.concat("_SUCCESS"):
            data = action.payload.data;

            return {
                ...state,
                insights: {
                    ...state.insights,
                    engagement_distribution: {loading: false, data: data.public === false ? data : JSON.parse(data)}
                }
            };
        case LOAD_GLOBAL_INSIGHT_ENGAGEMENT_DISTRIBUTION.concat("_FAILURE"):
            return {...state, insights: {...state.insights, engagement_distribution: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_EVENTS_VS_PARTICIPANTS_DISTRIBUTION:
            return {
                ...state,
                insights: {...state.insights, events_vs_participants_distribution: {loading: false, data: null}}
            };
        case LOAD_GLOBAL_INSIGHT_EVENTS_VS_PARTICIPANTS_DISTRIBUTION.concat("_SUCCESS"):
            data = action.payload.data;

            return {
                ...state,
                insights: {
                    ...state.insights,
                    events_vs_participants_distribution: {
                        loading: false,
                        data: data.public === false ? data : JSON.parse(action.payload.data)
                    }
                }
            };
        case LOAD_GLOBAL_INSIGHT_EVENTS_VS_PARTICIPANTS_DISTRIBUTION.concat("_FAILURE"):
            return {
                ...state,
                insights: {...state.insights, events_vs_participants_distribution: {loading: false, data: null}}
            };
        case LOAD_GLOBAL_INSIGHT_TOPIC_DISTRIBUTION:
            return {
                ...state,
                insights: {...state.insights, topic_distribution: {loading: false, data: null}}
            };
        case LOAD_GLOBAL_INSIGHT_TOPIC_DISTRIBUTION.concat("_SUCCESS"):
            data = action.payload.data;

            return {
                ...state,
                insights: {
                    ...state.insights,
                    topic_distribution: {
                        loading: false,
                        data: data.public === false ? data : JSON.parse(action.payload.data)
                    }
                }
            };
        case LOAD_GLOBAL_INSIGHT_TOPIC_DISTRIBUTION.concat("_FAILURE"):
            return {
                ...state,
                insights: {...state.insights, topic_distribution: {loading: false, data: null}}
            };
        case LOAD_GLOBAL_INSIGHT_AVERAGE_PARAMETERS:
            return {...state, insights: {...state.insights, average_parameters: {loading: false, data: null}}};
        case LOAD_GLOBAL_INSIGHT_AVERAGE_PARAMETERS.concat("_SUCCESS"):
            return {
                ...state,
                insights: {
                    ...state.insights,
                    average_parameters: {loading: false, data: action.payload.data}
                }
            };
        case LOAD_GLOBAL_INSIGHT_AVERAGE_PARAMETERS.concat("_FAILURE"):
            return {...state, insights: {...state.insights, average_parameters: {loading: false, data: null}}};
        default:
            return {...state};
    }
}
