import React, {Fragment} from "react";
import {connect} from "react-redux";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import {Link} from "react-router-dom";
import CampaignWhite from "../../../assets/images/campaign_white.svg";
import {Container, Nav, NavItem, Row} from "reactstrap";
import {
    CAMPAIGN_CONTACT_MODE_ALL,
    CAMPAIGN_CONTACT_MODE_ONLY_NOT_PREV_CONTACTED,
    CAMPAIGN_CONTACT_MODE_ONLY_PREV_CONTACTED
} from "../../../constants";

class CampaignDetailsHeader extends React.Component {

    contactPreviouslyContactedCommunitiesStyling(item) {
        const {selected_campaign} = this.props;

        return parseInt(selected_campaign.contact_mode) === CAMPAIGN_CONTACT_MODE_ALL ? `${item}-info` : `${item}-danger`;
    }

    render() {
        const {selected_campaign} = this.props;

        return (
            <Fragment>
                <Breadcrumb title={selected_campaign.name}
                            hideBreadcrumbRight={true} actions={() => {
                    return (
                        <Link to={"/campaigns"} className={"btn btn-green text-white"}>
                            Go to lists
                        </Link>
                    );
                }}
                    underTitle={() => {
                        return (
                            <div className={"d-flex align-items-center"}>
                                <img src={CampaignWhite} width={25}
                                     className={`p-1 rounded ${this.contactPreviouslyContactedCommunitiesStyling("bg")}`}
                                     alt={"campaign-logo"}/>
                                <span className={`ml-2 ${this.contactPreviouslyContactedCommunitiesStyling("text")}`}>
                                    {parseInt(selected_campaign.contact_mode) === CAMPAIGN_CONTACT_MODE_ALL && (<span>Customer asked to contact all communities</span>)}
                                    {parseInt(selected_campaign.contact_mode) === CAMPAIGN_CONTACT_MODE_ONLY_PREV_CONTACTED && (<span>Customer asked to contact <strong>ONLY</strong> previously contacted communities</span>)}
                                    {parseInt(selected_campaign.contact_mode) === CAMPAIGN_CONTACT_MODE_ONLY_NOT_PREV_CONTACTED && (<span>Customer asked to contact <strong>ONLY NOT</strong> previously contacted communities</span>)}
                                </span>
                            </div>
                        )
                    }}/>

                <Nav className="nav-primary report-nav d-flex align-items-center" tabs>
                    <NavItem>
                        <Link to={`/campaigns/${selected_campaign.id}/validate`} className={"nav-link " + (this.props.activeTab === "validate" ? "active" : "")}>
                                <i className="icofont icofont-tick-mark" />Validation
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link to={`/campaigns/${selected_campaign.id}/emails`} className={"nav-link " + (this.props.activeTab === "emails" ? "active" : "")}>
                            <i className="icofont icofont-send-mail" />Emails
                        </Link>
                    </NavItem>
                </Nav>
                <Container fluid={true}>
                    <Row>
                        {this.props.children}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state, ownProps) => ({
    selected_campaign: state.campaign.selected.campaign,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignDetailsHeader)