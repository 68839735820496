import {
    CONNECT_DISCORD,
    CONNECT_SLACK,
    DISCONNECT_DISCORD,
    DISCONNECT_SLACK,
    SET_DISCORD_STATUS,
    SET_SLACK_STATUS
} from "../action_types";

export function connectSlack(accessToken, communityId) {
    return {
        type: CONNECT_SLACK,
        api: true,
        payload: {
            access_token: accessToken,
            community_id: communityId
        }
    }
}

export function disconnectSlack(communityId) {
    return {
        type: DISCONNECT_SLACK,
        api: true,
        payload: {
            community_id: communityId
        }
    }
}

export function setSlackStatus(status) {
    return {
        type: SET_SLACK_STATUS,
        payload: status
    }
}

export function connectDiscord(accessToken, communityId) {
    return {
        type: CONNECT_DISCORD,
        api: true,
        payload: {
            access_token: accessToken,
            community_id: communityId
        }
    }
}

export function setDiscordStatus(status) {
    return {
        type: SET_DISCORD_STATUS,
        payload: status
    }
}

export function disconnectDiscord(communityId) {
    return {
        type: DISCONNECT_DISCORD,
        api: true,
        payload: {
            community_id: communityId
        }
    }
}
