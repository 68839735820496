import React from 'react';
import {connect} from "react-redux";
import user_avatar from "../../../assets/images/user_avatar.png";
import TextFormatter from "../../utils/text_formatter";

class HumanMessage extends React.Component{

    userAndSenderIsTheSame(sender, user) {
        return sender.is_community === user.is_community && sender.is_company === user.is_company;
    }

    isImage(attachment) {
        return attachment.filename.match(/\.(?:png|jpg|jpeg|gif|pjpeg)/g) !== null
    }

    renderAttachment(message) {
        return this.isImage(message.attachment)
            ? (<img src={"https://api.thecommunityatlas.com"+message.attachment.url} alt={"attachment"} />)
            : (<a target="_blank" rel="noopener noreferrer" href={"https://api.thecommunityatlas.com"+message.attachment.url}><u>{message.attachment.filename}</u></a>)
    }

    render(){
        const {message, selectedChannel, currentUser, date} = this.props;

        var formatedDate = date.toLocaleTimeString("en-US", {hour: '2-digit', minute:'2-digit'});

        return(
            <li className={`message d-flex flex-column 
            ${this.userAndSenderIsTheSame(message.sender, currentUser) ? "my-message align-self-end" : "align-self-start"}`}>
                {!message.sender.is_community && (
                    <img className="rounded-circle chat-user-img img-30"
                     src={selectedChannel.company.logo ? selectedChannel.company.logo.url : user_avatar}/>)}
                {message.sender.is_community && (
                    <img className="rounded-circle chat-user-img img-30" alt={"profile-pic"}
                      src={selectedChannel.community.logo ? selectedChannel.community.logo.url : user_avatar}/>)}
                <div className="message-data text-left">{message.sender.full_name}, {formatedDate}</div>
                {message.content && ( <TextFormatter value={message.content} /> )}
                {message.attachment && this.renderAttachment(message)}
            </li>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    currentUser: state.user.data,
});

export default connect(
    mapStateToProps,
)(HumanMessage);