import {
    CLEAR_SELECTED_CHANNEL,
    CREATE_CHANNEL,
    LOAD_CHANNELS,
    LOAD_MESSAGES,
    INCREMENT_CHANNELS_PAGE,
    INCREMENT_MESSAGES_PAGE,
    PUBNUB_LOAD_MESSAGE,
    SEARCH_CHANNELS,
    SELECT_CURRENT_CHANNEL,
    SEND_MESSAGE,
    ADD_CHANNEL_TO_LIST_OF_CHANNELS,
    CONFIRM_CHANNEL,
    DECLINE_CHANNEL,
    MARK_CHANNEL_READ,
    SET_WINDOW_FOCUS,
} from "../action_types"
import notification from "../../component/utils/notification";
import {toast} from "react-toastify";

const initial_state = {
    create: {
        community_id: null,
        welcome_message: null,
        saving: false,
    },
    current_channel: {
        id: null,
        new_message: {
            content: "",
            file: null,
            sending: false,
        },
        loading: false
    },
    message_limit: 10,
    list: {
        channels: {},
        loading: false,
        page: 0,
        limit: 20,
        load_more: false,
    },
    search_channels: {
        list: [],
        searching: false,
        param: "",
    },
    window: {
        focused: false,
    }
};

export default (state = initial_state, action) => {
    var create;
    var list;
    var selectedChannel;
    var currentChannel;
    var channels;
    var search;
    var message;
    var data;
    const messageElement = document.getElementById("channel-history");

    function scrollToBottom() {
        if(messageElement){
            const scrollHeight = messageElement.scrollHeight;
            const height = messageElement.clientHeight;
            const maxScrollTop = scrollHeight - height;
            messageElement.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }

    switch(action.type) {
        case CREATE_CHANNEL:
            create = Object.assign({}, state.create);
            create.saving = true;
            create.community_id = action.payload.community_id;
            create.welcome_message = action.payload.welcome_message;

            return {...state, create: create};
        case CREATE_CHANNEL.concat("_SUCCESS"):
            toast.success("Invitation send with success!");
            create = Object.assign({}, state.create);
            create.saving = false;
            create.community_id = null;
            create.welcome_message = null;

            return {...state, create: create};
        case LOAD_CHANNELS:
            list = Object.assign({}, state.list);
            list.loading = true;

            return {...state, list: list};
        case LOAD_CHANNELS.concat("_SUCCESS"):
            list = Object.assign({}, state.list);
            currentChannel = Object.assign({}, state.current_channel);

            if(action.payload.data[currentChannel.id]){
                delete(action.payload.data[currentChannel.id]);
            }

            list.channels = {...list.channels, ...action.payload.data};
            list.loading = false;

            list.load_more = Object.entries(action.payload.data).length < list.limit;

            return {...state,
                list: list
            };
        case LOAD_CHANNELS.concat("_FAILURE"):
            list = Object.assign({}, state.list);
            list.loading = false;

            return {...state, list: list};
        case CONFIRM_CHANNEL:
            list = Object.assign({}, state.list);
            list.loading = true;

            return {...state, list: list};
        case CONFIRM_CHANNEL.concat("_SUCCESS"):
            list = Object.assign({}, state.list);
            currentChannel = Object.assign({}, state.current_channel);
            channels = Object.assign({}, state.list.channels);
            data = action.payload.data;

            channels[currentChannel.id].can_write_messages = data.can_write_messages;
            channels[currentChannel.id].can_confirm_channel = data.can_confirm_channel;
            channels[currentChannel.id].status = data.status;
            channels[currentChannel.id].updated_at = data.updated_at;

            list.loading = false;

            return {...state, 
                list: {
                ...state.list,
                loading: list.loading,
                channels: channels
                }
            }
        case CONFIRM_CHANNEL.concat("_FAILURE"):
            list = Object.assign({}, state.list);
            list.loading = false;
            
            return {...state, list: list};
        case DECLINE_CHANNEL:
            list = Object.assign({}, state.list);
            list.loading = true;

            return {...state, list: list};
        case DECLINE_CHANNEL.concat("_SUCCESS"):
            list = Object.assign({}, state.list);
            channels = Object.assign({}, state.list.channels);
            currentChannel = Object.assign({}, state.current_channel);
            data = action.payload.data;

            channels[currentChannel.id].can_write_messages = data.can_write_messages;
            channels[currentChannel.id].can_confirm_channel = data.can_confirm_channel;
            channels[currentChannel.id].status = data.status;
            channels[currentChannel.id].updated_at = data.updated_at;

            list.loading = false;

            return {...state, 
                list: {
                ...state.list,
                loading: list.loading,
                channels: channels
                }
            }
        case DECLINE_CHANNEL.concat("_FAILURE"):
            list = Object.assign({}, state.list);
            list.loading = false;

            return {...state, list: list};
        case INCREMENT_CHANNELS_PAGE:
            list = Object.assign({}, state.list);
            list.page = list.page + 1;

            return {...state, list: list};
        case SEARCH_CHANNELS:
            search = Object.assign({}, state.search_channels);
            search.searching = true;
            search.param = action.payload;

            return {...state,
                search_channels: search,
            };
        case SEARCH_CHANNELS.concat("_SUCCESS"):
            search = Object.assign({}, state.search_channels);
            search.searching = false;
            search.list = action.payload.data;

            return {...state,
                search_channels: search,
            };
        case SEARCH_CHANNELS.concat("_FAILURE"):
            search = Object.assign({}, state.search_channels);
            search.searching = false;

            return {...state,
                search_channels: search,
            };
        case ADD_CHANNEL_TO_LIST_OF_CHANNELS:
            list = Object.assign({}, state.list);
            list.channels = {...list.channels, ...action.payload};

            return {...state,
                list: list
            };
        case LOAD_MESSAGES:
            currentChannel = Object.assign({}, state.current_channel);
            currentChannel.loading = true;

            return {...state, current_channel: currentChannel};
        case LOAD_MESSAGES.concat("_SUCCESS"):
            currentChannel = Object.assign({}, state.current_channel);
            channels = Object.assign({}, state.list.channels);

            channels[currentChannel.id].messages = action.payload.data.concat(channels[currentChannel.id].messages);
            currentChannel.loading = false;

            channels[currentChannel.id].load_more = action.payload.data.length === state.message_limit;

            if(channels[currentChannel.id].messages_page === 0){
                setTimeout(scrollToBottom, 20)
            }

            return {...state,
                current_channel: currentChannel,
                list: {
                    ...state.list,
                    channels: channels
                }
            };
        case LOAD_MESSAGES.concat("_FAILURE"):
            currentChannel = Object.assign({}, state.current_channel);
            currentChannel.loading = false;

            return {...state, current_channel: currentChannel};
        case INCREMENT_MESSAGES_PAGE:
            channels = Object.assign({}, state.list.channels);
            channels[state.current_channel.id].messages_page += 1;

            return {...state,
                list: {
                    ...state.list,
                    channels: channels
                }
            };
        case SEND_MESSAGE:
            message = Object.assign({}, state.current_channel.new_message);
            message.saving = true;
            message.content = action.payload.content;
            message.attachment = action.payload.attachment;

            return {...state,
                current_channel: {
                    ...state.current_channel,
                    new_message: message,
                }
            };
        case SEND_MESSAGE.concat("_SUCCESS"):
            message = Object.assign({}, state.current_channel.new_message);
            message.saving = false;
            message.content = "";
            message.attachment = null;

            return {...state,
                current_channel: {
                    ...state.current_channel,
                    new_message: message,
                }
            };
        case SEND_MESSAGE.concat("_FAILURE"):
            message = Object.assign({}, state.current_channel.new_message);
            message.saving = false;
            message.content = "";
            message.attachment = null;

            return {...state,
                current_channel: {
                    ...state.current_channel,
                    new_message: message,
                }
            };
        case PUBNUB_LOAD_MESSAGE:
            channels = Object.assign({}, state.list.channels);
            var payLoadMessage = action.payload.message;
            var currentUser = action.payload.current_user;

            var channel = payLoadMessage.channel;
            var message = payLoadMessage.message;

            if(!channels[channel.id]){
                channels[channel.id] = channel;
            }

            const currentCopyOfChannelObject = channels[channel.id];

            const updatedChannelObject = {
                ...currentCopyOfChannelObject,
                status: channel.status,
                messages: currentCopyOfChannelObject.messages.concat(message),
                updated_at: channel.updated_at,
                can_write_messages: channel.can_write_messages,
                can_confirm_channel: channel.can_confirm_channel,
            };

            if(state.current_channel.id !== channel.id || !state.window.focused){
                updatedChannelObject.total_unread_messages++
            }

            channels[channel.id] = updatedChannelObject;

            setTimeout(scrollToBottom, 20);

            if(!state.window.focused){
                // human message
                if(message.type === 1){
                    if(currentUser.id !== message.sender.id){
                        notification.create(message.sender.full_name, message.content);
                    }
                } else {
                    // system message
                    notification.create("The Community Atlas", "Status update");
                }
            }

            return {...state,
                list: {
                    ...state.list,
                    channels: channels
                }
            };
        case CLEAR_SELECTED_CHANNEL:
            selectedChannel = Object.assign({}, state.current_channel);
            selectedChannel.loading = false;
            selectedChannel.id = null;

            return {...state, current_channel: selectedChannel};
        case SELECT_CURRENT_CHANNEL:
            selectedChannel = Object.assign({}, state.current_channel);
            selectedChannel.id = action.payload;
            selectedChannel.new_message = {
                content: "",
                file: null,
                sending: false,
            }
            setTimeout(scrollToBottom, 20);
            return {...state, current_channel: selectedChannel};
        case MARK_CHANNEL_READ:
            list = Object.assign({}, state.list);
            list.loading = true;

            return{...state, list: list};
        case MARK_CHANNEL_READ.concat("_SUCCESS"):
            channels = Object.assign({}, state.list.channels);
            list = Object.assign({}, state.list);
            list.loading = false;
            channels[state.current_channel.id].total_unread_messages = 0;
            
            return {...state,
                list: {
                    ...state.list,
                    loading: list.loading,
                    channels: channels
                }
            };
        case MARK_CHANNEL_READ.concat("_FAILURE"):
            channels = Object.assign({}, state.list.channels);
            channels[state.current_channel.id].loading = false;

            return {...state, list: list};
        case SET_WINDOW_FOCUS:
            return {...state, window: {focused: action.payload}};
        default:
            return {...state};
    }
}
