import React from 'react';
import {Badge} from 'reactstrap'
import {connect} from "react-redux";

class MainCategory extends React.Component{
    getMainCategoryName() {
        const { categoryList, community } = this.props;
        let categoryName = null;

        categoryList.forEach((category) => {
            if(category.id == community.category_id){
                categoryName = category.name;
            }
        });

        return categoryName;
    }

    getStyle() {
        if(this.props.withMargins){
            return {
                marginTop: "2px",
                marginBottom: "5px",
                whiteSpace: "normal"
            }
        }
        
        return {};
    }

    render() {
        var mainCategoryName = this.getMainCategoryName();

        if(!mainCategoryName){
            return null;
        }

        if(this.props.justText){
            return <span>#{mainCategoryName}</span>;
        }

        return (<Badge color={'primary'} className={"text-white"} style={this.getStyle()}>#{mainCategoryName}</Badge>);
    }
}

const mapStateToProps = (state, ownProps) => ({
    categoryList: state.category.list
});

export default connect(
    mapStateToProps,
    null
)(MainCategory)
