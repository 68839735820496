import React from "react";
import {Badge, Label} from "reactstrap";
import {connect} from "react-redux";
import {
    searchCompaniesToSearchApplicants,
    selectCompanyToSearchApplicants,
    unselectCompanyToSearchApplicants
} from "../../../redux/job_applicants/actions";
import {withRouter} from "react-router";
import TypeaheadComponent from "../../common/typeahead_component";

class CompaniesFilterForApplicants extends React.Component {
    buildListOfSelectedCompanies() {
        const {filters, onCompanyUnselected, unselectCompanyToSearchApplicants} = this.props;
        const {selected_companies} = filters;

        return selected_companies.map((company, index) => {
            return (
                <Badge className={"pointer"} color={"info"} key={"filter-company-for-applicant-" + company.id} onClick={() => {
                    unselectCompanyToSearchApplicants(index);
                    onCompanyUnselected(company);
                }}>{company.name} x</Badge>
            );
        })
    }

    render() {
        const {filters, searchCompaniesToSearchApplicants, onCompanySelected, selectCompanyToSearchApplicants} = this.props;
        const {searching, options} = filters;
        
        return (
            <div>
                <Label className="d-block">
                    Company:
                </Label>

                <div className="faq-form m-b-10">
                    <TypeaheadComponent
                        id={"search-companies-for-applicants"}
                        labelKey={"name"}
                        searching={searching}
                        onSearch={searchCompaniesToSearchApplicants}
                        options={options}
                        onSelect={(selectedCompany) => {
                            selectCompanyToSearchApplicants(selectedCompany);
                            onCompanySelected(selectedCompany);
                        }}
                    />
                </div>

                <div className="m-b-10">
                    {this.buildListOfSelectedCompanies()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    filters: state.job_applicants.filters.company
});

const mapDispatchToProps = {
    searchCompaniesToSearchApplicants,
    selectCompanyToSearchApplicants,
    unselectCompanyToSearchApplicants
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CompaniesFilterForApplicants));