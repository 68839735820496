import React from "react";
import {Camera} from "react-feather";

function ImagePreview(props) {
    const logoUrl = props.logo ? props.logo.url : null;

    return (
        <div className={"image-preview-container"} style={{"backgroundImage": "url("+logoUrl+")", backgroundColor: logoUrl && "white"}}>
            <Camera />
        </div>
    );
}

export default ImagePreview;
