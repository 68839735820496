import {CONNECT_SLACK, CONNECT_DISCORD, SET_SLACK_STATUS, SET_DISCORD_STATUS} from "../action_types";
import {OAUTH_STATUS_CONNECTED, OAUTH_STATUS_CONNECTING, OAUTH_STATUS_ERROR} from "../../constants";

const initial_state = {
    slack: {
        status: null
    },
    discord: {
        status: null
    }
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case CONNECT_SLACK:
            return {...state, slack: {status: OAUTH_STATUS_CONNECTING}};
        case SET_SLACK_STATUS:
            return {...state, slack: {status: action.payload}};
        case CONNECT_SLACK.concat("_SUCCESS"):
            return {...state, slack: {status: OAUTH_STATUS_CONNECTED}};
        case CONNECT_SLACK.concat("_FAILURE"):
            return {...state, slack: {status: OAUTH_STATUS_ERROR}};
        case SET_DISCORD_STATUS:
            return {...state, discord: {status: action.payload}};
        case CONNECT_DISCORD:
            return {...state, discord: {status: OAUTH_STATUS_CONNECTING}};
        case CONNECT_DISCORD.concat("_SUCCESS"):
            return {...state, discord: {status: OAUTH_STATUS_CONNECTED}};
        case CONNECT_DISCORD.concat("_FAILURE"):
            return {...state, discord: {status: OAUTH_STATUS_ERROR}};
        default:
            return {...state};
    }
}
